import React from "react";
import { Link } from "react-router-dom";
import SearchInput from "../../../Common/SearchInput/SearchInput";
import FilterDropdown from "../../../Common/FilterDropdown/FilterDropdown";
import DateRangePicker from "../../../Common/DateRangePicker/DateRangePicker";
import { appActions } from "../../../../redux/actions/appActions";
import { connect } from "react-redux";
import { debounce } from "debounce";
import "./Payouts.css";
import { utils } from "../../../../helpers/utils";
import { CSVLink, CSVDownload } from "react-csv";
import { adminSettingsActions } from "../../../../redux/actions/adminSettingsActions";
import { useState } from "react";
import { addDays } from "date-fns";
import { applicationService } from "../../../../services/applicationService";
import LoaderComponent from "../../../Common/ContainerLoader/ContainerLoader";
import SkeletonLoading from "../../../Common/SkeletonLoading/SkeletonLoading";
import Drawer from "../../../Common/Drawer/Drawer";
import CheckBox from "../../../Common/CheckBox/CheckBox";
import { ListItemText } from "@material-ui/core";
import Button from "../../../Common/Button/Button";
import FilterVector from "../../../../images/FilterVector.svg";

const IPNStatusColor = {
  "IPN Pending": "pending",
  "IPN Generated": "generated",
  "Money Received": "received",
  "Spot Check Completed": "spot-check-completed",
  "PINs Generated": "transfered",
  "Payout Initiated via Plooto": "initiated",
  "Payout Initiated": "initiated",
  "EFT Completed": "initiated",
  "PVRR Approved": "pvrr-approved",
};

class Payouts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedApplications: {},
      datePickerOpen: false,
      selectedApplicationsIncentiveTotal: 0.0,
      allAppsSelected: false,
      incentiveCapApprovalExceeded: false,
      ipnContextOpen: {},
      ipnContactExport: {},
      cibcTransactionExport: {},
      ipnChequeExport: {},
      ipnBankDraftExport: {},
      openDrawer: false,
      dateRange: {},
      filters: [],
    };
    this.searchDebouncedData = debounce(this.searchData, 300);
  }
  setIpnContextOpen = (id, enableAdvanceExport, enableCIBCExport, enableChequeExport, enableBankDraftExport) => {
    let { ipnContextOpen, ipnContactExport, cibcTransactionExport, ipnChequeExport, ipnBankDraftExport } = this.state;
    let updateObj = {};
    let ipnContextOpen1 = {};
    if (ipnContextOpen[id]) {
      delete ipnContextOpen[id];
    } else {
      ipnContextOpen1[id] = true;
      if (enableAdvanceExport && utils.isNullOrEmpty(ipnContactExport[id])) {
        applicationService.getIPNForContactsExport(id).then((data) => {
          ipnContactExport[id] = data;
          this.setState({
            ipnContactExport,
          });
        });
      }
      if (enableCIBCExport && utils.isNullOrEmpty(cibcTransactionExport[id])) {
        applicationService.getCIBCTransactionExport(id).then((cibcData) => {
          cibcTransactionExport[id] = cibcData;
          this.setState({
            cibcTransactionExport,
          });
        });
      }
      if (enableChequeExport && utils.isNullOrEmpty(ipnChequeExport[id])) {
        applicationService.getChequeExport(id).then((data) => {
          ipnChequeExport[id] = data;
          this.setState({
            ipnChequeExport,
          });
        });
      }
      if (enableBankDraftExport && utils.isNullOrEmpty(ipnBankDraftExport[id])) {
        applicationService.getBankDraftExport(id).then((data) => {
          ipnBankDraftExport[id] = data;
          this.setState({
            ipnBankDraftExport,
          });
        });
      }
    }
    updateObj["ipnContextOpen"] = ipnContextOpen1;
    this.setState(updateObj);
  };

  componentDidMount() {
    this.props.loadAdminConfig();
    this.searchData(true);
  }
  onStatusFilterChange = (SelectedStatusFilters) => {
    this.props.setFilters("Payout", {
      SelectedStatusFilters,
    });
  };
  onSearch = (searchInput) => {
    this.props.setFilters("Payout", {
      searchInput,
    });
  };
  onDateChange = (dateRange) => {
    console.log('event', dateRange)
    this.setState({ dateRange });

    // this.props.setFilters("Payout", {
    //   dateRange,
    // });
  };

  searchData = (refresh = false) => {
    const { sortConfig, currentProgram, SelectedStatusFilters } = this.props;
    let config = { statuses: SelectedStatusFilters, sort: sortConfig, dateRange: this.state.dateRange, program: currentProgram };
    this.props.getIPNSByStatus(this.props.searchInput, config, refresh ? 0 : this.props.IPNListOffset);
  };

  handleListScroll = () => {
    if (this.props.moreIPNS) {
      this.searchDebouncedData();
    }
  };
  sortData = (property) => {
    this.props.setFilters("Payout", {
      sortConfig: { name: property, direction: this.props.sortConfig.direction === "desc" ? "asc" : "desc" },
    });
  };
  handleDateFilterChange = (ranges) => {
    this.props.setFilters("Payout", {
      SelectedDateRange: { startDate: ranges.range1.startDate, endDate: ranges.range1.endDate },
    });
  };

  toggleDatePicker = () => {
    this.setState({
      datePickerOpen: !this.state.datePickerOpen,
    });
  };
  customeArray = (value) => {
    const isExist = this.state.filters?.indexOf(value) > -1;
    if (isExist) {
      this.setState({ filters: this.state.filters.filter((i) => i !== value) });
    } else {
      this.setState({ filters: [...this.state.filters, value] });
    }
  };
  clearFilters = () => {
    this.props.setFilters("Payout", {
      dateRange: [],
      SelectedStatusFilters: [],
    });
    this.setState({
      dateRange: {},
      filters: [],
    });
  };

  applyFiters = () => {


    if (Object.keys(this.state.dateRange).length > 0) {
      this.props.setFilters("Payout", {
        dateRange: this.state.dateRange,
        SelectedStatusFilters: this.state.filters,
      });
      this.setState({ openDrawer: false });

    } else {
      this.props.setFilters("Payout", {
        // dateRange: this.state.dateRange,
        SelectedStatusFilters: this.state.filters,
      });
      this.setState({ openDrawer: false });
    }
  };

  toggleDrawer = () => {
    this.setState({ openDrawer: false });
  };

  render() {
    const { IPNList, IPNSByStatusLoading, configData, SelectedStatusFilters, SelectedDateRange, adminUserInfo, currentProgram } =
      this.props;
    const { ipnContextOpen, ipnContactExport, cibcTransactionExport, datePickerOpen, ipnChequeExport, ipnBankDraftExport } = this.state;
    let actions = {};
    if (adminUserInfo && adminUserInfo.permissions) {
      actions = adminUserInfo.permissions[currentProgram].actions;
    }
    let FilterOptions = [];
    if (configData && configData.ipnStatuses) {
      FilterOptions = configData.ipnStatuses["fields"].map((item) => {
        return { value: item.status, icon: <div class="icon-circle" style={{ backgroundColor: item.colorCode }}></div> };
      });
    }
    return (
      <div className="payout-tab">
        <div className="payout-top-bar">
          <div className="payout-search">
            <SearchInput value={this.props.searchInput} placeholder="Search for IPNs" onClick={this.onSearch} />
          </div>
          <div className="applications-top-bar-filter-btn">
            <Button
              className="filter-btn incent-Btn"
              uppercase
              title={"Filters"}
              svg={FilterVector}
              onClick={() => this.setState({ openDrawer: true })}
            />
          </div>
          <Drawer toggleDrawer={this.toggleDrawer} openDrawer={this.state.openDrawer} className={"program-panel-drawer"}>
            <div className="filter-drawer" style={{ width: "90%" }}>
              <h3>FILTER BY</h3>
              <DateRangePicker onChange={this.onDateChange} />
              <label className="filter-label-new filter-label ">Filter By Status</label>
              <div className="fiar-filter">
                {FilterOptions?.map((option) => (
                  <div
                    className={`filter-option ${this.props.className ? this.props.className : ""}`}
                    key={option.value}
                    onClick={() => this.customeArray(option.value)}
                  >
                    <CheckBox checked={this.state.filters?.indexOf(option.value) > -1} />
                    {option.icon}
                    <ListItemText primary={option.value} />
                  </div>
                ))}
              </div>
            </div>
            <Button className="inversed-btn drawer-btn first-btn" title="CLEAR ALL FILTERS" onClick={this.clearFilters} />
            <Button className="drawer-btn" title="APPLY FILTERS" onClick={this.applyFiters} />
          </Drawer>
        </div>
        <div className="payout-card-container" onScroll={this.handleListScroll} >
          {IPNSByStatusLoading && this.props.IPNListOffset === undefined ? (
            <SkeletonLoading skeletonFor="cards" />
          ) : IPNList && IPNList.length ? (
            IPNList.map((ipn, i) => {
              let enableReceived = false,
                enableTransferred = false,
                enableInitiated = false,
                enableSpotCheck = false,
                enableEFT = false,
                enablePVRR = false;
              if (ipn.status === "IPN Generated") {
                enableReceived = true;
                enableTransferred = false;
                enableInitiated = false;
                enableSpotCheck = false;
                enableEFT = false;
              } else if (ipn.status === "Money Received") {
                enableReceived = false;
                enableTransferred = false;
                enableInitiated = false;
                enableSpotCheck = true;
                enableEFT = false;
              } else if (ipn.status === "Spot Check Completed") {
                enableReceived = false;
                enableTransferred = false;
                enableEFT = true;
                enableInitiated = false;
                enableSpotCheck = false;
              } else if (ipn.status === "Payout Initiated via Plooto") {
                enableReceived = false;
                enableTransferred = false;
                enableInitiated = false;
                enableSpotCheck = false;
                enableEFT = false;
              } else if (ipn.status === "EFT Completed") {
                enableReceived = false;
                enableSpotCheck = false;
                enableEFT = false;
                enablePVRR = true;
              } else if (ipn.status === "Payout Initiated") {
                enablePVRR = true;
              }

              // Add Logic Based on Permissions
              if (utils.isNullOrEmpty(actions["Payout Board-Mark as 'Money Received'"])) {
                enableReceived = false;
              }
              if (utils.isNullOrEmpty(actions["Payout Board-Generate PIN"])) {
                enableTransferred = false;
              }
              if (utils.isNullOrEmpty(actions["Payout Board-Mark as 'Payout Initated via Plooto'"])) {
                enableInitiated = false;
              }
              if (utils.isNullOrEmpty(actions["Payout Board-Mark as 'Payout Initated'"])) {
                enableInitiated = false;
              }
              if (utils.isNullOrEmpty(actions["Payout Board-Mark as 'EFT Completed'"])) {
                enableEFT = false;
              }
              if (utils.isNullOrEmpty(actions["Payout Board-Mark as 'PVRR Approved'"])) {
                enablePVRR = false;
              }
              let enableCIBCExport = ipn.status === "Spot Check Completed" || ipn.status === "Money Received";
              let enableChequeExport = ipn.status === "Spot Check Completed" || ipn.status === "Money Received";
              let enableBankDraftExport = ipn.status === "Spot Check Completed" || ipn.status === "Money Received";
              let enableAdvanceExport = ipn.status === "Payout Initiated via Plooto" || ipn.status === "PINs Generated";
              let showChequeExport = ipnChequeExport[ipn.id] && ipnChequeExport[ipn.id].length > 0;
              let showBankDraftExport = ipnBankDraftExport[ipn.id] && ipnBankDraftExport[ipn.id].length > 0;
              let showContactsExport = ipnContactExport[ipn.id];
              let showCIBCExport = cibcTransactionExport[ipn.id] && cibcTransactionExport[ipn.id].length > 0;

              return (
                <div className="card">
                  <div className="header">
                    <div className="ipn-details">
                      <div className="ipnno">
                        {ipn.ipnNo}
                        <div title={ipn.status} className={`status ${IPNStatusColor[ipn.status]}`}></div>
                      </div>
                      <div className="info">
                        <div className="date">{utils.formatAmericanDate(ipn.createdAt)}</div>
                        <div className="creator">{ipn.approver}</div>
                      </div>
                    </div>
                    <div
                      className="ipn-action"
                      onClick={() =>
                        this.setIpnContextOpen(ipn.id, enableAdvanceExport, enableCIBCExport, enableChequeExport, enableBankDraftExport)
                      }
                    >
                      <div className={`expand-ipn-menu ${ipnContextOpen[ipn.id] ? "ipn-menu-open" : ""}`}>
                        <div
                          className={`expanded-menu-panel${enableAdvanceExport && !showContactsExport ? " expanded-menu-panel-loading" : ""
                            }`}
                        >
                          {actions["Payout Board-View as PDF"] ? (
                            <Link to={`viewipn/${ipn.id}`} target="_blank" rel="noopener noreferrer">
                              <div>View as PDF</div>
                            </Link>
                          ) : (
                            ""
                          )}
                          {actions["Payout Board-Export IPN"] ? (
                            <CSVLink
                              filename={`${ipn.ipnNo}.csv`}
                              headers={[
                                { label: "IPN Number", key: "ipnNo" },
                                { label: "Payout Approved Date", key: "payoutApprovedDate" },
                                { label: "Application ID", key: "appNo" },
                                { label: "Payee Name", key: "payeeName" },
                                { label: "Payment Mode", key: "paymentMode" },
                                { label: "Applicant Name", key: "customerName" },
                                { label: "Contractor Name", key: "contractorName" },
                                { label: "Approved Incentive", key: "incentive" },
                                { label: "Total Project Cost", key: "projectCost" },
                                { label: "GHG Savings (Tonnes)", key: "emissionsReduction" },
                              ]}
                              data={utils.buildIPNExport(ipn)}
                            >
                              <div>IPN Export</div>
                            </CSVLink>
                          ) : (
                            ""
                          )}
                          {/* { 
                        (enableAdvanceExport && showContactsExport) ? <React.Fragment>
                        {(ipnContactExport[ipn.id] && actions['Payout Board-Export Contacts'] ) ? <CSVLink 
                          filename={`${ipn.ipnNo}-Contacts.csv`} 
                          headers={ [
                            { label : 'Contact', key : 'payeeName'},
                            { label : 'Contact Email', key : 'payeeEmail'},
                            { label : 'Contact Name', key : 'payeeContactName'},
                            { label : 'Contact Bank Country', key : 'bankCountry'},
                            { label : 'Account Currency', key : 'currency'},
                            { label : 'Security Question', key : 'securityQuestion'},
                            { label : 'Security Answer', key : 'paymentPin'},
                            { label : 'Confirm Security Answer', key : 'paymentPin'}
                        ]}
                          data={ipnContactExport[ipn.id]}
                        ><div>Contacts Export</div></CSVLink> : ''}
                        {actions['Payout Board-Export Mass Pay'] ? <CSVLink 
                          filename={`${ipn.ipnNo}-Mass_Pay.csv`} 
                          headers={ [
                            { label : 'Memo', key : 'memo'},
                            { label : 'Contact', key : 'payeeName'},
                            { label : 'Amount', key : 'incentive'}
                          ]}
                          data={utils.buildMassPayExport(ipn)}
                        ><div>Export for Mass Pay</div></CSVLink> : ''}
                        </React.Fragment> : ''
                      } */}

                          {showCIBCExport ? (
                            <React.Fragment>
                              {actions["Payout Board-Export IPN"] ? (
                                <CSVLink
                                  filename={`${ipn.ipnNo}-EFT.csv`}
                                  headers={[
                                    { label: "Transaction Type", key: "transactionType" },
                                    { label: "Beneficiary name", key: "nameOnBankAccount" },
                                    { label: "Beneficiary Bank ID and Transit", key: "payeeRoutingNumber" },
                                    { label: "Beneficiary account", key: "payeeAccountNumber" },
                                    { label: "Amount", key: "postProjectReviewerApprovedIncentive" },
                                    { label: "Receiver ID", key: "appNo" },
                                    { label: "Originator full name", key: "originatorFullName" },
                                    { label: "Originator short name", key: "originatorShortName" },
                                    { label: "Return account", key: "returnAccount" },
                                    { label: "Transaction code", key: "transactionCode" },
                                    { label: "Sundry information", key: "sundryInformation" },
                                  ]}
                                  data={cibcTransactionExport[ipn.id]}
                                >
                                  <div>EFT Export</div>
                                </CSVLink>
                              ) : (
                                ""
                              )}
                            </React.Fragment>
                          ) : (
                            ""
                          )}

                          {showChequeExport ? (
                            <React.Fragment>
                              {actions["Payout Board-Cheque Export"] ? (
                                <CSVLink
                                  filename={`${ipn.ipnNo}-Cheque.csv`}
                                  headers={[
                                    { label: "Application ID", key: "appId" },
                                    { label: "Payee Name", key: "payeeCompanyName" },
                                    { label: "Amount", key: "postProjectReviewerApprovedIncentive" },
                                    { label: "Payee Mailing Address", key: "payeeMailingAddress" },
                                    { label: "Memo", key: "memo" },
                                  ]}
                                  data={ipnChequeExport[ipn.id]}
                                >
                                  <div>Cheque Export</div>
                                </CSVLink>
                              ) : (
                                ""
                              )}
                            </React.Fragment>
                          ) : (
                            ""
                          )}

                          {showBankDraftExport ? (
                            <React.Fragment>
                              {actions["Payout Board-Bank Draft Export"] ? (
                                <CSVLink
                                  filename={`${ipn.ipnNo}-BankDraft.csv`}
                                  headers={[
                                    { label: "Application ID", key: "appId" },
                                    { label: "Payee Name", key: "payeeCompanyName" },
                                    { label: "Amount", key: "postProjectReviewerApprovedIncentive" },
                                    { label: "Payee Mailing Address", key: "payeeMailingAddress" },
                                    { label: "Memo", key: "memo" },
                                  ]}
                                  data={ipnBankDraftExport[ipn.id]}
                                >
                                  <div>Bank Draft Export</div>
                                </CSVLink>
                              ) : (
                                ""
                              )}
                            </React.Fragment>
                          ) : (
                            ""
                          )}
                          {actions["Payout Board-PVRR Details"] && ipn.status === "PVRR Approved" ? (
                            <Link>
                              <div
                                onClick={() =>
                                  this.props.showActionModal({
                                    id: ipn.id,
                                    ipnNo: ipn.ipnNo,
                                    ipn: ipn,
                                    pvrrActionNote: ipn.pvrrActionNote ? ipn.pvrrActionNote : "",
                                    finalPVRRFile: utils.isNullOrEmpty(ipn.finalPVRRFile) ? "" : ipn.finalPVRRFile,
                                    pvrrApprovalDate: ipn.pvrrApprovalDate ? ipn.pvrrApprovalDate : "",
                                    pvrrApproverName: ipn.pvrrApproverName ? ipn.pvrrApproverName : "",
                                    incentiveTotal: ipn.incentiveTotal,
                                    ipnApplications: ipn.applications,
                                    modalType: "pvrr",
                                    ipnStatusChangeactionType: "pvrr-approved",
                                    modalEdit: false,
                                  })
                                }
                              >
                                View PVRR Details
                              </div>
                            </Link>
                          ) : (
                            ""
                          )}
                          {actions["Payout Board-Spot-Check Details"] && ipn.status === "Spot Check Completed" ? (
                            <Link>
                              <div
                                onClick={() =>
                                  this.props.showActionModal({
                                    id: ipn.id,
                                    ipn: ipn,
                                    drawer: false,
                                    modalType: "spot-check",
                                    ipnStatusChangeactionType: "spot-check",
                                  })
                                }
                              >
                                Spot-Check Details
                              </div>
                            </Link>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="apps-list">
                    {ipn?.applications?.length > 0
                      ? utils.getTopThree(ipn.applications).map((app, i) => (
                        <div className="app">
                          <div className="column name" title={app["payeeName"]}>
                            {app["payeeName"]}
                          </div>
                          <div className="column">{app["appNo"]}</div>
                          <div className="column right">{utils.formatAsCAD(app["incentive"])}</div>
                        </div>
                      ))
                      : ""}
                  </div>
                  <div className="footer">
                    <div className="morelink" onClick={() => this.props.showActionModal({ ipn: ipn, modalType: "view-ipn-apps" })}>
                      SHOW All ({ipn.applications ? Object.keys(ipn.applications).length : "0"})
                    </div>
                    <div className="incentive-total">{utils.formatAsCAD(ipn.incentiveTotal)}</div>
                  </div>
                  <div className="action-bar">
                    <div
                      class={`column received${enableReceived ? "" : " disabledButton"}`}
                      title="Mark as 'Money Received'"
                      onClick={() =>
                        this.props.showActionModal({ id: ipn.id, modalType: "update-ipn", ipnStatusChangeactionType: "money-received" })
                      }
                    ></div>
                    <div
                      class={`column spot-check${enableSpotCheck ? "" : " disabledButton"}`}
                      title="Mark as 'Spot Check Completed'"
                      onClick={() =>
                        this.props.showActionModal({
                          id: ipn.id,
                          ipn: ipn,
                          drawer: true,
                          modalType: "spot-check",
                          ipnStatusChangeactionType: "spot-check",
                        })
                      }
                    ></div>
                    {/* <div class={`column initiated${enableInitiated ? '' : ' disabledButton'}`}  title="Mark as 'Payout Initiated'"  onClick={() => this.props.showActionModal({id:ipn.id, modalType: "update-ipn",ipnStatusChangeactionType:'payout-initiated' })}></div> */}
                    <div
                      class={`column initiated${enableEFT ? "" : " disabledButton"}`}
                      title="Mark as 'EFT Completed'"
                      onClick={() =>
                        this.props.showActionModal({ id: ipn.id, modalType: "update-ipn", ipnStatusChangeactionType: "eft-completed" })
                      }
                    ></div>
                    <div
                      class={`column pvrr-approved${enablePVRR ? "" : " disabledButton"}`}
                      title="Mark as PVRR Approved"
                      onClick={() =>
                        this.props.showActionModal({
                          id: ipn.id,
                          ipnNo: ipn.ipnNo,
                          incentiveTotal: ipn.incentiveTotal,
                          ipnApplications: ipn.applications,
                          modalType: "pvrr",
                          ipnStatusChangeactionType: "pvrr-approved",
                          modalEdit: true,
                        })
                      }
                    ></div>
                  </div>
                </div>
              );
            })
          ) : (
            <div className="no-records-found">No records.</div>
          )}
        </div>
      </div>
    );
  }
}

function mapState(state) {
  const { configData } = state.settings;
  const { adminUserInfo, currentProgram } = state.authentication;
  const { IPNList, IPNListOffset, IPNSByStatusLoading, moreIPNS } = state.application;
  return { currentProgram, adminUserInfo, IPNList, IPNListOffset, IPNSByStatusLoading, moreIPNS, configData };
}

const actionCreators = {
  loadAdminConfig: adminSettingsActions.getAdminConfig,
  getIPNSByStatus: appActions.getIPNSByStatus,
};

export default connect(mapState, actionCreators)(Payouts);

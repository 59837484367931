
import AdminHeader from './AdminHeader/AdminHeader';
import AdminFooter from './AdminFooter/AdminFooter';
import AdminSidebarMenu from './AdminSideBarMenu/AdminSideBarMenu'
import React from 'react'
import '../Pages/AdminDashboard/AdminCommon.css'

function AdminLayout(props) {
   let showHeader = true, showFooter = true, showSideBarMenu = true;
   if (typeof props.showHeader !== 'undefined') { showHeader = props.showHeader; }
   if (typeof props.showFooter !== 'undefined') { showFooter = props.showFooter; }
   if (typeof props.showSideBarMenu !== 'undefined') { showSideBarMenu = props.showSideBarMenu; }

   return (
      <React.Fragment>
         {showHeader ? <AdminHeader history={props.history} /> : null}
         <div className='admin-dashboard-container' id='AdminDashboard'>
            {showSideBarMenu ? <AdminSidebarMenu history={props.history} /> : null}
            <div className="admin-dashboard">
               {props.children}
            </div>
         </div>
         {showFooter ? <AdminFooter /> : null}
      </React.Fragment>
   );
}

export default AdminLayout;
import React from "react";
import "./IncentivePaymentNote.css";
import { connect } from "react-redux";
import { utils } from "../../../../helpers/utils";
import Table from '../../../Common/Table/Table';
class IncentivePaymentNote extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  async componentDidMount() {}
  render() {
    const { ipnData } = this.props;
    let ipnApplications=ipnData && Object.values(ipnData.applications);
    
    return (
      <div className="incentive-payment-note">
        <div className="section-header">
          <div className="section-top-details">{ipnData.ipnNo}</div>
          <div className="section-top-details name-section">{ipnData.approver}</div>
          <div className="section-top-details">{utils.formatAmericanDate(ipnData.createdAt)}</div>
        </div>
        <div className="section-below-header">
          <h2 className="section-title">Incentive Payment Note</h2>
        </div>

        <Table rows={ipnApplications} headings={[
                {title:'Application ID', value:'appNo'}, 
                {title :'Payee Name', value : 'payeeName'},
                {title :'Payment Mode', value : 'paymentMode'},
                {title :'Incentive Amount Payable', value : 'incentive'},
                {title :'Actual Project Cost', value : 'projectCost'}, 
                {title :'Incentive % of Actual Project Cost', value : 'incentivePercentage'}
                ]}/> 
        {/* <div className="apps-list">
          
          {ipnData.applications
            ? Object.keys(ipnData.applications).map((appid, i) => (
                <div className="app">
                  <div className="column">{ipnData.applications[appid]["customerName"]}</div>
                  <div className="column">{ipnData.applications[appid]["appNo"]}</div>
                  <div className="column right">{utils.formatAsCAD(ipnData.applications[appid]["incentive"])}</div>
                </div>
              ))
            : ""}
        </div> */}
      </div>
    );
  }
}
function mapState(state) {}
const actionCreators = {};
export default connect(mapState, actionCreators)(IncentivePaymentNote);

import React from 'react';
import './Documents.css'
import DocumentsDetail from './DocumentsDetail'
import SearchInput from '../../../../components/Common/SearchInput/SearchInput'

class Documents extends React.Component {

    constructor(props) {
        super(props);
        this.state = {

        }
    }

    documentAction = (flag, id) => {
        console.log('Document>>>>>>>>>>>', flag, id)
    }

    render() {
        const DocumentsDetails = [
            {
                Id: 'doc-1',
                DocumentName: 'GST Registration',
                DocumentType: '.docx',
                DocumentURL: ''
            },
            {
                Id: 'doc-2',
                DocumentName: 'WCB Verification',
                DocumentType: '.jpg',
                DocumentURL: ''
            },
            {
                Id: 'doc-3',
                DocumentName: 'Insurance Policy Document',
                DocumentType: '.pdf',
                DocumentURL: ''
            }
        ]

        return (
            <div>
                <div className="document-container">
                    <div className="section-header">
                        <div className="section-top-details">CON-87239</div>
                        <div className="section-top-details name-section">Belle Gonzales</div>
                        <div className="section-top-details">belle@hotmail.com</div>
                    </div>
                    <div className="section-below-header">
                        <h2 className="section-title" dangerouslySetInnerHTML={{ __html: 'UPLOADED DOCUMENTS' }}></h2>
                        <div className="document-search-selection">
                            <SearchInput
                                placeholder='Search by Document Type/Name'
                            // onClick={this.onSearch}
                            />
                        </div>
                    </div>
                    <div className="contractor-document-outer-container">
                        <div className="contractor-document-container">
                            {DocumentsDetails.length ?
                                <React.Fragment>
                                    <div className="contractor-document-header">
                                        <div className="document-detail">S.No</div>
                                        <div className="document-detail">Document Name</div>
                                        <div className="document-detail">Document Type</div>
                                        <div className="document-detail"></div>
                                    </div>
                                    <div className={`contractor-document-table`}>
                                        {DocumentsDetails.map((DocumentDetail, i) => (
                                            <DocumentsDetail
                                                DocumentDetail={DocumentDetail}
                                                Index={i + 1}
                                                documentAction={this.documentAction}
                                            />
                                        ))}
                                    </div>
                                </React.Fragment>
                                :
                                <p>
                                    There are currently no uploaded documents against this profile.
                            </p>
                            }

                        </div>
                    </div>
                </div>
            </div >
        );
    }
}


export default Documents;

import React from "react";
import "./Home.css";
import AdminLayout from "../../../Common/AdminLayout";
import { AuthContext } from "../../../../firebaseAuthContext";
import SearchInput from "../../../Common/SearchInput/SearchInput";
import { ReactComponent as NavigationRight } from "../../../../images/NavigationRight.svg";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { adminSettingsActions } from "../../../../redux/actions/adminSettingsActions";
import { utils } from "../../../../helpers/utils";
import LoaderComponent from "../../../Common/Loader/Loader";
import SkeletonLoading from "../../../Common/SkeletonLoading/SkeletonLoading";

class Home extends React.Component {
  static contextType = AuthContext;
  constructor(props) {
    super(props);
    this.state = {
      programList: props.programList || [],
      SelectedFilters: [],
      searchInput: null,
    };
  }

  componentDidMount() {
    this.props.loadPrograms();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.programList !== this.props.programList) {
      this.SearchAndFilter(this.state.searchInput, this.state.SelectedFilters);
    }
  }

  onFilterChange = (SelectedFilters) => {
    this.SearchAndFilter(this.state.searchInput, SelectedFilters);
  };

  onSearch = (searchInput) => {
    this.SearchAndFilter(searchInput, this.state.SelectedFilters);
  };

  SearchAndFilter = (searchInput, SelectedFilters) => {
    const { programList } = this.props;
    let FilteredList = [];
    if (SelectedFilters.length) {
      this.setState(
        {
          SelectedFilters,
        },
        () => {
          SelectedFilters.map((filter) => {
            let ArrList = [];
            ArrList = programList.filter((program) => {
              return program && program.client.name === filter;
            });
            FilteredList.push(...ArrList);
          });

          if (searchInput) {
            let SearchPattern = new RegExp("(\\w*" + searchInput + "\\w*)", "gi");
            FilteredList = FilteredList.filter((program) => {
              return (
                program.name.match(SearchPattern) ||
                program.displayName.match(SearchPattern) ||
                (program.client && program.client.name.match(SearchPattern))
              );
            });
          }

          this.setState({
            programList: FilteredList,
            searchInput,
          });
        }
      );
    } else {
      if (searchInput) {
        let SearchPattern = new RegExp("(\\w*" + searchInput + "\\w*)", "gi");
        FilteredList = programList.filter((program) => {
          return (
            (program.displayName && program.displayName.match(SearchPattern)) ||
            (program.status && program.status.match(SearchPattern)) ||
            program.client.name.match(SearchPattern)
          );
        });
      } else {
        FilteredList = programList;
      }
      this.setState({
        programList: FilteredList,
        SelectedFilters: [],
        searchInput,
      });
    }
  };

  render() {
    const { displayName } = this.context.user;
    const { programList, SelectedFilters } = this.state;
    const { adminUserInfo, programsLoading } = this.props;
    let programsAccess = {};
    let filteredPrograms = [];
    let actionBtns = {};
    if (adminUserInfo && adminUserInfo.permissions) {
      actionBtns = adminUserInfo.permissions.admin.actions;
    }
    if (adminUserInfo && adminUserInfo.programs) {
      for (let p of adminUserInfo.programs) {
        programsAccess[p.value] = true;
      }
      filteredPrograms = programList.filter((item) => {
        return programsAccess[item.id];
      });
    }

    filteredPrograms = filteredPrograms.sort(function(a, b) {
      var c = new Date(a.createdAt);
      var d = new Date(b.createdAt);
      return c-d;
    });

    return (
      <AdminLayout showFooter={false} props={this.props}>
        <h4>
          Welcome, <span className="highlight-text">{displayName}</span>
        </h4>
        <div className="home-list-container">
          <div className="admin-dashboard-top-bar">
            <div className="admin-dashboard-search">
              <SearchInput placeholder="Search for a Client or Program" onClick={this.onSearch} />
            </div>
          </div>
          <div className="program-details-header program-details-row">
            <div className="program-details">
              <div>Program</div>
              <div>Client</div>
              <div>Start Date</div>
              <div>End Date</div>
              <div>Status</div>
              <div></div>
            </div>
          </div>
          {programsLoading ? (
            <LoaderComponent dashboard="admin" />
          ) : (
            <div className="program-details-list">
              {programList.length ? (
                filteredPrograms.length ? (
                  filteredPrograms.map((program, i) => (
                    <div className="program-details-row" key={`program-${i}`}>
                      <div className="program-details">
                        <div>
                          <label>Program Name:</label>
                          <span>{program.displayName}</span>
                        </div>
                        <div>
                          <label>Display Name:</label>
                          <span>{program.client.name}</span>
                        </div>
                        <div>
                          <label>Client:</label>
                          <span>{utils.formatDate(program.startDate)}</span>
                        </div>
                        <div>
                          <label>Created Date:</label>
                          <span>{utils.formatDate(program.endDate)}</span>
                        </div>
                        <div>
                          <label>Status:</label>
                          <span>{program.status}</span>
                        </div>
                        <div>
                          {actionBtns["Home-Access Dashboard"] ? <div className="program-navigation-link">
                            <Link to={program.link}>
                              Access Dashboard
                              <NavigationRight width="13" height="13" />
                            </Link>
                          </div> : null}
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <SkeletonLoading />
                )
              ) : (
                <div className="no-records-found">No records.</div>
              )}
            </div>
          )}
        </div>
      </AdminLayout>
    );
  }
}

function mapState(state) {
  const { programList, programsLoading } = state.settings;
  const { adminUserInfo } = state.authentication;
  return { adminUserInfo, programList, programsLoading };
}

const actionCreators = {
  loadPrograms: adminSettingsActions.getPrograms,
};

export default connect(mapState, actionCreators)(Home);

import React from "react";
import "./PortCustomer.css";
import ModalAppDataComponent from "../../../Common/ModalAppDetails/ModalAppData";
import { applicationService } from "../../../../services/applicationService";
import { userService } from "../../../../services/userService";
import LoaderComponent from "../../../Common/Loader/Loader";
import Input from "../../../Common/Input/Input";
import Button from "../../../Common/Button/Button";
import Table from "../../../Common/Table/Table";
import EmptyContainer from "../../../Common/EmptyContainer/EmptyContainer";
import { connect } from "react-redux";
import { utils } from "../../../../helpers/utils";
import { appActions } from "../../../../redux/actions/appActions";
import ResponsiveTable from "../../../Common/ResponsiveTable/ResponsiveTable";
import { ReactComponent as SelectedRadio } from "../../../../images/SelectedRadio.svg";
import { ReactComponent as UnSelectedRadio } from "../../../../images/UnSelectedRadio.svg";
import { debounce } from "debounce";

class PortCustomer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      appLoading: true,
      applicationObj: {},
      appStateNotes: "",
      confirmInputSelected: true,
      confirmInputSelectedDuplicateFacility: true,
      confirmedState: false,
      appStateLogs: this.props.activeStateLogs || [],
      portLogs: this.props.portLogs || [],
      confirmMessage: false,
      slide: 0,
      totalSlides: 3,
      slideName: "",
      backBtnName: "",
      selectedCustomerAccount: {},
      duplicateCustomerAccount: "",
      selectedFacility: {},
      appFlagNotes: "",
      duplicateslide: 0,
      customerAId: "",
      customerBId: "",
      customerACompanyName: "",
      customerBCompanyName: "",
      changeNote: "",
      duplicateFacilityList: {},
      selectedDuplicateFacility: {},
      chosenFacility: {},
      showDuplicateBtn: false,
      fieldDisable: true,
      portCustomerNotes: "",
      confirmingAction: false,
      assignedSuccessfully: false,
      hideAdditionalBtn: false,
    };
    this.debouncedLoadCustomers = debounce(this.loadCustomers, 500);
  }

  async componentDidMount() {
    applicationService.getApplication(this.props.applicationId).then((appObj) => {
      this.props.loadFacilityConfig();
      this.props.getApplicationPortLogs(this.props.applicationId);
      this.setState({
        applicationObj: appObj,
        appLoading: false,
        // currentAppState: currentAppState,
      });
    });
  }

  onChangeHandler = (name, value) => {
    this.setState({
      name: value,
    });
  };

  handleappStateNotesChange = (name, value) => {
    this.setState({
      appStateNotes: value,
    });
  };


  loadCustomerfacility = async (custId) => {
    this.props.loadFacilities(custId);
  };

  loadCustomers = (
    inputValue,
    callback
  ) => {
      return userService.getParticipants(this.props.currentProgram, "customer", inputValue, {statuses : ["Participating","Flagged for QA/QC"]}).then((customerData)=>{
        let finalList = customerData.data.map((item) => { 
            return {label : `${item.userNo} (${item.email})`, value : item.id, userInfo : item}; 
          });
          return finalList;
      });    
  };
 
  handleSelectedCustomerAccount = (value) => {
    this.setState(
      {
        customerAId: this.state.applicationObj.customerId,
        customerBId: value.value,
        customerACompanyName: this.state.applicationObj.companyName,
        customerBCompanyName: value.userInfo.companyName,
        selectedCustomerAccount: value,
        confirmInputSelected: false,
      },
      () => {
        this.loadCustomerfacility(this.state.customerBId);
      }
    );
  };

  chooseFacility = () => {
    const { applicationObj } = this.state;
    const { facilityList } = this.props;
    let chosenFacility = null;
    facilityList.forEach((f) => {
      if (f.id === applicationObj.facilityId) {
        chosenFacility = f;
      }
    });
    this.setState({
      chosenFacility: chosenFacility,
    });
  };

  facilitySummary = () => {
    const { applicationObj, backBtnName, slide, totalSlides, slideName, confirmInputSelectedDuplicateFacility } = this.state;
    const { facilityList } = this.props;

    function extractAppIds(linkedApplications) {
      return Object.keys(linkedApplications).map((key) => {
        return `ESB-${linkedApplications[key].appNo}`;
      });
    }

    let facilityHeadings = [
      { title: "Attach to Application", value: "attachToApp" },
      { title: "Facility Name", value: "facilityName" },
      { title: "Applications Currently Attached to Facility", value: "currentlyAttached" },
      { title: "Address of Facility", value: "facilityAddress" },
    ];

    let facilityRows = facilityList.map((facility) => {
      return {
        attachToApp: (
          <span onClick={() => this.selectFacility(facility.id)}>
            {applicationObj.facilityId === facility.id ? (
              <SelectedRadio width="20" height="20" />
            ) : (
              <UnSelectedRadio width="20" height="20" />
            )}
          </span>
        ),
        facilityName: facility.facilityName,
        currentlyAttached: utils.isNullOrEmpty(facility.linkedApplications) ? [] : extractAppIds(facility.linkedApplications).join(","),
        facilityAddress: `${facility.facilityAddressLine1 && facility.facilityAddressLine1.label} ${facility.facilityCity}`,
      };
    });

    return (
      <React.Fragment>
        <div className="container-box">
          <div className="additional-btn">
            <span className="back-btn" onClick={() => this.handleSelectDetailsBackBtn()}>
              {"< " + backBtnName}
            </span>
            <span className="details-btn">
              {slide}/{totalSlides} {slideName}{" "}
            </span>
          </div>
          <div className="slide-title-container">
            <div className="slide-title">Selecting from {this.state.selectedCustomerAccount.label} Account</div>
          </div>
          <div className="facility-table">
            {utils.isNullOrEmpty(facilityRows) ? (
              <EmptyContainer>No Facility Found</EmptyContainer>
            ) : (
              <ResponsiveTable rows={facilityRows} headings={facilityHeadings} />
            )}
          </div>
        </div>
        <div className="button-set ">
          <Button className="inversed-btn" title={"cancel"} onClick={() => this.handleCancel()} uppercase />
          <Button
            className="submit-details"
            disabled={confirmInputSelectedDuplicateFacility}
            title={"Next"}
            uppercase
            onClick={() => this.handleChangeNotesState()}
          />
        </div>
      </React.Fragment>
    );
  };

  duplicateFacilitySummary = () => {
    const { applicationObj } = this.state;
    const { facilityList } = this.props;

    function extractAppIds(linkedApplications) {
      return Object.keys(linkedApplications).map((key) => {
        return `ESB-${linkedApplications[key].appNo}`;
      });
    }

    let facilityHeadings = [
      { title: "Attach to Application", value: "attachToApp" },
      { title: "Facility Name", value: "facilityName" },
      { title: "Applications Currently Attached to Facility", value: "currentlyAttached" },
      { title: "Address of Facility", value: "facilityAddress" },
    ];

    let facilityRows = facilityList.map((facility) => {
      return {
        attachToApp: (
          <span onClick={() => this.selectFacility(facility.id)}>
            {applicationObj.facilityId === facility.id ? (
              <SelectedRadio width="20" height="20" />
            ) : (
              <UnSelectedRadio width="20" height="20" />
            )}
          </span>
        ),
        facilityName: facility.facilityName,
        currentlyAttached: utils.isNullOrEmpty(facility.linkedApplications) ? [] : extractAppIds(facility.linkedApplications).join(","),
        facilityAddress: `${facility.facilityAddressLine1 && facility.facilityAddressLine1.label} ${facility.facilityCity}`,
      };
    });

    return (
      <React.Fragment>
        <div className="container-box">
          <div className="facility-table">
            <ResponsiveTable rows={facilityRows} headings={facilityHeadings} />
          </div>
        </div>
        <div className="button-set ">
          <Button className="inversed-btn" title={"cancel"} onClick={() => () => this.handleCancel()} uppercase />
          <Button
            className="submit-details"
            // disabled={!utils.cleanHTMLText(appStateNotes)}
            title={"Next"}
            uppercase
            onClick={() => this.handleChangeNotesState()}
          />
        </div>
      </React.Fragment>
    );
  };

  handleChangeState = () => {
    let { slide } = this.state;
    this.setState({
      confirmedState: false,
      showDuplicateBtn: true,
      slide: slide + 1,
      slideName: "Select Facility Source",
      backBtnName: "Select Customer",
      duplicateslide: 0,
      confirmInputSelected: false,
    });
  };

  handleChangeStateToTable = () => {
    applicationService.getFacilitiesForCustomer(this.state.customerBId).then((customerBfacility) => {
        let isCustomerAfacilityExistsInCustomerB = customerBfacility.filter((F) => {
          return F.facilityName === this.state.selectedDuplicateFacility.label;
        });
        if (!isCustomerAfacilityExistsInCustomerB.length > 0) {
          let selectedFacilityData = {};
          applicationService.getFacility(this.state.selectedDuplicateFacility.facilityId).then((facilityData) => {
            if (facilityData) {
              selectedFacilityData = facilityData;
              selectedFacilityData.customerId = this.state.customerBId;
              delete selectedFacilityData.updatedAt;
              applicationService.createFacility(selectedFacilityData).then((duplicateFacilityId) => {
                if (duplicateFacilityId) {
                  this.setState(
                    {
                      duplicateslide: 0,
                      slide: 2,
                      showDuplicateBtn: true,
                      backBtnName: "Select Customer",
                      slideName: "Select Facility Source",
                      duplicateCustomerAccount: `CUS-${this.state.applicationObj.customerInfo.userNo} (${this.state.applicationObj.customerInfo.email})`,
                    },
                    () => {
                      this.props.loadFacilities(this.state.customerBId);
                    }
                  );
                }
              });
            }
          });
        } else {
          this.setState(
            {
              duplicateslide: 0,
              slide: 2,
              showDuplicateBtn: true,
              backBtnName: "Select Customer",
              slideName: "Select Facility Source",
              duplicateCustomerAccount: `CUS-${this.state.applicationObj.customerInfo.userNo} (${this.state.applicationObj.customerInfo.email})`,
            },
            () => {}
          );
        }
      
    });
  };

  handleChangeNotesState = () => {
    this.chooseFacility();
    let { slide } = this.state;
    this.setState({
      confirmInputSelected: true,
      showDuplicateBtn: false,
      slide: slide + 1,
      slideName: "Notes",
      backBtnName: "Select Facility Source",
    });
  };
  handleCancel = () => {
    this.props.closeActionModal(true);
    // console.log("cancel modal")
    /* this.setState({
      slide: 0,
    }) */
  };

  handleDuplicateFacility = () => {
    this.setState({
      backBtnName: "Select Customer",
      slideName: "Select Facility Source",
    });
  };
  handleGoBack = () => {
    this.setState({
      confirmedState: false,
      slide: 3,
      confirmingAction: false,
      hideAdditionalBtn: false,
    });
  };

  handlePortCustomer = () => {
    let { slide } = this.state;
    this.setState({
      slide: slide + 1,
      slideName: "Select Customer",
      backBtnName: "Back",
    });
  };

  handleDuplicateBtn = (CustomerID) => {
    let { duplicateslide } = this.state;
    applicationService.getFacilitiesForCustomer(this.state.customerAId).then((facilityData) => {
      this.setState(
        {
          duplicateFacilityList: facilityData,
          duplicateslide: duplicateslide + 1,
          showDuplicateBtn: false,
          backBtnName: "Select Facility Source",
          slideName: "Duplicate facility",
          duplicateCustomerAccount: `CUS-${this.state.applicationObj.customerInfo.userNo} (${this.state.applicationObj.customerInfo.email})`,
        },
        () => {
      
        }
      );
    });
  };
  handleDuplicateFacilityState = () => {
    this.setState({
      slide: 2,
    });
  };
  handleBackBtn = () => {
    let { slide } = this.state;
    this.setState({
      slide: slide - 1,
      slideName: "",
    });
  };
  handleSelectDetailsBackBtn = () => {
    let { slide } = this.state;
    this.setState({
      duplicateslide: 0,
      showDuplicateBtn: false,
      slide: slide - 1,
      slideName: "Select Customer",
      backBtnName: "Back",
    });
  };
  handleBackBtnToFacilitySource = () => {
    this.setState({
      duplicateslide: 0,
      showDuplicateBtn: true,
      slide: 2,
      slideName: "Select Facility Source",
      backBtnName: "Select Customer",
    });
  };

  handleFacilitySourceBackBtn = () => {
    let { slide } = this.state;
    this.setState({
      confirmedState: false,
      slide: slide - 1,
      slideName: "Select Facility Source",
      backBtnName: "Select Customer",
      showDuplicateBtn: true,
    });
  };
  handleDuplicateFacilityDropdown = (value) => {
    this.setState({
      selectedDuplicateFacility: value,
      confirmInputSelected: false,
    });
  };
  handleConfirmDetails = () => {
    this.setState({
      hideAdditionalBtn: true,
      confirmingAction: true,
      assignedSuccessfully: true,
      confirmedState: true,
      backBtnName: "Notes",
      slideName: "Confirmation Screen",
    });
  };

  selectFacility = (facilityId) => {
    let facilityList = this.props.facilityList;
    let fIndex = facilityList.findIndex((f) => f.facilityId === facilityId);
    let selectedFacility = fIndex > -1 ? facilityList[fIndex] : {};
    this.setState({
      selectedFacility,
      confirmInputSelectedDuplicateFacility : false,
      applicationObj: {
        ...this.state.applicationObj,
        facilityId,
      },
    });
  };

  handlePortCustomerDetails = async () => {
    const { adminUserInfo } = this.props;
    const  { customerAId, customerBId, chosenFacility,applicationObj,customerACompanyName,customerBCompanyName,portCustomerNotes } = this.state;
    let updateApplication = {};
    let userInfo = await userService.getParticipantDetails(this.props.currentProgram, customerBId);
    updateApplication.customerId = customerBId;
    updateApplication.facilityId = chosenFacility.id;
    updateApplication.appNo = applicationObj.appNo;
    updateApplication.changeLog = `Application changed from ${customerACompanyName} to ${customerBCompanyName}`;
    updateApplication.changeNote = `${portCustomerNotes}`;
    updateApplication.actionTaker = `${adminUserInfo && adminUserInfo.firstName} ${adminUserInfo && adminUserInfo.lastName}`;
    updateApplication.changeFromCustomerId = customerAId;
    updateApplication.changeToCustomerId = customerBId;
    updateApplication.customerInfo = userInfo
    updateApplication.companyName = userInfo.companyDetails.name;

    await applicationService.updateApplication(this.props.applicationId, updateApplication);
    
      this.setState(
        {
          confirmMessage: true,
          portCustomerNotes: "",
          confirmedState: false,
        },
        () => {
          setTimeout(() => {
            this.props.closeActionModal(true);
            this.props.reload();
          }, 1600);
        }
      );
  };

  duplicatefacilitySlideSummary = () => {
    const { slide, totalSlides, slideName, backBtnName, confirmInputSelected } = this.state;

    return (
      <React.Fragment>
        <div className="container-box">
          <div className="additional-btn">
            <span className="back-btn" onClick={() => this.handleBackBtnToFacilitySource()}>
              {"< " + backBtnName}
            </span>
            <span className="details-btn">
              {slide}/{totalSlides} {slideName}{" "}
            </span>
          </div>
          <div className="slide-title-container">
            <div className="slide-title">Duplicate from {this.state.duplicateCustomerAccount} Account</div>
          </div>
          <div className="input-facility-container">
            <div className="facility-selector-label">Which facility would you like to duplicate?</div>
            <div className="facility-selector-element">
              <Input
                placeholder={"Select Facility"}
                type="dropdown"
                name="selectedfacility"
                onChange={this.handleDuplicateFacilityDropdown}
                value={
                  !utils.isNullOrEmpty(this.state.selectedDuplicateFacility) ? this.state.selectedDuplicateFacility : "Select Facility"
                }
                options={this.state.duplicateFacilityList.map((opt) => {
                  return { facilityId: opt.id, label: `${opt.facilityName}` };
                })}
              />
            </div>
          </div>
        </div>
        <div className="button-set ">
          <Button className="inversed-btn" title={"Cancel"} onClick={() => this.handleCancel()} uppercase />
          <Button
            className="submit-details"
            disabled={confirmInputSelected}
            title={"Next"}
            uppercase
            onClick={() => this.handleChangeStateToTable()}
          />
        </div>
      </React.Fragment>
    );
  };
  handlePortCustomerNotesChange = (name, value) => {
    this.setState({
      portCustomerNotes: value,
      confirmInputSelected: true,
    });
  };
  render() {
    const {
      applicationObj,
      appLoading,
      customersLoading,
      confirmedState,
      confirmMessage,
      slide,
      totalSlides,
      slideName,
      backBtnName,
      confirmInputSelected,
      duplicateslide,
      fieldDisable,
      portCustomerNotes,
      assignedSuccessfully,
      confirmingAction,
      showDuplicateBtn,
      hideAdditionalBtn,
      customerAId,
      customerBId
    } = this.state;
    let { adminUserInfo, appStateLogsLoading, portLogs, currentProgram } = this.props;
    let actions = {};
    let canEdit = true;
    if (adminUserInfo && adminUserInfo.permissions) {
      actions = adminUserInfo.permissions[currentProgram].actions;
      if (utils.isNullOrEmpty(actions["Application Board-Change Application State"])) {
        canEdit = false;
      }
    }

    return (
      <div className="portcustomer-container">
        <React.Fragment>
          {appLoading ? (
            <LoaderComponent />
          ) : !appStateLogsLoading ? (
            <LoaderComponent />
          ) : (
            <ModalAppDataComponent applicationObj={applicationObj}></ModalAppDataComponent>
          )}
          <div className="header-container">
            <div className="section-below-header">
              <h2
                className="section-title"
                dangerouslySetInnerHTML={{ __html: `${slide !== 0 ? "Port Customer" : "Port Customer Logs"}` }}
              ></h2>
              {}
            </div>
            <div className="port-btn">
              {canEdit ? (
                showDuplicateBtn ? (
                  <Button
                    className="submit-details change-btn"
                    title={"Duplicate Facility"}
                    onClick={() => this.handleDuplicateBtn()}
                    uppercase
                  />
                ) : slide !== 0 ? (
                  " "
                ) : (
                  <Button
                    className="submit-details change-btn"
                    title={"port customer"}
                    onClick={() => this.handlePortCustomer()}
                    uppercase
                  />
                )
              ) : (
                ""
              )}
            </div>
          </div>
          {customersLoading ? (
            <LoaderComponent />
          ) : slide !== 0 ? (
            slide === 1 ? (
              <React.Fragment>
                <div className="container-box">
                  <div className="additional-btn">
                    <span className="back-btn" onClick={() => this.handleBackBtn()}>
                      {"< " + backBtnName}
                    </span>
                    <span className="details-btn">
                      {slide}/{totalSlides} {slideName}{" "}
                    </span>
                  </div>
                  <div className="slide-title-container">
                    <div className="slide-title">Customer Account Selection</div>
                  </div>
                  <div className="inner-container">
                    <div className="selector-label">Into which account would you like to port this application?</div>
                    <div className="selector-element">
                      <Input
                        placeholder={"Search for Customer"}
                        className={"select-customer-search"}
                        type="search"
                        name="selectedCustomerAccount"
                        onChange={this.handleSelectedCustomerAccount}
                        loadOptions={this.debouncedLoadCustomers}
                        error={customerBId === this.state.applicationObj.customerId  ? 'Application already belongs to this customer' : ''}
                        value={
                          !utils.isNullOrEmpty(this.state.selectedCustomerAccount)
                            ? this.state.selectedCustomerAccount
                            : "Search for Customer"
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="button-set ">
                  <Button className="inversed-btn" title={"cancel"} onClick={() => this.handleCancel()} uppercase />
                  <Button
                    className="submit-details"
                    disabled={confirmInputSelected || (customerBId === this.state.applicationObj.customerId)}
                    title={"Next"}
                    uppercase
                    onClick={() => this.handleChangeState()}
                  />
                </div>
              </React.Fragment>
            ) : duplicateslide !== 0 ? (
              this.duplicatefacilitySlideSummary()
            ) : slide === 2 ? (
              this.facilitySummary()
            ) : slide === 3 ? (
              confirmMessage ? (
                "Selected customer account has been ported successfully!"
              ) : (
                <React.Fragment>
                  <div className="container-box">
                    {hideAdditionalBtn ? (
                      ""
                    ) : (
                      <React.Fragment>
                      <div className="additional-btn">
                        <span className="back-btn" onClick={() => this.handleFacilitySourceBackBtn()}>
                          {"< " + backBtnName}
                        </span>
                        <span className="details-btn">
                          {slide}/{totalSlides} {slideName}{" "}
                        </span>
                      </div>
                      <div className="slide-title-container">
                        <div className="slide-title">
                          Additional Details
                        </div>
                      </div>
                      </React.Fragment>
                      
                    )}
                    <div className="confirm-container">
                      <div className="confirmation-slide-container">
                        <div className="confirmation-input">
                          <div className="confirm-inner-container">
                            <div className="confirm-selector-label">New Customer Account for the application</div>
                            <div className="confirm-selector-element">
                              <Input
                                type="text"
                                name="selectedCustomerAccount"
                                disabled={fieldDisable}
                                value={`${this.state.selectedCustomerAccount.userInfo.userNo} (${this.state.selectedCustomerAccount.userInfo.email})`}
                                placeholder={"Select Customer Account"}
                              />
                            </div>
                          </div>
                          <div className="confirm-inner-container">
                            <div className="confirm-selector-label">New Facility for the application</div>
                            <div className="confirm-selector-element">
                              <Input
                                type="text"
                                name="selectedFacility"
                                disabled={fieldDisable}
                                value={this.state.chosenFacility.facilityName}
                                placeholder={"Select Customer Account"}
                              />
                            </div>
                          </div>
                        </div>
                        {confirmingAction ? (
                          <React.Fragment>
                            <div className="confirmation-seperator">
                              <div className="info-text">
                                {assignedSuccessfully ? (
                                  <p>
                                    This is going to change the customer and the facility assigned to this application.
                                    <br /><br />
                                    Please verify all the details and the notes before clicking 'Port Customer'
                                  </p>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          </React.Fragment>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    <div className="form-inner-section">
                      <div className="form-label bold full">Notes:</div>
                    </div>
                    <div className="form-inner-section">
                      <div className="form-element full">
                        <Input
                          type="richtext"
                          value={portCustomerNotes}
                          onChange={this.handlePortCustomerNotesChange}
                          name="note"
                          disabled={confirmingAction}
                        />
                      </div>
                    </div>
                  </div>
                  {confirmedState ? (
                    <div className="button-set ">
                      <Button className="inversed-btn" title={"Go Back"} onClick={() => this.handleGoBack()} uppercase />
                      <Button
                        className="submit-details"
                        title={"Port Customer"}
                        uppercase
                        onClick={() => this.handlePortCustomerDetails()}
                      />
                    </div>
                  ) : (
                    <div className="button-set ">
                      <Button className="inversed-btn" title={"cancel"} onClick={() => this.handleCancel()} uppercase />
                      <Button
                        className="submit-details"
                        disabled={!utils.cleanHTMLText(portCustomerNotes)}
                        title={"Confirm Details"}
                        uppercase
                        onClick={() => this.handleConfirmDetails()}
                      />
                    </div>
                  )}{" "}
                </React.Fragment>
              )
            ) : (
              <LoaderComponent />
            )
          ) : (
            <React.Fragment>
              <div className="logs-container-box">
                {portLogs && portLogs.length ? (
                  <React.Fragment>
                    <Table
                      rows={portLogs}
                      headings={[
                        { title: "Date", value: "createdAt" },
                        { title: "Change Log", value: "changeLog" },
                        { title: "Change Note", value: "changeNote" },
                        { title: "Changed By", value: "actionTaker" },
                      ]}
                    />
                  </React.Fragment>
                ) : (
                  <EmptyContainer>There are currently no logs to show.</EmptyContainer>
                )}
              </div>
            </React.Fragment>
          )}
        </React.Fragment>
      </div>
    );
  }
}

function mapState(state) {
  const { adminUserInfo, currentProgram, customersLoading } = state.authentication;
  const { appStateLogsLoading, activeStateLogs, facilityList, portLogs } = state.application;
  return {
    adminUserInfo,
    appStateLogsLoading,
    activeStateLogs,
    facilityList,
    customersLoading,
    portLogs,
    currentProgram,
  };
}
const actionCreators = {
  loadFacilityConfig: appActions.getFacilitiesConfig,
  loadFacilities: appActions.getFacilitiesForCustomer,
  getApplicationPortLogs: appActions.getApplicationsPortLogs,
};

export default connect(mapState, actionCreators)(PortCustomer);

export const adminSettingsActionTypes = {
    SHOW_ADD_USER_MODAL: 'SHOW_ADD_USER_MODAL',
    SET_ACTIVE_ADMIN_USER : 'ADMIN_SET_ACTIVE_ADMIN_USER',
    GET_ADMIN_USERS_REQUEST : 'GET_ADMIN_USERS_REQUEST',
    GET_ADMIN_USERS_SUCCESS : 'GET_ADMIN_USERS_SUCCESS',
    GET_ADMIN_USERS_FAILURE : 'GET_ADMIN_USERS_FAILURE',
    GET_CLIENTS_REQUEST : 'GET_CLIENTS_REQUEST',
    GET_CLIENTS_SUCCESS : 'GET_CLIENTS_SUCCESS',
    GET_CLIENTS_FAILURE : 'GET_CLIENTS_FAILURE',
    GET_PROGRAMS_REQUEST : 'GET_PROGRAMS_REQUEST',
    GET_PROGRAMS_SUCCESS : 'GET_PROGRAMS_SUCCESS',
    GET_PROGRAMS_FAILURE : 'GET_PROGRAMS_FAILURE',
    GET_ADMIN_USER_DETAILS_REQUEST: 'GET_ADMIN_USER_DETAILS_REQUEST',
    GET_ADMIN_USER_DETAILS_SUCCESS: 'GET_ADMIN_USER_DETAILS_SUCCESS',
    GET_ADMIN_USER_DETAILS_FAILURE: 'GET_ADMIN_USER_DETAILS_FAILURE',
    GET_ADMIN_CONFIG_REQUEST : 'GET_ADMIN_CONFIG_REQUEST',
    GET_ADMIN_CONFIG_SUCCESS : 'GET_ADMIN_CONFIG_SUCCESS',
    GET_ADMIN_CONFIG_FAILURE : 'GET_ADMIN_CONFIG_FAILURE'
};
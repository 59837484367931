import React from "react";
import "./IPNSpotCheck.css";
import { connect } from "react-redux";
import { utils } from "../../../../helpers/utils";
import { applicationService } from "../../../../services/applicationService";
import EmptyContainer from "../../../Common/EmptyContainer/EmptyContainer";
import Loader from "../../../Common/Loader/Loader";
import Button from "../../../Common/Button/Button";
import SearchInput from "../../../Common/SearchInput/SearchInput";
import CheckBox from "../../../Common/CheckBox/CheckBox";
import Input from "../../../Common/Input/Input.js";
import { ReactComponent as TickMark } from "../../../../images/VerifiedTick.svg";
class IncentivePaymentNote extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cibcData: [],
      loading: true,
      showSelectedApps: false,
      searchInput: "",
      selectedApps: [],
      selectedApplicationData: [],
      spotCheckActionNote: "",
      searchCibcData: [],
      showMessage: false,
    };
  }
  componentDidMount() {
    const { id } = this.props;
    applicationService.getCIBCTransactionExport(id).then((cibcData) => {
      this.setState({
        cibcData: cibcData,
        searchCibcData: cibcData,
        loading: false,
      });
    });
  }
  onSearch = (searchInput) => {
    const { cibcData } = this.state;
    let FilteredList = [];
    if (searchInput) {
      let SearchPattern = new RegExp("(\\w*" + searchInput + "\\w*)", "gi");
      FilteredList = cibcData.filter((app) => {
        return (
          (app.applicationId && app.applicationId.match(SearchPattern)) ||
          (app.payeeCompanyName && app.payeeCompanyName.match(SearchPattern)) ||
          (app.postProjectReviewerApprovedIncentive && app.postProjectReviewerApprovedIncentive.toString().match(SearchPattern))
        );
      });
    } else {
      FilteredList = cibcData;
    }
    this.setState({
      searchCibcData: FilteredList,
      searchInput,
    });
  };
  completeSpotCheck = () => {
    this.setState({ confirmMessage: true });
  };
  cancelSpotCheck = () => {
    this.setState({ confirmMessage: false });
  };
  ShowMoreDetails = (id) => {
    let actElem = document.getElementById(id);
    if (actElem.classList.contains("active-row")) {
      actElem.classList.remove("active-row");
    } else {
      let actContElem = document.querySelector(".eft-apps.active-row");
      if (actContElem !== null) {
        actContElem.classList.remove("active-row");
      }
      actElem.classList.add("active-row");
    }
  };
  handleAllAppsSelection = () => {
    const { selectedApps, cibcData } = this.state;
    if (!utils.isNullOrEmpty(selectedApps)) {
      this.setState({ selectedApps: [] });
    } else {
      let allApps = [];
      for (var app of cibcData) {
        allApps.push(app.appNo);
      }
      this.setState({ selectedApps: allApps });
    }
  };
  handleAppselection = (appNo) => {
    const { selectedApps } = this.state;
    let newSelectedApps = selectedApps;
    if (newSelectedApps.includes(appNo)) {
      newSelectedApps = selectedApps.filter((n) => n !== appNo);
      this.setState({ selectedApps: newSelectedApps });
    } else {
      newSelectedApps.push(appNo);
      this.setState({ selectedApps: newSelectedApps });
    }
  };
  handleSelectedApps = () => {
    const { selectedApps, cibcData } = this.state;
    let selectedApplicationData = cibcData.filter((app) => selectedApps.includes(app.appNo));
    this.setState({
      selectedApplicationData,
      showSelectedApps: true,
    });
  };
  handleNote = (name, value) => {
    this.setState({ [name]: value });
  };
  handleConfirmNotes = (mess) => {
    this.setState({
      showMessage: mess,
    });
  };
  handleCompleteSpotCheck = () => {
    const { adminUserInfo, id } = this.props;
    const { spotCheckActionNote, selectedApps } = this.state;
    applicationService
      .updateIPN(id, {
        status: "Spot Check Completed",
        actionTaker: `${adminUserInfo && adminUserInfo.firstName} ${adminUserInfo && adminUserInfo.lastName}`,
        spotCheckReviewer: `${adminUserInfo && adminUserInfo.firstName} ${adminUserInfo && adminUserInfo.lastName}`,
        spotCheckActionNote: `${spotCheckActionNote}`,
        spotCheckedApplications: selectedApps,
        spotCheckedDate: new Date(),
      })
      .then((res) => {
        this.setState(
          {
            showMessage: "confirmedMessage",
          },
          () => {
            setTimeout(() => {
              this.props.toggleDrawer();
              this.props.searchData();
            }, 5000);
          }
        );
      });
  };
  render() {
    const { ipnData } = this.props;
    const {
      cibcData,
      loading,
      showMessage,
      searchCibcData,
      selectedApps,
      searchInput,
      selectedApplicationData,
      showSelectedApps,
      spotCheckActionNote,
    } = this.state;
    return (
      <div className="ipn-spot-check">
        {loading && utils.isNullOrEmpty(cibcData) ? (
          <Loader />
        ) : (
          <React.Fragment>
            <div className="section-summary">
              <div className="section-block">
                <div className="label">NO OF APPLICATIONS</div>
                <div className="info">
                  {!showSelectedApps
                      ? ipnData.applications.length
                      : `${selectedApps.length}/${ipnData.applications.length}`}
                </div>
              </div>
              <div className="section-block">
                <div className="label">TOTAL IPN VALUE</div>
                <div className="totals">{`$${
                  ipnData.incentiveTotal
                    ? ipnData.incentiveTotal
                        .toFixed(2)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    : ""
                }`}</div>
              </div>
            </div>
            <div className="section-below-header">
              <h2
                className="section-title"
                dangerouslySetInnerHTML={{ __html: `Complete Spot Check For ${ipnData ? ipnData.ipnNo : ""}`}
                }
              ></h2>
              {!showSelectedApps ? (
                <div className="spot-check-search">
                  <SearchInput value={searchInput} placeholder="Search for Applications" onClick={this.onSearch} />
                </div>
              ) : (
                ""
              )}
            </div>
            {!utils.isNullOrEmpty(cibcData) ? (
              <>
                {!showSelectedApps ? (
                  <div className="main-container">
                    <div className="eft-details">
                      <div>
                        <CheckBox
                          checked={!utils.isNullOrEmpty(selectedApps)}
                          unselect={selectedApps.length !== cibcData.length}
                          onClick={() => {
                            this.handleAllAppsSelection();
                          }}
                        />
                        {utils.isNullOrEmpty(selectedApps) ? "" : `(${selectedApps.length})`}
                      </div>
                      <div>
                        <span>Application ID</span>
                      </div>
                      <div>
                        <span>Payee Name</span>
                      </div>
                      <div>
                        <span>Incentive Amount</span>
                      </div>
                      <div></div>
                    </div>
                    <div className="apps-container">
                      {searchCibcData.map((app, ind) => {
                        return (
                          <div className="eft-apps" id={`app-${ind}`} key={ind}>
                            <div className="eft-details-row">
                              <div>
                                <label>Select:</label>
                                <span>
                                  <CheckBox
                                    checked={selectedApps.includes(app.appNo)}
                                    onClick={() => {
                                      this.handleAppselection(app.appNo);
                                    }}
                                  />
                                </span>
                              </div>
                              <div>
                                <label>Application ID</label>
                                <span>{app.applicationId}</span>
                              </div>
                              <div>
                                <label>Payee Name</label>
                                <span>{app.payeeCompanyName}</span>
                              </div>
                              <div>
                                <label>Incentive Amount</label>
                                <span>
                                  $
                                  {app.postProjectReviewerApprovedIncentive
                                    .toFixed(2)
                                    .toString()
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                </span>
                              </div>
                              <div className="more-options" onClick={() => this.ShowMoreDetails(`app-${ind}`)}></div>
                            </div>
                            <div className="more-details">
                              <div>
                                <label className="more-detail-label">Name on Bank Account:</label>
                                <span className="more-detail-value">{app.nameOnBankAccount}</span>
                              </div>
                              <div>
                                <label className="more-detail-label">Banking Details:</label>
                                <span className="more-detail-value">{`${app.payeeRoutingNumber.substring(
                                  1,
                                  4
                                )}-${app.payeeRoutingNumber.substring(4)}-${app.payeeAccountNumber}`}</span>
                              </div>
                              <div>
                                <label className="more-detail-label">Verification Document:</label>
                                <span className="more-detail-value">
                                  <a target="_blank" rel="noopener noreferrer" href={app.verficationDocument}>
                                    {app.verficationDocumentName}
                                  </a>
                                </span>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                ) : (
                  <div className="main-container">
                    <div className="message-container">
                      <div className="outer-container">
                        <div className="eft-details">
                          <div>
                            <span>Application ID</span>
                          </div>
                          <div>
                            <span>Payee Name</span>
                          </div>
                          {showMessage ? (
                            ""
                          ) : (
                            <div>
                              <span>Name on Bank Account</span>
                            </div>
                          )}
                          <div>
                            <span>Incentive Amount</span>
                          </div>
                        </div>
                        <div className="apps-container">
                          {selectedApplicationData.map((app, ind) => {
                            return (
                              <div className="eft-apps" id={`app-${ind}`} key={ind}>
                                <div className="eft-details-row">
                                  <div>
                                    <label>Application ID</label>
                                    <span>{app.applicationId}</span>
                                  </div>
                                  <div>
                                    <label>Payee Name</label>
                                    <span>{app.payeeCompanyName}</span>
                                  </div>
                                  {showMessage ? (
                                    ""
                                  ) : (
                                    <div>
                                      <label>Name on Bank Account</label>
                                      <span>{app.nameOnBankAccount}</span>
                                    </div>
                                  )}
                                  <div>
                                    <label>Incentive Amount</label>
                                    <span>
                                      $
                                      {app.postProjectReviewerApprovedIncentive
                                        .toFixed(2)
                                        .toString()
                                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                      {showMessage ? (
                        <div className="message-block">
                          <div className="vertical-separator">
                            <span className="separator"></span>
                          </div>
                          <div className="message-para">
                            {showMessage === "confirmedMessage" ? (
                              <>
                                <TickMark width="50px" height="50px" />
                                <p>This IPN has been successfully marked as 'Spot Check Completed'</p>
                              </>
                            ) : (
                              <p>Please review the details of the Spot Check throughly before completing the process.</p>
                            )}
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="note">
                      <div className="note-label">Notes:</div>
                      <Input
                        type="richtext"
                        disabled={showMessage}
                        name="spotCheckActionNote"
                        value={spotCheckActionNote}
                        onChange={this.handleNote}
                      />
                    </div>
                  </div>
                )}
                {showSelectedApps ? (
                  showMessage === "confirmedMessage" ? (
                    ""
                  ) : showMessage === "firstMessage" ? (
                    <div className="button-set">
                      <Button title={"Go Back"} className="inversed-btn" uppercase onClick={() => this.setState({ showMessage: false })} />
                      <Button
                        disabled={!utils.cleanHTMLText(spotCheckActionNote)}
                        title={"Complete Spot Check"}
                        uppercase
                        onClick={() => this.handleCompleteSpotCheck()}
                      />
                    </div>
                  ) : (
                    <div className="button-set">
                      <Button
                        title={"Go Back"}
                        className="inversed-btn"
                        uppercase
                        onClick={() => this.setState({ showSelectedApps: false })}
                      />
                      <Button
                        disabled={!utils.cleanHTMLText(spotCheckActionNote)}
                        title={"Confirm Notes"}
                        uppercase
                        onClick={() => this.handleConfirmNotes("firstMessage")}
                      />
                    </div>
                  )
                ) : (
                  <Button
                    disabled={utils.isNullOrEmpty(selectedApps)}
                    className="selection-btn"
                    title="Confirm Selection"
                    uppercase
                    onClick={() => this.handleSelectedApps()}
                  />
                )}
              </>
            ) : (
              <>
                <EmptyContainer>There are no applications with EFT transfers in this IPN</EmptyContainer>
                <Button className="empty-container" title="Cancel" uppercase onClick={() => this.props.toggleDrawer()}></Button>
              </>
            )}
          </React.Fragment>
        )}
      </div>
    );
  }
}
function mapState(state) {}
const actionCreators = {};
export default connect(mapState, actionCreators)(IncentivePaymentNote);

import React from 'react';
import './ParticipantCompanyDetails.css';
import { connect } from 'react-redux'

function Row(props) {
    return <div className="row-section">
    <div className="section-left"><span className='section-subtitle'>{props.label}</span></div>
    <div className="section-right">
      {props.data ? (props.data.map((item)=><div className='option'>{item.label ? item.label : item.value}</div>)) : <p>No Data.</p>}
    </div>
  </div>;
}

class ParticipantCompanyDetails extends React.Component {
  render() {
      const {activeParticipant} = this.props;
      return (
        <div className="participant-company-details-container">
          <div className="container-box">
            <div className="form-sections">
            {activeParticipant.companyDetails ? <React.Fragment>
            <div className="row-section first-row">
              <div className="section-left"><span className='section-title'>Requirements</span></div>
              <div className="section-right"><span className='section-title'>Answers</span></div>
            </div>
            <Row label={`Role in Company`} data={activeParticipant.companyDetails.roleInCompany ? [activeParticipant.companyDetails.roleInCompany] : undefined}/>
            {activeParticipant.accountCategory === 'contractor' ? <React.Fragment>
            <Row label={`Role in Supply Chain`} data={activeParticipant.companyDetails.supplyChainRole}/>
            <Row label={`Technology Focus for Company`} data={activeParticipant.companyDetails.technologyFocusForCompany}/>
            <Row label={`Markets Served by Company`} data={activeParticipant.companyDetails.marketsServedByCompany}/>
            <Row label={`Regions of Operation`} data={activeParticipant.companyDetails.regionsOfOperation}/>
            </React.Fragment> : <React.Fragment>
            <Row label={`Industry of the company`} data={activeParticipant.companyDetails.companyIndustry}/>
            <Row label={`NAICS Code`} data={activeParticipant.companyDetails.naicsCode ? [activeParticipant.companyDetails.naicsCode] : undefined}/>
            </React.Fragment> }
            </React.Fragment> : <p>No Data</p>}
            </div>
          </div>
        </div>
      );
    }
}


function mapState(state) {
  const { activeParticipant } = state.participant;
  return { activeParticipant };
}

const actionCreators = {
}

export default connect(mapState, actionCreators)(ParticipantCompanyDetails);

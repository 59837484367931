import React from 'react';
import Input from '../../../../Common/Input/Input';

class ContractorReviewDetail extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        const { ReviewDetail, Index, EditDetailsForReview, disableFields } = this.props
        return (
            <div className="contractor-review-details">
                {EditDetailsForReview ? <div className="review-detail">{Index}</div>
                    : ''}
                <div className="review-detail">{ReviewDetail.ReviewParameter}</div>
                <div className="review-detail">{ReviewDetail.SharedDetail}</div>
                <div className="review-detail review-status">
                    <Input type="switch" disabled={disableFields} name={ReviewDetail.paramName} checked={ReviewDetail.ReviewStatus} onChange={this.props.onChange}/> 
                </div>
            </div>
        );
    }
}

export default ContractorReviewDetail;

import React from "react";
import "./SkeletonLoading.css";

export default function SkeletonLoading(props) {
  return (
    <React.Fragment>
      {props.skeletonFor !== "cards" ? (
        <div className="table-loading">
          <div className="skeleton skeleton-table">
            <div className="skeleton1 skeleton-text"></div>
            <div className="skeleton1 skeleton-text"></div>
            <div className="skeleton1 skeleton-text"></div>
            <div className="skeleton1 skeleton-text"></div>
            <div className="skeleton1 skeleton-text"></div>
            <div className="skeleton1 skeleton-text"></div>
          </div>
          <div className="skeleton skeleton-table">
            <div className="skeleton1 skeleton-text"></div>
            <div className="skeleton1 skeleton-text"></div>
            <div className="skeleton1 skeleton-text"></div>
            <div className="skeleton1 skeleton-text"></div>
            <div className="skeleton1 skeleton-text"></div>
            <div className="skeleton1 skeleton-text"></div>
          </div>
          <div className="skeleton skeleton-table">
            <div className="skeleton1 skeleton-text"></div>
            <div className="skeleton1 skeleton-text"></div>
            <div className="skeleton1 skeleton-text"></div>
            <div className="skeleton1 skeleton-text"></div>
            <div className="skeleton1 skeleton-text"></div>
            <div className="skeleton1 skeleton-text"></div>
          </div>
          <div className="skeleton skeleton-table">
            <div className="skeleton1 skeleton-text"></div>
            <div className="skeleton1 skeleton-text"></div>
            <div className="skeleton1 skeleton-text"></div>
            <div className="skeleton1 skeleton-text"></div>
            <div className="skeleton1 skeleton-text"></div>
            <div className="skeleton1 skeleton-text"></div>
          </div>
          <div className="skeleton skeleton-table">
            <div className="skeleton1 skeleton-text"></div>
            <div className="skeleton1 skeleton-text"></div>
            <div className="skeleton1 skeleton-text"></div>
            <div className="skeleton1 skeleton-text"></div>
            <div className="skeleton1 skeleton-text"></div>
            <div className="skeleton1 skeleton-text"></div>
          </div>
          <div className="skeleton skeleton-table">
            <div className="skeleton1 skeleton-text"></div>
            <div className="skeleton1 skeleton-text"></div>
            <div className="skeleton1 skeleton-text"></div>
            <div className="skeleton1 skeleton-text"></div>
            <div className="skeleton1 skeleton-text"></div>
            <div className="skeleton1 skeleton-text"></div>
          </div>
          <div className="skeleton skeleton-table">
            <div className="skeleton1 skeleton-text"></div>
            <div className="skeleton1 skeleton-text"></div>
            <div className="skeleton1 skeleton-text"></div>
            <div className="skeleton1 skeleton-text"></div>
            <div className="skeleton1 skeleton-text"></div>
            <div className="skeleton1 skeleton-text"></div>
          </div>
          <div className="skeleton skeleton-table">
            <div className="skeleton1 skeleton-text"></div>
            <div className="skeleton1 skeleton-text"></div>
            <div className="skeleton1 skeleton-text"></div>
            <div className="skeleton1 skeleton-text"></div>
            <div className="skeleton1 skeleton-text"></div>
            <div className="skeleton1 skeleton-text"></div>
          </div>
          <div className="skeleton skeleton-table">
            <div className="skeleton1 skeleton-text"></div>
            <div className="skeleton1 skeleton-text"></div>
            <div className="skeleton1 skeleton-text"></div>
            <div className="skeleton1 skeleton-text"></div>
            <div className="skeleton1 skeleton-text"></div>
            <div className="skeleton1 skeleton-text"></div>
          </div>
          <div className="skeleton skeleton-table">
            <div className="skeleton1 skeleton-text"></div>
            <div className="skeleton1 skeleton-text"></div>
            <div className="skeleton1 skeleton-text"></div>
            <div className="skeleton1 skeleton-text"></div>
            <div className="skeleton1 skeleton-text"></div>
            <div className="skeleton1 skeleton-text"></div>
          </div>
          <div className="skeleton skeleton-table">
            <div className="skeleton1 skeleton-text"></div>
            <div className="skeleton1 skeleton-text"></div>
            <div className="skeleton1 skeleton-text"></div>
            <div className="skeleton1 skeleton-text"></div>
            <div className="skeleton1 skeleton-text"></div>
            <div className="skeleton1 skeleton-text"></div>
          </div>
          <div className="skeleton skeleton-table">
            <div className="skeleton1 skeleton-text"></div>
            <div className="skeleton1 skeleton-text"></div>
            <div className="skeleton1 skeleton-text"></div>
            <div className="skeleton1 skeleton-text"></div>
            <div className="skeleton1 skeleton-text"></div>
            <div className="skeleton1 skeleton-text"></div>
          </div>
        </div>
      ) : (
        <div className="card-loading">
          <div className="card">
            <div className="skeleton-head ipn-head"></div>
            <div className="apps-content">
              <div className="skeleton-app app-text1"></div>
              <div className="skeleton-app app-text2"></div>
            </div>
            <div className={props.contentOf === 'fiar' ? 'skeleton-head fiar-bar' : 'skeleton-bar bar'}></div>
          </div>
          <div className="card">
            <div className="skeleton-head ipn-head"></div>
            <div className="apps-content">
              <div className="skeleton-app app-text1"></div>
              <div className="skeleton-app app-text2"></div>
            </div>
            <div className={props.contentOf === 'fiar' ? 'skeleton-head fiar-bar' : 'skeleton-bar bar'}></div>
          </div>
          <div className="card">
            <div className="skeleton-head ipn-head"></div>
            <div className="apps-content">
              <div className="skeleton-app app-text1"></div>
              <div className="skeleton-app app-text2"></div>
            </div>
            <div className={props.contentOf === 'fiar' ? 'skeleton-head fiar-bar' : 'skeleton-bar bar'}></div>
          </div>
          <div className="card">
            <div className="skeleton-head ipn-head"></div>
            <div className="apps-content">
              <div className="skeleton-app app-text1"></div>
              <div className="skeleton-app app-text2"></div>
            </div>
            <div className={props.contentOf === 'fiar' ? 'skeleton-head fiar-bar' : 'skeleton-bar bar'}></div>
          </div>
          <div className="card">
            <div className="skeleton-head ipn-head"></div>
            <div className="apps-content">
              <div className="skeleton-app app-text1"></div>
              <div className="skeleton-app app-text2"></div>
            </div>
            <div className={props.contentOf === 'fiar' ? 'skeleton-head fiar-bar' : 'skeleton-bar bar'}></div>
          </div>
          <div className="card">
            <div className="skeleton-head ipn-head"></div>
            <div className="apps-content">
              <div className="skeleton-app app-text1"></div>
              <div className="skeleton-app app-text2"></div>
            </div>
            <div className={props.contentOf === 'fiar' ? 'skeleton-head fiar-bar' : 'skeleton-bar bar'}></div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
}

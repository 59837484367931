import React from "react";
import './CheckBox.css';
import WhiteTick from '../../../images/WhiteTick.svg';
import UnselectCheckbox from '../../../images/UnselectCheckbox.svg';
const CheckBox = (props) => {
    return (
        <div
            className={`check-box ${props.checked ? ' check-box-checked' : ' check-box-unchecked'}`}
            onClick={props.onClick}>

        {props.unselect?<img className={`check-mark`} src={UnselectCheckbox} alt='check mark'/>:<img className={`check-mark`} src={WhiteTick} alt='check mark'/>}
        </div>

    );
}

export default CheckBox;
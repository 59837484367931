import React from 'react';
import { connect } from 'react-redux'
import { userActions } from '../../../../../redux/actions/userActions';
import './CustomerReview.css'
import CustomerReviewDetail from './CustomerReviewDetail'
import Input from '../../../../Common/Input/Input';
import Button from '../../../../Common/Button/Button'

class ContractorReview extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            EditDetailsForReview: true
        }
    }

    completeReview = () => {
        this.setState({
            EditDetailsForReview: !this.state.EditDetailsForReview
        })
    }


    render() {
        const CustomerReviewDetails = [
            {
                ReviewParameter: 'Company Name',
                SharedDetail: 'Enerva Energy Solutions Inc',
                ReviewStatus: false
            },
            {
                ReviewParameter: 'Address',
                SharedDetail: 'Test address',
                ReviewStatus: false
            },
            {
                ReviewParameter: 'Website',
                SharedDetail: 'www.google.com',
                ReviewStatus: false
            },
            {
                ReviewParameter: 'Member of SESA',
                SharedDetail: 'Yes',
                ReviewStatus: false
            },
            {
                ReviewParameter: 'Member of CANREA',
                SharedDetail: 'Yes',
                ReviewStatus: false
            },
            {
                ReviewParameter: 'Company Name',
                SharedDetail: 'Enerva Energy Solutions Inc',
                ReviewStatus: false
            },
            {
                ReviewParameter: 'Address',
                SharedDetail: 'Test address',
                ReviewStatus: false
            },
            {
                ReviewParameter: 'Website',
                SharedDetail: 'www.google.com',
                ReviewStatus: false
            },
            {
                ReviewParameter: 'Member of SESA',
                SharedDetail: 'Yes',
                ReviewStatus: false
            },
            {
                ReviewParameter: 'Member of CANREA',
                SharedDetail: 'Yes',
                ReviewStatus: false
            }
        ]
        const { EditDetailsForReview } = this.state;
        return (
            <div>
                <div className="review-container">
                    <div className="section-header">
                        <div className="section-top-details">CON-87239</div>
                        <div className="section-top-details name-section">Belle Gonzales</div>
                        <div className="section-top-details">belle@hotmail.com</div>
                    </div>
                    <div className="section-below-header">
                        <h2 className="section-title" dangerouslySetInnerHTML={{ __html: 'Contractor Review' }}></h2>
                        <div className="review-status-selection">
                            <Input
                                type='dropdown'
                                label={'CHANGE STATUS'}
                                name='accountCategory'
                                // onChange={props.onCatChange}
                                // value={AccountDetails.accountCategory === 'contractor' ? { value: 'contractor', label: 'Contractor View' } : { value: 'customer', label: 'Customer View' }}
                                options={[{ value: 'Participating Contractor', label: 'Participating Contractor' }, { value: 'Flagged for QA/QC', label: 'Flagged for QA/QC' }]}
                                placeholder={'Select status from list'}
                            />
                        </div>
                    </div>
                    <div className="customer-review-container">
                        <div className={`contractor-review-table ${EditDetailsForReview ? 'review-mode' : ''}`}>
                            <div className="contractor-review-table-left">
                                <div className="contractor-review-header">
                                    {EditDetailsForReview ? <div className="review-detail">S.No</div>
                                        : ''}
                                    <div className="review-detail">Review Parameter</div>
                                    <div className="review-detail">Shared Detail</div>
                                    <div className="review-detail">Confirmed & Approved</div>
                                </div>
                                <div className="contractor-review-inner-container">
                                    {CustomerReviewDetails.map((ReviewDetail, i) => (
                                        <CustomerReviewDetail EditDetailsForReview={EditDetailsForReview} ReviewDetail={ReviewDetail} Index={i + 1} />
                                    ))}
                                </div>
                            </div>
                            <div className='vertical-separator-box'>
                                <span className='separator'></span>
                            </div>
                            <div className="contractor-review-table-right">
                                <span>You are about to complete the review and update the status of the contractor.</span>
                                <span>Please verify the details and the notes and confirm their accuracy</span>
                            </div>
                        </div>
                        <div className="review-notes-container">
                            <label>Notes:</label>
                            {EditDetailsForReview ?
                                <textarea id="notes" name="notes" rows="3" cols="50" />
                                :
                                <div className="review-notes-text">
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis
                                </div>
                            }
                        </div>
                    </div>
                    {EditDetailsForReview ?
                        <div className="review-btn-container">
                            <Button
                                title={'Complete Review'}
                                className='signup-btn'
                                onClick={this.completeReview}
                                uppercase
                            />
                        </div>
                        :
                        <div className="review-btn-container">
                            <Button
                                title={'Edit Review'}
                                className='edit-review'
                                onClick={this.completeReview}
                                uppercase
                            />
                            <Button
                                title={'Confirm Review'}
                                className='signup-btn'
                                uppercase
                            />
                        </div>
                    }
                </div>
            </div>
        );
    }
}

function mapState(state) {
    const { addParticipantModalShown } = state.registration;
    return { addParticipantModalShown };
}

const actionCreators = {
    showModal: userActions.showEditParticipantModal
}

export default connect(mapState, actionCreators)(ContractorReview);

import firebase from './Firebase';
const moment = require('moment-timezone');

export const utils = {
    formatNumberWithCommas : (arg)=>{
        if(arg){
            if(parseFloat(arg) >= 1000000){
                return `${utils.convertToInternationalCurrencySystem(arg)}`;
            } else {
                return `${(+arg).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
            }
        }
        else {
            return 'N/A';
        }
    },
    formatNumberWithOnlyCommas : (arg)=>{
        if(arg){
            return `$${(+arg).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
        }
        else {
            return 'N/A';
        }
    },
    getMinDate : () => {
        return moment('2021-05-01 23:59:00');
    },
    uploadFromBlobAsync: async ({ file, name }) => {
        if (!file || !name) return null
        try {
            const snapshot = await firebase.uploadBytes(firebase.storageRef(firebase.storage, name), file);
            return await firebase.getDownloadURL(snapshot.ref);
        } catch (error) {
            throw error
        }
    },
    deleteFileFromFirebaseStorage : async (fileURL) => {
        var fileRef = await firebase.storageRef(firebase.storage,fileURL);
        return await firebase.deleteObject(fileRef).then(() => {
            // File deleted successfully
          }).catch((error) => {
            // Uh-oh, an error occurred!
          });
    },
    formatDateForES : (dateString)=>{
        return moment(dateString).format();
    },
    formatPhoneNumber: (phoneNumberString) => {
        var cleaned = ('' + phoneNumberString).replace(/\D/g, '')
        var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)
        if (match) {
            var intlCode = (match[1] ? '+1 ' : '')
            return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('')
        }
        return null;
    },
    isNullOrEmpty: (item) => {
        if (typeof item === 'undefined' || (item === null)) {
            return true;
        }
        if (typeof item === 'string') {
            return item.trim() === '';
        }
        else if (typeof item === 'object') {
            return Object.keys(item).length === 0;
        }
        else if (typeof item === 'number') {
            return item < 0;
        }
        return false;
    },
    cleanHTMLText: (html)=>{
        var div = document.createElement("div");
        div.innerHTML = html;
        let ans = div.textContent;
        div.remove();
        return ans;
    },
    getProfilePicFromName: (firstName, lastName) => {
        let firstLetter = firstName[0].toUpperCase();
        let secondLetter = lastName[0].toUpperCase();
        return `${firstLetter}${secondLetter}`;
    },
    formatTitle: (text) => {
        return text.replace(
            /\w\S*/g,
            function (txt) {
                return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
            }
        );
    },
    convertToInternationalCurrencySystem :(labelValue) =>{

        // Nine Zeroes for Billions
        return Math.abs(Number(labelValue)) >= 1.0e+9
    
        ? (Math.abs(Number(labelValue)) / 1.0e+9).toFixed(2) + "B"
        // Six Zeroes for Millions
        : Math.abs(Number(labelValue)) >= 1.0e+6
    
        ? (Math.abs(Number(labelValue)) / 1.0e+6).toFixed(2) + "M"
        // Three Zeroes for Thousands
    
        : Math.abs(Number(labelValue));
    
    },
    formatAsCAD: (arg) => {
        if(arg){
            if(parseFloat(arg) >= 1000000){
                return `$${utils.convertToInternationalCurrencySystem(arg)}`;
            } else {
                return `$${(+arg).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
            }
        }
        else {
            return 'N/A';
        }
    },
    formatKwh: (arg) => {
        if(arg){
            return `${(+arg).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} kWh`;
        }
        else {
            return 'N/A';
        }
    },
    formatKw: (arg) => {
        if(arg){
            return `${(+arg).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} kW`;
        }
        else {
            return 'N/A';
        }
    },
    getDateFromAmericanDateString : (targetDate)=>{
        return moment(targetDate, "MM/DD/YYYY").toDate();
    },
    formatDate: (date) => {
        return moment(date).format("DD MMM YYYY");
    },
    getCurrentDateAndTimeStamp : () =>{
        return moment(new Date()).format("DD_MMM_YYYY_HH_mm_ss");
    },
    getDaysFromToday: (date) => {
        return moment(new Date()).diff(moment(date), 'days');
    },
    formatAmericanDate: (date) => {
        if(date){
            return moment(date).format("MM/DD/YYYY");
        } else {
            return "N/A";
        }
    },
    addDays : (date, numDays)=>{
        return moment(utils.formatAmericanDate(date)).add(numDays, 'days').format("MM/DD/YYYY");
    },
    convertCADateToDate : (targetDate)=>{
        return moment(targetDate, "MM/DD/YYYY").toDate();
    },
    formatLogTime: (date) => {
        return moment(date).format("MM-DD-YY hh:mm:ss");
    },
    formatLogTimeUTC: (date) => {
        return moment(date).utc().format("MM-DD-YY hh:mm:ss A");
    },
    validateEmail: (email) => {
        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,20})+$/.test(email)) {
            return true;
        }
        return false;
    },
    hasNumber: (myString) => {
        return /\d/.test(myString);
    },
    hasLowerCase: (str) => {
        return (/[a-z]/.test(str));
    },
    hasUpperCase: (str) => {
        return (/[A-Z]/.test(str));
    },
    hasSpecialChar: (str) => {
        var format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
        if (format.test(str)) {
            return true;
        } else {
            return false;
        }
    },
    getParameterByName: (name, url = window.location.href) => {
        name = name.replace(/[\[\]]/g, '\\$&');
        var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
            results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, ' '));
    },
    validateCAPostalCode: (postalCode) => {
        if (!postalCode) {
            return false;
        }
        postalCode = postalCode.toString().trim();
        let ca = new RegExp(/^[ABCEGHJKLMNPRSTVXY]\d[ABCEGHJKLMNPRSTVWXYZ]( )?\d[ABCEGHJKLMNPRSTVWXYZ]\d$/i);
        if (ca.test(postalCode.toString())) {
            return true;
        }
        return false;
    },
    formatCAPostalCode: (postalCode) => {
        postalCode = postalCode.replace(/\s/g, "");
        let partA = postalCode.substring(0, 3).toUpperCase();
        let partB = postalCode.substring(3, 6).toUpperCase();
        return `${partA} ${partB}`;
    },
    validatePassword: (pass) => {
        let validCount = 0;
        if (pass.length < 8) {
            return false;
        }
        if (utils.hasNumber(pass)) {
            validCount++;
        }
        if (utils.hasUpperCase(pass)) {
            validCount++;
        }
        if (utils.hasLowerCase(pass)) {
            validCount++;
        }
        if (utils.hasSpecialChar(pass)) {
            validCount++;
        }
        return validCount >= 3;
    },
    makeWebRequest: (url, method, body) => {
        let myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        var requestOptions = {
            method: method,
            headers: myHeaders,
            redirect: 'follow'
        };

        if (body) {
            var raw = JSON.stringify(body);
            requestOptions.body = raw;
        }

        return new Promise(function (resolve, reject) {
            fetch(url, requestOptions)
                .then(response => response.text())
                .then(result => {
                    let json = JSON.parse(result);
                    if (json.success) {
                        resolve(json.data);
                    } else {
                        reject(json.error);
                    }
                })
                .catch(function(e) {
                    console.log("errorutils==>",e); // "oh, no!"
                });
        });
    },
    makeWebRequestFileDownload: (url, method, body) => {
        let myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/octet-stream");
        var requestOptions = {
            method: method,
            headers: myHeaders,
            redirect: 'follow'
        };

        /* if (body) {
            var raw = JSON.stringify(body);
            requestOptions.body = raw;
        } */

        return new Promise(function (resolve, reject) {
            let fileName = '';
            fetch(url, requestOptions)
                .then(response => {
                    fileName = response.headers.get('content-disposition').match(/"([^"]+)"/)[1];
                    console.log(fileName)
                    return response.blob();
                })
                .then(blob => {
                    const link = document.createElement('a');
                    const url = URL.createObjectURL(blob);
                    link.href = url;
                    link.download = `${fileName}`;
                    link.click();
                    resolve(blob);
                })
                .catch(function (e) {
                    console.log("errorutils==>", e); // "oh, no!"
                    reject(e);
                });
        });
    },
    makeWebRequestFileDownloadZip: (url, method,fileName, body) => {
        let myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/octet-stream");
        var requestOptions = {
            method: method,
            headers: myHeaders,
            redirect: 'follow'
        };

        return new Promise(function (resolve, reject) {
            fetch(url, requestOptions)
                .then(response => {
                    return response.blob();
                })
                .then(blob => {
                    const link = document.createElement('a');
                    const url = URL.createObjectURL(blob);
                    link.href = url;
                    link.download = `${fileName}`;
                    link.click();
                    resolve(blob);
                })
                .catch(function (e) {
                    console.log("errorutils==>", e); // "oh, no!"
                    reject(e);
                });
        });
    },
    handleFileUpload : (event, folderPath) => {
        const files = event;
        const formData = new FormData()
        formData.append('files', files);
        formData.append("documentPath", folderPath);
        return new Promise(function (resolve, reject) {
         fetch(firebase.fileUploadUrl, {
          method: 'POST',
          body: formData
        })
        .then(response => response.json())
        .then(data => {
          resolve(data)
        })
        .catch(error => {
          reject(error)
        })})
      },
    makeWebRequestForApplications: (url, method, body) => {
        let myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        var requestOptions = {
            method: method,
            headers: myHeaders,
            redirect: 'follow'
        };

        if (body) {
            var raw = JSON.stringify(body);
            requestOptions.body = raw;
        }

        return new Promise(function (resolve, reject) {
            firebase.auth.currentUser.getIdToken().then((token) => {
            url = `${url}?token=${token}`;
            fetch(url, requestOptions)
                .then(response => response.text())
                .then(result => {
                    let json = JSON.parse(result);
                    if (json.success) {
                        resolve(json.data);
                    } else {
                        reject(json.error);
                    }
                }).catch((error) => {
                    reject(error);
                });
            });
        });
    },
    sortData: (property, order = 'desc', columnType = "string") => {
        var sort_order = 1;
        if (order === "desc") {
            sort_order = -1;
        }
        switch (columnType) {
            case 'string':
                return function (a, b) {
                    let varA = (typeof a[property] === 'string')
                        ? a[property].toUpperCase() : a[property];
                    let varB = (typeof b[property] === 'string')
                        ? b[property].toUpperCase() : b[property];
                    if (varA < varB) {
                        return -1 * sort_order;
                    } else if (varA > varB) {
                        return 1 * sort_order;
                    } else {
                        return 0 * sort_order;
                    }
                }
            case 'date':
                return function (a, b) {
                    let dateA = new Date(a[property]);
                    let dateB = new Date(b[property])
                    if (dateA < dateB) {
                        return -1 * sort_order;
                    } else if (dateA > dateB) {
                        return 1 * sort_order;
                    } else {
                        return 0 * sort_order;
                    }
                }
            case 'number':
                return function (a, b) {
                    a = parseFloat(a[property]);
                    b = parseFloat(b[property]);
                    if (a < b) {
                        return -1 * sort_order;
                    } else if (a > b) {
                        return 1 * sort_order;
                    } else {
                        return 0 * sort_order;
                    }
                }
            default:
                break;
        }
    },
    getAdminConfigDoc: (docName) => {
        return new Promise(function (resolve, reject) {
            firebase.db.collection('adminConfig').doc(docName).get().then(function (doc) {
                if (doc.exists) {
                    resolve(doc.data());
                } else {
                    reject("Cannot find config");
                }
            }).catch(function (error) {
                console.log("Cannot find config", error);
            });;
        });
    },
    getCollectionAsArray: (collectionName) => {
        return new Promise((resolve, reject) => {
          firebase.db.collection(collectionName)
            .get().then((snap) => {
              const data = [];
              snap.forEach((doc) => {
                let item = doc.data();
                item.id = doc.id;
                if (item.createdAt) {
                  item.createdAt = item.createdAt.toDate().toString();
                }
                if (item.updatedAt) {
                  item.updatedAt = item.updatedAt.toDate().toString();
                }
                for (let key in item) {
                  if (key.toLowerCase().includes("time")) {
                    if (typeof item[key] !== 'string') {
                      item[key] = item[key].toDate().toString();
                    }
                  }
                }
                data.push(item);
              });
              const result = data;
              if (result) {
                resolve(result);
              } else {
                reject(new Error());
              }
              return;
            })
            .catch(err => reject(err));
        })
      },
    getAdminConfigDocForSPP: (docName) => {
        return new Promise(function (resolve, reject) {
            firebase.db.collection('era').doc('speed').collection('adminConfig').doc(docName).get().then(function (doc) {
                if (doc.exists) {
                    resolve(doc.data());
                } else {
                    reject("Cannot find config");
                }
            }).catch(function (error) {
                console.log("Cannot find config", error);
            });;
        });
    },
    getTEM : (measure) => {
        let options = ["Total Emissions Reduction",
        "Total Emissions Reduction - VFD for Fan/Pump Motor",
        "Total Emissions Reduction - Strip Curtain",
        "Total Emissions Reduction - Water Heater",
        "Total Emissions Reduction - Insulation",
        "Total Emissions Reduction - Windows",
        "Total Emissions Reduction - Economizer",
        "Total Emissions Reduction - Steam Fittings and Pipe Insulation",
        "Total Emissions Reduction - Steam Boiler",
        "Total Emissions Reduction - Boiler or Heater",
        "Total Emissions Reduction - ASHP or VRF",
        "Total Emissions Reduction - Make-Up Air Unit",
        "Total Emissions Reduction - HRV/ERV",
        "Total Emissions Reduction - Pipe Insulation",
        "Total Emissions Reduction - Gas Heat Pump"];
        for (let item of options) {
          if (measure[item]) {
            return parseFloat(measure[item]).toFixed(2);
          }
        }
        return '';
      },
      sumOfTEMs : (measures) => {
        let ans = 0.0;
        for (let item of measures) {
          ans += parseFloat(utils.getTEM(item));
        }
        return ans.toFixed(2);
      },
      getTEMI : (measure) => {
        let options = ["Total Eligible Measure Incentive",
        "Total Eligible Measure Incentive - VFD for Fan/Pump Motor",
            "Total Eligible Measure Incentive - Strip Curtain",
            "Total Eligible Measure Incentive - Water Heater",
            "Total Eligible Measure Incentive - Insulation",
            "Total Eligible Measure Incentive - Windows",
            "Total Eligible Measure Incentive - Economizer",
            "Total Eligible Measure Incentive - Steam Fittings and Pipe Insulation",
            "Total Eligible Measure Incentive - Steam Boiler",
            "Total Eligible Measure Incentive - Boiler or Heater",
            "Total Eligible Measure Incentive - ASHP or VRF",
            "Total Eligible Measure Incentive - Make-Up Air Unit",
            "Total Eligible Measure Incentive - HRV/ERV",
            "Total Eligible Measure Incentive - Pipe Insulation", 
            "Total Eligible Measure Incentive - Gas Heat Pump"];
    for (let item of options) {
      if (measure[item]) {
        return parseFloat(measure[item]).toFixed(2);
      }
    }
    return '';
  },
  sumOfTEMIs : (measures, program, stream) => {
    let ans = 0.0;
    for (let item of measures) {
      ans += parseFloat(utils.getTEMI(item));
    }
    if(program === 'esb' && stream === "esb" && ans >= 250000){
      ans = 250000.00;
    } else if(program === 'esb' && stream === "esb" && ans >= 1000000) {
        ans = 1000000.00;
    } else if(program === 'speed' && ans >= 1000000) {
        ans = 1000000.00;
    }
    return ans.toFixed(2);
  },
  getApplicableTEMI : (measure) => {
    let options = ["Total Applicable Measure Incentive",
        "Total Applicable Measure Incentive - VFD for Fan/Pump Motor",
            "Total Applicable Measure Incentive - Strip Curtain",
            "Total Applicable Measure Incentive - Water Heater",
            "Total Applicable Measure Incentive - Insulation",
            "Total Applicable Measure Incentive - Windows",
            "Total Applicable Measure Incentive - Economizer",
            "Total Applicable Measure Incentive - Steam Fittings and Pipe Insulation",
            "Total Applicable Measure Incentive - Steam Boiler",
            "Total Applicable Measure Incentive - Boiler or Heater",
            "Total Applicable Measure Incentive - ASHP or VRF",
            "Total Applicable Measure Incentive - Make-Up Air Unit",
            "Total Applicable Measure Incentive - HRV/ERV",
            "Total Applicable Measure Incentive - Pipe Insulation",
            "Total Applicable Measure Incentive - Gas Heat Pump",];
    for (let item of options) {
      if (measure[item]) {
        return parseFloat(measure[item]).toFixed(2);
      }
    }
    return 0.0;
  },
      sumOfApplicableTEMIs : (measures, applicationObj) => {
        let maxVal = applicationObj && applicationObj.program === 'esb' ? 250000.0 : 1000000;
        if(applicationObj && applicationObj['Reviewer Estimated Incentive']){
          maxVal = parseFloat(applicationObj['Reviewer Estimated Incentive']);
          maxVal = applicationObj && applicationObj.program === 'speed' ? maxVal * 1.05 : maxVal * 1.10;// Set speed to 105% and ESB to 110% of original
        }
        let ans = 0.0;
        for (let item of measures) {
          ans += parseFloat(utils.getApplicableTEMI(item));
        }
        if (ans >= maxVal) {
          ans = maxVal;
        }
        return ans.toFixed(2);
      },
      buildIPNExport : (ipn) =>{
          if(ipn.applications){
            let data = Object.keys(ipn.applications).map((key)=>{
                return { 
                    ipnNo : ipn.ipnNo, 
                    payoutApprovedDate : utils.formatAmericanDate(ipn.createdAt),
                    appNo : `${ipn.applications[key].appNo}`,
                    payeeName : ipn.applications[key].payeeName || 'N/A',
                    paymentMode : ipn.applications[key].paymentMode || 'N/A',
                    customerName : ipn.applications[key].customerName || '',
                    contractorName : ipn.applications[key].contractorName || '',
                    incentive : ipn.applications[key].incentive,
                    projectCost : ipn.applications[key].projectCost,
                    emissionsReduction : ipn.applications[key].emissionsReduction,
                 }
            })
            return data;
          } else {
              return [];
          }
      },
      buildDIARExport : (appList, namesOnBankAccount) =>{
        if(appList){
          let data = appList.map((app)=>{
              let linkedMeasures = [];
              let delegatedPayeeName = "";
              let namesOBA = namesOnBankAccount.length && namesOnBankAccount.map((ele) => {
                if (ele.appNo === app.appNo) {
                    delegatedPayeeName = ele.nameOnBankAccount;
                }
            })
              if(app.linkedMeasures){
                  linkedMeasures = app.linkedMeasures;
              }
              return { 
                  appNo : app.appNo || '',
                  payeeName : app.payeeName || 'N/A',
                  customerName : app.customerCompanyName || '',
                  contractorName : app.contractorCompanyName || '',
                  expectedProjectStartDate : app.expectedProjectStartDate,
                  expectedProjectEndDate : app.expectedProjectEndDate,
                  isReceivingOtherFunding : app.isReceivingOtherFunding,
                  applicationLink : `${firebase.hostUrl}/esb/viewapplication/${app.id}`,
                  preProjectApplicationDraftSubmittedDate : app.preProjectApplicationDraftSubmittedDate,
                  projectCompletionDeadline : app.projectCompletionDeadline,
                  preProjectApplicationDraftApprovedDate : app.preProjectApplicationDraftApprovedDate,
                  postProjectApplicationSubmittedDate : app.postProjectApplicationSubmittedDate,
                  incentiveRecommendedDate : app.incentiveRecommendedDate,
                  recommendedIncentive : app.recommendedIncentive,
                  payeeAccountCategory : app.payeeAccountCategory,
                  actualProjectEndDate : app.actualProjectEndDate,
                  measureNames : linkedMeasures.map((m)=>m.name).join(','),
                  measureCategories : linkedMeasures.map((m)=>m.category).join(','),
                  qaQcType : app.qaQcType,
                  customer1MIncentiveCapHit : app.customer1MIncentiveCapHit ? "Yes" : "No",
                  IncentivePercentage : `${app.IncentivePercentage}%`,
                  differenceAmountPost : app.differenceAmountPost,
                  explanationForDifference : app.explanationForDifference,
                  differentNameOnBankAccount : app.differentNameOnBankAccount,
                  delegatedPayeeName : delegatedPayeeName,
                  followedUpViaPhoneCall : app.followedUpViaPhoneCall,
                  followedUpViaEmail : app.followedUpViaEmail,
                  approvedBankDetailsNote : utils.cleanHTMLText(app.approvedBankDetailsNote)
               }
          })
          return data;
        } else {
            return [];
        }
    },
      buildMassPayExport : (ipn) =>{
        if(ipn.applications){
          let data = Object.keys(ipn.applications).map((key)=>{
              return { 
                  memo : `${ipn.applications[key].appNo.replace("ESB-","")}/${ipn.ipnNo.replace("IPN-","")}`, 
                  payeeName : ipn.applications[key].payeeName || 'N/A',
                  incentive : ipn.applications[key].incentive,
               }
          })
          return data;
        } else {
            return [];
        }
    },
      getTopThree : (apps)=>{
          let topThree = [];
          if(!utils.isNullOrEmpty(apps)){
            for(let app of apps){
                topThree.push(app);
                }
                topThree.sort((a,b)=>{
                    return parseFloat(b.incentive) - parseFloat(a.incentive)
                })
                topThree = topThree.splice(0,3);
          }
          return topThree;
      },
       checkForDIARUpdates : (callback) => {
        firebase.db.collection('applications')
        .where("status", "==", "Incentive Recommendation Approval Pending")
        .onSnapshot((_) => {
            callback();
        });
      }
}
import React from 'react';
import './Drawer.css';
import Drawer from "@material-ui/core/Drawer";
import CloseIcon from '../../../images/CloseIconGreen.svg'

class DrawerComponent extends React.Component {
    constructor(props) {
        super(props);

    }

    render() {
        const { openDrawer } = this.props

        return <div className={`static-drawer-component ${this.props.classname ? this.props.classname : ''}`}>
            <div>
                <React.Fragment key={"right"}>
                    <Drawer
                        anchor={"right"}
                        open={openDrawer}
                        onClose={() => this.props.toggleDrawer(!openDrawer)}
                        className={this.props.className}
                    >
                        <div className='drawer-container'>
                            <img className='close-icon' onClick={() => this.props.toggleDrawer(!openDrawer)} src={CloseIcon} alt='close modal'/>
                            {this.props.children}
                        </div>
                    </Drawer>
                </React.Fragment>
            </div>
        </div>
    }

}

export default DrawerComponent;
import React from 'react';
import AdminActionButton from '../../../Common/AdminActionButton/AdminActionButton';

class ChangeRequestTab extends React.Component {

    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        const { Request, currentProgram } = this.props;
        // const status = Request.Status.toLowerCase();
        // // const statusConfig = {
        // //     showFlagForQA : ['pending approval','details submission pending','participating'].includes(status),
        // //     showSuspend : ['pending approval','participating'].includes(status),
        // //     showReinstate : ['flagged for qa/qc', 'suspended'].includes(status),
        // //     showViewApplications : ['participating','flagged for qa/qc', 'suspended'].includes(status),
        // //     showDocs: false,
        // //     // showDocs: ['pending approval','participating','flagged for qa/qc', 'suspended'].includes(status)
        // // }
        return (
            <div className='customer-details-row' id={this.props.id}>
                <div className="customer-details">
                    <div><label>Change Request No:</label><span>{Request.changeReqNo}</span></div>
                    <div><label>Created Date:</label><span>{Request.createdAt}</span></div>
                    <div><label>Customer Name:</label><span>{Request.companyName}</span></div>
                    <div><label>Application ID:</label><span>{Request.appNo}</span></div>
                    <div><label>Request Type:</label><span>{Request.requestType}</span></div>
                    <div><label>Status:</label><span>{Request.status}</span></div>
                    <div className="show-more-btn" onClick={() => this.props.ShowMoreDetails(this.props.id)}></div>
                </div>
                    <div className="customer-more-details">
                        <div>
                            { Request.status.toLowerCase() === 'submitted' ? 
                            <AdminActionButton 
                                iconClass={`request-icon`}
                                actionName={`Change Request Board-Review Request`}
                                showActionModal={this.props.showActionModal}
                                application={Request}
                                appModalType={'review'}
                                title={'Review Request'}
                            />
                            :''}
                            { Request.status.toLowerCase() === 'rejected' ||  Request.status.toLowerCase() === 'approved' ? 
                            <AdminActionButton 
                                iconClass={`request-icon`}
                                actionName={`Change Request Board-View Request`}
                                showActionModal={this.props.showActionModal}
                                application={Request}
                                appModalType={'view-change-request'}
                                title={'View Request'}
                            />
                            :''}
                            {
                              Request.status.toLowerCase() === 'approved' ? 
                              <AdminActionButton 
                                iconClass={`reject-icon`}
                                actionName={`Change Request Board-Reject Request`}
                                showActionModal={this.props.showActionModal}
                                application={Request}
                                appModalType={'reject-change-request'}
                                title={'Reject Request'}
                            />
                              :''}
                            {
                              Request.status.toLowerCase() === 'rejected' ? 
                            //   <div className="more-details-links reinitiate-request-icon" onClick={() => this.props.showActionModal({...Request, modalType: 'reinitiate-change-request'})}>Reinitiate Request</div> 
                              <AdminActionButton 
                                iconClass={`reinitiate-request-icon`}
                                actionName={`Change Request Board-Reinitiate Request`}
                                showActionModal={this.props.showActionModal}
                                application={Request}
                                appModalType={'reinitiate-change-request'}
                                title={'Reinitiate Request'}
                            />
                              :''}
                        </div>
                    </div>
            </div>
        );
    }
}

export default ChangeRequestTab;

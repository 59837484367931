import React from 'react';
import './SearchInput.css';
import { ReactComponent as SearchVector } from '../../../images/SearchVector.svg'

class SearchInput extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            InputValue: props.value
        }

    }

    render() {
        const { InputValue } = this.state
        return <div className={`search-box ${this.props.classname ? this.props.classname : ''}`}>
            <input
                onChange={(e) => {
                    const { value } = e.target;
                    this.setState({ InputValue: value }, () => {
                        this.props.onClick(this.state.InputValue)
                    })
                }}
                className='search-input'
                placeholder={this.props.placeholder}
                type='text'
                value={InputValue} />
            <button
                className='search-btn'
                onClick={this.props.disabled ? null : () => this.props.onClick(this.state.InputValue)}>
                <SearchVector width="18px" height="20px" />
            </button>
        </div>
    }

}

export default SearchInput;
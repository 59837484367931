import React from "react";
import PaymentRow from "./PaymentRow";
import SearchInput from "../../../Common/SearchInput/SearchInput";
import FilterDropdown from "../../../Common/FilterDropdown/FilterDropdown";
import { appActions } from "../../../../redux/actions/appActions";
import { connect } from "react-redux";
import { debounce } from "debounce";
import "./Payment.css";
import { adminSettingsActions } from "../../../../redux/actions/adminSettingsActions";
import FlagIcon from "../../../Common/FlagIcon/FlagIcon";
import SkeletonLoading from "../../../Common/SkeletonLoading/SkeletonLoading";
import Button from "../../../Common/Button/Button";
import Drawer from "../../../Common/Drawer/Drawer";
import FilterVector from "../../../../images/FilterVector.svg"

class PostProjectTab extends React.Component {
  constructor(props) {
    super(props);
    this.searchDebouncedData = debounce(this.searchData, 300);
    this.state = {
      openDrawer: false,
      selectedStatusFilters: [],
      selectedFlagFilters: [],
      programStream: [],
    };
  }

  componentDidMount() {
    if (this.props.currentProgram === "retrofit") {
      this.props.loadAdminConfigRetrofit();
    } else if(this.props.currentProgram === 'speed') {
      this.props.loadSPPAdminConfig()
    } else {
      this.props.loadAdminConfig();
    }
    this.searchData(true);
  }

  ShowMoreDetails = (id) => {
    let actElem = document.getElementById(id);
    if (actElem.classList.contains("active-row")) {
      actElem.classList.remove("active-row");
    } else {
      let actContElem = document.querySelector(".payment-details-row.active-row");
      if (actContElem !== null) {
        actContElem.classList.remove("active-row");
      }
      actElem.classList.add("active-row");
    }
  };

  onStatusFilterChange = (SelectedStatusFilters) => {
    this.props.setFilters("Payment", {
      SelectedStatusFilters,
    });
  };

  onFlagFilterChange = (SelectedFlagFilters) => {
    this.props.setFilters("Payment", {
      SelectedFlagFilters,
    });
  };

  onSearch = (searchInput) => {
    this.props.setFilters("Payment", {
      searchInput,
    });
  };

  searchData = (refresh = false) => {
    const { sortConfig, SelectedStatusFilters, SelectedFlagFilters, onlyActiveApps, currentProgram, StageFilters } = this.props;
    let config = {
      statuses: SelectedStatusFilters,
      stage: StageFilters,
      flags: SelectedFlagFilters,
      sort: sortConfig,
      activeApps: onlyActiveApps,
      program: currentProgram,
      stream: this.state.programStream,
    };
    if (this.props.currentProgram !== "retrofit") {
      this.props.getApplicationsByStage("Payment", this.props.searchInput, config, refresh ? 0 : this.props.appListOffset);
    } else {
      this.props.getApplicationsByStageRetrofit("Payment", this.props.searchInput, config, refresh ? 0 : this.props.appListOffset);
    }
  };

  handleListScroll = () => {
    if (this.props.moreApps) {
      this.searchDebouncedData();
    }
  };

  sortData = (property) => {
    this.props.setFilters("Payment", {
      sortConfig: { name: property, direction: this.props.sortConfig.direction === "desc" ? "asc" : "desc" },
    });
  };

  toggleDrawer = (status) => {
    let updateObj = {
      openDrawer: status,
    };
    this.setState(updateObj);
  };

  handleCheckboxChange = (e) => {
    const { name, value, checked } = e.target;
    let checkedStatus = this.state[name];
    if (checked) {
      // push selected value in list
      checkedStatus = [...checkedStatus, value];
    } else {
      // remove unchecked value from the list
      checkedStatus = checkedStatus.filter((x) => x !== value);
      // setLang(prev => prev.filter(x => x !== value));
    }
    this.setState({
      [name]: checkedStatus,
    });
  };

  applyFiters = () => {
    const { selectedFlagFilters, selectedStatusFilters, programStream } = this.state;
    this.props.setFilters("Payment", {
      SelectedFlagFilters: selectedFlagFilters,
      SelectedStatusFilters: selectedStatusFilters,
      stream: programStream,
    });
    this.toggleDrawer(false);
  };

  clearFilters = () => {
    this.props.setFilters("Payment", {
      SelectedFlagFilters: [],
      SelectedStatusFilters: [],
      stream: [],
    });
    this.setState({
      selectedStatusFilters: [],
      selectedFlagFilters: [],
      programStream: [],
    });
  };

  render() {
    const { SelectedStatusFilters, appStage, applicationsByStageLoading, SelectedFlagFilters, appList, configData, currentProgram } =
      this.props;
    // const FilterOptions = [
    //   "Application Under Review",
    //   "Application Draft",
    //   "Application Information Requested",
    //   "Application Submitted",
    //   "Application Rejected",
    // ];
    const { openDrawer, state, programStream, selectedFlagFilters, selectedStatusFilters } = this.state;
    let flagsList = [];
    let FilterOptions = [];
    let Filters = [];

    if (configData) {
      if (currentProgram === "retrofit") {
        Filters = configData.appStatuses["Payment"].map((item) => item.applicationStatusName);
        FilterOptions = [...Filters.slice(4, 10), ...Filters.slice(0, 4)]
        let paymentFlags = configData.appFlags.flags.filter((item) => item.stage === "Payment");
        flagsList = paymentFlags.map((item) => {
          return { value: item.status, icon: <FlagIcon color={item.colorCode} /> };
        });
      } else {
        flagsList = configData.appFlags.flags.map((item) => {
          return { value: item.status, icon: <FlagIcon color={item.colorCode} /> };
        });
        FilterOptions = configData.appStatuses["Payment"].map((item) => item.status);
      }
    }
    // if (configData) {
    //   flagsList = configData.appFlags.flags.map((item) => {
    //     return { value: item.status, icon: <FlagIcon color={item.colorCode} /> };
    //   });
    //   FilterOptions = configData.appStatuses["Payment"].map((item) => item.status);
    // }
    let diffPrograms = [
      { label: "Energy Savings For Business", value: "esb" },
      { label: "Comprehensive Energy Savings", value: "ces" },
    ];
    return (
      <div className="payment-tab">
        <Drawer toggleDrawer={this.toggleDrawer} openDrawer={openDrawer} className={"program-panel-drawer"}>
          <div className="filter-drawer">
            <h3>FILTER BY</h3>
            {configData ? (
              <div className="all-filters">
                <div className="filter">
                  <label className="filter-label">Status</label>
                  <div className="filter-options">
                    {FilterOptions.map((status, i) => {
                      return (
                        <label key={i}>
                          <input
                            type="checkbox"
                            name="selectedStatusFilters"
                            checked={selectedStatusFilters.includes(status)}
                            value={status}
                            onChange={(e) => this.handleCheckboxChange(e)}
                          />{" "}
                          {status}
                        </label>
                      );
                    })}
                  </div>
                </div>
                <div className="filter">
                  <label className="filter-label">Flag</label>
                  <div className="filter-options">
                    {flagsList.map((flagItem, i) => {
                      return (
                        <label key={i}>
                          <input
                            type="checkbox"
                            name="selectedFlagFilters"
                            checked={selectedFlagFilters.includes(flagItem.value)}
                            value={flagItem.value}
                            onChange={(e) => this.handleCheckboxChange(e)}
                          />{" "}
                          <span>{flagItem.icon}</span>
                          {flagItem.value}
                        </label>
                      );
                    })}
                  </div>
                </div>
                <div className="filter">
                  <label className="filter-label">Program</label>
                  <div className="filter-options">
                    {diffPrograms.map((program, i) => {
                      return (
                        <label key={i}>
                          <input
                            type="checkbox"
                            name="programStream"
                            checked={programStream.includes(program.value)}
                            value={program.value}
                            onChange={(e) => this.handleCheckboxChange(e)}
                          />
                          {program.label}
                        </label>
                      );
                    })}
                  </div>
                </div>
              </div>
            ) : null}
          </div>
          <Button className="inversed-btn drawer-btn first-btn" title="CLEAR ALL FILTERS" onClick={this.clearFilters} />
          <Button className="drawer-btn" title="APPLY FILTERS" onClick={this.applyFiters} />
        </Drawer>
        <div className="applications-top-bar">
          <div className="applications-top-bar-search">
            <SearchInput value={this.props.searchInput} placeholder="Search for Applications" onClick={this.onSearch} />
          </div>
          {currentProgram === "esb" ? (
            <div className="applications-top-bar-filter-btn">
              <Button className="filter-btn" uppercase title={"Filters"} svg={FilterVector} onClick={() => this.setState({ openDrawer: true })} />
            </div>
          ) : null}
          {currentProgram === "esb" ? null : <>
            <div className="applications-top-bar-filter">
              {configData ? (
                <FilterDropdown
                  label={"Filter by Status"}
                  FilterOptions={FilterOptions}
                  SelectedFilters={SelectedStatusFilters}
                  onChange={this.onStatusFilterChange}
                  className={"program-filter"}
                />
              ) : (
                ""
              )}
            </div>
            <div className="applications-top-bar-filter">
              {configData ? (
                <FilterDropdown
                  label={"Filter by Flag"}
                  iconBasedOption={true}
                  iconClass="flag-filter"
                  FilterOptions={flagsList}
                  SelectedFilters={SelectedFlagFilters}
                  onChange={this.onFlagFilterChange}
                  className={"program-filter"}
                />
              ) : (
                ""
              )}
            </div>
          </>}
        </div>
        <div className="payment-details-header payment-details-row">
          {currentProgram !== "retrofit" ? (
            <div className="payment-details">
              <div onClick={() => this.sortData("appNo", "string")}>
                <span>ID</span>
              </div>
              <div onClick={() => this.sortData("payeeInfo.payeeName", "string")}>
                <span>Payee</span>
              </div>
              <div onClick={() => this.sortData("companyName", "string")}>
                <span>Customer</span>
              </div>
              <div onClick={() => this.sortData("contractor.label", "string")}>
                <span>Contractor</span>
              </div>
              <div onClick={() => this.sortData("status", "string")}>
                <span>Status</span>
              </div>
              <div onClick={() => this.sortData("Payment - Incentive Recommended Time", "date")}>
                <span>Recommended Date</span>
              </div>
              <div onClick={() => this.sortData("PostProjectReviewerApprovedIncentive", "number")}>
                <span>Recommended Incentive</span>
              </div>
              <div onClick={() => this.sortData("Payment - Incentive Approved Time", "number")}>
                <span>Incentive Approved Date</span>
              </div>
              <div onClick={() => this.sortData("ipnNo", "number")}>
                <span>IPN ID</span>
              </div>
              <div></div>
            </div>
          ) : (
            <div className="payment-details">
              <div onClick={() => this.sortData("application_id", "string")}>ID</div>
              <div onClick={() => this.sortData("application_name", "string")}>Application Name</div>
              <div onClick={() => this.sortData("application_project_type", "string")}>Application Project Type</div>
              <div onClick={() => this.sortData("applicant_account_id", "string")}>Email</div>
              <div onClick={() => this.sortData("multi_site", "string")}>Multi-Site</div>
              <div onClick={() => this.sortData("application_status_name", "string")}>Status</div>
              <div onClick={() => this.sortData("pre_project_approval_date", "date")}>Post-Project Approved Date</div>
              <div onClick={() => this.sortData("submission_dt", "string")}>Invoice Submitted Date</div>
              <div onClick={() => this.sortData("total_est_app_incentive", "number")}>Estimated Incentive</div>
              <div></div>
            </div>
          )}
        </div>
        <div className="payment-details-list" id="CustomerDetailsList" onScroll={this.handleListScroll}>
          {applicationsByStageLoading && appStage !== "Payment" ? (
            <SkeletonLoading />
          ) : appList && appList.length ? (
            appList.map((application, i) => (
              <PaymentRow
                application={application}
                currentProgram={currentProgram}
                key={`application-${i}`}
                id={`application-${i}`}
                showActionModal={this.props.showActionModal}
                ShowMoreDetails={this.ShowMoreDetails}
              />
            ))
          ) : (
            <div className="no-records-found">No records.</div>
          )}
        </div>
      </div>
    );
  }
}

function mapState(state) {
  const { configData } = state.settings;
  const { currentProgram } = state.authentication;
  const { appList, appListOffset, appStage, applicationsByStageLoading, appStatusLogsLoading, activeStatusLogs, moreApps } =
    state.application;
  return {
    currentProgram,
    appStage,
    appList,
    appListOffset,
    applicationsByStageLoading,
    appStatusLogsLoading,
    activeStatusLogs,
    moreApps,
    configData,
  };
}

const actionCreators = {
  loadAdminConfig: adminSettingsActions.getAdminConfig,
  loadSPPAdminConfig: adminSettingsActions.getSPPAdminConfig,
  loadAdminConfigRetrofit: adminSettingsActions.getAdminConfigRetrofit,
  getApplicationsByStage: appActions.getApplicationsByStage,
  getApplicationsByStageRetrofit: appActions.getApplicationsByStageRetrofit,
};

export default connect(mapState, actionCreators)(PostProjectTab);

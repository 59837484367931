import React from "react";
import Tabs from "../../Common/Tabs/Tabs";
import DIAR from "./DIAR/DIAR";
import FIAR from "./FIAR/FIAR";
import ProgramLayout from "../../Common/ProgramLayout";
import Modal from "../../Common/Modal/Modal";
import { connect } from "react-redux";
import { userActions } from "../../../redux/actions/userActions";
import { appActions } from "../../../redux/actions/appActions";
import Button from "../../Common/Button/Button";
import { AuthContext } from "../../../firebaseAuthContext";
import Input from "../../Common/Input/Input";
import { userService } from "../../../services/userService";
import { applicationService } from "../../../services/applicationService";
import "./IncentiveReviewBoard.css";
import StatusLogs from "../ReviewBoard/StatusLogs/StatusLogs";
import AssignLabels from "./AssignLabels/AssignLabels";
import ViewChangeRequests from "../ApplicationsBoard/ViewChangeRequests/ViewChangeRequests";
import ViewChangeRequestLogs from "../ApplicationsBoard/ViewChangeRequestLogs/ViewChangeRequestLogs"; 
import IncentivesSelectedForAction from './IncentivesSelectedForAction/IncentivesSelectedForAction';
import Drawer from "../../Common/Drawer/Drawer";
import { utils } from "../../../helpers/utils";
import { adminSettingsActions } from "../../../redux/actions/adminSettingsActions";
import { debounce } from "debounce";
import IncentivePaymentNote from "./IncentivePaymentNote/IncentivePaymentNote";
import {labelService} from '../../../services/labelService';
import firebase from '../../../helpers/Firebase';

class IncentiveReviewBoard extends React.Component {
  static contextType = AuthContext;
  constructor(props) {
    super(props);
    let currentProgram = "esb";
    if(props.history){
      if(this.props.history.location.pathname.includes("speed")){
        currentProgram = "speed";
      }
    }
    this.props.setActiveProgram(currentProgram);
    this.state = {
      showFlagModal: false,
      labelUnassignedSuccessfully : false,
      selectedApplications: {},
      selectedApplicationsIncentiveTotal: 0.0,
      activeReviewer: null,
      postProjectActiveReviewer: null,
      activeAppId: "",
      activeAppNo: "",
      activeCustomerId: "",
      activeCustomerCategory: "",
      showAssignForReviewModal: false,
      showStatusLogsModal: false,
      showChangeRequestsModal: false,
      showChangeRequestLogsModal: false,
      showLabelLogsModal:false,
      showIncentiveCapApprovalExceededModal:false,
      showAssignLabelModal:false,
      showRemoveLabelModal:false,
      showNotesModal: false,
      showFlagLogsModal: false,
      showMeasureLogsModal: false,
      showAddFlagModal: false,
      showAssignforApprovalModal: false,
      showRejectRecommendationModal: false,
      showViewCustomerApplications: false,
      showIncentivePaymentCompletedModal: false,
      IncentivePaymentCompletedSuccessfully: false,
      showSelectedAppsForActionModal:false,
      showIpnApplicationsModal:false,
      selectedTab: "DIAR",
      assigningReviewer: false,
      assignedSuccessfully: false,
      appFlagLoading: true,
      appFlagType: "",
      "DIARFilters": {
        SelectedLabelFilters: [],
        stream: [],
        searchInput: "",
        sortConfig: { name: "appNo", direction: "desc" },
      },
      "FIARFilters": {
        SelectedStatusFilters: [],
        SelectedFlagFilters: [],
        searchInput: "",
        sortConfig: { name: "createdAt", direction: "desc" },
      }
    };
    this.searchDebouncedData = debounce(this.searchData, 450);
  }

  handleappFlagTypeChange = (value) => {
    this.setState({
      appFlagType: value,
    });
  };

  handleAllAppsSelection=()=>{
    let { appList } = this.props;
    let selectedApplications = {};
    let selectedApplicationsIncentiveTotal = 0.0;
    if(utils.isNullOrEmpty(this.state.selectedApplications)){
      for(let appObj of appList){
        selectedApplications[appObj.id]={
          incentive:appObj.PostProjectReviewerApprovedIncentive,
          appNo:appObj.appNo,
          payeeName:appObj.payeeName,
          customerName:appObj.customerCompanyName,
          contractorName:appObj.contractorCompanyName,
          projectCost:appObj.ProjectCost,
          incentivePercentage:appObj.IncentivePercentage,
          emissionsReduction:appObj.estimatedEmissionsReduction,
          expectedProjectStartDate:appObj.expectedProjectStartDate,
          expectedProjectEndDate:appObj.expectedProjectEndDate,
          isReceivingOtherFunding:appObj.isReceivingOtherFunding,
          preProjectApplicationDraftSubmittedDate:appObj.preProjectApplicationDraftSubmittedDate,
          projectCompletionDeadline:appObj.projectCompletionDeadline,
          preProjectApplicationDraftApprovedDate:appObj.preProjectApplicationDraftApprovedDate,
          postProjectApplicationSubmittedDate:appObj.postProjectApplicationSubmittedDate,
          incentiveRecommendedDate:appObj.incentiveRecommendedDate,
          recommendedIncentive:appObj.recommendedIncentive,
          payeeAccountCategory:appObj.payeeAccountCategory,
          actualProjectEndDate:appObj.actualProjectEndDate,
          paymentMode:appObj.paymentMode,
        };
        selectedApplicationsIncentiveTotal =  Number(selectedApplicationsIncentiveTotal) +  Number(appObj.PostProjectReviewerApprovedIncentive);
      }
    }

    this.setState({
      selectedApplications,
      selectedApplicationsIncentiveTotal
      // incentiveCapApprovalExceeded : false
    })
  }

  handleAppselection = (appObj) => {
    let {selectedApplications,selectedApplicationsIncentiveTotal,incentiveCapApprovalExceeded}=this.state;
    if(selectedApplications[appObj.id]){
      delete selectedApplications[appObj.id];
      selectedApplicationsIncentiveTotal = Number(selectedApplicationsIncentiveTotal) - Number(appObj.PostProjectReviewerApprovedIncentive);
    }
    else {
      selectedApplications[appObj.id]={
        incentive:appObj.PostProjectReviewerApprovedIncentive,
        appNo:appObj.appNo,
        payeeName:appObj.payeeName,
        customerName:appObj.customerCompanyName,
        contractorName:appObj.contractorCompanyName,
        projectCost:appObj.ProjectCost,
        incentivePercentage:appObj.IncentivePercentage,
        emissionsReduction:appObj.estimatedEmissionsReduction,
        expectedProjectStartDate:appObj.expectedProjectStartDate,
        expectedProjectEndDate:appObj.expectedProjectEndDate,
        isReceivingOtherFunding:appObj.isReceivingOtherFunding,
        preProjectApplicationDraftSubmittedDate:appObj.preProjectApplicationDraftSubmittedDate,
        projectCompletionDeadline:appObj.projectCompletionDeadline,
        preProjectApplicationDraftApprovedDate:appObj.preProjectApplicationDraftApprovedDate,
        postProjectApplicationSubmittedDate:appObj.postProjectApplicationSubmittedDate,
        incentiveRecommendedDate:appObj.incentiveRecommendedDate,
        recommendedIncentive:appObj.recommendedIncentive,
        payeeAccountCategory:appObj.payeeAccountCategory,
        actualProjectEndDate:appObj.actualProjectEndDate,
        paymentMode:appObj.paymentMode,
      };
      selectedApplicationsIncentiveTotal =  Number(selectedApplicationsIncentiveTotal) +  Number(appObj.PostProjectReviewerApprovedIncentive);
    }

    // if(selectedApplicationsIncentiveTotal>250000){ 
    //   incentiveCapApprovalExceeded=true;
    //   this.openIncentiveCapApprovalExceededModal();
    // }else{
    //   incentiveCapApprovalExceeded=false;
    // }
    this.setState({
      selectedApplications: selectedApplications,
      selectedApplicationsIncentiveTotal: selectedApplicationsIncentiveTotal,
      // incentiveCapApprovalExceeded:incentiveCapApprovalExceeded
    });
  
  };
  componentDidMount() {
    this.props.loadUserDetails();
    this.props.loadAdminConfig();
    this.props.loadLabels();
    utils.checkForDIARUpdates(()=>{
      this.searchData();
    });
    firebase.db.collection('applications')
    .where("status", "==", "Incentive Recommendation Approval Pending")
    .onSnapshot((_) => {
        window.setTimeout(this.searchData, 2000);
    });
  }

  setFilters = (stage, newState) => {
    let updateObj = {};
    updateObj = {
      [`${stage}Filters`]: {
        ...this.state[`${stage}Filters`],
        ...newState,
      },
    };
    this.setState(updateObj, () => {
      this.searchDebouncedData();
    });
  };

  searchData = () => {
   if(this.state.selectedTab==="DIAR"){
    const { sortConfig, SelectedStatusFilters, SelectedLabelFilters, searchInput, dateRange, stream  } = this.state[`${this.state.selectedTab}Filters`];
    let config = { statuses: SelectedStatusFilters, labels: SelectedLabelFilters, sort: sortConfig, dateRange, program : this.props.currentProgram, stream: stream };
    this.props.getApplicationsForDIAR( searchInput, config );
    this.setState({
      selectedApplicationsIncentiveTotal : 0.0,
      selectedApplications : {}
    });
   } else {
    const { sortConfig,searchInput,SelectedStatusFilters, dateRange } = this.state[`${this.state.selectedTab}Filters`];
    let config = { statuses: SelectedStatusFilters,  sort: sortConfig, dateRange, program : this.props.currentProgram};
    this.props.getIPNSByStatus(searchInput, config,0);
   }
   
  };

  showActionModal = (selectedapplicationData) => {
    this.setState({
      selectedapplicationData,
    });

    if (selectedapplicationData.modalType === "view-status-log") {
      this.props.getLogs(selectedapplicationData.id);
      this.setState({
        showStatusLogsModal: true,
        activeAppId: selectedapplicationData.id,
      });
    } else if (selectedapplicationData.modalType === "view-change-requests") {
      this.props.getChangeRequests(selectedapplicationData.id);
      this.setState({
        showChangeRequestsModal: true,
        activeAppId: selectedapplicationData.id,
      });
    } else if (selectedapplicationData.modalType === "view-change-request-logs") {
      this.props.getChangeRequestLogs(selectedapplicationData.id);
      this.setState({
        showChangeRequestLogsModal: true,
        activeAppId: selectedapplicationData.id,
      });
    }else if (selectedapplicationData.modalType === "assign-label") {
      this.setState({
        showAssignLabelModal: true,
        activeAppId: selectedapplicationData.id,
      });
    }else if (selectedapplicationData.modalType === "label-logs-application") {
      this.setState({
        showLabelLogsModal: true,
        activeAppId: selectedapplicationData.id,
      });
     } else if (selectedapplicationData.modalType === "remove-label") {
      this.setState({
        showRemoveLabelModal: true,
        activeAppId: selectedapplicationData.id,
      });
     } else if (selectedapplicationData.modalType === "show-selected-apps-for-action") {
      this.setState({
        showSelectedAppsForActionModal: true,
        selectedapplicationData:selectedapplicationData
      });
     }
     else if (selectedapplicationData.modalType === "view-ipn-apps") {
     this.setState({
       showIpnApplicationsModal: true,
       ipnData:selectedapplicationData.ipn
     });
    }
     //else if (selectedapplicationData.modalType === "label-logs-application") {
    //   this.setState({
    //     showLabelLogsModal: true,
    //     activeAppId: selectedapplicationData.id,
    //   });
    // } 
     else {
      // Do Nothing
    }
  };

  unassignLabel = (appData) => {
    let { labels } = appData;
    delete labels[appData.selectedLabel.id];
    applicationService.updateApplication(appData.id, {
      labels
    }).then(()=>{
      this.setState({
        labelUnassignedSuccessfully : true
      },()=>{
        setTimeout(()=>{
          this.closeActionModal(true);
        },2500);
      })
    })
  }

  closeActionModal = (reload) => {
    this.setState(
      {
        showFlagModal: false,
        labelUnassignedSuccessfully : false,
        showAssignForReviewModal: false,
        showStatusLogsModal: false,
        showChangeRequestsModal: false,
        showChangeRequestLogsModal: false,
        showAssignLabelModal:false,
        showRemoveLabelModal:false,
        showLabelLogsModal:false,
        showIncentiveCapApprovalExceededModal:false,
        showNotesModal: false,
        activeReviewer: null,
        postProjectActiveReviewer: null,
        appFlagType: null,
        assignedSuccessfully: false,
        openDrawer: false,
        showAddFlagModal: false,
        showFlagLogsModal: false,
        showMeasureLogsModal: false,
        showAssignforApprovalModal: false,
        showRejectRecommendationModal: false,
        showViewCustomerApplications: false,
        showIncentivePaymentCompletedModal: false,
        IncentivePaymentCompletedSuccessfully: false,
        showSelectedAppsForActionModal:false, 
        showIpnApplicationsModal:false,
        activeAppNo: "",
      },
      () => {
        if (reload) {
          this.searchData();
        }
      }
    );
  };

  flagApplicationForQaQc = async () => {
    console.log("FLAG for QA QC");
  };

  onTabChange = (selectedTab) => {
    this.setState({
      selectedTab,
    });
  };

  onReviewerChange = (activeReviewer) => {
    this.setState({
      activeReviewer,
    });
  };
  onpostProjectReviewerChange = (postProjectActiveReviewer) => {
    this.setState({
      postProjectActiveReviewer,
    });
  };
  openIncentiveCapApprovalExceededModal=()=>{
    this.setState({
      showIncentiveCapApprovalExceededModal:true
    });
  }
  completeIncentivePayment = () => {
    const { activeAppId } = this.state;
    applicationService.updateApplication(activeAppId, { status: "Incentive Payment Completed" }).then((res) => {
      this.setState(
        {
          IncentivePaymentCompletedSuccessfully: true,
        },
        () => {
          setTimeout(() => {
            this.closeActionModal(true);
          }, 5000);
        }
      );
    });
  };
  assignApplicationForReview = () => {
    const { adminUserInfo } = this.props;
    const { appFlagType } = this.state;
    this.setState(
      {
        assigningReviewer: true,
      },
      () => {
        var reviewer = { label: "" };
        let assignReviewObj = {
          applicationId: this.state.activeAppId,
          stage: this.state.selectedapplicationData.stage,
          reviewerId: this.state.activeReviewer,
          postProjectReviewer: this.state.postProjectActiveReviewer,
          actionTaker: `${adminUserInfo && adminUserInfo.firstName} ${adminUserInfo && adminUserInfo.lastName}`,
        };
        if (this.state.selectedapplicationData.stage !== "Post-Project") {
          reviewer = this.state.activeReviewer;
        } else {
          reviewer = this.state.postProjectActiveReviewer;
          assignReviewObj.flag = { definition: `${appFlagType.definition}`, status: appFlagType.value, colorCode: appFlagType.colorCode };
        }
        assignReviewObj.actionNote = `Application assigned to ${reviewer && reviewer.label} for review`;

        applicationService.assignApplicationToReviewer(assignReviewObj).then((res) => {
          this.setState(
            {
              assigningReviewer: false,
              assignedSuccessfully: true,
            },
            () => {
              setTimeout(() => {
                this.closeActionModal(true);
              }, 500);
            }
          );
        });
      }
    );
  };

  toggleDrawer = (status) => {
    let updateObj = {
      openDrawer: status,
    };
    if (!status) {
      updateObj.appFlagType = null;
      updateObj.postProjectActiveReviewer = null;
      updateObj.activeReviewer = null;
    }
    this.setState(updateObj);
  };

  render() {
    const { displayName } = this.context.user;
    const {
      activeStatusLogs,
      activeChangeRequests,
      appStatusLogsLoading,
      activeChangeReqLogs,
      appChangeReqLogsLoading,
      currentProgram
      //   appNotesLoading,
      //   flagLogs,
      //   measureLogs,
      //   configData,
    } = this.props;
    const {
      selectedapplicationData,
      //   showFlagModal,
      //   showAssignForReviewModal,
      //   assigningReviewer,
      //   activeReviewer,
      // //   postProjectActiveReviewer,
      //   reviewerList,
      //   assignedSuccessfully,
      showStatusLogsModal,
      showChangeRequestsModal,
      showChangeRequestLogsModal,
      showAssignLabelModal,
      showRemoveLabelModal,
      showLabelLogsModal,
      showIncentiveCapApprovalExceededModal,
      showSelectedAppsForActionModal,
      showIpnApplicationsModal,
      labelUnassignedSuccessfully,
      LabelOptions,
    } = this.state;

    return (
      <ProgramLayout showFooter={false} props={this.props}>
        <React.Fragment>
          <Modal
            IsOpen={showStatusLogsModal}
            className={"esb-modal"}
            handleCloseModal={() => this.closeActionModal()}
          
            modalSize={"large"}
            loading={appStatusLogsLoading}
          >
            <StatusLogs applicationId={this.state.activeAppId} activeStatusLogs={activeStatusLogs} />
          </Modal>
          <Modal
            HeaderText={""}
            IsOpen={showChangeRequestsModal}
            className={"esb-modal"}
           
            handleCloseModal={() => this.closeActionModal()}
            modalSize={"large"}
          >
            <ViewChangeRequests applicationId={this.state.activeAppId} activeChangeRequests={activeChangeRequests} />
          </Modal>
          <Modal
            HeaderText={""}
            IsOpen={showChangeRequestLogsModal}
            className={"esb-modal"}
            handleCloseModal={() => this.closeActionModal()}
            modalSize={"large"}
            loading={appChangeReqLogsLoading}
          >
            <ViewChangeRequestLogs applicationId={this.state.activeAppId} activeChangeReqLogs={activeChangeReqLogs} />
          </Modal>
          <Modal
            IsOpen={showAssignLabelModal}
            className={"esb-modal"}
            handleCloseModal={() => this.closeActionModal()}
            modalSize={"large"}
            // loading={appChangeReqLogsLoading}
          >
            <AssignLabels applicationId={this.state.activeAppId} activeStatusLogs={activeStatusLogs} closeActionModal={this.closeActionModal}/>
          </Modal>
          <Modal
            HeaderText={`Unassign Label`}
            IsOpen={showRemoveLabelModal}
            className={"esb-modal"}
            handleCloseModal={() => this.closeActionModal()}
            modalSize={"small"}
          >
            {labelUnassignedSuccessfully ? <p>Label unassigned from Application ID #{this.state.selectedapplicationData ? this.state.selectedapplicationData.appNo : ''}</p> :
              <React.Fragment><p>You are about to unassign label '{(this.state.selectedapplicationData && this.state.selectedapplicationData.selectedLabel ) ? this.state.selectedapplicationData.selectedLabel.name : ''}' From Application ID #{this.state.selectedapplicationData ? this.state.selectedapplicationData.appNo : ''}</p>
              <p>Are you sure?</p>
              <div className="button-set">
                  <Button 
                    className="inversed-btn"
                    title={"Cancel"}
                    uppercase
                    onClick={() => this.closeActionModal()}
                  />
                  <Button 
                    title={"Unassign Label"}
                    uppercase
                    onClick={() => this.unassignLabel(this.state.selectedapplicationData)}
                  />
               </div></React.Fragment>}
          </Modal>
          
          <Modal
            HeaderText={`Label Logs`}
            IsOpen={showLabelLogsModal}
            className={"esb-modal"}
            handleCloseModal={() => this.closeActionModal()}
            modalSize={"small"}
            // loading={appChangeReqLogsLoading}
          >
                 Hello
          </Modal>
          <Modal
          
            IsOpen={showSelectedAppsForActionModal}
            className={"esb-modal"}
            handleCloseModal={() => this.closeActionModal()}
            modalSize={"large"}
            // loading={appChangeReqLogsLoading}
          >
           <IncentivesSelectedForAction  selectedApplicationsIncentiveTotal={this.state.selectedApplicationsIncentiveTotal}
                selectedApplications={this.state.selectedApplications}   handleAllAppsSelection={() => this.handleAllAppsSelection()} closeActionModal={this.closeActionModal} searchData={this.searchData} selectedapplicationData={this.state.selectedapplicationData}></IncentivesSelectedForAction>
          </Modal>
          <Modal
            // HeaderText={`Label Logs`}
            IsOpen={showIpnApplicationsModal}
            className={"esb-modal"}
            handleCloseModal={() => this.closeActionModal()}
            modalSize={"large"}
            
            // loading={appChangeReqLogsLoading}
          >
             <IncentivePaymentNote ipnData={this.state.ipnData}></IncentivePaymentNote>   
          </Modal>
          <Modal
             HeaderText={`Incentive Approval Cap Exceeded`}
            IsOpen={showIncentiveCapApprovalExceededModal}
            className={"esb-modal incentive-total-validation-mdl"}
            handleCloseModal={() => this.closeActionModal()}
            modalSize={"small"}
            // loading={appChangeReqLogsLoading}
          >
              <p>The Incentives included in your selection exceeds the approval limit of $250,000.</p>
              <p>Please reselect Applications to remain within the approval limit</p>
              <div className="button-set">
                  <Button 
                    title={"Go back"}
                    uppercase
                    onClick={() => this.closeActionModal()}
                  />
               </div>
          </Modal>
        </React.Fragment>
        <React.Fragment>
          <Tabs onTabChange={this.onTabChange}>
            <div label="DIAR">
              <DIAR
                SelectedStatusFilters={this.state["DIARFilters"].SelectedStatusFilters}
                SelectedLabelFilters={this.state["DIARFilters"].SelectedLabelFilters}
                stream={this.state["DIARFilters"].stream}
                searchInput={this.state["DIARFilters"].searchInput}
                sortConfig={this.state["DIARFilters"].sortConfig}
                setFilters={this.setFilters}
                showActionModal={this.showActionModal}
                handleAppselection={this.handleAppselection}
                handleAllAppsSelection={this.handleAllAppsSelection}
                selectedApplicationsIncentiveTotal={this.state.selectedApplicationsIncentiveTotal}
                selectedApplications={this.state.selectedApplications}
                // incentiveCapApprovalExceeded={this.state.incentiveCapApprovalExceeded}
              />
            </div>
            <div label="FIAR">
              <FIAR
                SelectedStatusFilters={this.state["FIARFilters"].SelectedStatusFilters}
                searchInput={this.state["FIARFilters"].searchInput}
                sortConfig={this.state["FIARFilters"].sortConfig}
                setFilters={this.setFilters}
                showActionModal={this.showActionModal}
              />
            </div>
          </Tabs>
        </React.Fragment>
      </ProgramLayout>
    );
  }
}

function mapState(state) {
  const { adminUserInfo ,currentProgram } = state.authentication;
  const { configData } = state.settings;
  const {
    applicationsByStatusLoading,
    appStatusLogsLoading,
    activeStatusLogs,
    appList,
    appListOffset,
    activeChangeRequests,
    activeChangeReqLogs,
    appChangeReqLogsLoading,
    appNotesLoading,
    flagLogs,
    measureLogs,
  } = state.application;
  return {
    currentProgram,
    adminUserInfo,
    applicationsByStatusLoading,
    appStatusLogsLoading,
    activeStatusLogs,
    appList,
    appListOffset,
    activeChangeRequests,
    activeChangeReqLogs,
    appChangeReqLogsLoading,
    appNotesLoading,
    flagLogs,
    measureLogs,
    configData,
  };
}

const actionCreators = {
  loadAdminConfig: adminSettingsActions.getAdminConfig,
  getApplicationsForDIAR: appActions.getApplicationsForDIAR,
  getIPNSByStatus: appActions.getIPNSByStatus,
  getLogs: appActions.getApplicationsStatusLogs,
  getChangeRequests: appActions.getApplicationChangeRequests,
  getChangeRequestLogs: appActions.getApplicationChangeRequestLogs,
  getFlagLogs: appActions.getFlagLogs,
  getMeasureLogs: appActions.getMeasureLogs,
  loadUserDetails: userActions.getUserDetails,
  setActiveProgram : userActions.setActiveProgram,
  loadLabels : appActions.getLabels
};

export default connect(mapState, actionCreators)(IncentiveReviewBoard);

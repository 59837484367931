import React from 'react';
import Switch from "react-switch";
import './Switch.css';
const SwitchComponent = (props) => {
  const { name, onChange, checked, mode,disabled } = props;
  return (
    <div className="switch-container">
      <div className="switch-box">
        <Switch
          onChange={(isChecked) => { onChange(name, isChecked) }}
          checked={checked}
          checkedIcon={false}
          onHandleColor="#FFFFFF"
          uncheckedIcon={false}
          disabled={disabled}
          className="react-switch"
        />
        <div className={`switch-value ${checked ? 'switch-checked' : 'switch-unchecked'}`}>{mode === 'OnOff' ? checked ? 'On' : 'Off' : checked ? 'Yes' : 'No'}</div>
      </div>
    </div>
  );
}

export default SwitchComponent;
import React from "react";
import "./ViewChangeRequestLogs.css";
import Table from "../../../Common/Table/Table";
import ModalAppDataComponent from "../../../Common/ModalAppDetails/ModalAppData";
import EmptyContainer from "../../../Common/EmptyContainer/EmptyContainer";
import { AuthContext } from "../../../../firebaseAuthContext";
import { applicationService } from "../../../../services/applicationService";
import LoaderComponent from "../../../Common/Loader/Loader";
import LeftArrow from '../../../../images/leftArrow.svg';

class ViewChangeRequestLogs extends React.Component {
  static contextType = AuthContext;
  constructor(props) {
    super(props);
    this.state = {
      applicationObj: {},
      changeReqObj: {},
      newProjectDeadline: null,
      errors: {},
      reviewLoading: true,
      readyForApprove: false,
      approvedSuccessfully: false,
      confirmingApproval: false,
      changeNote: "",
      reviewStatus: { value: "", label: "Select status from list" },
    };
  }

  async componentDidMount() {
    applicationService.getApplication(this.props.applicationId).then((appObj) => {
      this.setState({
        applicationObj: appObj,
        reviewLoading: false,
      });
    });
  }

  render() {
    const { reviewLoading, applicationObj } = this.state;
    const { activeChangeReqLogs, closeChangeReqLogs } = this.props;
    return (
      <div>
        <div className="change-req-logs-container">
          {reviewLoading ? (
            <LoaderComponent />
          ) : (
            <React.Fragment>
              <ModalAppDataComponent applicationObj={applicationObj}></ModalAppDataComponent>
              <button onClick={closeChangeReqLogs} className="back-button">
                <img src={LeftArrow} alt="arrow" />
                Back
              </button>
              <div className="section-below-header">
                <h2 className="section-title" dangerouslySetInnerHTML={{ __html: "Change Request Logs" }}></h2>
              </div>
              {activeChangeReqLogs && activeChangeReqLogs.length ? (
                <div className="content-container">
                  <Table
                    rows={activeChangeReqLogs}
                    headings={[
                      { title: "Change Request No.", value: "changeReqNo" },
                      { title: "Created Date", value: "createdAt" },
                      { title: "Request Type", value: "reqType" },
                      { title: "Description", value: "description" },
                      { title: "Note", value: "changeNotes" },
                      { title: "From Status", value: "fromStatus" },
                      { title: "To Status", value: "toStatus" },
                      { title: "Updated By", value: "updatedBy" },
                    ]}
                  />
                </div>
              ) : (
                <div className="content-container">
                  <EmptyContainer>No Change Request Logs to Show</EmptyContainer>
                </div>
              )}
            </React.Fragment>
          )}
        </div>
      </div>
    );
  }
}

export default ViewChangeRequestLogs;

export const appActionTypes = {
    CLEAR_MEASURE_DRAWER : 'CLEAR_MEASURE_DRAWER',
    GET_ACTIVE_CONTRACTORS_REQUEST : 'GET_ACTIVE_CONTRACTORS_REQUEST',
    GET_ACTIVE_CONTRACTORS_SUCCESS : 'GET_ACTIVE_CONTRACTORS_SUCCESS',
    GET_ACTIVE_CONTRACTORS_FAILURE : 'GET_ACTIVE_CONTRACTORS_FAILURE',
    GET_MEASURE_CATEGORIES_REQUEST : 'GET_MEASURE_CATEGORIES_REQUEST',
    GET_MEASURE_CATEGORIES_SUCCESS : 'GET_MEASURE_CATEGORIES_SUCCESS',
    GET_MEASURE_CATEGORIES_FAILURE : 'GET_MEASURE_CATEGORIES_FAILURE',
    GET_LABELS_REQUEST : 'GET_LABELS_REQUEST',
    GET_LABELS_SUCCESS : 'GET_LABELS_SUCCESS',
    GET_LABELS_FAILURE : 'GET_LABELS_FAILURE',
    GET_MEASURE_TYPES_REQUEST : 'GET_MEASURE_TYPES_REQUEST',
    GET_MEASURE_TYPES_SUCCESS : 'GET_MEASURE_TYPES_SUCCESS',
    GET_MEASURE_TYPES_FAILURE : 'GET_MEASURE_TYPES_FAILURE',
    GET_MEASURES_REQUEST : 'GET_MEASURES_REQUEST',
    GET_MEASURES_SUCCESS : 'GET_MEASURES_SUCCESS',
    GET_MEASURES_FAILURE : 'GET_MEASURES_FAILURE',
    GET_MEASURE_CONFIG_REQUEST : 'GET_MEASURE_CONFIG_REQUEST',
    GET_MEASURE_CONFIG_SUCCESS : 'GET_MEASURE_CONFIG_SUCCESS',
    GET_MEASURE_CONFIG_FAILURE : 'GET_MEASURE_CONFIG_FAILURE',
    GET_FACILITIES_FOR_CUSTOMER_REQUEST : 'GET_FACILITIES_FOR_CUSTOMER_REQUEST',
    GET_FACILITIES_FOR_CUSTOMER_SUCCESS : 'GET_FACILITIES_FOR_CUSTOMER_SUCCESS',
    GET_FACILITIES_FOR_CUSTOMER_FAILURE : 'GET_FACILITIES_FOR_CUSTOMER_FAILURE',
    GET_APPLICATIONS_TOTAL_INCENTIVE_SUM_REQUEST : 'GET_APPLICATIONS_TOTAL_INCENTIVE_SUM_REQUEST',
    GET_APPLICATIONS_TOTAL_INCENTIVE_SUM_SUCCESS : 'GET_APPLICATIONS_TOTAL_INCENTIVE_SUM_SUCCESS',
    GET_APPLICATIONS_TOTAL_INCENTIVE_SUM_FAILURE : 'GET_APPLICATIONS_TOTAL_INCENTIVE_SUM_FAILURE',
    GET_APPLICATIONS_FOR_PARTICIPANT_REQUEST : 'GET_APPLICATIONS_FOR_PARTICIPANT_REQUEST',
    GET_APPLICATIONS_FOR_PARTICIPANT_SUCCESS : 'GET_APPLICATIONS_FOR_PARTICIPANT_SUCCESS',
    GET_APPLICATIONS_FOR_PARTICIPANT_FAILURE : 'GET_APPLICATIONS_FOR_PARTICIPANT_FAILURE',
    GET_APPLICATIONS_BY_STAGE_REQUEST : 'GET_APPLICATIONS_BY_STAGE_REQUEST',
    GET_APPLICATIONS_BY_STAGE_SUCCESS : 'GET_APPLICATIONS_BY_STAGE_SUCCESS',
    GET_APPLICATIONS_BY_STAGE_FAILURE : 'GET_APPLICATIONS_BY_STAGE_FAILURE',
    GET_IPNS_BY_STATUS_REQUEST : 'GET_IPNS_BY_STATUS_REQUEST',
    GET_IPNS_BY_STATUS_SUCCESS : 'GET_IPNS_BY_STATUS_SUCCESS',
    GET_IPNS_BY_STATUS_FAILURE : 'GET_IPNS_BY_STATUS_FAILURE',
    GET_APPLICATIONS_BY_STATUS_REQUEST : 'GET_APPLICATIONS_BY_STATUS_REQUEST',
    GET_APPLICATIONS_BY_STATUS_SUCCESS : 'GET_APPLICATIONS_BY_STATUS_SUCCESS',
    GET_APPLICATIONS_BY_STATUS_FAILURE : 'GET_APPLICATIONS_BY_STATUS_FAILURE',
    GET_APPLICATIONS_TO_REVIEW_REQUEST : 'GET_APPLICATIONS_TO_REVIEW_REQUEST',
    GET_APPLICATIONS_TO_REVIEW_SUCCESS : 'GET_APPLICATIONS_TO_REVIEW_SUCCESS',
    GET_APPLICATIONS_TO_REVIEW_FAILURE : 'GET_APPLICATIONS_TO_REVIEW_FAILURE',
    GET_FACILITY_CONFIG_REQUEST : 'GET_FACILITY_CONFIG_REQUEST',
    GET_FACILITY_CONFIG_SUCCESS : 'GET_FACILITY_CONFIG_SUCCESS',
    GET_FACILITY_CONFIG_FAILURE : 'GET_FACILITY_CONFIG_FAILURE',
    GET_FLAGS_CONFIG_REQUEST : 'GET_FLAGS_CONFIG_REQUEST',
    GET_FLAGS_CONFIG_SUCCESS : 'GET_FLAGS_CONFIG_SUCCESS',
    GET_FLAGS_CONFIG_FAILURE : 'GET_FLAGS_CONFIG_FAILURE',
    GET_STATUSES_CONFIG_REQUEST : 'GET_STATUSES_CONFIG_REQUEST',
    GET_STATUSES_CONFIG_SUCCESS : 'GET_STATUSES_CONFIG_SUCCESS',
    GET_STATUSES_CONFIG_FAILURE : 'GET_STATUSES_CONFIG_FAILURE',
    GET_IPN_STATUSES_CONFIG_REQUEST : 'GET_IPN_STATUSES_CONFIG_REQUEST',
    GET_IPN_STATUSES_CONFIG_SUCCESS : 'GET_IPN_STATUSES_CONFIG_SUCCESS',
    GET_IPN_STATUSES_CONFIG_FAILURE : 'GET_IPN_STATUSES_CONFIG_FAILURE',
    CREATE_APPLICATION_REQUEST : 'CREATE_APPLICATION_REQUEST',
    CREATE_APPLICATION_SUCCESS : 'CREATE_APPLICATION_SUCCESS',
    CREATE_APPLICATION_FAILURE : 'CREATE_APPLICATION_FAILURE',
    CREATE_FACILITY_REQUEST : 'CREATE_FACILITY_REQUEST',
    CREATE_FACILITY_SUCCESS : 'CREATE_FACILITY_SUCCESS',
    CREATE_FACILITY_FAILURE : 'CREATE_FACILITY_FAILURE',
    UPDATE_FACILITY_REQUEST : 'UPDATE_FACILITY_REQUEST',
    UPDATE_APPLICATION_REQUEST : 'UPDATE_APPLICATION_REQUEST',
    UPDATE_APPLICATION_SUCCESS : 'UPDATE_APPLICATION_SUCCESS',
    UPDATE_APPLICATION_FAILURE : 'UPDATE_APPLICATION_FAILURE',
    CREATE_APPLICATION_MEASURE_REQUEST : 'CREATE_APPLICATION_MEASURE_REQUEST',
    CREATE_APPLICATION_MEASURE_SUCCESS : 'CREATE_APPLICATION_MEASURE_SUCCESS',
    CREATE_APPLICATION_MEASURE_FAILURE : 'CREATE_APPLICATION_MEASURE_FAILURE',
    UPDATE_APPLICATION_MEASURE_REQUEST : 'UPDATE_APPLICATION_MEASURE_REQUEST',
    UPDATE_APPLICATION_MEASURE_SUCCESS : 'UPDATE_APPLICATION_MEASURE_SUCCESS',
    UPDATE_APPLICATION_MEASURE_FAILURE : 'UPDATE_APPLICATION_MEASURE_FAILURE',
    RELOAD_APPLICATION_MEASURE_REQUEST : 'RELOAD_APPLICATION_MEASURE_REQUEST',
    RELOAD_APPLICATION_MEASURE_SUCCESS : 'RELOAD_APPLICATION_MEASURE_SUCCESS',
    RELOAD_APPLICATION_MEASURE_FAILURE : 'RELOAD_APPLICATION_MEASURE_FAILURE',
    GET_APPLICATION_MEASURES_REQUEST : 'GET_APPLICATION_MEASURES_REQUEST',
    GET_APPLICATION_MEASURES_SUCCESS : 'GET_APPLICATION_MEASURES_SUCCESS',
    GET_APPLICATION_MEASURES_FAILURE : 'GET_APPLICATION_MEASURES_FAILURE',
    DELETE_APPLICATION_MEASURE_REQUEST : 'DELETE_APPLICATION_MEASURE_REQUEST',
    DELETE_APPLICATION_MEASURE_SUCCESS : 'DELETE_APPLICATION_MEASURE_SUCCESS',
    DELETE_APPLICATION_MEASURE_FAILURE : 'DELETE_APPLICATION_MEASURE_FAILURE',
    CREATE_APPLICATION_DOCUMENT_REQUEST : 'CREATE_APPLICATION_DOCUMENT_REQUEST',
    CREATE_APPLICATION_DOCUMENT_SUCCESS : 'CREATE_APPLICATION_DOCUMENT_SUCCESS',
    CREATE_APPLICATION_DOCUMENT_FAILURE : 'CREATE_APPLICATION_DOCUMENT_FAILURE',
    UPDATE_APPLICATION_DOCUMENT_REQUEST : 'UPDATE_APPLICATION_DOCUMENT_REQUEST',
    UPDATE_APPLICATION_DOCUMENT_SUCCESS : 'UPDATE_APPLICATION_DOCUMENT_SUCCESS',
    UPDATE_APPLICATION_DOCUMENT_FAILURE : 'UPDATE_APPLICATION_DOCUMENT_FAILURE',
    GET_APPLICATION_DOCUMENTS_REQUEST : 'GET_APPLICATION_DOCUMENTS_REQUEST',
    GET_APPLICATION_DOCUMENTS_SUCCESS : 'GET_APPLICATION_DOCUMENTS_SUCCESS',
    GET_APPLICATION_DOCUMENTS_FAILURE : 'GET_APPLICATION_DOCUMENTS_FAILURE',
    GET_APPLICATION_STATUS_LOGS_REQUEST : 'GET_APPLICATION_STATUS_LOGS_REQUEST',
    GET_APPLICATION_STATUS_LOGS_SUCCESS : 'GET_APPLICATION_STATUS_LOGS_SUCCESS',
    GET_APPLICATION_STATUS_LOGS_FAILURE : 'GET_APPLICATION_STATUS_LOGS_FAILURE',
    GET_APPLICATION_PORT_LOGS_REQUEST : 'GET_APPLICATION_PORT_LOGS_REQUEST',
    GET_APPLICATION_PORT_LOGS_SUCCESS : 'GET_APPLICATION_PORT_LOGS_SUCCESS',
    GET_APPLICATION_PORT_LOGS_FAILURE : 'GET_APPLICATION_PORT_LOGS_FAILURE',
    GET_APPLICATION_CHANGE_REQS_REQUEST : 'GET_APPLICATION_CHANGE_REQS_REQUEST',
    GET_APPLICATION_CHANGE_REQS_SUCCESS : 'GET_APPLICATION_CHANGE_REQS_SUCCESS',
    GET_APPLICATION_CHANGE_REQS_FAILURE : 'GET_APPLICATION_CHANGE_REQS_FAILURE',
    GET_APPLICATION_CHANGE_REQ_LOGS_REQUEST : 'GET_APPLICATION_CHANGE_REQ_LOGS_REQUEST',
    GET_APPLICATION_CHANGE_REQ_LOGS_SUCCESS : 'GET_APPLICATION_CHANGE_REQ_LOGS_SUCCESS',
    GET_APPLICATION_CHANGE_REQ_LOGS_FAILURE : 'GET_APPLICATION_CHANGE_REQ_LOGS_FAILURE',
    GET_APPLICATION_FLAG_LOGS_REQUEST : 'GET_APPLICATION_FLAG_LOGS_REQUEST',
    GET_APPLICATION_FLAG_LOGS_SUCCESS : 'GET_APPLICATION_FLAG_LOGS_SUCCESS',
    GET_APPLICATION_FLAG_LOGS_FAILURE : 'GET_APPLICATION_FLAG_LOGS_FAILURE',
    GET_APPLICATION_MEASURE_LOGS_REQUEST : 'GET_APPLICATION_MEASURE_LOGS_REQUEST',
    GET_APPLICATION_MEASURE_LOGS_SUCCESS : 'GET_APPLICATION_MEASURE_LOGS_SUCCESS',
    GET_APPLICATION_MEASURE_LOGS_FAILURE : 'GET_APPLICATION_MEASURE_LOGS_FAILURE',
    GET_APPLICATION_MEASURE_DETAIL_LOGS_REQUEST : 'GET_APPLICATION_MEASURE_DETAIL_LOGS_REQUEST',
    GET_APPLICATION_MEASURE_DETAIL_LOGS_SUCCESS : 'GET_APPLICATION_MEASURE_DETAIL_LOGS_SUCCESS',
    GET_APPLICATION_MEASURE_DETAIL_LOGS_FAILURE : 'GET_APPLICATION_MEASURE_DETAIL_LOGS_FAILURE',
    SEARCH_CHANGE_REQS_REQUEST : 'SEARCH_CHANGE_REQS_REQUEST',
    SEARCH_CHANGE_REQS_SUCCESS : 'SEARCH_CHANGE_REQS_SUCCESS',
    SEARCH_CHANGE_REQS_FAILURE : 'SEARCH_CHANGE_REQS_FAILURE',
    GET_APPLICATION_NOTES_REQUEST : 'GET_APPLICATION_NOTES_REQUEST',
    GET_APPLICATION_NOTES_SUCCESS : 'GET_APPLICATION_NOTES_SUCCESS',
    GET_APPLICATION_NOTES_FAILURE : 'GET_APPLICATION_NOTES_FAILURE',
    DELETE_APPLICATION_DOCUMENT_REQUEST : 'DELETE_APPLICATION_DOCUMENT_REQUEST',
    DELETE_APPLICATION_DOCUMENT_SUCCESS : 'DELETE_APPLICATION_DOCUMENT_SUCCESS',
    DELETE_APPLICATION_DOCUMENT_FAILURE : 'DELETE_APPLICATION_DOCUMENT_FAILURE',
    SET_MEASURE_CONFIG : 'APPLICATION_SET_MEASURE_CONFIG',
    SET_MEASURE_CATEGORY : 'SET_MEASURE_CATEGORY',
    SET_MEASURE_TYPE : 'SET_MEASURE_TYPE',
    SET_MEASURE : 'SET_MEASURE',
    //REVIEW DOCUMENTS
    CREATE_APPLICATION_REVIEW_DOCUMENT_REQUEST : 'CREATE_APPLICATION_REVIEW_DOCUMENT_REQUEST',
    CREATE_APPLICATION_REVIEW_DOCUMENT_SUCCESS : 'CREATE_APPLICATION_REVIEW_DOCUMENT_SUCCESS',
    CREATE_APPLICATION_REVIEW_DOCUMENT_FAILURE : 'CREATE_APPLICATION_REVIEW_DOCUMENT_FAILURE',
    UPDATE_APPLICATION_REVIEW_DOCUMENT_REQUEST : 'UPDATE_APPLICATION_REVIEW_DOCUMENT_REQUEST',
    UPDATE_APPLICATION_REVIEW_DOCUMENT_SUCCESS : 'UPDATE_APPLICATION_REVIEW_DOCUMENT_SUCCESS',
    UPDATE_APPLICATION_REVIEW_DOCUMENT_FAILURE : 'UPDATE_APPLICATION_REVIEW_DOCUMENT_FAILURE',
    DELETE_APPLICATION_REVIEW_DOCUMENT_REQUEST : 'DELETE_APPLICATION_REVIEW_DOCUMENT_REQUEST',
    DELETE_APPLICATION_REVIEW_DOCUMENT_SUCCESS : 'DELETE_APPLICATION_REVIEW_DOCUMENT_SUCCESS',
    DELETE_APPLICATION_REVIEW_DOCUMENT_FAILURE : 'DELETE_APPLICATION_REVIEW_DOCUMENT_FAILURE',
    GET_APPLICATION_REVIEW_DOCUMENTS_REQUEST : 'GET_APPLICATION_REVIEW_DOCUMENTS_REQUEST',
    GET_APPLICATION_REVIEW_DOCUMENTS_SUCCESS : 'GET_APPLICATION_REVIEW_DOCUMENTS_SUCCESS',
    GET_APPLICATION_REVIEW_DOCUMENTS_FAILURE : 'GET_APPLICATION_REVIEW_DOCUMENTS_FAILURE',
    GET_APPLICATION_REVIEW_DOCUMENTS_LOGS_REQUEST : 'GET_APPLICATION_REVIEW_DOCUMENTS_LOGS_REQUEST',
    GET_APPLICATION_REVIEW_DOCUMENTS_LOGS_SUCCESS : 'GET_APPLICATION_REVIEW_DOCUMENTS_LOGS_SUCCESS',
    GET_APPLICATION_REVIEW_DOCUMENTS_LOGS_FAILURE : 'GET_APPLICATION_REVIEW_DOCUMENTS_LOGS_FAILURE',
    GET_APPLICATION_STATE_LOGS_REQUEST : 'GET_APPLICATION_STATE_LOGS_REQUEST',
    GET_APPLICATION_STATE_LOGS_SUCCESS : 'GET_APPLICATION_STATE_LOGS_SUCCESS',
    GET_APPLICATION_STATE_LOGS_FAILURE : 'GET_APPLICATION_STATE_LOGS_FAILURE',
    GET_APPLICATIONS_REVIEW_PROGRESS_REQUEST : "GET_APPLICATIONS_REVIEW_PROGRESS_REQUEST",
    GET_APPLICATIONS_REVIEW_PROGRESS_SUCCESS : "GET_APPLICATIONS_REVIEW_PROGRESS_SUCCESS",
    GET_APPLICATIONS_REVIEW_PROGRESS_FAILURE : "GET_APPLICATIONS_REVIEW_PROGRESS_FAILURE",
};
import React from "react";
import { utils } from "../../../../helpers/utils";
import FlagIcon from "../../../Common/FlagIcon/FlagIcon";
import AdminActionButton from "../../../Common/AdminActionButton/AdminActionButton";
class ApplicationTab extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  viewApplication = (id) => {
    window.open(`viewapplication/${id}`, "_blank");
  };

  render() {
    const { application, currentProgram } = this.props;
    let showReviewDocument = false;
    if (
      application.status === "Incentive Recommended" ||
      application.status === "Incentive Recommendation Approval Pending" ||
      application.status === "Incentive Approved" ||
      application.status === "Incentive Payment in Progress" ||
      application.status === "Banking Details Pending" ||
      application.status === "Banking Details In Review" ||
      application.status === "Revise Banking Details" ||
      currentProgram === "retrofit"
    ) {
      showReviewDocument = true;
    }
    return (
      <div className="payment-details-row" id={this.props.id}>
        {currentProgram !== "retrofit" ? (
          <div className={`payment-details ${application.isLinkedToCES ? "linked-app" : ""}`}>
            <div>
              <label>ID:</label>
              <span>
                {application.flag ? (
                  <React.Fragment>
                    <FlagIcon currentStatus={application.flag.status} className="flag-app-icon" color={application.flag.colorCode} />
                  </React.Fragment>
                ) : (
                  ""
                )}
                {application.appNo}
              </span>
            </div>
            <div>
              <label>Payee:</label>
              <span>{application.payeeName}</span>
            </div>
            <div>
              <label>Customer:</label>
              <span>{application.customerCompanyName}</span>
            </div>
            <div>
              <label>Contractor:</label>
              <span>{application.contractorCompanyName}</span>
            </div>
            <div>
              <label>Status:</label>
              <span>{application.status}</span>
            </div>
            <div>
              <label>Recommended Date:</label>
              <span>{application.recommendedDate}</span>
            </div>
            <div>
              <label>Recommended Incentive:</label>
              <span>{utils.formatAsCAD(application.recommendedIncentive)}</span>
            </div>
            <div>
              <label>Incentive Approved Date:</label>
              <span>{application.incentiveApprovedDate}</span>
            </div>
            <div>
              <label>IPN ID:</label>
              <span>{application.ipnNo}</span>
            </div>
            <div className="show-more-btn" onClick={() => this.props.ShowMoreDetails(this.props.id)}></div>
          </div>
        ) : (
          <div className="payment-details">
            <div>
              <label>ID:</label>
              <span>
                {application.flag ? (
                  <React.Fragment>
                    <FlagIcon currentStatus={application.flag.status} className="flag-app-icon" color={application.flag.colorCode} />
                  </React.Fragment>
                ) : (
                  ""
                )}
                {application.appNo}
              </span>
            </div>
            <div>
              <label>Application Name:</label>
              <span>{application.applicationName}</span>
            </div>
            <div>
              <label>Application Project Type:</label>
              <span>{application.applicationProjectType}</span>
            </div>
            <div>
              <label>Email:</label>
              <span>{application.applicantEmail}</span>
            </div>
            <div>
              <label>Multi-Site:</label>
              <span>{application.multiSite}</span>
            </div>
            <div>
              <label>Status:</label>
              <span>{application.status}</span>
            </div>
            <div>
              <label>Post-Project Approved Date:</label>
              <span>{application.preProjectApprovalDate}</span>
            </div>
            <div>
              <label>Invoice Submitted Date:</label>
              <span>{application.invoiceSubmittedDate}</span>
            </div>
            <div>
              <label>Estimated Incentive:</label>
              <span>{utils.formatAsCAD(application.estimatedIncentive)}</span>
            </div>
            <div className="show-more-btn" onClick={() => this.props.ShowMoreDetails(this.props.id)}></div>
          </div>
        )}
        <div className="payment-more-details">
          <div>
            <AdminActionButton
              iconClass={`view-icon`}
              actionName={`Application Board-View Application`}
              viewApplication={this.viewApplication}
              application={application}
              title={"View Application"}
            />
            {(application.status === "Banking Details In Review" || application.status === "Revise Banking Details") &&
            !application.isLinkedToCES ? (
              <AdminActionButton
                iconClass={`assign-icon`}
                actionName={`Application Board-Assign for Review`}
                showActionModal={this.props.showActionModal}
                application={application}
                appModalType={"assign-for-review"}
                title={"Assign for Review"}
              />
            ) : (
              ""
            )}
            {application.status === "Incentive Payment in Progress" ? (
              <AdminActionButton
                iconClass={`dollar-icon`}
                actionName={`Application Board-Incentive Payment Completed`}
                showActionModal={this.props.showActionModal}
                application={application}
                appModalType={"incentive-payment-completed"}
                title={"Incentive Payment Completed"}
              />
            ) : (
              ""
            )}
            {application.status === "Incentive Recommended" && !application.isLinkedToCES ? (
              <AdminActionButton
                iconClass={`review-icon`}
                actionName={`Application Board-Approve Recommendation`}
                showActionModal={this.props.showActionModal}
                application={application}
                appModalType={"approve-recommendation"}
                title={"Approve Recommendation"}
              />
            ) : (
              ""
            )}
            {application.status === "Incentive Recommended" && !application.isLinkedToCES ? (
              <AdminActionButton
                iconClass={`reject-app-icon`}
                actionName={`Application Board-Reject Recommendation`}
                showActionModal={this.props.showActionModal}
                application={application}
                appModalType={"reject-Recommendation"}
                title={"Reject Recommendation"}
              />
            ) : (
              ""
            )}
            {application.isLinkedToCES ? null : (
              <>
                <AdminActionButton
                  iconClass={`assign-invoice`}
                  actionName={`Application Board-Assign Invoice for Review`}
                  showActionModal={this.props.showActionModal}
                  application={application}
                  appModalSubType={"assign"}
                  appModalType={"assign-invoice-for-review"}
                  title={"Assign Invoice for Review"}
                />
                <AdminActionButton
                  iconClass={`change-invoice`}
                  actionName={`Application Board-Change Invoice Reviewer`}
                  showActionModal={this.props.showActionModal}
                  application={application}
                  appModalSubType={"change"}
                  appModalType={"assign-invoice-for-review"}
                  title={"Change Invoice Reviewer"}
                />
                <AdminActionButton
                  iconClass={`status-log-icon`}
                  actionName={`Review Board-Internal Status Logs`}
                  showActionModal={this.props.showActionModal}
                  application={application}
                  appModalType={"internal-status-logs"}
                  title={"Internal Status Logs"}
                />
                <AdminActionButton
                  iconClass={`view-icon`}
                  actionName={`Application Board-View Customer Applications`}
                  showActionModal={this.props.showActionModal}
                  application={application}
                  appModalType={"view-customer-applications"}
                  title={"View Customer Applications"}
                />
                <AdminActionButton
                  iconClass={`view-icon`}
                  actionName={`Application Board-View Applicant Projects`}
                  showActionModal={this.props.showActionModal}
                  application={application}
                  appModalType={"view-customer-applications"}
                  title={"View Applicant Projects"}
                />
                {/* <AdminActionButton 
                              iconClass={`status-log-icon`}
                              actionName={`Application Board-Flag Logs`}
                              showActionModal={this.props.showActionModal}
                              application={application}
                              appModalType={'flag-logs-application'}
                              title={'Flag Logs'}
                          /> */}
                <AdminActionButton
                  iconClass={`flag-icon`}
                  actionName={`Application Board-Application Flags`}
                  showActionModal={this.props.showActionModal}
                  application={application}
                  appModalType={"flag-logs-application"}
                  title={"Application Flag"}
                />
                <AdminActionButton
                  iconClass={`status-log-icon`}
                  actionName={`Application Board-Status Logs`}
                  showActionModal={this.props.showActionModal}
                  application={application}
                  appModalType={"view-status-log"}
                  title={"Status Logs"}
                />
                {application.status === "Banking Details In Review" ||
                application.status === "Revise Banking Details" ||
                application.status === "Banking Details Pending" ? (
                  <AdminActionButton
                    iconClass={`assign-icon`}
                    actionName={`Application Board-Send Manually`}
                    showActionModal={this.props.showActionModal}
                    application={application}
                    appModalType={"send-cheque"}
                    title={"Send Manually"}
                  />
                ) : (
                  ""
                )}
                <AdminActionButton
                  iconClass={`change-request-icon`}
                  actionName={`Application Board-View Change Requests`}
                  showActionModal={this.props.showActionModal}
                  application={application}
                  appModalType={"view-change-requests"}
                  title={"View Change Requests"}
                />
              </>
            )}
            {/* <AdminActionButton 
                            iconClass={`status-log-icon`}
                            actionName={`Application Board-Change Request Logs`}
                            showActionModal={this.props.showActionModal}
                            application={application}
                            appModalType={'view-change-request-logs'}
                            title={'Change Request Logs'}
                        /> */}
            <AdminActionButton
              iconClass={`notes-icon`}
              actionName={`Application Board-Internal Notes`}
              showActionModal={this.props.showActionModal}
              application={application}
              appModalType={"view-notes"}
              title={"Internal Notes"}
            />
            <AdminActionButton
              iconClass={`upload-icon`}
              actionName={`Application Board-Review Documents`}
              showActionModal={this.props.showActionModal}
              application={application}
              appModalType={"review-documents"}
              title={"Review Documents"}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default ApplicationTab;

import React from "react";
import Loader from '../../../images/Loader.svg';
import './ContainerLoader.css'

const LoaderComponent = (props) => {
    return (
        <div className='container-loader'>
            <img src={Loader} alt='loader..' />
            {props.children}
        </div>
    )
}

export default LoaderComponent;
import React from "react";
import "./Clients.css";
import SearchInput from "../../../../Common/SearchInput/SearchInput";
import { ReactComponent as EditUser } from "../../../../../images/EditUser.svg";
import DrawerWithDynamicForm from "../../../../Common/DrawerWithDynamicForm/DrawerWithDynamicForm";
import { userService } from "../../../../../services/userService";
import { utils } from "../../../../../helpers/utils";
import { connect } from "react-redux";
import { adminSettingsActions } from "../../../../../redux/actions/adminSettingsActions";
import SkeletonLoading from "../../../../Common/SkeletonLoading/SkeletonLoading";

class Clients extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      SelectedFilters: [],
      searchInput: null,
      openDrawer: false,
      dynamicForm: {},
      clientList: props.clientList || [],
      AddClientJSON: {
        title: "ADD CLIENT",
        actionButton: {
          title: "ADD CLIENT",
        },
        fields: [
          {
            label: "Client Name",
            type: "text",
            mandatory: true,
            name: "name",
            placeholder: "Type the organization name",
          },
          {
            label: "Client Display Name",
            type: "text",
            mandatory: false,
            name: "displayName",
            placeholder: "Organization Display Name with Dolphin",
          },
        ],
      },
      EditClientJSON: {
        title: "EDIT CLIENT",
        actionButton: {
          title: "UPDATE CLIENT",
        },
        fields: [
          {
            label: "Client Name",
            type: "text",
            mandatory: true,
            name: "name",
            placeholder: "Type the organization name",
          },
          {
            label: "Client Display Name",
            type: "text",
            mandatory: false,
            name: "displayName",
            placeholder: "Organization Display Name with Dolphin",
          },
        ],
        data: {
          clientname: "Emissions Reductions Alberta",
          displayname: "ERA",
        },
      },
      type: "add",
    };
  }

  componentDidMount() {}

  componentDidUpdate(prevProps) {
    if (prevProps.clientList !== this.props.clientList) {
      this.onSearch(this.state.searchInput);
    }
  }

  onSearch = (searchInput) => {
    this.SearchAndFilter(searchInput, this.state.SelectedFilters);
  };

  SearchAndFilter = (searchInput, SelectedFilters) => {
    const { clientList } = this.props;
    console.log("Client List====>", clientList, searchInput);
    let FilteredList = [];
    if (SelectedFilters.length) {
      this.setState(
        {
          SelectedFilters,
        },
        () => {
          SelectedFilters.map((filter) => {
            let ArrList = [];
            ArrList = clientList.filter((client) => {
              return client && client.client.name === filter;
            });
            FilteredList.push(...ArrList);
          });

          if (searchInput) {
            let SearchPattern = new RegExp("(\\w*" + searchInput + "\\w*)", "gi");
            FilteredList = FilteredList.filter((client) => {
              return (
                client.name.match(SearchPattern) ||
                client.displayName.match(SearchPattern) ||
                (client.client && client.client.name.match(SearchPattern))
              );
            });
          }

          this.setState({
            clientList: FilteredList,
            searchInput,
          });
        }
      );
    } else {
      if (searchInput) {
        let SearchPattern = new RegExp("(\\w*" + searchInput + "\\w*)", "gi");
        FilteredList = clientList.filter((client) => {
          return (
            client.name.match(SearchPattern) ||
            client.displayName.match(SearchPattern) ||
            (client.client && client.client.name.match(SearchPattern))
          );
        });
      } else {
        FilteredList = clientList;
      }
      this.setState({
        clientList: FilteredList,
        SelectedFilters: [],
        searchInput,
      });
    }
  };

  upsertClient = (flag, client) => {
    const { AddClientJSON, EditClientJSON } = this.state;
    if (flag === "add") {
      this.setState({
        dynamicForm: AddClientJSON,
      });
    } else if (flag === "edit") {
      EditClientJSON.data = client;
      this.setState({
        dynamicForm: EditClientJSON,
      });
    }
    this.setState({
      openDrawer: true,
      type: flag,
    });
  };

  toggleDrawer = (status) => {
    this.setState({
      openDrawer: status,
    });
  };

  handleFormSubmit = (formData) => {
    const { type } = this.state;
    if (type === "edit") {
      userService.updateClient(formData.id, formData).then((res) => {
        console.log("updateClient", res);
        this.props.loadClients();
      });
    } else {
      userService.createClient(formData).then(() => {
        this.props.loadClients();
      });
    }
  };

  render() {
    const { openDrawer, dynamicForm, clientList } = this.state;
    const { adminUserInfo, clientsLoading } = this.props;
    let actions = {};
    if (adminUserInfo && adminUserInfo.permissions) {
      actions = adminUserInfo.permissions.admin.actions;
    }
    return (
      <div className="client-list-container">
        <DrawerWithDynamicForm
          toggleDrawer={this.toggleDrawer}
          openDrawer={openDrawer}
          className={"admin-panel-drawer"}
          formJSON={dynamicForm}
          submitForm={this.handleFormSubmit}
        />
        <div className="admin-dashboard-top-bar">
          <div className="admin-dashboard-search">
            <SearchInput placeholder="Search for a Client" onClick={this.onSearch} />
          </div>
          {actions["Settings-Create Client"] ? (
            <div className="add-client" onClick={() => this.upsertClient("add")}>
              Add Client
            </div>
          ) : (
            ""
          )}
        </div>
        <div className="client-details-header client-details-row">
          <div className="client-details">
            <div>Client Name</div>
            <div>Display Name</div>
            <div>Created Date</div>
            <div></div>
          </div>
        </div>
        <div className="client-details-list">
          {clientsLoading ? (
            <SkeletonLoading />
          ) : clientList.length ? (
            clientList.map((client, i) => (
              <div className="client-details-row" key={`client-${i}`}>
                <div className="client-details">
                  <div>
                    <label>Client Name:</label>
                    <span>{client.name}</span>
                  </div>
                  <div>
                    <label>Display Name:</label>
                    <span>{client.displayName}</span>
                  </div>
                  <div>
                    <label>Created Date:</label>
                    <span>{utils.formatDate(client.createdAt)}</span>
                  </div>
                  <div>
                    {actions["Settings-Edit Client"] ? (
                      <div className="client-edit-link" onClick={() => this.upsertClient("edit", client)}>
                        Edit Client
                        <EditUser width="13" height="13" />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div className="no-records-found">No records.</div>
          )}
        </div>
      </div>
    );
  }
}

function mapState(state) {
  const { adminUserInfo } = state.authentication;
  const { clientList, clientsLoading } = state.settings;
  return { adminUserInfo, clientList, clientsLoading };
}

const actionCreators = {
  loadClients: adminSettingsActions.getClients,
  loadPrograms: adminSettingsActions.getPrograms,
};

export default connect(mapState, actionCreators)(Clients);

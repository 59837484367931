import React, { Component } from "react";

import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

class ImageZoom extends Component {

  contextListener = (e)=>{
    e.preventDefault();
  }

  componentDidMount(){
    window.addEventListener("contextmenu", this.contextListener);
  }

  componentWillUnmount(){
    window.removeEventListener("contextmenu", this.contextListener);
  }

  render() {
    const { url } = this.props;
    let isPDF = url.toLowerCase().includes(".pdf");
    const FILE_URL = url;
    return (
      <React.Fragment>
      {isPDF ? <div>
        <iframe  className="pdf-file" title='void-cheque' src={FILE_URL+"#zoom=80&toolbar=0"}/>
      </div> :
      <TransformWrapper initialScale={2} initialPositionX={0} initialPositionY={0}>
        {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
          <React.Fragment>
            <div className="tools">
              <button onClick={()=>zoomIn()}>+</button>
              <button onClick={()=>zoomOut()}>-</button>
              <button onClick={()=>resetTransform()}>Reset</button>
            </div>
            <TransformComponent>
              <div className="checkContainer" onContextMenu={(e)=>{ e.preventDefault();return false;}}>
                {/* {isPDF ? <iframe  title='void-checque' style={{ width: "100%" }} src={FILE_URL}/> : */}
                <img alt='cheque pic' style={{ width: "100%" }} src={FILE_URL} />
              </div>
            </TransformComponent>
          </React.Fragment>
        )}
      </TransformWrapper>}
      </React.Fragment>
    );
  }
}
export default ImageZoom;
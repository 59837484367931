import React, { Component } from "react";
import { AuthContext } from "../../../../firebaseAuthContext";
import Button from "../../../Common/Button/Button";
import FormStep from "../../../Common/FormStep/FormStep";
import DynamicFormBuilder from "../DynamicFormBuilder";
import ResponsiveTable from "../../../Common/ResponsiveTable/ResponsiveTable";
import { utils } from "../../../../helpers/utils";
import Modal from "../../../Common/Modal/Modal";
import Table from "../../../Common/Table/Table";
import ReviewDocuments from "../../ApplicationsBoard/ReviewDocuments/ReviewDocuments";
import { applicationService } from "../../../../services/applicationService";

export class StepperDetailsReview extends Component {
  static contextType = AuthContext;
  constructor(props) {
    super(props);
    this.state = {
      steps: [],
      slideIndex: 0,
      showMeasureCriteriaTextModal: false,
      measureCriteriaText: "",
      currentMeasureId: "",
      measureDetailModalOpen: false,
      currentMeasureDetails: {},
      showDocumentsModal: false,
      uploadFilesFieldName: "",
      uploadDocumentFor: "",
      allReviewedMeasures: [],
      noOfMeasuresToBeReviewed: 0,
      noOfMeasuresReviewed: [],
      uploadedFiles: [],
      currentMeasureType: "",
    };
  }
  componentDidMount = () => {
    this.updateCompState();
  };
  // componentDidUpdate = (prevProps) => {
  //   if (prevProps !== this.props) {
  //     this.updateCompState();
  //   }
  // };
  updateCompState = () => {
    const { applicationObj, currentFacility } = this.props;
    let noOfMeasures = 0;
    let allDeletedMeasureIds = [];
    if (currentFacility && currentFacility.facilitiesDetails) {
      currentFacility.facilitiesDetails.prescriptiveMeasures.map((ele) => {
        if (ele.isActive) {
          noOfMeasures = noOfMeasures + 1;
        } else {
          allDeletedMeasureIds.push(ele.prescriptive_measure_id);
        }
      });
      currentFacility.facilitiesDetails.customMeasures.map((ele) => {
        if (ele.isActive) {
          noOfMeasures = noOfMeasures + 1;
        } else if (!ele.isActive) {
          allDeletedMeasureIds.push(ele.custom_measure_id);
        }
      });
    }
    applicationService.getRetrofitApplicationsReviewProgress(applicationObj.application_id).then((progressData) => {
      let preProjectSteps = [
        "Facility",
        "Project Eligibility",
        "Measures",
        "Project Costs",
        "Emergency Replacement",
        "QA/QC",
        "Final Document Checklist",
      ];

      let postProjectSteps = [
        "Facility",
        "Project Eligibility",
        "Measures",
        "Project Costs",
        "Emergency Replacement",
        "QA/QC",
        "Disposal",
        "Final Document Checklist",
      ];
      let steps = applicationObj.stage === "Post-Project" ? postProjectSteps : preProjectSteps;
      let index = applicationObj.currentStepper
        ? applicationObj.currentStepper[currentFacility.facility_id] && applicationObj.currentStepper[currentFacility.facility_id]["name"]
          ? steps.indexOf(applicationObj.currentStepper[currentFacility.facility_id].name)
          : 0
        : 0;
      let reviewCompletedMeasures = [];
      if (
        progressData[applicationObj.stage] &&
        progressData[applicationObj.stage].facilityQuestions &&
        progressData[applicationObj.stage].facilityQuestions[currentFacility.facility_id] &&
        applicationObj.isPrimaryReviewer
      ) {
        reviewCompletedMeasures =
          (!utils.isNullOrEmpty(progressData[applicationObj.stage]) &&
            !utils.isNullOrEmpty(progressData[applicationObj.stage].facilityQuestions) &&
            progressData[applicationObj.stage].facilityQuestions[currentFacility.facility_id] &&
            progressData[applicationObj.stage].facilityQuestions[currentFacility.facility_id]["Measures"] &&
            Object.keys(progressData[applicationObj.stage].facilityQuestions[currentFacility.facility_id]["Measures"]).filter((key) => {
              if (
                progressData[applicationObj.stage].facilityQuestions[currentFacility.facility_id]["Measures"][key].reviewState ===
                "Completed"
              ) {
                if (allDeletedMeasureIds.includes(Number(key))) {
                } else return key;
              }
            })) ||
          [];
      } else {
        reviewCompletedMeasures =
          (!utils.isNullOrEmpty(progressData[applicationObj.stage]) &&
            !utils.isNullOrEmpty(progressData[applicationObj.stage].facilityQuestions) &&
            progressData[applicationObj.stage].facilityQuestions[currentFacility.facility_id] &&
            progressData[applicationObj.stage].facilityQuestions[currentFacility.facility_id]["Measures"] &&
            Object.keys(progressData[applicationObj.stage].facilityQuestions[currentFacility.facility_id]["Measures"]).filter((key) => {
              if (
                progressData[applicationObj.stage].facilityQuestions[currentFacility.facility_id]["Measures"][key].secondaryReviewState ===
                "Completed"
              ) {
                if (allDeletedMeasureIds.includes(Number(key))) {
                } else return key;
              }
            })) ||
          [];
      }
      applicationService.getRetrofitReviewProcessDocuments(currentFacility.facility_id).then((data) => {
        if (noOfMeasures === reviewCompletedMeasures.length) {
          if (this.props.savedStepperName === "Measures") {
            this.props.showNewSaveAndProceedBtn(true);
          } else {
            this.props.showNewSaveAndProceedBtn(false);
          }
        }
        this.setState({
          steps: steps,
          slideIndex: index,
          noOfMeasuresToBeReviewed: noOfMeasures,
          noOfMeasuresReviewed: reviewCompletedMeasures,
          uploadedFiles: data,
        });
      });
    });
  };
  handleChangeSlide = (stepName) => {
    const { noOfMeasuresToBeReviewed, noOfMeasuresReviewed } = this.state;
    const { steps } = this.state;
    if (typeof stepName === "string") {
      if (stepName === "Measures") {
        if (noOfMeasuresReviewed.length === noOfMeasuresToBeReviewed) {
          this.props.showNewSaveAndProceedBtn(true);
        }
      }
      let index = steps.indexOf(stepName);
      this.setState({ slideIndex: index, errors: {}, currentMeasureId: "" });
    } else {
      if (stepName === 2) {
        if (noOfMeasuresReviewed.length === noOfMeasuresToBeReviewed) {
          this.props.showNewSaveAndProceedBtn(true);
        } else {
          this.props.showNewSaveAndProceedBtn("MeasureStep");
        }
        // this.props.submitForm(false, "Facility", true);
        this.setState({ slideIndex: stepName, errors: {}, currentMeasureId: "" });
      } else {
        this.props.showNewSaveAndProceedBtn(false);
        this.props.submitForm(false, "Facility", false);
        this.setState({ slideIndex: stepName, errors: {}, currentMeasureId: "" });
      }
    }
  };
  handleDocumentUpload = (type, fieldName) => {
    const { currentFacility } = this.props;
    applicationService.getRetrofitReviewProcessDocuments(currentFacility.facility_id).then((data) => {
      this.setState({ showDocumentsModal: true, uploadDocumentFor: type, uploadedFiles: data, uploadFilesFieldName: fieldName });
    });
  };
  startMeasureReview = (mId, reviewedMId, measure_type) => {
    const { submitForm, showNewSaveAndProceedBtn } = this.props;
    const { noOfMeasuresReviewed } = this.state;
    let addNewId = noOfMeasuresReviewed;
    if (typeof reviewedMId === "number" && !addNewId.includes(`${reviewedMId}`)) {
      addNewId.push(`${reviewedMId}`);
    }
    if (mId === false) {
      submitForm(false, "Facility", true);
    } else {
      showNewSaveAndProceedBtn(false);
      submitForm(false, "Facility", false);
    }
    this.setState({ currentMeasureId: mId, noOfMeasuresReviewed: addNewId, currentMeasureType: measure_type });
  };
  viewMeasureDetails = (facilityId, measureId) => {
    const { applicationObj } = this.props;
    window.open(
      `inforeview/${"measures"}/${measureId}/${facilityId}/${applicationObj.application_id}`,
      "",
      "resizable=no,width=800,height=900"
    );
  };
  viewCustomMeasureDetails = (facilityId, measureId) => {
    const { applicationObj } = this.props;
    window.open(
      `inforeview/${"custom-measures"}/${measureId}/${facilityId}/${applicationObj.application_id}`,
      "",
      "resizable=no,width=800,height=900"
    );
  };
  openEPPList = () => {
    window.open(`inforeview/${"epplist"}`, "", "resizable=no,width=800,height=900");
  };
  facilityDetails = (currentFacility) => {
    const { applicationObj, reviewQuestions, saveAndProceed, submitForm } = this.props;
    return (
      <div className="facility-review-questions">
        <ResponsiveTable
          headings={[{ title: "EPP LIST", value: "viewlist" }]}
          rows={[
            {
              viewlist: (
                <div className="view-btn" onClick={() => this.openEPPList()}>
                  View
                </div>
              ),
            },
          ]}
        />
        <div className="terms">* IESO Checklist Questions </div>
        <h2 className="step-header-title">FACILITY - #{currentFacility.facility_id}</h2>
        <DynamicFormBuilder
          saveAndProceed={saveAndProceed}
          currentFacility={currentFacility}
          facilityId={currentFacility.facility_id}
          submitForm={submitForm}
          steps={this.state.steps}
          applicationObj={applicationObj}
          reviewQuestions={reviewQuestions}
          changeSlide={this.handleChangeSlide}
          tab={"Facility"}
          step="Facility"
        />
      </div>
    );
  };
  projectEligibilityDetails = (mainProjectDetails) => {
    const { applicationObj, reviewQuestions, saveAndProceed, submitForm, currentFacility } = this.props;
    return (
      <div className="project-eligibility-review-questions">
        <ResponsiveTable
          headings={
            applicationObj.stage === "Pre-Project"
              ? [
                  { title: "Capped Total Estimated Facility Incentive", value: "totalCappedIncentive" },
                  { title: "Total Estimated Facility Incentive", value: "totalFacilityIncentive" },
                ]
              : [
                  { title: "Capped Total Estimated Facility Incentive", value: "totalCappedIncentive" },
                  { title: "Capped Total Actual Facility Incentive", value: "cap_tot_act_facility_incentive" },
                  { title: "Total Estimated Facility Incentive", value: "totalFacilityIncentive" },
                  { title: "Total Actual Facility Incentive", value: "tot_actual_facility_incentive" },
                ]
          }
          rows={
            applicationObj.stage === "Pre-Project"
              ? [
                  {
                    totalCappedIncentive: `$${mainProjectDetails.cap_tot_est_facility_incentive
                      .toFixed(2)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`,

                    totalFacilityIncentive: `$${mainProjectDetails.tot_est_facility_incentive
                      .toFixed(2)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`,
                  },
                ]
              : [
                  {
                    totalCappedIncentive: mainProjectDetails.cap_tot_est_facility_incentive
                      ? `$${mainProjectDetails.cap_tot_est_facility_incentive
                          .toFixed(2)
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
                      : "N/A",
                    cap_tot_act_facility_incentive: mainProjectDetails.cap_tot_act_facility_incentive
                      ? `$${mainProjectDetails.cap_tot_act_facility_incentive
                          .toFixed(2)
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
                      : "N/A",
                    tot_actual_facility_incentive: mainProjectDetails.tot_actual_facility_incentive
                      ? `$${mainProjectDetails.tot_actual_facility_incentive
                          .toFixed(2)
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
                      : "N/A",
                    totalFacilityIncentive: mainProjectDetails.tot_est_facility_incentive
                      ? `$${mainProjectDetails.tot_est_facility_incentive
                          .toFixed(2)
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
                      : "N/A",
                  },
                ]
          }
        />
        <div className="terms">* IESO Checklist Questions </div>
        <DynamicFormBuilder
          facilityId={currentFacility.facility_id}
          currentFacility={currentFacility}
          submitForm={submitForm}
          applicationObj={applicationObj}
          saveAndProceed={saveAndProceed}
          reviewQuestions={reviewQuestions}
          changeSlide={this.handleChangeSlide}
          steps={this.state.steps}
          tab={"Facility"}
          step="Project Eligibility"
        />
      </div>
    );
  };
  measuresDetails = (prescreptiveMeasuresDetails, customMeasuresDetails) => {
    const { applicationObj, reviewQuestions, submitForm, saveAndProceed, currentFacility } = this.props;
    let { currentMeasureId, noOfMeasuresReviewed, noOfMeasuresToBeReviewed, uploadedFiles, currentMeasureType } = this.state;
    let stayOnMeasures =
      (noOfMeasuresReviewed && noOfMeasuresReviewed.length) === noOfMeasuresToBeReviewed - 1 ||
      (noOfMeasuresReviewed && noOfMeasuresReviewed.length) === noOfMeasuresToBeReviewed
        ? false
        : true;
    let tableHeader =
      applicationObj.stage === "Pre-Project"
        ? [
            { title: "End-Use", value: "endUse" },
            { title: "Measures", value: "Measures" },
            { title: "Measure Type", value: "Measure Type" },
            { title: "Estimated Peak Demand Savings", value: "estimatePeakDemand" },
            { title: "Estimated kWh Savings", value: "estimatedSaving" },
            { title: "Estimated Calculated Incentive", value: "estimatedCalculatedIncentive" },
            { title: "Review", value: "Review" },
          ]
        : [
            { title: "End-Use", value: "endUse" },
            { title: "Measures", value: "Measures" },
            { title: "Measure Type", value: "Measure Type" },
            { title: "Actual Peak Demand Savings", value: "actualPeakDemand" },
            { title: "Actual kWh Savings", value: "actualSaving" },
            { title: "Estimated Calculated Incentive", value: "estimatedCalculatedIncentive" },
            { title: "Actual Calculated Incentive", value: "calculatedIncentive" },
            { title: "Review", value: "Review" },
          ];
    let prescreptiveMeasureRows = prescreptiveMeasuresDetails
      .filter((ele) => ele.isActive)
      .map((measure) => ({
        endUse: (
          <div className="facility-name">
            {measure.end_use}
            <div
              className="view-facility"
              onClick={() => this.viewMeasureDetails(currentFacility.facility_id, measure.prescriptive_measure_id)}
            >
              {`(#${measure.prescriptive_measure_id})`}
            </div>
          </div>
        ),
        Measures: (
          <div className="facility-name">
            {measure.conservation_measure_name}
            <div className="criteria-text">
              <span className="turncate-text"> {measure.measure_description}</span>
              <span
                className="expand"
                onClick={() =>
                  this.setState({
                    measureCriteriaText: measure.measure_description,
                    showMeasureCriteriaTextModal: true,
                  })
                }
              >
                Expand
              </span>
            </div>
          </div>
        ),
        "Measure Type": "Prescriptive",
        estimatePeakDemand: measure.est_peak_demand_savings || "N/A",
        estimatedSaving: utils.formatKwh(measure.estimated_kwh_saving) || "N/A",
        estimatedCalculatedIncentive: utils.formatAsCAD(measure.estimated_calculated_incentive) || "N/A",
        actualPeakDemand: measure.act_peak_demand_savings || "N/A",
        actualSaving: measure.actual_kwh_saving || "N/A",
        calculatedIncentive: utils.formatAsCAD(measure.actual_calculated_incentive) || "N/A",
        Review: (
          <Button
            title={
              currentMeasureId === measure.prescriptive_measure_id
                ? "Complete Review"
                : !utils.isNullOrEmpty(noOfMeasuresReviewed) && noOfMeasuresReviewed.includes(`${measure.prescriptive_measure_id}`)
                ? "Edit Review"
                : "Start Review"
            }
            disabled={currentMeasureId}
            onClick={() => this.startMeasureReview(measure.prescriptive_measure_id, "", "Prescriptive")}
          />
        ),
        colorCode:
          currentMeasureId === measure.prescriptive_measure_id
            ? "in-progress"
            : !utils.isNullOrEmpty(noOfMeasuresReviewed) && noOfMeasuresReviewed.includes(`${measure.prescriptive_measure_id}`)
            ? "completed"
            : "",
        "Eligibility Criteria": measure.eligibility_criteria ? (
          <div className="criteria-text">
            <span className="turncate-text">{measure.eligibility_criteria}</span>
            <span
              className="expand"
              onClick={() =>
                this.setState({
                  measureCriteriaText: measure.eligibility_criteria,
                  showMeasureCriteriaTextModal: true,
                })
              }
            >
              Expand
            </span>
          </div>
        ) : (
          "N/A"
        ),
      }));
    let customMeasureRows = customMeasuresDetails
      .filter((ele) => ele.isActive)
      .map((measure) => ({
        endUse: (
          <div className="facility-name">
            {measure.end_use}
            <div
              className="view-facility"
              onClick={() => this.viewCustomMeasureDetails(currentFacility.facility_id, measure.custom_measure_id)}
            >
              {`(#${measure.custom_measure_id})`}
            </div>
          </div>
        ),
        Measures: (
          <div className="facility-name">
            {measure.custom_measure_name}
            <div className="criteria-text">
              <span className="turncate-text"> {measure.energy_efficient_case_desc}</span>
              <span
                className="expand"
                onClick={() =>
                  this.setState({
                    measureCriteriaText: measure.energy_efficient_case_desc,
                    showMeasureCriteriaTextModal: true,
                  })
                }
              >
                Expand
              </span>
            </div>
          </div>
        ),
        "Measure Type": "Custom",
        estimatePeakDemand: measure.estimated_kw_reduction || "N/A",
        estimatedSaving: utils.formatKwh(measure.estimated_kwh_savings) || "N/A",
        estimatedCalculatedIncentive: utils.formatAsCAD(measure.calculated_incentive_pre_proj) || "N/A",
        actualPeakDemand: measure.actual_kw_reduction || "N/A",
        actualSaving: measure.actual_kwh_savings || "N/A",
        calculatedIncentive: utils.formatAsCAD(measure.calculated_incentive_post_proj) || "N/A",
        Review: (
          <Button
            title={
              currentMeasureId === measure.custom_measure_id
                ? "Complete Review"
                : !utils.isNullOrEmpty(noOfMeasuresReviewed) && noOfMeasuresReviewed.includes(`${measure.custom_measure_id}`)
                ? "Edit Review"
                : "Start Review"
            }
            disabled={currentMeasureId}
            onClick={() => this.startMeasureReview(measure.custom_measure_id, "", "Custom")}
          />
        ),
        colorCode:
          currentMeasureId === measure.custom_measure_id
            ? "in-progress"
            : !utils.isNullOrEmpty(noOfMeasuresReviewed) && noOfMeasuresReviewed.includes(`${measure.custom_measure_id}`)
            ? "completed"
            : "",
        "Eligibility Criteria": measure.eligibility_criteria ? (
          <div className="criteria-text">
            <span className="turncate-text">{measure.eligibility_criteria}</span>
            <span
              className="expand"
              onClick={() =>
                this.setState({
                  measureCriteriaText: measure.eligibility_criteria,
                  showMeasureCriteriaTextModal: true,
                })
              }
            >
              Expand
            </span>
          </div>
        ) : (
          "N/A"
        ),
      }));
    let tableRows = [...prescreptiveMeasureRows, ...customMeasureRows];
    let filteredList = uploadedFiles.filter((obj) => obj.measure_id === `${currentMeasureId}`);
    return (
      <div className={`measures-review-questions`}>
        <ResponsiveTable headings={tableHeader} rows={tableRows} />
        {currentMeasureId && typeof currentMeasureId === "number" ? (
          <div className="measure-review">
            <div className="terms">* IESO Checklist Questions </div>
            <h2 className="step-header-title">MEASURE - #{currentMeasureId}</h2>
            <DynamicFormBuilder
              submitForm={submitForm}
              measuresDetails={[...prescreptiveMeasuresDetails, ...customMeasuresDetails]}
              saveAndProceed={saveAndProceed}
              applicationObj={applicationObj}
              reviewQuestions={reviewQuestions}
              currentMeasureType={currentMeasureType}
              currentMeasureId={currentMeasureId}
              facilityId={currentFacility.facility_id}
              steps={this.state.steps}
              handleDocumentUpload={this.handleDocumentUpload}
              changeSlide={this.handleChangeSlide}
              startMeasureReview={this.startMeasureReview}
              stayOnMeasures={stayOnMeasures}
              uploadedFileList={filteredList}
              tab={"Facility"}
              step="Measures"
            />
          </div>
        ) : (
          ""
        )}
      </div>
    );
  };
  projectCostDetails = () => {
    const { applicationObj, reviewQuestions, currentFacility, saveAndProceed, submitForm } = this.props;
    let tableHeader =
      applicationObj.stage === "Pre-Project"
        ? [
            { title: "Measure Details", value: "measure_details" },
            { title: "Total Estimated Facility Eligible Costs", value: "tot_est_facility_eligible_cost" },
          ]
        : [
            { title: "Measure Details", value: "measure_details" },
            { title: "Total Estimated Facility Eligible Costs", value: "tot_est_facility_eligible_cost" },
            { title: "Total Actual Facility Eligible Cost", value: "tot_act_facility_cost" },
          ];
    let tableRows = [
      {
        tot_est_facility_eligible_cost: currentFacility.facilitiesDetails
          ? `$${currentFacility.facilitiesDetails.facility.tot_est_facility_eligible_cost
              .toFixed(2)
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
          : "N/A",
        tot_act_facility_cost:
          currentFacility.facilitiesDetails && currentFacility.facilitiesDetails.facility.tot_act_facility_cost
            ? `$${currentFacility.facilitiesDetails.facility.tot_act_facility_cost
                .toFixed(2)
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
            : "N/A",
        measure_details: (
          <div
            className="view-btn"
            onClick={() =>
              window.open(
                `inforeview/${"projectcosts"}/${"measures"}/${currentFacility.facility_id}/${applicationObj.application_id}`,
                "",
                "resizable=no,width=800,height=900"
              )
            }
          >
            View
          </div>
        ),
      },
    ];
    return (
      <div className="project-costs-review-questions">
        <ResponsiveTable headings={tableHeader} rows={tableRows} />
        <div className="terms">* IESO Checklist Questions </div>
        <DynamicFormBuilder
          submitForm={submitForm}
          facilityId={currentFacility.facility_id}
          saveAndProceed={saveAndProceed}
          applicationObj={applicationObj}
          reviewQuestions={reviewQuestions}
          changeSlide={this.handleChangeSlide}
          steps={this.state.steps}
          tab={"Facility"}
          step="Project Costs"
        />
      </div>
    );
  };
  emergencyReplacementDetails = () => {
    const { applicationObj, reviewQuestions, currentFacility, saveAndProceed, submitForm } = this.props;
    return (
      <div className="emergency-replacement-review-questions">
        <div className="terms">* IESO Checklist Questions </div>
        <DynamicFormBuilder
          submitForm={submitForm}
          currentFacility={currentFacility}
          facilityId={currentFacility.facility_id}
          saveAndProceed={saveAndProceed}
          applicationObj={applicationObj}
          reviewQuestions={reviewQuestions}
          steps={this.state.steps}
          changeSlide={this.handleChangeSlide}
          tab={"Facility"}
          step="Emergency Replacement"
        />
      </div>
    );
  };

  qaqcDetails = () => {
    const { applicationObj, reviewQuestions, currentFacility, saveAndProceed, submitForm } = this.props;
    const { uploadedFiles } = this.state;
    let filteredList = uploadedFiles.filter((obj) => !obj.name.includes(currentFacility.facility_id) && obj.measure_id === "" && obj.isDisposalDocument === false && obj.isFinalDocumentChecklist === false);
    return (
      <div className="qaqc-review-questions">
        <div className="terms">* IESO Checklist Questions </div>
        <DynamicFormBuilder
          submitForm={submitForm}
          currentFacility={currentFacility}
          facilityId={currentFacility.facility_id}
          saveAndProceed={saveAndProceed}
          applicationObj={applicationObj}
          reviewQuestions={reviewQuestions}
          steps={this.state.steps}
          handleDocumentUpload={this.handleDocumentUpload}
          changeSlide={this.handleChangeSlide}
          uploadedFileList={filteredList}
          tab={"Facility"}
          step="QA/QC"
        />
      </div>
    );
  };
  disposalDetails = () => {
    const { applicationObj, reviewQuestions, currentFacility, saveAndProceed, submitForm } = this.props;
    let filteredList = this.state.uploadedFiles.filter((obj) => obj.isDisposalDocument === true);
    let tableHeader = [{ title: "Measure Details", value: "measure_details" }];
    let tableRows = [
      {
        measure_details: (
          <div
            className="view-btn"
            onClick={() =>
              window.open(
                `inforeview/${"disposal"}/${"measures"}/${currentFacility.facility_id}/${applicationObj.application_id}`,
                "",
                "resizable=no,width=800,height=900"
              )
            }
          >
            View
          </div>
        ),
      },
    ];
    return (
      <div className="disposal-review-questions">
        <ResponsiveTable headings={tableHeader} rows={tableRows} />
        <div className="terms">* IESO Checklist Questions </div>
        <DynamicFormBuilder
          submitForm={submitForm}
          currentFacility={currentFacility}
          facilityId={currentFacility.facility_id}
          saveAndProceed={saveAndProceed}
          applicationObj={applicationObj}
          reviewQuestions={reviewQuestions}
          steps={this.state.steps}
          handleDocumentUpload={this.handleDocumentUpload}
          changeSlide={this.handleChangeSlide}
          uploadedFileList={filteredList}
          tab={"Facility"}
          step="Disposal"
        />
      </div>
    );
  };
  finalDocumentDetails = () => {
    const { applicationObj, reviewQuestions, currentFacility, saveAndProceed, submitForm } = this.props;
    let filteredList = this.state.uploadedFiles.filter((obj) => obj.isFinalDocumentChecklist === true);
    return (
      <div className="final-document-review-questions">
        <div className="terms">* IESO Checklist Questions </div>
        <DynamicFormBuilder
          submitForm={submitForm}
          currentFacility={currentFacility}
          facilityId={currentFacility.facility_id}
          saveAndProceed={saveAndProceed}
          applicationObj={applicationObj}
          reviewQuestions={reviewQuestions}
          steps={this.state.steps}
          handleDocumentUpload={this.handleDocumentUpload}
          changeSlide={this.handleChangeSlide}
          uploadedFileList={filteredList}
          tab={"Facility"}
          step="Final Document Checklist"
        />
      </div>
    );
  };
  closeMeasureFileUploadModal = () => {
    const { currentFacility } = this.props;
    applicationService.getRetrofitReviewProcessDocuments(currentFacility.facility_id).then((data) => {
      this.setState({ uploadedFiles: data, showDocumentsModal: false });
    });
  };
  render() {
    let {
      steps,
      slideIndex,
      measureCriteriaText,
      showMeasureCriteriaTextModal,
      measureDetailModalOpen,
      uploadFilesFieldName,
      showDocumentsModal,
      uploadDocumentFor,
      currentMeasureId,
    } = this.state;
    const { currentFacility, applicationObj, forceSlideIndex } = this.props;
    let mainProjectDetails = currentFacility && currentFacility.facilitiesDetails ? currentFacility.facilitiesDetails.facility : "";
    let prescreptiveMeasuresDetails =
      currentFacility && currentFacility.facilitiesDetails ? currentFacility.facilitiesDetails.prescriptiveMeasures : "";
    let customMeasuresDetails =
      currentFacility && currentFacility.facilitiesDetails ? currentFacility.facilitiesDetails.customMeasures : "";
    if (forceSlideIndex) {
      this.handleChangeSlide(forceSlideIndex);
    }

    return (
      <div className="stepper-details">
        <Modal
          IsOpen={showMeasureCriteriaTextModal}
          className={"esb-modal measure-details-modal"}
          handleCloseModal={() => this.setState({ showMeasureCriteriaTextModal: false, measureCriteriaText: "" })}
          modalSize={"small"}
        >
          <div className="measure-text-container">
            <p>{measureCriteriaText}</p>
          </div>
        </Modal>
        {/* <Modal
          IsOpen={measureDetailModalOpen}
          className={"esb-modal measure-details-modal"}
          handleCloseModal={() => this.setState({ measureDetailModalOpen: false, currentMeasureDetails: {} })}
          modalSize={"large"}
        >
          <h3>Measure Specific Fields</h3>
          <div className="measure-details-container">
            {!utils.isNullOrEmpty(currentMeasureDetails) ? (
              <Table
                rows={currentMeasureDetails}
                headings={[
                  { title: "Field Name", value: "label" },
                  { title: "Field Value", value: "value" },
                ]}
              />
            ) : (
              ""
            )}
          </div>
        </Modal> */}
        <Modal
          skipTopBit={true}
          IsOpen={showDocumentsModal}
          className={"esb-modal"}
          handleCloseModal={() => this.closeMeasureFileUploadModal()}
          modalSize={"large"}
          applicationId={applicationObj.id}
        >
          <ReviewDocuments
            application={applicationObj}
            currentFacilityId={currentFacility.facility_id}
            currentMeasureId={currentMeasureId}
            applicationId={applicationObj.id}
            uploadDocumentFor={uploadDocumentFor}
            uploadFilesFieldName={uploadFilesFieldName}
          />
        </Modal>
        <FormStep steps={steps} slideIndex={slideIndex} onChange={this.handleChangeSlide} />
        {applicationObj.stage === "Pre-Project" ? (
          <React.Fragment>
            {slideIndex === 0 ? this.facilityDetails(currentFacility) : null}
            {slideIndex === 1 ? this.projectEligibilityDetails(mainProjectDetails) : null}
            {slideIndex === 2 ? this.measuresDetails(prescreptiveMeasuresDetails, customMeasuresDetails) : null}
            {slideIndex === 3 ? this.projectCostDetails() : null}
            {slideIndex === 4 ? this.emergencyReplacementDetails() : null}
            {slideIndex === 5 ? this.qaqcDetails() : null}
            {slideIndex === 6 ? this.finalDocumentDetails() : null}
          </React.Fragment>
        ) : (
          <React.Fragment>
            {slideIndex === 0 ? this.facilityDetails(currentFacility) : null}
            {slideIndex === 1 ? this.projectEligibilityDetails(mainProjectDetails) : null}
            {slideIndex === 2 ? this.measuresDetails(prescreptiveMeasuresDetails, customMeasuresDetails) : null}
            {slideIndex === 3 ? this.projectCostDetails() : null}
            {slideIndex === 4 ? this.emergencyReplacementDetails() : null}
            {slideIndex === 5 ? this.qaqcDetails() : null}
            {slideIndex === 6 ? this.disposalDetails() : null}
            {slideIndex === 7 ? this.finalDocumentDetails() : null}
          </React.Fragment>
        )}
      </div>
    );
  }
}

export default StepperDetailsReview;

import React, { Component } from "react";
import "./AppReviewRetrofit.css";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { applicationService } from "../../../services/applicationService";
import { AuthContext } from "../../../firebaseAuthContext";
import { userActions } from "../../../redux/actions/userActions";
import { appActions } from "../../../redux/actions/appActions";
import Loader from "../../Common/Loader/Loader";
import ContainerLoader from "../../Common/ContainerLoader/ContainerLoader";
import ProgramLayout from "../../Common/ProgramLayout";
import Button from "../../Common/Button/Button";
import Modal from "../../Common/Modal/Modal";
import { utils } from "../../../helpers/utils";
import CustomTabs from "./CustomTabs";
import { userService } from "../../../services/userService";
import DynamicFormBuilder from "./DynamicFormBuilder";
import { adminSettingsActions } from "../../../redux/actions/adminSettingsActions";
import { ReactComponent as ViewIcon } from "../../../images/ViewIcon.svg";
import ResponsiveTable from "../../Common/ResponsiveTable/ResponsiveTable";
import StepperDetailsReview from "./StepperDetailsReview/StepperDetailsReview";
import Input from "../../Common/Input/Input";
import FilterDropdown from "../../Common/FilterDropdown/FilterDropdown";
// import Socket from "socket.io-client";
// import Connection from "../../Common/SocketIO/Socket";
import firebase from "../../../helpers/Firebase";
import NetworkError from "./NetworkError/NetworkError.js";
import Table from "../../Common/Table/Table";
import { ReactComponent as DownloadIcon } from "../../../images/DownloadGrey.svg";
export class AppReviewRetrofit extends Component {
  static contextType = AuthContext;
  // socketio = null;
  constructor(props) {
    super(props);
    this.db = firebase.db;
    this.collectionName = 'ieso';
    this.collectionDoc = 'retrofit';
    this.subCollectionName = 'reviewerState';
    this.docRef = null;
    applicationService
      .getApplicationRetrofit(this.props.match.params.id)
      .then(async (data) => {
        let { reviewerId } = this.getCurrentReviewerAndAppId(data);
        this.docRef = this.db.collection(this.collectionName)
          .doc(this.collectionDoc)
          .collection(this.subCollectionName)
          .doc(reviewerId);
      })
    let currentProgram = "esb";
    if (props.history) {
      if (this.props.history.location.pathname.includes("speed")) {
        currentProgram = "speed";
      }
      if (this.props.history.location.pathname.includes("retrofit")) {
        currentProgram = "retrofit";
      }
    }
    this.props.setActiveProgram(currentProgram);
    this.state = {
      disableConfirmStart: false,
      dataLoaded: false,
      applicationObj: {},
      showConfirmReviewStartModal: false,
      showReviewDetails: false,
      participantDetailsLoaded: false,
      participantDetails: {},
      facilities: {},
      errors: {},
      confirm: false,
      formData: {},
      uploadedDocument: [],
      selectedTab: "",
      facilityDetailModalOpen: false,
      currentFacilityDetails: {},
      currentReviewFacility: {},
      saveAndProceed: false,
      disableSaveButton: false,
      primayReviewDecision: "",
      primaryReviewDecisionNote: "",
      showFinalConfirmationModal: false,
      alreadyReviewedFacilities: [],
      applicationSecondaryReview: false,
      SelectedFilters: [],
      inProgressfacilityId: "",
      forceSlideIndex: "",
      openTextModal: false,
      summaryText: "",
      reviewResult: "",
      currentMeasureDetails: [],
      measureDetailModalOpen: false,
      savedStepperName: "",
      showNetworkError: false,
      currentQuestionFieldName: "",
      reviewDecisionOptions: [
        { label: "Information Request (IR)", value: "Information Request (IR)" },
        { label: "Return Application for Edits (IR)", value: "Return Application for Edits (IR)" },
        { label: "Awaiting IESO Exception Approval", value: "Awaiting IESO Exception Approval" },
        { label: "Enerva Guidance", value: "Enerva Guidance" },
        { label: "Peer Review Pending Assignment", value: "Peer Review Pending Assignment" },
        { label: "Reject Application", value: "Reject Application" },
        { label: "Cancelled Application", value: "Cancelled Application" },
        { label: "Withdrawn Application", value: "Withdrawn Application" },
      ],
      secondaryReviewOptions: [
        { label: "Peer Review Approved", value: "Peer Review Approved" },
        { label: "Peer Review Rejected", value: "Peer Review Rejected" },
      ],
      reviewResultOptions: [
        { label: "No Discrepancies", value: "No Discrepancies" },
        { label: "Minor Discrepancies (<=10% inc. change)", value: "Minor Discrepancies (<=10% inc. change)" },
        { label: "Major Discrepancies (>10% inc. change)", value: "Major Discrepancies (>10% inc. change)" },
      ],
      showButtonForMeasures: false,
      isDisconnected: false,
      previousFlagStatus: "",
      internalQAResultsOption: [
        { label: "Pass", value: "Pass" },
        { label: "Return", value: "Return" },
        { label: "Cancelled", value: "Cancelled" },
        { label: "Return as Ineligible", value: "Return as Ineligible" },
        { label: "Pass after edit", value: "Pass after edit" },
      ],
      internalQAResults: "",
      affectedReviewSections: "",
      viewUploadedFilesModal: false,
      currentViewFileFacilityId: "",
      currentViewFileSection: "",
      currentViewFileMeasureId: "",
    };
  }

  // onUnload = (e) => {
  //   const changeReviewState = (toState) => {
  //     const { applicationObj } = this.state;
  //     let rId = "";
  //     let appId = applicationObj.application_id;
  //     let updateObj = {
  //       applicationId: appId,
  //       reviewerState: toState === "In-Progress" ? "Application-In-Progress" : "Idle",
  //     };
  //     if (applicationObj.isPrimaryReviewer) {
  //       updateObj[appId] = {
  //         reviewState: toState === "In-Progress" ? "In-Progress" : "Paused",
  //       };
  //       if (applicationObj.stage === "Pre-Project") {
  //         rId = applicationObj.reviewer && applicationObj.reviewer.value;
  //         updateObj["reviewerId"] = applicationObj.reviewer;
  //       } else {
  //         rId = applicationObj.postProjectReviewer && applicationObj.postProjectReviewer.value;
  //         updateObj["reviewerId"] = applicationObj.postProjectReviewer;
  //       }
  //     } else {
  //       updateObj[appId] = {
  //         secondaryReviewState: toState === "In-Progress" ? "In-Progress" : "Paused",
  //       };
  //       if (applicationObj.stage === "Pre-Project") {
  //         rId = applicationObj.secondaryReviewer && applicationObj.secondaryReviewer.value;
  //         updateObj["reviewerId"] = applicationObj.reviewer;
  //       } else {
  //         rId = applicationObj.postProjectSecondaryReviewer && applicationObj.postProjectSecondaryReviewer.value;
  //         updateObj["reviewerId"] = applicationObj.postProjectSecondaryReviewer;
  //       }
  //     }

  //     // applicationService.postRetrofitApplicationReviewState(rId, updateObj);
  //   };
  //   e.preventDefault();
  //   e.returnValue = "Wait !!";
  //   // setTimeout(function () {
  //   //   setTimeout(function () {
  //   //     changeReviewState("In-Progress");
  //   //   }, 1000);
  //   // }, 1);
  //   // changeReviewState("No-Apps");
  // };

  getCurrentReviewerAndAppId = (data) => {
    const applicationObj = data;
    let rId = "";
    let appId = `${applicationObj.application_id}`;
    let reviewer = {};

    if (applicationObj.isPrimaryReviewer) {
      if (applicationObj.stage === "Pre-Project") {
        reviewer = applicationObj.reviewer;
        rId = applicationObj.reviewer && applicationObj.reviewer.value;
      } else {
        reviewer = applicationObj.postProjectReviewer;
        rId = applicationObj.postProjectReviewer && applicationObj.postProjectReviewer.value;
      }
    } else {
      if (applicationObj.stage === "Pre-Project") {
        reviewer = applicationObj.secondaryReviewer;
        rId = applicationObj.secondaryReviewer && applicationObj.secondaryReviewer.value;
      } else {
        reviewer = applicationObj.postProjectSecondaryReviewer;
        rId = applicationObj.postProjectSecondaryReviewer && applicationObj.postProjectSecondaryReviewer.value;
      }
    }
    return { reviewerId: rId, appId: appId, reviewer: reviewer };
  };

  // connectSocket = (token) => {
  //   console.log("Trying to connect socket");
  //   this.socketio = Socket(`${firebase.socketUrl}${firebase.socketUrlPath}`, {
  //     rejectUnauthorized: false,
  //     secure: true,
  //     path: firebase.socketUrlPath,
  //     query: { token: token },
  //   });
  // };

  // initSocket = async (reviewerId, appID) => {
  //   if (this.socketio !== null) {
  //     this.socketio.on("disconnect", () => {
  //       console.log("io disconnect");
  //     });
  //     this.socketio.on("connect", () => {
  //       const socketIO = new Connection(this.socketio, reviewerId, appID);
  //       this.socketio.on("multiple_connection", async (response) => {
  //         this.setState({
  //           disableConfirmStart: response.status,
  //         });
  //         console.log("regiter status", response.status);
  //       });
  //       this.socketio.on("disconnect", () => {
  //         this.setState({
  //           disableConfirmStart: true,
  //         });
  //         console.log("Socket disconnected");
  //       });
  //     });
  //   }
  //   // });
  // };
  handleConnectionChange = () => {
    const condition = navigator.onLine ? "online" : "offline";
    if (condition === "online") {
      const webPing = setInterval(() => {
        fetch("//google.com", {
          mode: "no-cors",
        })
          .then(() => {
            this.setState({ isDisconnected: false }, () => {
              return clearInterval(webPing);
            });
          })
          .catch(() => this.setState({ isDisconnected: true }));
      }, 2000);
      return;
    }
    return this.setState({ isDisconnected: true });
  };

  handleBeforeUnload = (event) => {
    event.preventDefault();
    this.docRef.update({ reviewerState: "Idle" });
    return event.returnValue = "Are you sure?";
  };

  componentWillUnmount() {
    // window.removeEventListener("beforeunload", this.onUnload);
    window.removeEventListener("online", this.handleConnectionChange);
    window.removeEventListener("offline", this.handleConnectionChange);

    this.docRef.update({ reviewerState: "Idle" });
    window.removeEventListener("beforeunload", this.handleBeforeUnload);
    clearInterval(this.timer);
    // this.socketio.disconnect();
    // console.log("socketio disconnected", this.socketio.disconnect());
  }
  componentDidMount = async () => {
    const appIdToLoad = this.props.match.params.id;
    // window.addEventListener("beforeunload", this.onUnload);
    this.handleConnectionChange();
    window.addEventListener("online", this.handleConnectionChange);
    window.addEventListener("offline", this.handleConnectionChange);
    this.props.loadAdminConfigRetrofit();
    applicationService
      .getApplicationRetrofit(appIdToLoad)
      .then(async (data) => {
        let facilities = "";
        let flagStatus = "";
        try {
          facilities = await applicationService.getFacilitiesByApplicationIdRetrofit(appIdToLoad);
        } catch (err) {
          console.log("Facilities", err);
        }
        try {
          flagStatus = await applicationService.getRetrofitFlagStatus(appIdToLoad, data.stage);
        } catch (err) {
          console.log("Flag Status", err);
        }
        let { reviewerId, appId } = this.getCurrentReviewerAndAppId(data);
        if (reviewerId && appId) {
          // this.docRef = this.db.collection(this.collectionName)
          //   .doc(this.collectionDoc)
          //   .collection(this.subCollectionName)
          //   .doc(reviewerId);
          this.docRef.get().then((doc) => {
            const data = doc.data();
            const reviewrLastUpdateTime = typeof data.updatedAt === 'object' ? data.updatedAt.toDate() : new Date(data.updatedAt);
            const timeDifferenceInSeconds = Math.abs(reviewrLastUpdateTime - new Date()) / 1000;
            if (timeDifferenceInSeconds > 3 && data.reviewerState === "In-Progress") {
              console.log("Reset Reviewer State")
              this.docRef.update({ reviewerState: "Idle", updatedAt: new Date() }).then(() => {
                this.setState({
                  disableConfirmStart: false
                })
              })
            }
          })
          this.docRef.onSnapshot(snapshot => {
            const currentReviewerData = snapshot.data();
            if (currentReviewerData && currentReviewerData.reviewerState === 'In-Progress') {
              this.setState({
                disableConfirmStart: true
              })
            } else if (currentReviewerData && currentReviewerData.reviewerState === 'Idle') {
              this.setState({
                disableConfirmStart: false
              })
            }
          })
          window.addEventListener("beforeunload", this.handleBeforeUnload, { capture: true });
          // const token = await firebase.auth.currentUser.getIdToken(true);
          // this.connectSocket(token);
          // this.initSocket(reviewerId, appId);
        }
        // if (this.socketio !== null && reviewerId && appId) {
        //   this.socketio.emit("start_review", { reviewerId: reviewerId, appId: appId });
        // }
        let activeFacilities = facilities && facilities.filter((f) => f.isActive === true);
        this.props.getRetrofitApplicationsReviewProgress(appIdToLoad);
        this.setState({
          applicationObj: { ...data },
          applicationSecondaryReview: data.flag && data.flag.status === "Peer Review Assigned" ? true : false,
          selectedTab: data.currentReviewTab ? data.currentReviewTab : "Participant",
          dataLoaded: true,
          facilities: activeFacilities,
          previousFlagStatus: flagStatus,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  onTabChange = async (selectedTab) => {
    const { applicationObj } = this.state;
    let newCurrentReviewFacility = selectedTab === "Facility" ? this.state.currentReviewFacility : "";
    if (selectedTab === "Summary") {
      await this.props.getRetrofitApplicationsReviewProgress(applicationObj.application_id);
    }
    this.setState({
      selectedTab,
      currentReviewFacility: newCurrentReviewFacility,
      // participantDetailsLoaded: false,
    });
  };
  confirmStartProcess = async () => {
    const { applicationSecondaryReview, applicationObj } = this.state;
    // let { reviewerId, appId } = this.getCurrentReviewerAndAppId(applicationObj);
    // if (reviewerId && appId) {
    //   const token = await firebase.auth.currentUser.getIdToken(true);
    //   this.connectSocket(token);
    //   this.initSocket(reviewerId, appId);
    // }
    this.setState({
      showConfirmReviewStartModal: !applicationSecondaryReview,
    });
    if (applicationSecondaryReview) {
      this.startReviewProcess();
    }
  };
  startReviewProcess = async () => {
    this.timer = setInterval(
      () => {
        console.log('update state to progress');
        this.docRef.update({ reviewerState: "In-Progress", currentReviewApplicationId: String(this.state.applicationObj.application_id), updatedAt: new Date() });
      },
      3000,
    );
    const { facilities, applicationObj } = this.state;
    let rId = "";
    let { reviewerId, appId } = this.getCurrentReviewerAndAppId(applicationObj);
    let updateObj = {
      applicationId: appId,
      // reviewerState: "Application-In-Progress",
    };
    if (applicationObj.isPrimaryReviewer) {
      updateObj[appId] = {
        reviewState: "In-Progress",
      };
      if (applicationObj.stage === "Pre-Project") {
        rId = applicationObj.reviewer && applicationObj.reviewer.value;
        updateObj["reviewerId"] = applicationObj.reviewer;
      } else {
        rId = applicationObj.postProjectReviewer && applicationObj.postProjectReviewer.value;
        updateObj["reviewerId"] = applicationObj.postProjectReviewer;
      }
    } else {
      updateObj[appId] = {
        reviewState: "Completed",
        secondaryReviewState: "In-Progress",
      };
      if (applicationObj.stage === "Pre-Project") {
        rId = applicationObj.secondaryReviewer && applicationObj.secondaryReviewer.value;
        updateObj["reviewerId"] = applicationObj.reviewer;
      } else {
        rId = applicationObj.postProjectSecondaryReviewer && applicationObj.postProjectSecondaryReviewer.value;
        updateObj["reviewerId"] = applicationObj.postProjectSecondaryReviewer;
      }
    }
    applicationService.postRetrofitApplicationReviewState(rId, updateObj).then((reviewStatedata) => {
      userService.getParticipantDetailsRetrofit(applicationObj.applicant_person_id).then((participantData) => {
        let uploadedDocument = [];
        facilities.map((facility) => (uploadedDocument = uploadedDocument.concat(facility.facilitiesDetails.fileUploads)));
        applicationService.getRetrofitApplicationsReviewProgress(applicationObj.application_id).then((progressData) => {
          let reviewCompletedFacilities = [];
          if (
            !utils.isNullOrEmpty(progressData[applicationObj.stage]) &&
            !utils.isNullOrEmpty(progressData[applicationObj.stage].facilityQuestions) &&
            applicationObj.isPrimaryReviewer
          ) {
            reviewCompletedFacilities = Object.keys(progressData[applicationObj.stage].facilityQuestions).filter((fKey) => {
              if (progressData[applicationObj.stage].facilityQuestions[fKey].reviewState === "Completed") {
                return fKey;
              }
            });
          } else if (
            !utils.isNullOrEmpty(progressData[applicationObj.stage]) &&
            !utils.isNullOrEmpty(progressData[applicationObj.stage].facilityQuestions) &&
            !applicationObj.isPrimaryReviewer
          ) {
            reviewCompletedFacilities = Object.keys(progressData[applicationObj.stage].facilityQuestions).filter((fKey) => {
              if (progressData[applicationObj.stage].facilityQuestions[fKey].secondaryReviewState === "Completed") {
                return fKey;
              }
            });
          }
          this.setState({
            showReviewDetails: true,
            showConfirmReviewStartModal: false,
            participantDetails: participantData,
            participantDetailsLoaded: true,
            uploadedDocument: uploadedDocument,
            alreadyReviewedFacilities: reviewCompletedFacilities,
          });
        });
      });
    });
  };
  viewDocument = (url) => {
    window.open(url);
  };
  getCurrentProgress = async () => {
    const { applicationObj } = this.state;
    await this.props.getRetrofitApplicationsReviewProgress(applicationObj.application_id);
  };
  submitForm = (value, tab, disableSaveButton, step) => {
    const { selectedTab, currentReviewFacility, alreadyReviewedFacilities, facilities, applicationObj } = this.state;
    let whileReiviewFacility = currentReviewFacility;
    let reviewedCurrentFacility = alreadyReviewedFacilities;
    if (selectedTab === "Participant") {
      this.getCurrentProgress();
    }

    if (tab) {
      this.onTabChange(tab);
    }
    if (step === "Final Document Checklist") {
      this.getCurrentProgress();
      if (currentReviewFacility.facility_id && !reviewedCurrentFacility.includes(`${currentReviewFacility.facility_id}`)) {
        reviewedCurrentFacility.push(`${currentReviewFacility.facility_id}`);
      }
      // if (facilities.length === reviewedCurrentFacility.length) {
      //   this.onTabChange("Summary");
      // }
      whileReiviewFacility = {};
    }
    this.setState({
      saveAndProceed: value,
      disableSaveButton: disableSaveButton,
      currentReviewFacility: whileReiviewFacility,
      alreadyReviewedFacilities: reviewedCurrentFacility,
      forceSlideIndex: "",
    });
  };
  showNewSaveAndProceedBtn = (value) => {
    if (value === false) {
      this.setState({ showButtonForMeasures: value, forceSlideIndex: "" });
    } else {
      this.setState({ showButtonForMeasures: value, savedStepperName: "Measures" });
    }
  };
  showFacilityDetailModal = (state) => {
    this.setState({
      facilityDetailModalOpen: state,
    });
  };
  startFacilityReview = (facility) => {
    const { applicationObj } = this.state;
    let btnState = false;
    if (!utils.isNullOrEmpty(applicationObj.currentStepper) && applicationObj.currentStepper[facility.facility_id]) {
      btnState = applicationObj.currentStepper[facility.facility_id].name === "Measures";
    }
    let { reviewerId, appId } = this.getCurrentReviewerAndAppId(applicationObj);
    applicationService.getRetrofitApplicationReviewState(reviewerId).then((data) => {
      let savedStepperName =
        data &&
        data[appId] &&
        data[appId]["currentStepper"] &&
        data[appId]["currentStepper"][facility.facility_id] &&
        data[appId]["currentStepper"][facility.facility_id].name;
      this.setState({
        currentReviewFacility: facility,
        inProgressfacilityId: facility.facility_id,
        disableSaveButton: btnState,
        savedStepperName: savedStepperName,
      });
    });
  };

  applicationSummary = () => {
    const { applicationObj, facilities } = this.state;
    return (
      <div className="application-summary">
        <div className="step-header-container">
          <h2 className="step-header-container-title" dangerouslySetInnerHTML={{ __html: "Application Summary" }}></h2>
        </div>
        {applicationObj && (
          <div className="form-section">
            <div className="details col-left">
              <div className="form-inner-elements">
                <div className="application-form-label">Company</div>
                <div className="application-form-element">
                  {applicationObj.applicationDetails ? applicationObj.applicationDetails.application.company : "N/A"}
                </div>
              </div>
              <div className="form-inner-elements">
                <div className="application-form-label">Applicant Name</div>
                <div className="application-form-element">{`${applicationObj.applicant_first_name} ${applicationObj.applicant_last_name}`}</div>
              </div>
              <div className="form-inner-elements">
                <div className="application-form-label">Applicant Email</div>
                <div className="application-form-element">{applicationObj.applicant_account_id}</div>
              </div>
              <div className="form-inner-elements">
                <div className="application-form-label">Applicant Rep Name</div>
                <div className="application-form-element">
                  {applicationObj.app_rep_first_name ? `${applicationObj.app_rep_first_name} ${applicationObj.app_rep_last_name}` : "N/A"}
                </div>
              </div>
              <div className="form-inner-elements">
                <div className="application-form-label">Applicant Rep Email</div>
                <div className="application-form-element">
                  {applicationObj.app_rep_account_id ? applicationObj.app_rep_account_id : "N/A"}
                </div>
              </div>
              <div className="form-inner-elements">
                <div className="application-form-label">Application Creation Date</div>
                <div className="application-form-element">
                  {applicationObj.creation_dt ? utils.formatDate(applicationObj.creation_dt) : "N/A"}
                </div>
              </div>
              <div className="form-inner-elements">
                <div className="application-form-label">Pre-Project Submission Recieved Date</div>
                <div className="application-form-element">
                  {applicationObj.pre_project_submission_date ? utils.formatDate(applicationObj.pre_project_submission_date) : "N/A"}
                </div>
              </div>
              <div className="form-inner-elements">
                <div className="application-form-label">Estimated Project Start Date</div>
                <div className="application-form-element">
                  {applicationObj.applicationDetails
                    ? utils.formatDate(applicationObj.applicationDetails.application.estimated_project_start_date)
                    : "N/A"}
                </div>
              </div>
              {applicationObj.stage === "Post-Project" && (
                <div className="form-inner-elements">
                  <div className="application-form-label">Actual Project Start Date</div>
                  <div className="application-form-element">
                    {applicationObj.applicationDetails
                      ? utils.formatDate(applicationObj.applicationDetails.application.actual_project_start_date)
                      : "N/A"}
                  </div>
                </div>
              )}
            </div>
            <div className="vertical-separator-box">
              <div className="separator"></div>
            </div>
            <div className="details col-right">
              <div className="form-inner-elements">
                <div className="application-form-label">Application Project Type </div>
                <div className="application-form-element">
                  {applicationObj.application_project_type ? applicationObj.application_project_type : "N/A"}
                </div>
              </div>
              <div className="form-inner-elements">
                <div className="application-form-label">Estimated Date of Project Completion</div>
                <div className="application-form-element">
                  {applicationObj.applicationDetails
                    ? utils.formatDate(applicationObj.applicationDetails.application.estimated_completion_date)
                    : "N/A"}
                </div>
              </div>
              {applicationObj.stage === "Post-Project" && (
                <div className="form-inner-elements">
                  <div className="application-form-label">Actual Project Completion Date</div>
                  <div className="application-form-element">
                    {applicationObj.applicationDetails
                      ? utils.formatDate(applicationObj.applicationDetails.application.actual_project_completion_date)
                      : "N/A"}
                  </div>
                </div>
              )}
              <div className="form-inner-elements">
                <div className="application-form-label">Other Financial Incentives</div>
                <div className="application-form-element">
                  {applicationObj.applicationDetails
                    ? applicationObj.applicationDetails.application.other_financial_incentives === "N"
                      ? "No"
                      : applicationObj.applicationDetails.application.other_financial_incentives === "Y"
                        ? `$${applicationObj.applicationDetails.application.total_funding_amount
                          .toFixed(2)
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
                        : "N/A"
                    : "N/A"}
                </div>
              </div>
              <div className="form-inner-elements">
                <div className="application-form-label">Pre-Project Approval Date</div>
                <div className="application-form-element">
                  {applicationObj.pre_project_approval_date ? utils.formatDate(applicationObj.pre_project_approval_date) : "N/A"}
                </div>
              </div>
              <div className="form-inner-elements">
                <div className="application-form-label">Pre-Project Estimated Incentive</div>
                <div className="application-form-element">{`$${applicationObj.total_est_app_incentive
                  .toFixed(2)
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}</div>
              </div>
              <div className="form-inner-elements">
                <div className="application-form-label">Pre-Project Eligible Project Costs</div>
                <div className="application-form-element">
                  {applicationObj.applicationDetails && applicationObj.applicationDetails.application.total_est_app_eligible_cost
                    ? `$${applicationObj.applicationDetails.application.total_est_app_eligible_cost
                      .toFixed(2)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
                    : "N/A"}
                </div>
              </div>
              <div className="form-inner-elements">
                <div className="application-form-label">Pre-Project SHA Amount</div>
                <div className="application-form-element">
                  {applicationObj.applicationDetails && applicationObj.applicationDetails.application.total_estimated_sha_amount
                    ? `$${applicationObj.applicationDetails.application.total_estimated_sha_amount
                      .toFixed(2)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
                    : "N/A"}
                </div>
              </div>
              {applicationObj.stage === "Post-Project" && (
                <>
                  <div className="form-inner-elements">
                    <div className="application-form-label">Post-Project Total Actual Incentive</div>
                    <div className="application-form-element">
                      {applicationObj.applicationDetails && applicationObj.applicationDetails.application.total_actual_app_incentive
                        ? `$${applicationObj.applicationDetails.application.total_actual_app_incentive
                          .toFixed(2)
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
                        : "N/A"}
                    </div>
                  </div>
                  <div className="form-inner-elements">
                    <div className="application-form-label">Post-Project Eligible Project Costs</div>
                    <div className="application-form-element">
                      {applicationObj.applicationDetails && applicationObj.applicationDetails.application.total_actual_app_eligible_cost
                        ? `$${applicationObj.applicationDetails.application.total_actual_app_eligible_cost
                          .toFixed(2)
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
                        : "N/A"}
                    </div>
                  </div>
                  <div className="form-inner-elements">
                    <div className="application-form-label">Post-Project SHA Amount</div>
                    <div className="application-form-element">
                      {applicationObj.applicationDetails && applicationObj.applicationDetails.application.total_final_sha_amount
                        ? `$${applicationObj.applicationDetails.application.total_final_sha_amount
                          .toFixed(2)
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
                        : "N/A"}
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        )}
      </div>
    );
  };

  participantTab = () => {
    const { configData } = this.props;
    const { applicationObj, uploadedDocument, saveAndProceed, participantDetails } = this.state;
    let participantTableHeader = [
      { title: "Participant Address", value: "Participant Address" },
      { title: "# HST", value: "hst" },
      { title: "Documents", value: "Documents" },
    ];
    let participantTableRows = [
      {
        "Participant Address": participantDetails.address1
          ? `${participantDetails.address1}, ${participantDetails.address2 ? participantDetails.address2 + "," : ""} ${participantDetails.address3 ? participantDetails.address3 + "," : ""
          } ${participantDetails.city}, ${participantDetails.province}, ${participantDetails.postal_code}`
          : "N/A",
        hst: applicationObj.applicationDetails
          ? applicationObj.applicationDetails.application && applicationObj.applicationDetails.application.hst
          : "N/A",
        Documents: (
          <div className="doc-table">
            {uploadedDocument.map((doc, i) => {
              return (
                <div className="document" key={i}>
                  <div className="doc-name">{doc.upload_file_doc_name}</div>
                  <div className="view-doc">
                    <ViewIcon className="view-eye-icon" width="24" height="15" onClick={() => this.viewDocument(doc.url)} />
                  </div>
                </div>
              );
            })}
          </div>
        ),
      },
    ];
    return (
      <div className="participant-tab">
        <div className="participant-tab-table">
          <ResponsiveTable headings={participantTableHeader} rows={participantTableRows} />
        </div>
        <div className="terms">* IESO Checklist Questions </div>
        <DynamicFormBuilder
          applicationObj={applicationObj}
          submitForm={this.submitForm}
          reviewQuestions={configData.reviewQuestions}
          tab={"Participant"}
          saveAndProceed={saveAndProceed}
        />
      </div>
    );
  };
  setSelectedMeasure = (facilityId, measureId) => {
    const { applicationObj } = this.state;
    window.open(
      `inforeview/${"measures"}/${measureId}/${facilityId}/${applicationObj.application_id}`,
      "",
      "resizable=no,width=800,height=900"
    );
    // const { facilities } = this.state;
    // let selectedFacility = facilities.filter((obj) => `${obj.facility_id}` === facilityId);
    // let selectedMeasure = selectedFacility[0].facilitiesDetails
    //   ? selectedFacility[0].facilitiesDetails.prescriptiveMeasures.filter((obj) => `${obj.prescriptive_measure_id}` === measureId)
    //   : [];
    // this.viewMeasureDetails(selectedMeasure[0]);
  };
  // viewMeasureDetails = (measure) => {
  //   let details = [
  //     { label: "Prescriptive Measure ID", value: measure.prescriptive_measure_id || "N/A" },
  //     { label: "Conservation measure name", value: measure.conservation_measure_name || "N/A" },
  //     { label: "Measure category", value: measure.measure_category || "N/A" },
  //     { label: "Manufacturer", value: measure.manufacturer || "N/A" },
  //     {
  //       label: "Model Number",
  //       value: measure.model_number || "N/A",
  //     },
  //     { label: "Estimated Quantity", value: measure.estimated_quantity || "N/A" },
  //     { label: "Actual Quantity", value: measure.actual_quantity || "N/A" },
  //     { label: "Measure Unit", value: measure.measure_unit || "N/A" },
  //     { label: "Estimated kW Reduction", value: measure.estimated_kw_reduction || "N/A" },
  //     { label: "Actual kW Reduction", value: measure.acutal_kw_reduction || "N/A" },
  //     { label: "Estimated kWh Savings", value: measure.estimated_kwh_saving || "N/A" },
  //     { label: "Actual kWh Savings", value: measure.actual_kwh_saving || "N/A" },
  //     { label: "Incentive per Unit", value: measure.incentive_per_unit || "N/A" },
  //     { label: "Estimated Calculated Incentive", value: utils.formatAsCAD(measure.estimated_calculated_incentive) },
  //     { label: "Actual Calculated Incentive", value: utils.formatAsCAD(measure.actual_calculated_incentive) },
  //     { label: "Measure description", value: measure.measure_description || "N/A" },
  //     { label: "Energy Saving per Unit", value: measure.energy_saving_per_unit || "N/A" },
  //     { label: "Demand Saving per Unit", value: measure.demand_saving_per_unit || "N/A" },
  //     { label: "Peak Demand Savings", value: measure.peak_demand_savings || "N/A" },
  //     { label: "Estimated Peak Demand Savings", value: measure.est_peak_demand_savings || "N/A" },
  //     { label: "Actual Peak Demand Savings", value: measure.act_peak_demand_savings || "N/A" },
  //     { label: "End-use", value: measure.end_use || "N/A" },
  //     { label: "Eligibility criteria", value: measure.eligibility_criteria || "N/A" },
  //     { label: "Is complete?", value: measure.is_complete || "N/A" },
  //     { label: "ES/DLC Code", value: measure.es_dlc_code || "N/A" },
  //     { label: "Assumed Base Case", value: measure.assumed_base_case || "N/A" },
  //     { label: "Measure Creation Date", value: measure.creation_dt || "N/A" },
  //   ];
  //   this.setState({
  //     currentMeasureDetails: details,
  //     measureDetailModalOpen: true,
  //   });
  // };
  // setSelectedFacility = (facilityId, measureId) => {
  //   const { facilities } = this.state;
  //   let selectedFacility = facilities.filter((obj) => `${obj.facility_id}` === facilityId);
  //   this.viewFacilityDetails(selectedFacility[0].facilitiesDetails);
  // };
  viewFacilityDetails = (facilityId) => {
    const { applicationObj } = this.state;
    window.open(
      `inforeview/${"facility"}/${facilityId}/${facilityId}/${applicationObj.application_id}`,
      "",
      "resizable=no,width=800,height=900"
    );
    // let details = [
    //   { label: "Facility Name", value: (facilitiesDetails.facility && facilitiesDetails.facility.facility_name) || "N/A" },
    //   { label: "Facility ID", value: (facilitiesDetails.facility && facilitiesDetails.facility.facility_id) || "N/A" },
    //   { label: "Facility Ownership", value: (facilitiesDetails.facility && facilitiesDetails.facility.ownership_type) || "N/A" },
    //   { label: "Facility Type", value: (facilitiesDetails.facility && facilitiesDetails.facility.facility_type_name) || "N/A" },
    //   {
    //     label: "Facility Address",
    //     value:
    //       (facilitiesDetails.facility &&
    //         `${facilitiesDetails.facility.address1},${facilitiesDetails.facility.address2 ? " " + facilitiesDetails.facility.address2 + "," : ""
    //         }${facilitiesDetails.facility.address3 ? " " + facilitiesDetails.facility.address3 + "," : ""} ${facilitiesDetails.facility.city
    //         }, ${facilitiesDetails.facility.province}, ${facilitiesDetails.facility.postal_code}`) ||
    //       "N/A",
    //   },
    //   {
    //     label: "Estimate Project Start Date",
    //     value: utils.formatDate(facilitiesDetails.facility && facilitiesDetails.facility.estimated_project_start_date) || "N/A",
    //   },
    //   {
    //     label: "Estimated Facility Incentive",
    //     value: utils.formatAsCAD(facilitiesDetails.facility && facilitiesDetails.facility.tot_est_facility_incentive) || "N/A",
    //   },
    //   {
    //     label: "Other Financial Incentives",
    //     value: (facilitiesDetails.facility && facilitiesDetails.facility.other_financial_incentives) || "N/A",
    //   },
    //   {
    //     label: "Pre-Project Estimated Energy Savings",
    //     value: `${facilitiesDetails.facility && facilitiesDetails.facility.tot_estimated_kwh_saving}kwh` || "N/A",
    //   },
    //   {
    //     label: "Pre-Project Estimated Demand Savings",
    //     value: `${facilitiesDetails.facility && facilitiesDetails.facility.tot_est_peak_demand_savings}kwh` || "N/A",
    //   },
    // ];
    // if (applicationObj.stage === "Post-Project") {
    //   details = [
    //     { label: "Facility Name", value: (facilitiesDetails.facility && facilitiesDetails.facility.facility_name) || "N/A" },
    //     { label: "Facility ID", value: (facilitiesDetails.facility && facilitiesDetails.facility.facility_id) || "N/A" },
    //     { label: "Facility Ownership", value: (facilitiesDetails.facility && facilitiesDetails.facility.ownership_type) || "N/A" },
    //     { label: "Facility Type", value: (facilitiesDetails.facility && facilitiesDetails.facility.facility_type_name) || "N/A" },
    //     {
    //       label: "Facility Address",
    //       value:
    //         (facilitiesDetails.facility &&
    //           `${facilitiesDetails.facility.address1},${facilitiesDetails.facility.address2 ? " " + facilitiesDetails.facility.address2 + "," : ""
    //           }${facilitiesDetails.facility.address3 ? " " + facilitiesDetails.facility.address3 + "," : ""} ${facilitiesDetails.facility.city
    //           }, ${facilitiesDetails.facility.province}, ${facilitiesDetails.facility.postal_code}`) ||
    //         "N/A",
    //     },
    //     {
    //       label: "Estimate Project Start Date",
    //       value: utils.formatDate(facilitiesDetails.facility && facilitiesDetails.facility.estimated_project_start_date) || "N/A",
    //     },
    //     {
    //       label: "Actual Project Start Date",
    //       value: utils.formatDate(facilitiesDetails.facility && facilitiesDetails.facility.actual_project_start_date) || "N/A",
    //     },
    //     {
    //       label: "Actual Project Completion Date",
    //       value: utils.formatDate(facilitiesDetails.facility && facilitiesDetails.facility.actual_project_completion_date) || "N/A",
    //     },
    //     {
    //       label: "Estimated Facility Incentive",
    //       value: utils.formatAsCAD(facilitiesDetails.facility && facilitiesDetails.facility.tot_est_facility_incentive) || "N/A",
    //     },
    //     {
    //       label: "Actual Facility Incentive",
    //       value: utils.formatAsCAD(facilitiesDetails.facility && facilitiesDetails.facility.tot_actual_facility_incentive) || "N/A",
    //     },
    //     {
    //       label: "Other Financial Incentives",
    //       value: (facilitiesDetails.facility && facilitiesDetails.facility.other_financial_incentives) || "N/A",
    //     },
    //     {
    //       label: "Pre-Project Estimated Energy Savings",
    //       value: `${facilitiesDetails.facility && facilitiesDetails.facility.tot_estimated_kwh_saving}kwh` || "N/A",
    //     },
    //     {
    //       label: "Actual Energy Savings",
    //       value: `${facilitiesDetails.facility && facilitiesDetails.facility.tot_actual_kwh_saving}kwh` || "N/A",
    //     },
    //     {
    //       label: "Pre-Project Estimated Demand Savings",
    //       value: `${facilitiesDetails.facility && facilitiesDetails.facility.tot_est_peak_demand_savings}kwh` || "N/A",
    //     },
    //     {
    //       label: "Actual Energy Savings",
    //       value: `${facilitiesDetails.facility && facilitiesDetails.facility.tot_act_peak_demand_savings}kwh` || "N/A",
    //     },
    //   ];
    // }
    // this.setState({
    //   facilityDetailModalOpen: true,
    //   currentFacilityDetailsLoading: false,
    //   currentFacilityDetails: details,
    // });
  };
  forceChangeSlide = (ind) => {
    this.setState({ forceSlideIndex: ind });
  };

  facilityTab = () => {
    const {
      applicationObj,
      forceSlideIndex,
      facilities,
      inProgressfacilityId,
      currentReviewFacility,
      saveAndProceed,
      alreadyReviewedFacilities,
      applicationSecondaryReview,
      participantDetailsLoaded,
      savedStepperName
    } = this.state;
    // this.startReviewProcess();
    const { configData, reviewProgress } = this.props;
    let facilitiesReviewed =
      Object.keys(reviewProgress).length > 0 && reviewProgress[applicationObj.stage]
        ? reviewProgress[applicationObj.stage].facilityQuestions
        : "";
    let facilityTableHeader = [
      { title: "Facility Name", value: "Facility Name" },
      { title: "Project Type", value: "Project Type" },
      { title: "Project Size", value: "Project Size" },
      { title: "Facility Type Name", value: "Facility Type Name" },
      { title: "Facility Address", value: "Facility Address" },
      { title: "Utility Account #", value: "Utility Account #" },
      { title: "Review", value: "Review" },
      { title: "Documents", value: "Documents" },
    ];
    let facilityTableRows = facilities
      .filter((onlyFacility) => {
        if (!utils.isNullOrEmpty(currentReviewFacility)) {
          // console.log(onlyFacility);
          if (onlyFacility.facility_id === currentReviewFacility.facility_id) {
            return onlyFacility;
          }
        } else {
          return onlyFacility;
        }
      })
      .map((facility) => ({
        "Facility Name": (
          <div className="facility-name">
            {facility.facility_name}
            <div className="view-facility" onClick={() => this.viewFacilityDetails(facility.facility_id)}>
              {`(#${facility.facility_id})`}
            </div>
          </div>
        ),
        "Project Type": facility.project_type ? facility.project_type : "N/A",
        "Project Size": facility.project_size_class ? facility.project_size_class : "N/A",
        "Facility Type Name": facility.facilitiesDetails
          ? facility.facilitiesDetails.facility && facility.facilitiesDetails.facility.facility_type_name
          : "N/A",
        "Facility Address": facility.facilitiesDetails?.facility
          ? `${facility.facilitiesDetails.facility.address1}, ${facility.facilitiesDetails.facility.address2 ? facility.facilitiesDetails.facility.address2 + "," : ""
          } ${facility.facilitiesDetails.facility.address3 ? facility.facilitiesDetails.facility.address3 + "," : ""} ${facility.facilitiesDetails.facility.city
          }, ${facility.facilitiesDetails.facility.province}, ${facility.facilitiesDetails.facility.postal_code}`
          : "N/A",
        "Utility Account #": facility.facilitiesDetails?.facility?.electric_utility_number
          ? facility.facilitiesDetails.facility.electric_utility_number
          : "N/A",
        Review: (
          <Button
            title={
              alreadyReviewedFacilities.includes(`${facility.facility_id}`)
                ? "Edit Review"
                : applicationSecondaryReview
                  ? (facilitiesReviewed &&
                    facilitiesReviewed[facility.facility_id] &&
                    facilitiesReviewed[facility.facility_id].secondaryReviewState === "In-Progress") ||
                    inProgressfacilityId
                    ? "Complete Review"
                    : "Start Review"
                  : (facilitiesReviewed &&
                    facilitiesReviewed[facility.facility_id] &&
                    facilitiesReviewed[facility.facility_id].reviewState === "In-Progress") ||
                    inProgressfacilityId
                    ? "Complete Review"
                    : "Start Review"
            }
            disabled={!utils.isNullOrEmpty(currentReviewFacility)}
            onClick={() => this.startFacilityReview(facility)}
          />
        ),
        colorCode: alreadyReviewedFacilities.includes(`${facility.facility_id}`)
          ? "completed"
          : applicationSecondaryReview
            ? facilitiesReviewed &&
              facilitiesReviewed[facility.facility_id] &&
              facilitiesReviewed[facility.facility_id].secondaryReviewState === "In-Progress"
              ? "in-progress"
              : ""
            : facilitiesReviewed &&
              facilitiesReviewed[facility.facility_id] &&
              facilitiesReviewed[facility.facility_id].reviewState === "In-Progress"
              ? "in-progress"
              : "",
        Documents: (
          <div className="doc-table">
            {facility.facilitiesDetails &&
              facility.facilitiesDetails.fileUploads.length > 0 &&
              facility.facilitiesDetails.fileUploads.map((doc, i) => {
                return (
                  <div className="document" key={i}>
                    <div className="doc-name" title={doc.upload_file_doc_name}>
                      {doc.upload_file_doc_name}
                    </div>
                    <div className="view-doc">
                      <ViewIcon className="view-eye-icon" width="24" height="15" onClick={() => this.viewDocument(doc.url)} />
                    </div>
                  </div>
                );
              })}
          </div>
        ),
      }));
    return (
      <>
        {participantDetailsLoaded ? (
          <div className="facility-tab">
            <div className="facility-tab-table">
              <ResponsiveTable headings={facilityTableHeader} rows={facilityTableRows} />
            </div>
            {!utils.isNullOrEmpty(currentReviewFacility) ? (
              <div className="stepper-review-container">
                <StepperDetailsReview
                  applicationObj={applicationObj}
                  facilities={facilities}
                  reviewQuestions={configData.reviewQuestions}
                  currentFacility={currentReviewFacility}
                  saveAndProceed={saveAndProceed}
                  forceSlideIndex={forceSlideIndex}
                  submitForm={this.submitForm}
                  savedStepperName={savedStepperName}
                  showNewSaveAndProceedBtn={this.showNewSaveAndProceedBtn}
                />
              </div>
            ) : (
              ""
            )}
          </div>
        ) : (
          <ContainerLoader />
        )}
      </>
    );
  };

  selectPrimaryReviewerOption = (opt) => {
    this.setState({
      primayReviewDecision: opt,
    });
  };
  selectReviewerResultOption = (opt) => {
    this.setState({
      reviewResult: opt,
    });
  };
  selectInternalQAResults = (opt) => {
    this.setState({
      internalQAResults: opt,
    });
  };
  selectAffectedReviewSections = (name, value) => {
    this.setState({
      [name]: value,
    });
  };
  getNote = (name, value) => {
    this.setState({
      primaryReviewDecisionNote: value,
    });
  };
  finalPrimaryReviewSubmit = () => {
    this.setState({ showFinalConfirmationModal: true });
  };
  completingPrimaryReview = () => {
    const { adminUserInfo } = this.props;
    const {
      primaryReviewDecisionNote,
      primayReviewDecision,
      applicationObj,
      applicationSecondaryReview,
      reviewResult,
      internalQAResults,
      affectedReviewSections,
    } = this.state;
    let assignReviewObj = {
      applicationId: `${applicationObj.application_id}`,
      stage: applicationObj.stage,
      actionTaker: `${adminUserInfo && adminUserInfo.firstName} ${adminUserInfo && adminUserInfo.lastName}`,
      flag: primayReviewDecision.label,
      reviewNote: primaryReviewDecisionNote,
    };
    if (!applicationSecondaryReview) {
      applicationService.completePrimaryReview(assignReviewObj).then((res) => {
        let rId = "";
        let appId = applicationObj.application_id;
        let updateObj = {
          applicationId: appId,
          reviewerState: "Idle",
        };
        updateObj[appId] = {
          reviewState: "Completed",
        };
        if (applicationObj.stage === "Pre-Project") {
          rId = applicationObj.reviewer && applicationObj.reviewer.value;
          updateObj["reviewerId"] = applicationObj.reviewer;
        } else {
          rId = applicationObj.postProjectReviewer && applicationObj.postProjectReviewer.value;
          updateObj["reviewerId"] = applicationObj.postProjectReviewer;
        }
        applicationService.postRetrofitApplicationReviewState(rId, updateObj);
        this.setState(
          {
            primaryReviewDecisionNote: "",
            primayReviewDecision: "",
            showFinalConfirmationModal: false,
          },
          () => {
            this.props.history.push("/retrofit/review-board");
            // this.props.closeActionModal(true);
          }
        );
      });
    } else {
      assignReviewObj["reviewResult"] = reviewResult;
      assignReviewObj["affectedReviewSections"] = affectedReviewSections;
      assignReviewObj["internalQAResults"] = internalQAResults;
      applicationService.completeSecondaryReview(assignReviewObj).then((res) => {
        let rId = "";
        let appId = applicationObj.application_id;
        let updateObj = {
          applicationId: appId,
          reviewerState: "Idle",
        };
        updateObj[appId] = {
          reviewState: "Completed",
          secondaryReviewState: "Completed",
        };
        if (applicationObj.stage === "Pre-Project") {
          rId = applicationObj.secondaryReviewer && applicationObj.secondaryReviewer.value;
          updateObj["reviewerId"] = applicationObj.secondaryReviewer;
        } else {
          rId = applicationObj.postProjectSecondaryReviewer && applicationObj.postProjectSecondaryReviewer.value;
          updateObj["reviewerId"] = applicationObj.postProjectSecondaryReviewer;
        }
        applicationService.postRetrofitApplicationReviewState(rId, updateObj);
        this.setState(
          {
            primaryReviewDecisionNote: "",
            primayReviewDecision: "",
            showFinalConfirmationModal: false,
          },
          () => {
            this.props.history.push("/retrofit/review-board");
            // this.props.closeActionModal(true);
          }
        );
      });
    }
  };
  onFilterChange = (SelectedFilters) => {
    this.setState({ SelectedFilters: SelectedFilters });
  };
  openUploadedDocumentModal = (facilityId, section, mid, fieldName) => {
    this.props.getRetrofitReviewProcessDocuments(facilityId);
    this.props.getRetrofitReviewProcessDocumentsLogs(facilityId);
    this.setState({
      viewUploadedFilesModal: true,
      currentViewFileSection: section,
      currentViewFileFacilityId: facilityId,
      currentViewFileMeasureId: mid,
      currentQuestionFieldName: fieldName
    });
  };

  summaryTab = () => {
    const {
      reviewDecisionOptions,
      applicationObj,
      secondaryReviewOptions,
      SelectedFilters,
      primayReviewDecision,
      primaryReviewDecisionNote,
      applicationSecondaryReview,
      reviewResultOptions,
      reviewResult,
      participantDetailsLoaded,
      internalQAResultsOption,
      internalQAResults,
      affectedReviewSections,
    } = this.state;
    // this.startReviewProcess();
    const { reviewProgress, reviewProgressLoading, configData } = this.props;
    let reviewOptions = applicationSecondaryReview ? secondaryReviewOptions : reviewDecisionOptions;

    let preProjectSteps = [
      "Facility",
      "Project Eligibility",
      "Measures",
      "Project Costs",
      "Emergency Replacement",
      "QA/QC",
      "Final Document Checklist",
    ];

    let postProjectSteps = [
      "Facility",
      "Project Eligibility",
      "Measures",
      "Project Costs",
      "Emergency Replacement",
      "QA/QC",
      "Disposal",
      "Final Document Checklist",
    ];
    let preProjectAffectedSections = [
      "Facility",
      "Project Eligibility",
      "Measures",
      "Project Costs",
      "Emergency Replacement",
      "QA/QC",
      "Final Document Checklist",
      "N/A",
    ];

    let postProjectAffectedSections = [
      "Facility",
      "Project Eligibility",
      "Measures",
      "Project Costs",
      "Emergency Replacement",
      "QA/QC",
      "Disposal",
      "Final Document Checklist",
      "N/A",
    ];
    let steps = applicationObj.stage === "Post-Project" ? postProjectSteps : preProjectSteps;
    let affectedSections = applicationObj.stage === "Post-Project" ? postProjectAffectedSections : preProjectAffectedSections;
    let filterOptions = applicationObj.isPrimaryReviewer
      ? ["Yes", "No", "Information Request", "TBD", "N/A"]
      : ["No Discrepancies", "Minor Discrepancies", "Major Discrepancies"];
    return (
      <>
        {participantDetailsLoaded && !reviewProgressLoading ? (
          <div className="summary-tab">
            <div className="summary-container">
              <div className="container-head">
                <h2 className="section-title">{applicationSecondaryReview ? "SECONDARY REVIEW SUMMARY" : "PRIMARY REVIEW SUMMARY"}</h2>
                <FilterDropdown
                  FilterOptions={filterOptions}
                  className="program-filter"
                  SelectedFilters={SelectedFilters}
                  onChange={this.onFilterChange}
                  label={"FILTER RESPONSE"}
                  iconClass={"filterIcon"}
                // onSubmit={this.handleFormSubmit}
                />
              </div>
              <div className="review-summar-info">
                <div className="questions-ans">
                  {reviewProgress && reviewProgress[applicationObj.stage] && reviewProgress[applicationObj.stage].participantQuestions && (
                    <div className="participant-question-ans">
                      <div className="q-header">Participant</div>
                      {reviewProgress &&
                        reviewProgress[applicationObj.stage] &&
                        reviewProgress[applicationObj.stage].participantQuestions &&
                        Object.keys(reviewProgress[applicationObj.stage].participantQuestions)
                          .filter((fEle) => {
                            if (applicationObj.isPrimaryReviewer) {
                              if (utils.isNullOrEmpty(SelectedFilters)) {
                                if (fEle !== "reviewState" && fEle !== "secondaryReviewState") {
                                  return fEle;
                                }
                              } else if (SelectedFilters.includes(reviewProgress[applicationObj.stage].participantQuestions[fEle].value)) {
                                return fEle;
                              }
                            } else {
                              if (utils.isNullOrEmpty(SelectedFilters)) {
                                if (fEle !== "reviewState" && fEle !== "secondaryReviewState" && !fEle.includes("Primary")) {
                                  return fEle;
                                }
                              } else if (SelectedFilters.includes(reviewProgress[applicationObj.stage].participantQuestions[fEle].value)) {
                                return fEle;
                              }
                            }
                          })
                          .sort((a, b) => {
                            let arrayA = a.split("").filter((val) => val.match(/\d+/g));
                            let arrayB = b.split("").filter((val) => val.match(/\d+/g));
                            let numA = Number(arrayA.join(""));
                            let numB = Number(arrayB.join(""));

                            return numA - numB;
                          })
                          .map((ele) => {
                            if (reviewProgress[applicationObj.stage].participantQuestions[ele].question === undefined) {
                              return (
                                <div className="question-ans">
                                  <div className="question"></div>
                                  <div className="ans">
                                    <span className="turncate-text">{reviewProgress[applicationObj.stage].participantQuestions[ele]}</span>
                                    <span
                                      className="expand"
                                      onClick={() =>
                                        this.setState({
                                          summaryText: reviewProgress[applicationObj.stage].participantQuestions[ele],
                                          openTextModal: true,
                                        })
                                      }
                                    >
                                      Expand
                                    </span>
                                  </div>
                                </div>
                              );
                            } else if (
                              reviewProgress[applicationObj.stage].participantQuestions[ele].question.includes(
                                "Other General discrepancies"
                              )
                            ) {
                              return (
                                <div className="question-ans">
                                  <div className="question">{reviewProgress[applicationObj.stage].participantQuestions[ele].question}</div>
                                  <div className="ans">
                                    <span className="turncate-text">
                                      {reviewProgress[applicationObj.stage].participantQuestions[ele].value}
                                    </span>
                                    <span
                                      className="expand"
                                      onClick={() =>
                                        this.setState({
                                          summaryText: reviewProgress[applicationObj.stage].participantQuestions[ele].value,
                                          openTextModal: true,
                                        })
                                      }
                                    >
                                      Expand
                                    </span>
                                  </div>
                                </div>
                              );
                            } else if (reviewProgress[applicationObj.stage].participantQuestions[ele].question.includes("Upload ")) {
                              return <div className="question-ans" style={{ display: "none" }}></div>;
                            } else if (reviewProgress[applicationObj.stage].participantQuestions[ele].label) {
                              return (
                                <div className="question-ans">
                                  <div className="question">{reviewProgress[applicationObj.stage].participantQuestions[ele].question}</div>
                                  <div className="ans">{reviewProgress[applicationObj.stage].participantQuestions[ele].value}</div>
                                </div>
                              );
                            } else {
                              return (
                                <div className="question-ans">
                                  <div className="question">{reviewProgress[applicationObj.stage].participantQuestions[ele].question}</div>
                                  <div className="ans">{reviewProgress[applicationObj.stage].participantQuestions[ele].value}</div>
                                </div>
                              );
                            }
                          })}
                    </div>
                  )}
                  {reviewProgress && reviewProgress[applicationObj.stage] && reviewProgress[applicationObj.stage].facilityQuestions && (
                    <div className="facility-question-ans">
                      {reviewProgress[applicationObj.stage] &&
                        reviewProgress[applicationObj.stage].facilityQuestions &&
                        Object.keys(reviewProgress[applicationObj.stage].facilityQuestions).map((key) => {
                          return (
                            reviewProgress[applicationObj.stage].facilityQuestions &&
                            steps.map((keyEle) => (
                              <>
                                {keyEle === "Measures" || keyEle === "reviewState" ? (
                                  ""
                                ) : (
                                  <div className="q-header">
                                    {`${keyEle}  - #`}
                                    <span className="show-details" onClick={() => this.viewFacilityDetails(key)}>{`${key}`}</span>
                                  </div>
                                )}
                                {reviewProgress[applicationObj.stage].facilityQuestions &&
                                  reviewProgress[applicationObj.stage].facilityQuestions[key][keyEle]
                                  ? keyEle === "Measures"
                                    ? Object.keys(reviewProgress[applicationObj.stage].facilityQuestions[key][keyEle])
                                      .filter((fEle) => {
                                        if (fEle !== "reviewState" && fEle !== "secondaryReviewState") {
                                          return fEle;
                                        }
                                      })
                                      .map((mId) => (
                                        <>
                                          <div className="q-header">
                                            {`Measure - #`}
                                            <span
                                              className="show-details"
                                              onClick={() => this.setSelectedMeasure(key, mId)}
                                            >{`${mId}`}</span>
                                            {`- #${key}`}
                                          </div>
                                          {Object.keys(reviewProgress[applicationObj.stage].facilityQuestions[key][keyEle][mId])
                                            .filter((fEle) => {
                                              if (applicationObj.isPrimaryReviewer) {
                                                if (utils.isNullOrEmpty(SelectedFilters)) {
                                                  if (fEle !== "reviewState" && fEle !== "secondaryReviewState") {
                                                    return fEle;
                                                  }
                                                } else if (
                                                  SelectedFilters.includes(
                                                    reviewProgress[applicationObj.stage].facilityQuestions[key][keyEle][mId][fEle].value
                                                  )
                                                ) {
                                                  return fEle;
                                                }
                                              } else {
                                                if (utils.isNullOrEmpty(SelectedFilters)) {
                                                  if (
                                                    fEle !== "reviewState" &&
                                                    fEle !== "secondaryReviewState" &&
                                                    !fEle.includes("Primary")
                                                  ) {
                                                    return fEle;
                                                  }
                                                } else if (
                                                  SelectedFilters.includes(
                                                    reviewProgress[applicationObj.stage].facilityQuestions[key][keyEle][mId][fEle].value
                                                  )
                                                ) {
                                                  return fEle;
                                                }
                                              }
                                            })
                                            .sort((a, b) => {
                                              // let arrayA = a.split("").filter((val)=> val.match(/\d+/g));
                                              // let arrayB = b.split("").filter((val)=> val.match(/\d+/g));
                                              // let numA = Number(arrayA.join(""));
                                              // let numB = Number(arrayB.join(""));

                                              // console.log("questionInOrder ====", eleA, eleB);
                                              // return numA - numB;
                                              let questionInOrder = configData && configData.reviewQuestions ? configData.reviewQuestions["Facility"] : "";
                                              let eleA = questionInOrder.filter((element) => element["fieldName"] === a);
                                              let eleB = questionInOrder.filter((element) => element["fieldName"] === b);

                                              if (eleA[0] && eleB[0]) {
                                                return eleA[0].order - eleB[0].order;
                                              }
                                            }).filter((validEle)=> !utils.isNullOrEmpty(reviewProgress[applicationObj.stage].facilityQuestions[key][keyEle][mId][validEle]))
                                            .map((mField) => {
                                              if (
                                                reviewProgress[applicationObj.stage].facilityQuestions[key][keyEle][mId][mField]
                                                  .question === undefined
                                              ) {
                                                return (
                                                  <div className="question-ans">
                                                    <div className="question"></div>
                                                    <div className="ans">
                                                      <span className="turncate-text">
                                                        {reviewProgress[applicationObj.stage].facilityQuestions[key][keyEle][mId][mField]}
                                                      </span>
                                                      <span
                                                        className="expand"
                                                        onClick={() =>
                                                          this.setState({
                                                            summaryText:
                                                              reviewProgress[applicationObj.stage].facilityQuestions[key][keyEle][mId][
                                                              mField
                                                              ],
                                                            openTextModal: true,
                                                          })
                                                        }
                                                      >
                                                        Expand
                                                      </span>
                                                    </div>
                                                  </div>
                                                );
                                              } else if (
                                                reviewProgress[applicationObj.stage].facilityQuestions[key][keyEle][mId][
                                                  mField
                                                ].question.includes("Other General discrepancies")
                                              ) {
                                                return (
                                                  <div className="question-ans">
                                                    <div className="question">
                                                      {
                                                        reviewProgress[applicationObj.stage].facilityQuestions[key][keyEle][mId][mField]
                                                          .question
                                                      }
                                                    </div>
                                                    <div className="ans">
                                                      <span className="turncate-text">
                                                        {
                                                          reviewProgress[applicationObj.stage].facilityQuestions[key][keyEle][mId][mField]
                                                            .value
                                                        }
                                                      </span>
                                                      <span
                                                        className="expand"
                                                        onClick={() =>
                                                          this.setState({
                                                            summaryText:
                                                              reviewProgress[applicationObj.stage].facilityQuestions[key][keyEle][mId][
                                                                mField
                                                              ].value,
                                                            openTextModal: true,
                                                          })
                                                        }
                                                      >
                                                        Expand
                                                      </span>
                                                    </div>
                                                  </div>
                                                );
                                              } else if (
                                                reviewProgress[applicationObj.stage].facilityQuestions[key][keyEle][mId][
                                                  mField
                                                ].question.includes("Upload ")
                                              ) {
                                                return (
                                                  <div className="question-ans">
                                                    <div className="question">
                                                      {
                                                        reviewProgress[applicationObj.stage].facilityQuestions[key][keyEle][mId][mField]
                                                          .question
                                                      }
                                                    </div>
                                                    <div className="ans">
                                                      <span
                                                        className="view-file-btn"
                                                        onClick={() => this.openUploadedDocumentModal(key, "Measures", mId, mField)}
                                                      >
                                                        View Documents
                                                      </span>
                                                    </div>
                                                  </div>
                                                );
                                              } else if (
                                                reviewProgress[applicationObj.stage].facilityQuestions[key][keyEle][mId][mField].label
                                              ) {
                                                return (
                                                  <div className="question-ans">
                                                    <div className="question">
                                                      {
                                                        reviewProgress[applicationObj.stage].facilityQuestions[key][keyEle][mId][mField]
                                                          .question
                                                      }
                                                    </div>
                                                    <div className="ans">
                                                      {
                                                        reviewProgress[applicationObj.stage].facilityQuestions[key][keyEle][mId][mField]
                                                          .value
                                                      }
                                                    </div>
                                                  </div>
                                                );
                                              } else {
                                                return (
                                                  <div className="question-ans">
                                                    <div className="question">
                                                      {
                                                        reviewProgress[applicationObj.stage].facilityQuestions[key][keyEle][mId][mField]
                                                          .question
                                                      }
                                                    </div>
                                                    <div className="ans">
                                                      {
                                                        reviewProgress[applicationObj.stage].facilityQuestions[key][keyEle][mId][mField]
                                                          .value
                                                      }
                                                    </div>
                                                  </div>
                                                );
                                              }
                                            })}
                                        </>
                                      ))
                                    : Object.keys(reviewProgress[applicationObj.stage].facilityQuestions[key][keyEle])
                                      .filter((fEle) => {
                                        if (applicationObj.isPrimaryReviewer) {
                                          if (utils.isNullOrEmpty(SelectedFilters)) {
                                            if (fEle !== "reviewState" && fEle !== "secondaryReviewState") {
                                              return fEle;
                                            }
                                          } else if (
                                            SelectedFilters.includes(
                                              reviewProgress[applicationObj.stage].facilityQuestions[key][keyEle][fEle].value
                                            )
                                          ) {
                                            return fEle;
                                          }
                                        } else {
                                          if (utils.isNullOrEmpty(SelectedFilters)) {
                                            if (fEle !== "reviewState" && fEle !== "secondaryReviewState" && !fEle.includes("Primary")) {
                                              return fEle;
                                            }
                                          } else if (
                                            SelectedFilters.includes(
                                              reviewProgress[applicationObj.stage].facilityQuestions[key][keyEle][fEle].value
                                            )
                                          ) {
                                            return fEle;
                                          }
                                        }
                                      })
                                      .sort((a, b) => {
                                        // let arrayA = a.split("").filter((val)=> val.match(/\d+/g));
                                        // let arrayB = b.split("").filter((val)=> val.match(/\d+/g));
                                        // let numA = Number(arrayA.join(""));
                                        // let numB = Number(arrayB.join(""));

                                        // return numA - numB;
                                        let questionInOrder = configData && configData.reviewQuestions ? configData.reviewQuestions["Facility"] : "";
                                        let eleA = questionInOrder.filter((element) => element["fieldName"] === a);
                                        let eleB = questionInOrder.filter((element) => element["fieldName"] === b);

                                        if (eleA[0] && eleB[0]) {
                                          return eleA[0].order - eleB[0].order;
                                        }
                                      })
                                      .map((ele) => {
                                        if (
                                          reviewProgress[applicationObj.stage].facilityQuestions[key][keyEle][ele].question === undefined
                                        ) {
                                          return (
                                            <div className="question-ans">
                                              <div className="question"></div>
                                              <div className="ans">
                                                <span className="turncate-text">
                                                  {reviewProgress[applicationObj.stage].facilityQuestions[key][keyEle][ele]}
                                                </span>
                                                <span
                                                  className="expand"
                                                  onClick={() =>
                                                    this.setState({
                                                      summaryText:
                                                        reviewProgress[applicationObj.stage].facilityQuestions[key][keyEle][ele],
                                                      openTextModal: true,
                                                    })
                                                  }
                                                >
                                                  Expand
                                                </span>
                                              </div>
                                            </div>
                                          );
                                        } else if (
                                          reviewProgress[applicationObj.stage].facilityQuestions[key][keyEle][ele].question.includes(
                                            "Other General discrepancies"
                                          )
                                        ) {
                                          return (
                                            <div className="question-ans">
                                              <div className="question">
                                                {reviewProgress[applicationObj.stage].facilityQuestions[key][keyEle][ele].question}
                                              </div>
                                              <div className="ans">
                                                <span className="turncate-text">
                                                  {reviewProgress[applicationObj.stage].facilityQuestions[key][keyEle][ele].value}
                                                </span>
                                                <span
                                                  className="expand"
                                                  onClick={() =>
                                                    this.setState({
                                                      summaryText:
                                                        reviewProgress[applicationObj.stage].facilityQuestions[key][keyEle][ele].value,
                                                      openTextModal: true,
                                                    })
                                                  }
                                                >
                                                  Expand
                                                </span>
                                              </div>
                                            </div>
                                          );
                                        } else if (
                                          reviewProgress[applicationObj.stage].facilityQuestions[key][keyEle][ele].question.includes(
                                            "Upload "
                                          )
                                        ) {
                                          return (
                                            <div className="question-ans">
                                              <div className="question">
                                                {reviewProgress[applicationObj.stage].facilityQuestions[key][keyEle][ele].question}
                                              </div>
                                              <div className="ans">
                                                <span
                                                  className="view-file-btn"
                                                  onClick={() => this.openUploadedDocumentModal(key, keyEle, "")}
                                                >
                                                  View Documents
                                                </span>
                                              </div>
                                            </div>
                                          );
                                        } else if (reviewProgress[applicationObj.stage].facilityQuestions[key][keyEle][ele].label) {
                                          return (
                                            <div className="question-ans">
                                              <div className="question">
                                                {reviewProgress[applicationObj.stage].facilityQuestions[key][keyEle][ele].question}
                                              </div>
                                              <div className="ans">
                                                {reviewProgress[applicationObj.stage].facilityQuestions[key][keyEle][ele].value}
                                              </div>
                                            </div>
                                          );
                                        } else {
                                          return (
                                            <div className="question-ans">
                                              <div className="question">
                                                {reviewProgress[applicationObj.stage].facilityQuestions[key][keyEle][ele].question}
                                              </div>
                                              <div className="ans">
                                                {reviewProgress[applicationObj.stage].facilityQuestions[key][keyEle][ele].value}
                                              </div>
                                            </div>
                                          );
                                        }
                                      })
                                  : ""}
                              </>
                            ))
                          );
                        })}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="review-decision-container">
              <h2 className="section-title">REVIEW DECISION</h2>
              <Input
                placeholder="Please select a Review Decision"
                type="dropdown"
                // disabled={finalConfirmation}
                name="primayReviewDecision"
                className="decision-options"
                onChange={this.selectPrimaryReviewerOption}
                value={primayReviewDecision ? primayReviewDecision : undefined}
                options={reviewOptions.map((type) => type)}
                isSearchable={false}
              />
              {applicationSecondaryReview ? (
                <>
                  <h2 className="section-title">REVIEW RESULT</h2>
                  <Input
                    placeholder="Please select a Review Result"
                    type="dropdown"
                    // disabled={finalConfirmation}
                    name="primayReviewDecision"
                    className="decision-options"
                    onChange={this.selectReviewerResultOption}
                    value={reviewResult ? reviewResult : undefined}
                    options={reviewResultOptions.map((type) => type)}
                    isSearchable={false}
                  />
                  <h2 className="section-title">AFFECTED REVIEW SECTIONS</h2>
                  <Input
                    placeholder="Please select a Affected Review Sheet Sections"
                    type="multiselect"
                    // disabled={finalConfirmation}
                    name="affectedReviewSections"
                    className="decision-options"
                    onChange={this.selectAffectedReviewSections}
                    value={affectedReviewSections ? affectedReviewSections : undefined}
                    options={affectedSections.map((type) => {
                      return { label: type, value: type };
                    })}
                    isSearchable={false}
                  />
                  <h2 className="section-title">INTERNAL QA RESULTS</h2>
                  <Input
                    placeholder="Please select a Internal QA Results"
                    type="dropdown"
                    // disabled={finalConfirmation}
                    name="internalQAResults"
                    className="decision-options"
                    onChange={this.selectInternalQAResults}
                    value={internalQAResults ? internalQAResults : undefined}
                    options={internalQAResultsOption.map((type) => type)}
                    isSearchable={false}
                  />
                </>
              ) : (
                ""
              )}
              <div className={`note-section ${applicationSecondaryReview ? "secondary" : ""}`}>
                {/* <label className="note-label">Review Note</label> */}
                <h2 className="section-title">REVIEW NOTE</h2>
                <Input
                  type="richtext"
                  // disabled={finalConfirmation}
                  name="primaryReviewDecisionNote"
                  value={primaryReviewDecisionNote}
                  onChange={this.getNote}
                />
              </div>
            </div>
          </div>
        ) : (
          <ContainerLoader />
        )}
      </>
    );
  };
  downloadFile = (filePath) => {
    var link = document.createElement("a");
    link.target = "_blank";
    link.href = filePath;
    link.download = filePath.substr(filePath.lastIndexOf("/") + 1);
    link.click();
  };
  handleNetworkError = () => {
    this.setState({ showNetworkError: true });
  };
  render() {
    let {
      disableConfirmStart,
      dataLoaded,
      selectedTab,
      applicationObj,
      showConfirmReviewStartModal,
      showReviewDetails,
      participantDetails,
      participantDetailsLoaded,
      facilityDetailModalOpen,
      currentFacilityDetails,
      disableSaveButton,
      currentReviewFacility,
      facilities,
      showFinalConfirmationModal,
      primayReviewDecision,
      primaryReviewDecisionNote,
      alreadyReviewedFacilities,
      applicationSecondaryReview,
      showButtonForMeasures,
      summaryText,
      openTextModal,
      viewUploadedFilesModal,
      measureDetailModalOpen,
      reviewResult,
      savedStepperName,
      isDisconnected,
      showNetworkError,
      previousFlagStatus,
      internalQAResults,
      affectedReviewSections,
      currentViewFileFacilityId,
      currentViewFileSection,
      currentViewFileMeasureId,
      currentQuestionFieldName
    } = this.state;
    const { reviewProgress, reviewDocumentList, reviewDocumentsLogs, appReviewDocumentsLoading } = this.props;
    let uploadedDocuments = [];
    if (!utils.isNullOrEmpty(reviewDocumentList)) {
      uploadedDocuments = reviewDocumentList.filter((obj) => {
        if (currentViewFileSection === "Measures") {
          if (obj.measure_id === `${currentViewFileMeasureId}` && obj.fieldName === currentQuestionFieldName) {
            return obj;
          }
        } else if (currentViewFileSection === "QA/QC") {
          if (
            !obj.name.includes(`${currentViewFileFacilityId}`) &&
            obj.measure_id === "" &&
            obj.facility_id === `${currentViewFileFacilityId}` &&
            !obj.isFinalDocumentChecklist &&
            !obj.isDisposalDocument
          ) {
            return obj;
          }
        } else if (currentViewFileSection === "Final Document Checklist") {
          if (obj.isFinalDocumentChecklist) {
            return obj;
          }
        } else if (currentViewFileSection === "Disposal") {
          if (obj.isDisposalDocument) {
            return obj;
          }
        } else {
          return obj;
        }
      });
    }
    let showDisabledBtn = true;
    let enableTabChange = false;
    let facilitiesLength = facilities && facilities.length;
    if (facilitiesLength === alreadyReviewedFacilities.length) {
      enableTabChange = true;
      if (
        !utils.isNullOrEmpty(reviewProgress) &&
        reviewProgress[applicationObj.stage] &&
        reviewProgress[applicationObj.stage].participantQuestions &&
        !utils.isNullOrEmpty(primayReviewDecision) &&
        utils.cleanHTMLText(primaryReviewDecisionNote)
      ) {
        showDisabledBtn = false;
      }
    }
    if (
      !utils.isNullOrEmpty(primayReviewDecision) &&
      utils.cleanHTMLText(primaryReviewDecisionNote) &&
      (primayReviewDecision.label === "Cancelled Application" || primayReviewDecision.label === "Withdrawn Application")
    ) {
      showDisabledBtn = false;
    }
    if (selectedTab === "Participant") {
      disableSaveButton = false;
    }
    if (isDisconnected) {
      window.addEventListener("click", this.handleNetworkError);
    }
    let affectedReviewSectionsString = utils.isNullOrEmpty(affectedReviewSections)
      ? ""
      : Object.values(affectedReviewSections)
        .map((ele) => {
          return ele.value;
        })
        .join();
    return (
      <>
        {isDisconnected ? (
          <div className="toast-message">
            <div className="container">
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 40 38" fill="none">
                <g clip-path="url(#clip0_632_3509)">
                  <path
                    d="M18.902 1.77916C19.4061 0.962294 20.5939 0.962294 21.098 1.77916L36.1059 26.0965C36.6365 26.9562 36.0181 28.0645 35.0079 28.0645H4.99212C3.98192 28.0645 3.36352 26.9562 3.89407 26.0965L18.902 1.77916Z"
                    fill="#FFFFFF"
                  />
                  <path d="M20 8V18" stroke="#433D50" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M20 23H20.01" stroke="#433D50" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                </g>
                <defs>
                  <clipPath id="clip0_632_3509">
                    <rect width="40" height="37.4194" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              <span className="message">You are not connected to the internet.</span>
            </div>
          </div>
        ) : (
          ""
        )}
        {showNetworkError ? (
          <NetworkError applicationId={applicationObj.application_id} isDisconnected={isDisconnected} />
        ) : (
          <ProgramLayout showFooter={false} showSideBarMenu={false} props={this.props}>
            {dataLoaded ? (
              <div className="main-container">
                <div className="review-application-container">
                  <Modal
                    IsOpen={viewUploadedFilesModal}
                    className={"esb-modal measure-details-modal"}
                    handleCloseModal={() => this.setState({ viewUploadedFilesModal: false })}
                    modalSize={"large"}
                    loading={appReviewDocumentsLoading}
                  >
                    <div className="confirm-review-container">
                      <div className="section-header">
                        <div className="section-top-details">{applicationObj.application_id}</div>
                        <div className="section-top-details name-section">{`${applicationObj.applicant_first_name} ${applicationObj.applicant_last_name}`}</div>
                        <div className="section-top-details">{applicationObj.applicant_account_id}</div>
                      </div>
                      <div className="section-below-header">
                        <h2 className="title" dangerouslySetInnerHTML={{ __html: "Uploaded Files" }}></h2>
                      </div>
                      {!utils.isNullOrEmpty(uploadedDocuments) ? <div className="uploaded-documents-table">
                        <ResponsiveTable
                          headings={[
                            { title: "S. No.", value: "SNO" },
                            { title: "Document Name", value: "name" },
                            { title: "Document Type", value: "extension" },
                            { title: "Uploaded Date", value: "createdAt" },
                            { title: "Uploaded By", value: "uploadedBy" },
                            { title: "", value: "moreActions" },
                          ]}
                          rows={uploadedDocuments.map((doc, index) => {
                            return {
                              SNO: index + 1,
                              name: (
                                <div className="doc-name">
                                  <span className="turncate-string">
                                    {doc.name}
                                    <span className="tooltiptext">{doc.name}</span>
                                  </span>
                                </div>
                              ),
                              extension: doc.extension,
                              createdAt: utils.formatDate(doc.createdAt),
                              uploadedBy: doc.actionTaker,
                              moreActions: (
                                <div className="more-actions">
                                  <DownloadIcon
                                    className="view-eye-icon"
                                    width="30px"
                                    height="20px"
                                    onClick={() => this.downloadFile(doc.url)}
                                  />
                                </div>
                              ),
                            };
                          })}
                        />
                      </div> : <div className="no-records-found">No records.</div>}
                    </div>
                  </Modal>
                  <Modal
                    IsOpen={openTextModal}
                    className={"esb-modal measure-details-modal"}
                    handleCloseModal={() => this.setState({ openTextModal: false, summaryText: "" })}
                    modalSize={"small"}
                  >
                    <div className="text-container">
                      <p>{summaryText}</p>
                    </div>
                  </Modal>
                  <Modal
                    IsOpen={showConfirmReviewStartModal}
                    className={"esb-modal"}
                    handleCloseModal={() => this.setState({ showConfirmReviewStartModal: false })}
                    modalSize={"small"}
                  >
                    <h2 className="route-heading">Route Application On CDMIS</h2>
                    <p>
                      Before you start the review, please login to CDMIS portal and route the application {applicationObj.application_id} to
                      yourself
                    </p>
                    <div className="button-set">
                      <Button
                        className="inversed-btn"
                        title={"Cancel"}
                        uppercase
                        onClick={() => this.setState({ showConfirmReviewStartModal: false })}
                      />
                      <Button title={"Application Routed"} onClick={() => this.startReviewProcess()} uppercase />
                    </div>
                  </Modal>
                  {/* <Modal
                IsOpen={facilityDetailModalOpen}
                className={"esb-modal measure-details-modal"}
                handleCloseModal={() => this.showFacilityDetailModal(false)}
                modalSize={"large"}
              >
                <h3>Facility Details</h3>
                <div className="measure-details-container">
                  {currentFacilityDetails ? (
                    <Table
                      rows={currentFacilityDetails}
                      headings={[
                        { title: "Field Name", value: "label" },
                        { title: "Field Value", value: "value" },
                      ]}
                    />
                  ) : (
                    ""
                  )}
                </div>
              </Modal> */}
                  <Modal
                    IsOpen={showFinalConfirmationModal}
                    className={"esb-modal confirmation-details-modal"}
                    handleCloseModal={() => this.setState({ showFinalConfirmationModal: false })}
                    modalSize={"large"}
                  >
                    <div className="confirm-review-container">
                      <div className="section-header">
                        <div className="section-top-details">{applicationObj.application_id}</div>
                        <div className="section-top-details name-section">{`${applicationObj.applicant_first_name} ${applicationObj.applicant_last_name}`}</div>
                        <div className="section-top-details">
                          $
                          {applicationObj.stage === "Post-Project" ? applicationObj.total_actual_app_incentive : applicationObj.total_est_app_incentive
                            .toFixed(2)
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        </div>
                      </div>
                      <div className="section-below-header">
                        <h2 className="title" dangerouslySetInnerHTML={{ __html: "Confirm Review" }}></h2>
                      </div>
                      <div className="container">
                        {!applicationSecondaryReview ? (
                          <div className="facility-table">
                            <div className="message-block">
                              <div className="fields-block">
                                <Input
                                  label="Review Decision"
                                  placeholder="Please select a Review Decision"
                                  type="dropdown"
                                  disabled={true}
                                  name="primayReviewDecision"
                                  className="decision-options"
                                  value={primayReviewDecision ? primayReviewDecision : undefined}
                                  isSearchable={false}
                                />
                              </div>
                              <div className="vertical-separator">
                                <span className="separator"></span>
                              </div>
                              <div className="message">
                                <p>
                                  You are about to mark the review for this application as <span>{primayReviewDecision.label}</span>. This
                                  might send out an email to the applicants & applicant reps. Please verify the accuracy of the information
                                  and the note.
                                </p>
                              </div>
                            </div>
                            <div className="note-section">
                              <label>Note</label>
                              {/* <div className="text-disabled-container">{utils.cleanHTMLText(primaryReviewDecisionNote)}</div> */}
                              <Input
                                type="richtext"
                                onChange={() => this.getNote}
                                disabled={true}
                                name="primaryReviewDecisionNote"
                                value={primaryReviewDecisionNote}
                              />
                            </div>
                          </div>
                        ) : (
                          <div className="facility-table secondary-facility-table">
                            <div className="message-block">
                              <div className="message">
                                <p>
                                  You are about to mark the review for this application as <span>{primayReviewDecision.label}</span>. This
                                  might send out an email to the applicants & applicant reps. Please verify the accuracy of the information
                                  and the note.
                                </p>
                              </div>
                              {/* <div className="vertical-separator">
                              <span className="separator"></span>
                            </div> */}
                            </div>
                            <div className="fields-block">
                              <Table
                                rows={[
                                  { label: "Review Decision", value: primayReviewDecision.value },
                                  { label: "Review Result", value: reviewResult.value },
                                  { label: "Affected Review Sections", value: affectedReviewSectionsString },
                                  { label: "Internal QA Results", value: internalQAResults.value },
                                ]}
                                headings={[
                                  { title: "Field Name", value: "label" },
                                  { title: "Field Value", value: "value" },
                                ]}
                              />
                            </div>
                            <div className="note-section">
                              <label>Note</label>
                              {/* <div className="text-disabled-container">{utils.cleanHTMLText(primaryReviewDecisionNote)}</div> */}
                              <Input
                                type="richtext"
                                onChange={() => this.getNote}
                                disabled={true}
                                name="primaryReviewDecisionNote"
                                value={primaryReviewDecisionNote}
                              />
                            </div>
                          </div>
                        )}
                      </div>
                      <div className="button-set">
                        <Button
                          title={"Back"}
                          className="inversed-btn"
                          uppercase
                          onClick={() => this.setState({ showFinalConfirmationModal: false })}
                        />
                        <Button title={"Confirm Review"} uppercase onClick={() => this.completingPrimaryReview()} />
                      </div>
                    </div>
                  </Modal>
                  <div className="section-header">
                    <h2 className="section-title">{`${applicationObj.stage} Review of #${applicationObj.application_id}`}</h2>
                  </div>
                  <div className="container-box">{this.applicationSummary()}</div>
                  {!showReviewDetails && (
                    <div className="start-review-container">
                      {/* <div className="static-btn">
                    <Button uppercase className="heading-btn" title="Start Review" />
                  </div> */}
                      <div className="primary-review-start">
                        <h2 className="primary-review-start-heading">
                          {!disableConfirmStart
                            ? applicationSecondaryReview
                              ? "START SECONDARY REVIEW OF APPLICATION"
                              : "START PRIMARY REVIEW OF APPLICATION"
                            : "This application is currently being reviewed elsewhere. Applications cannot be reviewed in multiple windows."}
                        </h2>
                        {!disableConfirmStart ? (
                          <p className="primary-review-start-sub-heading">
                            <span>
                              {applicationObj.stage === "Pre-Project"
                                ? applicationSecondaryReview
                                  ? applicationObj.secondaryReviewer && applicationObj.secondaryReviewer.label
                                  : applicationObj.reviewer && applicationObj.reviewer.label
                                : applicationSecondaryReview
                                  ? applicationObj.postProjectSecondaryReviewer && applicationObj.postProjectSecondaryReviewer.label
                                  : applicationObj.postProjectReviewer && applicationObj.postProjectReviewer.label}
                            </span>
                            , you are about to start the review of this application.
                          </p>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="primary-review-start-button-set">
                        <Link to="/retrofit/review-board">
                          <Button className="inversed-btn" title={"Go Back"} uppercase />
                        </Link>
                        {!disableConfirmStart ? (
                          <Button disabled={disableConfirmStart} title={"Confirm Start"} onClick={this.confirmStartProcess} uppercase />
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  )}
                </div>
                {showReviewDetails && (
                  <div className="primary-review-container">
                    <div className="section-header">
                      <h2 className="section-title">{applicationSecondaryReview ? "Secondary Review" : `Primary Review`}</h2>
                    </div>
                    <div className="tab-section">
                      <CustomTabs
                        flagStatus={applicationSecondaryReview ? previousFlagStatus : applicationObj.flag && applicationObj.flag.status}
                        onTabChange={this.onTabChange}
                        currentReviewTab={selectedTab ? selectedTab : ""}
                      >
                        <div label="Participant">{this.participantTab()}</div>
                        <div label="Facility">{this.facilityTab()}</div>
                        <div label="Summary">{this.summaryTab()}</div>
                      </CustomTabs>
                    </div>
                    <div className="custom-btn">
                      {selectedTab === "Summary" ? (
                        <Button
                          className="save-btn first"
                          disabled={showDisabledBtn}
                          title="Save & Proceed"
                          uppercase
                          onClick={() => this.finalPrimaryReviewSubmit()}
                        />
                      ) : selectedTab === "Facility" && utils.isNullOrEmpty(currentReviewFacility) ? (
                        enableTabChange ? (
                          <Button
                            className="save-btn second"
                            title="Save & Proceed"
                            uppercase
                            onClick={() => this.onTabChange("Summary")}
                          />
                        ) : (
                          ""
                        )
                      ) : showButtonForMeasures ? (
                        <Button className="save-btn third" title="Save & Proceed" uppercase onClick={() => this.forceChangeSlide(3)} />
                      ) : (
                        <Button
                          className="save-btn last"
                          disabled={disableSaveButton}
                          title="Save & Proceed"
                          uppercase
                          onClick={() => this.submitForm(true)}
                        />
                      )}
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <Loader />
            )}
          </ProgramLayout>
        )}
      </>
    );
  }
}
function mapState(state) {
  const { reviewProgressLoading, reviewProgress, reviewDocumentList, reviewDocumentsLogs, appReviewDocumentsLoading } = state.application;
  const { currentProgram, adminUserInfo } = state.authentication;
  const { configData } = state.settings;
  return {
    currentProgram,
    adminUserInfo,
    configData,
    reviewProgressLoading,
    reviewProgress,
    reviewDocumentList,
    reviewDocumentsLogs,
    appReviewDocumentsLoading,
  };
}
const actionCreators = {
  setActiveProgram: userActions.setActiveProgram,
  loadAdminConfigRetrofit: adminSettingsActions.getAdminConfigRetrofit,
  getRetrofitApplicationsReviewProgress: appActions.getRetrofitApplicationsReviewProgress,
  getRetrofitReviewProcessDocuments: appActions.getRetrofitReviewProcessDocuments,
  getRetrofitReviewProcessDocumentsLogs: appActions.getRetrofitReviewProcessDocumentsLogs,
};
export default connect(mapState, actionCreators)(AppReviewRetrofit);

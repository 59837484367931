import React from 'react';
import './StatusLogs.css';
import { connect } from 'react-redux'
import { participantActions } from '../../../../redux/actions/participantActions';
import Table from '../../../Common/Table/Table';
import EmptyContainer from "../../../Common/EmptyContainer/EmptyContainer";
class StatusLogs extends React.Component {

  componentDidMount(){
  }
  render() {
      const {activeStatusLogs} = this.props;
      // console.log("activeStatusLogs", activeStatusLogs);
      return (
        <div className="status-logs-container">
          <div className="container-box">
            <div className="form-sections">
              {activeStatusLogs.length ? <Table rows={activeStatusLogs} headings={[
                {title:'Changed From', value:'changedFrom'}, 
                {title : 'Changed To', value : 'changedTo'},
                {title :'Changed Date & Time', value : 'createdAt'},
                {title :'Change Notes', value : 'changeNotes'},
                {title :'Status Updated By', value : 'updatedBy'}
                ]}/> : 
                <EmptyContainer>No Logs to Show</EmptyContainer>
                }
            </div>
          </div>
        </div>
      );
    }
}


function mapState(state) {
  const { activeParticipant, activeStatusLogs } = state.participant;
  return { activeParticipant, activeStatusLogs };
}

const actionCreators = {
  getLogs : participantActions.getParticipantStatusLogs
}

export default connect(mapState, actionCreators)(StatusLogs);

import React from "react";
import { Link } from "react-router-dom";
import SearchInput from "../../../Common/SearchInput/SearchInput";
import FilterDropdown from "../../../Common/FilterDropdown/FilterDropdown";
import DateRangePicker from "../../../Common/DateRangePicker/DateRangePicker";
import { appActions } from "../../../../redux/actions/appActions";
import { connect } from "react-redux";
import { debounce } from "debounce";
import "./FIAR.css";
import { utils } from "../../../../helpers/utils";
import { CSVLink, CSVDownload } from "react-csv";
import { adminSettingsActions } from "../../../../redux/actions/adminSettingsActions";
import SkeletonLoading from "../../../Common/SkeletonLoading/SkeletonLoading";
import Button from "../../../Common/Button/Button";
import Drawer from "../../../Common/Drawer/Drawer";
import FilterVector from "../../../../images/FilterVector.svg";
import { ListItemText } from "@material-ui/core";
import CheckBox from "../../../Common/CheckBox/CheckBox";
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

class FIARTab extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedApplications: {},
      selectedApplicationsIncentiveTotal: 0.0,
      allAppsSelected: false,
      incentiveCapApprovalExceeded: false,
      ipnContextOpen: {},
      startDate: {},
      endDate: {},
      openDrawer: false,
      dateRange: {},
      filters: [],
    };
    this.searchDebouncedData = debounce(this.searchData, 300);
  }

  setIpnContextOpen = (id) => {
    let { ipnContextOpen } = this.state;
    let ipnContextOpen1 = {};
    if (ipnContextOpen[id]) {
      delete ipnContextOpen[id];
    } else {
      ipnContextOpen1[id] = true;
    }
    this.setState({
      ipnContextOpen: ipnContextOpen1,
    });
  };
  componentDidMount() {
    this.props.loadAdminConfig();
    this.searchData(true);
  }

  ShowMoreDetails = (id) => {
    let actElem = document.getElementById(id);
    if (actElem.classList.contains("active-row")) {
      actElem.classList.remove("active-row");
    } else {
      let actContElem = document.querySelector(".preproject-details-row.active-row");
      if (actContElem !== null) {
        actContElem.classList.remove("active-row");
      }
      actElem.classList.add("active-row");
    }
  };

  onStatusFilterChange = (SelectedStatusFilters) => {
    this.props.setFilters("FIAR", {
      SelectedStatusFilters,
    });
  };

  customeArray = (value) => {
    const isExist = this.state.filters?.indexOf(value) > -1;
    if (isExist) {
      this.setState({ filters: this.state.filters.filter((i) => i !== value) });
    } else {
      this.setState({ filters: [...this.state.filters, value] });
    }
  };
  onSearch = (searchInput) => {
    this.props.setFilters("FIAR", {
      searchInput,
    });
  };

  onDateChange = (dateRange) => {
    this.setState({ dateRange });
  };

  searchData = (refresh = false) => {
    const { sortConfig, currentProgram } = this.props;
    let config = { sort: sortConfig, program: currentProgram };
    this.props.getIPNSByStatus(this.props.searchInput, config, refresh ? 0 : this.props.IPNListOffset);
  };

  handleListScroll = () => {
    if (this.props.moreApps) {
      this.searchDebouncedData();
    }
  };
  onAllAppsChange = () => {};
  sortData = (property) => {
    this.props.setFilters("FIAR", {
      sortConfig: { name: property, direction: this.props.sortConfig.direction === "desc" ? "asc" : "desc" },
    });
  };
  clearFilters = () => {
    this.props.setFilters("FIAR", {
      dateRange: [],
      SelectedStatusFilters: [],
    });
    this.setState({
      dateRange: {},
      filters: [],
    });
  };

  applyFiters = () => {
    if (Object.keys(this.state.dateRange).length > 0) {
      this.props.setFilters("FIAR", {
        dateRange: this.state.dateRange,
        SelectedStatusFilters: this.state.filters,
      });
      this.setState({ openDrawer: false });
    } else {
      this.props.setFilters("FIAR", {
        // dateRange: this.state.dateRange,
        SelectedStatusFilters: this.state.filters,
      });
      this.setState({ openDrawer: false });
    }
  };

  toggleDrawer = () => {
    this.setState({ openDrawer: false });
  };

  render() {
    const { IPNList, IPNSByStatusLoading, adminUserInfo, configData, SelectedStatusFilters, currentProgram } = this.props;
    const { ipnContextOpen } = this.state;

    let actions = {};
    if (adminUserInfo && adminUserInfo.permissions) {
      actions = adminUserInfo.permissions[currentProgram].actions;
    }

    let FilterOptions = [];
    if (configData && configData.ipnStatuses) {
      FilterOptions = configData.ipnStatuses["fields"].map((item) => {
        return { value: item.status, icon: <div class="icon-circle" style={{ backgroundColor: item.colorCode }}></div> };
      });
    }
    return (
      <div className="fiar-tab">
        <div className="fiar-top-bar secondRow">
          <div className="fiar-search">
            <SearchInput value={this.props.searchInput} placeholder="Search for IPNs" onClick={this.onSearch} />
          </div>
          <div className="applications-top-bar-filter-btn">
            <Button
              className="filter-btn incent-Btn"
              uppercase
              title={"Filters"}
              svg={FilterVector}
              onClick={() => this.setState({ openDrawer: true })}
            />
          </div>
          <Drawer toggleDrawer={this.toggleDrawer} openDrawer={this.state.openDrawer} className={"program-panel-drawer"}>
            <div className="filter-drawer" style={{ width: "90%" }}>
              <h3>FILTER BY</h3>
              <DateRangePicker onChange={this.onDateChange} value={[this.state.dateRange.startDate, this.state.dateRange.endDate]} />
              <label className="filter-label-new filter-label ">Filter By Label</label>
              <div className="fiar-filter">
                {FilterOptions?.map((option) => (
                  <div
                    className={`filter-option ${this.props.className ? this.props.className : ""}`}
                    key={option.value}
                    onClick={() => this.customeArray(option.value)}
                  >
                    <CheckBox checked={this.state.filters?.indexOf(option.value) > -1} />
                    {option.icon}
                    <ListItemText primary={option.value} />
                  </div>
                ))}
              </div>
            </div>
            <Button className="inversed-btn drawer-btn first-btn" title="CLEAR ALL FILTERS" onClick={this.clearFilters} />
            <Button className="drawer-btn" title="APPLY FILTERS" onClick={this.applyFiters} />
          </Drawer>
        </div>
        <div className="fiar-card-container">
          {IPNSByStatusLoading ? (
            <SkeletonLoading skeletonFor="cards" contentOf="fiar" />
          ) : IPNList && IPNList.length ? (
            IPNList.map((ipn, i) => (
              <div key={i} className="card">
                <div className="header">
                  <div className="ipn-details">
                    <div className="ipnno">
                      {ipn.ipnNo}
                      <div
                        title={ipn.status}
                        className={`status ${ipn.status === "IPN Pending" ? "pending" : ""} ${
                          ipn.status === "IPN Generated" ? "generated" : ""
                        } ${ipn.status === "Money Received" ? "received" : ""} ${ipn.status === "PINs Generated" ? "transfered" : ""}  ${
                          ipn.status === "Payout Initiated via Plooto" ||
                          ipn.status === "Payout Initiated" ||
                          ipn.status === "EFT Completed"
                            ? "initiated"
                            : ""
                        }${ipn.status === "Spot Check Completed" ? "spot-check-completed" : ""} 
                        ${ipn.status === "PVRR Approved" ? "pvrr-approved" : ""}`}
                      ></div>
                    </div>
                    <div className="info">
                      <div className="date">{utils.formatAmericanDate(ipn.createdAt)}</div>
                      <div className="creator">{ipn.approver}</div>
                    </div>
                  </div>
                  <div className="ipn-action" onClick={() => this.setIpnContextOpen(ipn.id)}>
                    <div className={`expand-ipn-menu ${ipnContextOpen[ipn.id] ? "ipn-menu-open" : ""}`}>
                      <div className="expanded-menu-panel">
                        {actions["Incentive Review Board-View as PDF"] ? (
                          <Link to={`viewipn/${ipn.id}`} target="_blank" rel="noopener noreferrer">
                            <div>View as PDF</div>
                          </Link>
                        ) : (
                          ""
                        )}
                        {actions["Incentive Review Board-Export IPN"] ? (
                          <CSVLink
                            filename={`${ipn.ipnNo}.csv`}
                            headers={[
                              { label: "IPN Number", key: "ipnNo" },
                              { label: "Payout Approved Date", key: "payoutApprovedDate" },
                              { label: "Application ID", key: "appNo" },
                              { label: "Payee Name", key: "payeeName" },
                              { label: "Applicant Name", key: "customerName" },
                              { label: "Contractor Name", key: "contractorName" },
                              { label: "Approved Incentive", key: "incentive" },
                              { label: "Total Project Cost", key: "projectCost" },
                              { label: "GHG Savings (Tonnes)", key: "emissionsReduction" },
                            ]}
                            data={utils.buildIPNExport(ipn)}
                          >
                            <div>IPN Export</div>
                          </CSVLink>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                {ipn.status !== "IPN Pending" ? (
                  <div className="apps-list">
                    {ipn?.applications.length > 0
                      ? utils.getTopThree(ipn.applications).map((app, gtInd) => (
                          <div key={`top${gtInd}`} className="app">
                            <div className="column name" title={app["payeeName"]}>
                              {app["payeeName"]}
                            </div>
                            <div className="column">{app["appNo"]}</div>
                            <div className="column right">{utils.formatAsCAD(app["incentive"])}</div>
                          </div>
                        ))
                      : ""}
                  </div>
                ) : (
                  ""
                )}
                {ipn.status !== "IPN Pending" ? (
                  <div className="footer">
                    <div className="morelink" onClick={() => this.props.showActionModal({ ipn: ipn, modalType: "view-ipn-apps" })}>
                      Show All ({ipn.applications ? Object.keys(ipn.applications).length : "0"})
                    </div>
                    <div className="incentive-total">{`$${ipn.incentiveTotal
                      .toFixed(2)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}</div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            ))
          ) : (
            <div className="no-records-found">No records.</div>
          )}
        </div>
      </div>
    );
  }
}

function mapState(state) {
  const { configData } = state.settings;
  const { adminUserInfo, currentProgram } = state.authentication;
  const { IPNList, IPNListOffset, IPNSByStatusLoading, moreIPNS } = state.application;
  return { currentProgram, adminUserInfo, IPNList, IPNListOffset, IPNSByStatusLoading, moreIPNS, configData };
}

const actionCreators = {
  loadAdminConfig: adminSettingsActions.getAdminConfig,
  getIPNSByStatus: appActions.getIPNSByStatus,
};

export default connect(mapState, actionCreators)(FIARTab);

import React, { Component } from "react";
import { utils } from "../../../../helpers/utils";
import "./StartPrimaryReview.css";
import Button from "../../../Common/Button/Button";
import { applicationService } from "../../../../services/applicationService";
import ResponsiveTable from "../../../Common/ResponsiveTable/ResponsiveTable";
import LoaderComponent from "../../../Common/Loader/Loader";
import Input from "../../../Common/Input/Input";

export default class StartPrimaryReview extends Component {
  constructor(props) {
    super(props);

    this.state = {
      startReview: false,
      dataLoading: true,
      facilities: [],
      primayReviewDecision: "",
      primaryReviewDecisionNote: "",
      finalConfirmation: false,
      reviewOptions: [
        { label: "Information Request (IR)", value: "Information Request (IR)" },
        { label: "Return Application for Edits (IR)", value: "Return Application for Edits (IR)" },
        { label: "Awaiting IESO Exception Approval", value: "Awaiting IESO Exception Approval" },
        { label: "Enerva Guidance", value: "Enerva Guidance" },
        { label: "Peer Review Pending Assignment", value: "Peer Review Pending Assignment" },
        { label: "Reject Application", value: "Reject Application" },
        { label: "Cancelled Application", value: "Cancelled Application" },
        { label: "Withdrawn Application", value: "Withdrawn Application" },
      ],
      primayReviewDecision: "",
    };
  }
  componentDidMount = () => {
    const { applicationId } = this.props;
    applicationService.getFacilitiesByApplicationIdRetrofit(applicationId).then((data) => {
      this.setState({
        facilities: data,
        dataLoading: false,
      });
    });
  };
  exportFacility = async (appId, facilityId) => {
    await applicationService.getExcelByApplicationAndFacilityIdRetrofit(appId, facilityId);
  };
  selectPrimaryReviewerOption = (opt) => {
    this.setState({
      primayReviewDecision: opt,
    });
  };
  getNote = (name, value) => {
    this.setState({
      primaryReviewDecisionNote: value,
    });
  };
  completingPrimaryReview = () => {
    const { adminUserInfo, applicationId, application } = this.props;
    const { primaryReviewDecisionNote, primayReviewDecision } = this.state;
    let assignReviewObj = {
      applicationId: `${applicationId}`,
      stage: application.stage,
      actionTaker: `${adminUserInfo && adminUserInfo.firstName} ${adminUserInfo && adminUserInfo.lastName}`,
      flag: primayReviewDecision.label,
      reviewNote: primaryReviewDecisionNote,
    };
    applicationService.completePrimaryReview(assignReviewObj).then((res) => {
      this.setState(
        {
          primaryReviewDecisionNote: "",
          primayReviewDecision: "",
          startReview: false,
          finalConfirmation: false,
        },
        () => {
          this.props.closeActionModal(true);
        }
      );
    });
  };
  render() {
    const { application, closeActionModal } = this.props;
    const { startReview, dataLoading, facilities, reviewOptions, finalConfirmation, primayReviewDecision, primaryReviewDecisionNote } =
      this.state;
    let activeFacilities = facilities && facilities.filter((f) => f.isActive === true);
    let facilityHeadings = [
      { title: "Facility Name", value: "facilityName" },
      { title: "Facility ID", value: "facilityId" },
      { title: "Facility Total Incentive", value: "facilityIncentive" },
      { title: "Facility Export for Review", value: "exportFile" },
    ];
    let facilityRows =
      activeFacilities &&
      activeFacilities.map((facility) => {
        return {
          exportFile: (
            <span onClick={() => this.exportFacility(facility.application_id, facility.facility_id)}>
              <a>Export Facility</a>
            </span>
          ),
          facilityName: facility.facility_name,
          facilityId: facility.facility_id,
          facilityIncentive:
            facility.facilitiesDetails &&
            facility.facilitiesDetails.facility &&
            `$${facility.facilitiesDetails.facility.tot_est_facility_incentive
              .toFixed(2)
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`,
        };
      });
    return (
      <div className="retrofit-review-container">
        {dataLoading ? (
          <LoaderComponent />
        ) : (
          <>
            <div className="section-header">
              <div className="section-top-details">{application.appNo}</div>
              <div className="section-top-details name-section">{application.applicantName}</div>
              <div className="section-top-details">
                $
                {application.estimatedIncentive
                  .toFixed(2)
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
              </div>
            </div>
            <div className="section-below-header">
              <h2 className="title" dangerouslySetInnerHTML={{ __html: "Start Primary Review" }}></h2>
            </div>
            <div className="container">
              {!startReview ? (
                <p>
                  Please login to the CDMIS Portal and route the application <span>{application.id}</span> to yourself
                </p>
              ) : (
                <div className="facility-table">
                  <ResponsiveTable headings={facilityHeadings} rows={facilityRows} />
                  <div className="horizontal-bar"></div>
                  <div className="message-block">
                    <div className="fields-block">
                    <Input
                      label="Review Decision"
                      placeholder="Please select a Review Decision"
                      type="dropdown"
                      disabled={finalConfirmation}
                      name="primayReviewDecision"
                      className="decision-options"
                      onChange={this.selectPrimaryReviewerOption}
                      value={primayReviewDecision ? primayReviewDecision : undefined}
                      options={reviewOptions.map((type) => type)}
                      isSearchable={false}
                    />
                    </div>
                    {finalConfirmation ? (
                      <>
                        <div className="vertical-separator">
                          <span className="separator"></span>
                        </div>
                        <div className="message">
                          <p>
                            You are about to mark the review for this application as <span>{primayReviewDecision.label}</span>. This might send out an email to
                            the applicants & applicant reps. Please verify the accuracy of the information and the note.
                          </p>
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="note-section">
                    <label>Note</label>
                    <Input type="richtext" disabled={finalConfirmation} name="primaryReviewDecisionNote" value={primaryReviewDecisionNote} onChange={this.getNote} />
                  </div>
                </div>
              )}
            </div>
            {!startReview ? (
              <div className="button-set">
                <Button title={"CANCEL"} className="inversed-btn" uppercase onClick={closeActionModal} />
                <Button title={"Application Routed"} uppercase onClick={() => this.setState({ startReview: true })} />
              </div>
            ) : finalConfirmation ? (
              <div className="button-set">
                <Button title={"Back"} className="inversed-btn" uppercase onClick={() => this.setState({ finalConfirmation: false })} />
                <Button title={"Confirm Review"} uppercase onClick={() => this.completingPrimaryReview()} />
              </div>
            ) : (
              <div className="button-set">
                <Button title={"CANCEL"} className="inversed-btn" uppercase onClick={() => this.setState({ startReview: false })} />
                <Button
                  title={"Complete Review"}
                  disabled={!primayReviewDecision || !utils.cleanHTMLText(primaryReviewDecisionNote)}
                  uppercase
                  onClick={() => this.setState({ finalConfirmation: true })}
                />
              </div>
            )}
          </>
        )}
      </div>
    );
  }
}

import React from "react";
import ContractorRow from "./ContractorRow";
import ContractorRowRetrofit from "./ContractorRowRetrofit";
import SearchInput from "../../../../components/Common/SearchInput/SearchInput";
import FilterDropdown from "../../../../components/Common/FilterDropdown/FilterDropdown";
import { participantActions } from "../../../../redux/actions/participantActions";
import { connect } from "react-redux";
import { debounce } from "debounce";
import SkeletonLoading from "../../../Common/SkeletonLoading/SkeletonLoading";

class ContractorsTab extends React.Component {
  constructor(props) {
    super(props);
    this.searchDebouncedData = debounce(this.searchData, 300);
  }

  async componentDidMount() {
    this.searchData(true);
  }

  ShowMoreDetails = (id) => {
    let actElem = document.getElementById(id);
    if (actElem.classList.contains("active-row")) {
      actElem.classList.remove("active-row");
    } else {
      let actContElem = document.querySelector(".customer-details-row.active-row");
      if (actContElem !== null) {
        actContElem.classList.remove("active-row");
      }
      actElem.classList.add("active-row");
    }
  };

  onFilterChange = (SelectedFilters) => {
    if (this.props.currentProgram !== "retrofit") {
      this.props.setFilters("contractor", {
        SelectedFilters,
      });
    }
  };

  onSearch = (searchInput) => {
    this.props.setFilters("contractor", {
      searchInput,
    });
  };

  searchData = (refresh = false) => {
    const { sortConfig, SelectedFilters } = this.props;
    let config = { statuses: SelectedFilters, sort: sortConfig };
    if (this.props.currentProgram !== "retrofit") {
      this.props.getParticipants(
        this.props.currentProgram,
        "contractor",
        this.props.searchInput,
        config,
        refresh ? 0 : this.props.participantOffset
      );
    } else {
      this.props.getParticipantsRetrofit(
        this.props.currentProgram,
        "contractor",
        this.props.searchInput,
        config,
        refresh ? 0 : this.props.participantOffset
      );
    }
  };

  handleListScroll = () => {
    if (this.props.moreParticipants) {
      this.searchDebouncedData();
    }
  };

  sortData = (property) => {
    if (this.props.currentProgram !== "retrofit") {
      this.props.setFilters("contractor", {
        sortConfig: { name: property, direction: this.props.sortConfig.direction === "desc" ? "asc" : "desc" },
      });
    }
  };
  render() {
    const { contractorList, SelectedFilters, currentProgram, participantOffset, contractorsLoading } = this.props;
    const FilterOptions = [
      "Email Verification Pending",
      "Details Submission Pending",
      "Pending Approval",
      "Participating",
      "Flagged for QA/QC",
      "Suspended",
      "Inactive"
    ];
    return (
      <div className="customer-tab">
        <div className="participant-top-bar">
          <div className="participant-top-bar-search">
            <SearchInput
              value={this.props.searchInput}
              placeholder={this.props.currentProgram !== "retrofit" ? "Search for Contractors" : "Search for Applicant Reps"}
              onClick={this.onSearch}
            />
          </div>
          {currentProgram !== "retrofit" ? (
            <>
              <div className="participant-top-bar-filter">
                <FilterDropdown
                  label={"Filter by Status"}
                  FilterOptions={FilterOptions}
                  SelectedFilters={SelectedFilters}
                  onChange={this.onFilterChange}
                  className={"program-filter"}
                />
              </div>
            </>
          ) : (
            ""
          )}
          {/* <div onClick={() => this.props.showModal(true)} className="add-participant"></div> */}
        </div>
        <div className="customer-details-header customer-details-row">
          <div className="customer-details">
            {currentProgram === "retrofit" ? (
              <>
                <div onClick={() => this.sortData("userNo", "string")}>Applicant Rep ID</div>
                <div onClick={() => this.sortData("first_name", "string")}>First Name</div>
                <div onClick={() => this.sortData("last_name", "string")}>Last Name</div>{" "}
              </>
            ) : (
              <>
                <div onClick={() => this.sortData("userNo", "string")}>Contractor ID</div>
                <div onClick={() => this.sortData("companyName", "string")}>Customer</div>
                <div onClick={() => this.sortData("status", "string")}>Status</div>
              </>
            )}
            <div onClick={() => this.sortData("createdAt", "date")}>Created Date</div>
            <div onClick={() => this.sortData("email", "string")}>Email ID</div>
            <div></div>
          </div>
        </div>
        <div className="customer-details-list" id="CustomerDetailsList" onScroll={this.handleListScroll}>
          {participantOffset === 0 && contractorsLoading ? (
            <SkeletonLoading />
          ) : contractorList && contractorList.length ? (
            contractorList.map((Contractor, i) =>
              currentProgram !== "retrofit" ? (
                <ContractorRow
                  Contractor={Contractor}
                  key={`contractor-${i}`}
                  id={`contractor-${i}`}
                  showActionModal={this.props.showActionModal}
                  ShowMoreDetails={this.ShowMoreDetails}
                />
              ) : (
                <ContractorRowRetrofit
                  Contractor={Contractor}
                  key={`contractor-${i}`}
                  id={`contractor-${i}`}
                  showActionModal={this.props.showActionModal}
                  ShowMoreDetails={this.ShowMoreDetails}
                />
              )
            )
          ) : (
            <div className="no-records-found">No records.</div>
          )}
        </div>
      </div>
    );
  }
}

function mapState(state) {
  const { adminUserInfo, currentProgram } = state.authentication;
  const { contractorList, contractorsLoading, activeParticipantLoading, moreParticipants, participantOffset } = state.participant;
  return {
    currentProgram,
    adminUserInfo,
    contractorList,
    contractorsLoading,
    activeParticipantLoading,
    moreParticipants,
    participantOffset,
  };
}

const actionCreators = {
  getParticipants: participantActions.getParticipants,
  getParticipantsRetrofit: participantActions.getParticipantsRetrofit,
};

export default connect(mapState, actionCreators)(ContractorsTab);

import React from "react";
import ChangeRequestRow from "./ChangeRequestRow";
import SearchInput from "../../../Common/SearchInput/SearchInput";
import FilterDropdown from "../../../Common/FilterDropdown/FilterDropdown";
import { appActions } from "../../../../redux/actions/appActions";
import { connect } from "react-redux";
import { debounce } from "debounce";
import { adminSettingsActions } from "../../../../redux/actions/adminSettingsActions";
import SkeletonLoading from "../../../Common/SkeletonLoading/SkeletonLoading";

const statusFilters = ["Submitted", "Approved", "Rejected"];

const requestTypeFilters = ["Extension to Deadline", "Inclusion of Additional Measures", "Changes in Measure Quantities or Sizes", "Other"];

class ChangeRequestsTab extends React.Component {
  constructor(props) {
    super(props);
    this.searchDebouncedData = debounce(this.searchData, 300);
  }

  componentDidMount() {
    this.props.loadAdminConfig();
    this.searchData(true);
  }

  ShowMoreDetails = (id) => {
    let actElem = document.getElementById(id);
    if (actElem.classList.contains("active-row")) {
      actElem.classList.remove("active-row");
    } else {
      let actContElem = document.querySelector(".customer-details-row.active-row");
      if (actContElem !== null) {
        actContElem.classList.remove("active-row");
      }
      actElem.classList.add("active-row");
    }
  };

  onStatusFilterChange = (SelectedStatusFilters) => {
    this.props.setFilters({
      SelectedStatusFilters,
    });
  };

  onReqTypeFilterChange = (SelectedReqTypeFilters) => {
    this.props.setFilters({
      SelectedReqTypeFilters,
    });
  };

  onSearch = (searchInput) => {
    this.props.setFilters({
      searchInput,
    });
  };

  onStreamChange = (stream) =>{
    this.props.setFilters({
      stream,
    });
  }

  searchData = (refresh = false) => {
    const { sortConfig, SelectedFilters, currentProgram, stream } = this.props;
    let config = { statuses: SelectedFilters, requestTypes: [], sort: sortConfig, program: currentProgram, stream: stream };
    this.props.searchChangeRequests(this.props.searchInput, config, refresh ? 0 : this.props.changeReqListOffset);
  };

  handleListScroll = () => {
    if (this.props.moreChangeReqs) {
      this.searchDebouncedData();
    }
  };

  sortData = (property) => {
    this.props.setFilters({
      sortConfig: { name: property, direction: this.props.sortConfig.direction === "desc" ? "asc" : "desc" },
    });
  };
  render() {
    const {
      changeReqsLoading,
      changeReqListOffset,
      changeReqList,
      SelectedStatusFilters,
      SelectedReqTypeFilters,
      configData,
      currentProgram,
      stream
    } = this.props;

    return (
      <div className="change-req-tab">
        <div className="change-req-top-bar">
          <div className="change-req-top-bar-search">
            <SearchInput value={this.props.searchInput} placeholder="Search for Change Requests" onClick={this.onSearch} />
          </div>
          <div className="change-req-top-bar-filter">
            {configData ? (
              <FilterDropdown
                className="program-filter"
                label={"Filter by Status"}
                FilterOptions={statusFilters}
                SelectedFilters={SelectedStatusFilters}
                onChange={this.onStatusFilterChange}
              />
            ) : (
              ""
            )}
          </div>
          <div className="change-req-top-bar-filter">
            <FilterDropdown
              className="program-filter"
              label={"Filter by Request Type"}
              FilterOptions={requestTypeFilters}
              SelectedFilters={SelectedReqTypeFilters}
              onChange={this.onReqTypeFilterChange}
            />
          </div>
          <div className="change-req-top-bar-filter">
            <FilterDropdown
                label={"Filter by Program"}
                objectBasedOption={true}
                FilterOptions={[{value: "Energy Savings For Business", id: "esb"}, {value: "Comprehensive Energy Savings", id: "ces"}]}
                SelectedFilters={stream}
                onChange={this.onStreamChange}
                className={"program-filter"}
              />
          </div>
        </div>
        <div className="customer-details-header customer-details-row">
          <div className="customer-details">
            <div onClick={() => this.sortData("changeReqNo", "string")}>Change Request #</div>
            <div onClick={() => this.sortData("createdAt", "date")}>Created Date</div>
            <div onClick={() => this.sortData("companyName", "string")}>Customer Name</div>
            <div onClick={() => this.sortData("appNo", "string")}>Application ID</div>
            <div onClick={() => this.sortData("requestType", "string")}>Request Type</div>
            <div onClick={() => this.sortData("status", "string")}>Status</div>
            <div></div>
          </div>
        </div>
        <div className="customer-details-list" id="CustomerDetailsList" onScroll={this.handleListScroll}>
          {changeReqsLoading && changeReqListOffset === 0 ? (
            <SkeletonLoading />
          ) : changeReqList && changeReqList.length ? (
            changeReqList.map((Request, i) => (
              <ChangeRequestRow
                Request={Request}
                key={`request-${i}`}
                id={`request-${i}`}
                showActionModal={this.props.showActionModal}
                ShowMoreDetails={this.ShowMoreDetails}
                currentProgram={currentProgram}
              />
            ))
          ) : (
            <div className="no-records-found">No records.</div>
          )}
        </div>
      </div>
    );
  }
}

function mapState(state) {
  const { configData } = state.settings;
  // console.log(configData)
  const { adminUserInfo, currentProgram } = state.authentication;
  const { changeReqList, changeReqsLoading, changeReqListOffset, moreChangeReqs } = state.application;
  return { currentProgram, adminUserInfo, changeReqsLoading, changeReqList, changeReqListOffset, moreChangeReqs, configData };
}

const actionCreators = {
  loadAdminConfig: adminSettingsActions.getAdminConfig,
  searchChangeRequests: appActions.searchChangeRequests,
};

export default connect(mapState, actionCreators)(ChangeRequestsTab);

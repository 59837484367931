import React from "react";
import { ReactComponent as HamBurger } from "../../../images/HamBurger.svg";
import { ReactComponent as LeftArrow } from "../../../images/leftArrow.svg";
import { ReactComponent as Home } from "../../../images/QuickLinks.svg";
import { ReactComponent as AdminVector } from "../../../images/AdminVector.svg";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import "./AdminSideBarMenu.css";
import { userActions } from "../../../redux/actions/userActions";

class AdminSideBarMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      menuCollapse: false,
      activeMenuItem: null,
    };
  }

  componentDidMount() {
    this.defaultActiveMenuItem();
  }

  setMenuCollapse = async () => {
    await this.props.setSideBarMenuCollapse(!this.props.menuCollapse);
  };

  setActiveMenuItem = (activeMenuItem, secondParam) => {
    if (window.innerWidth < 1025 && secondParam === "Onclick") {
      this.props.setSideBarMenuCollapse(!this.props.menuCollapse);
    }
    this.setState({
      activeMenuItem,
    });
  };

  defaultActiveMenuItem = () => {
    let Path = window.location && window.location.pathname;
    if (Path) {
      switch (Path) {
        case "/home":
          this.setActiveMenuItem("Home");
          break;
        case "/reports":
          this.setActiveMenuItem("Reports");
          break;
        case "/settings":
          this.setActiveMenuItem("AdminSettings");
          break;
        default:
          break;
      }
    }
  };

  render() {
    const { activeMenuItem } = this.state;
    const { adminUserInfo, menuCollapse } = this.props;
    let boards = {};
    if (adminUserInfo && adminUserInfo.permissions) {
      boards = adminUserInfo.permissions.admin.boards;
    }
    return (
      <div className={`admin-sidebar-menu ${menuCollapse ? "admin-sidebar-menu-close" : ""}`}>
        <nav>
          <div className={`admin-burger-menu ${menuCollapse ? "admin-burger-menu-collapsed" : ""}`} onClick={() => this.setMenuCollapse()}>
            <HamBurger width="42px" height="26px" />
          </div>
          <div className={`admin-menu ${menuCollapse ? "admin-menu-collapsed" : ""}`}>
            <div className="admin-top-menu">
              <div className={`admin-menu-items ${activeMenuItem === "Home" ? "active-menu" : ""}`}>
                <Link to="/home" onClick={() => this.setActiveMenuItem("Home", "Onclick")}>
                  <Home width="18px" height="18px" />
                  <span className={`menu-item-label ${menuCollapse ? "menu-items-collapsed" : ""}`}>Home</span>
                </Link>
              </div>
              {boards["Settings"] ? (
                <div className={`admin-menu-items ${activeMenuItem === "AdminSettings" ? "active-menu" : ""}`}>
                  <Link to="/settings" onClick={() => this.setActiveMenuItem("AdminSettings", "Onclick")}>
                    <AdminVector width="18px" height="18px" />
                    <span className={`menu-item-label ${menuCollapse ? "menu-items-collapsed" : ""}`}>Settings</span>
                  </Link>
                </div>
              ) : (
                ""
              )}
            </div>

            <div className="admin-bottom-menu" onClick={() => this.setMenuCollapse(!menuCollapse)}>
              <span className={`bottom-arrow ${menuCollapse ? "menu-items-collapsed" : ""}`}>
                {/* <RightArrow width="20" height="20"/> */}
                {menuCollapse ? (
                  <LeftArrow className="right-arrow" />
                ) : (
                  <span>
                    <LeftArrow /> Collapse
                  </span>
                )}
              </span>
            </div>
          </div>
        </nav>
      </div>
    );
  }
}

function mapState(state) {
  const { adminUserInfo, menuCollapse } = state.authentication;
  return { adminUserInfo, menuCollapse };
}

const actionCreators = {
  setSideBarMenuCollapse: userActions.setSideBarMenuCollapse,
};

export default connect(mapState, actionCreators)(AdminSideBarMenu);

import React from 'react';
import Tabs from '../../../../Common/Tabs/Tabs'
import Modal from '../../../../Common/Modal/Modal';
import { connect } from 'react-redux'
import { userActions } from '../../../../../redux/actions/userActions';
import { participantActions } from '../../../../../redux/actions/participantActions';
import EditParticipant from '../../EditParticipant/EditParticipant';
import ParticipantCompanyDetails from '../../ParticipantCompanyDetails/ParticipantCompanyDetails';
import StatusLogs from '../../StatusLogs/StatusLogs';
import { utils } from '../../../../../helpers/utils';
import './CustomerEdit.css';

class CustomerEdit extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showLoader: true,
            createAccountModalOpen: false
        }
    }

    componentDidMount() {
        this.props.getParticipant(this.props.currentProgram, this.props.selectedCustomer.uid);
        this.props.getLogs(this.props.currentProgram, this.props.selectedCustomer.uid);
    }

    addParticipant = () => {
        this.setState({ createAccountModalOpen: true }, () => {
            this.props.showModal(false)
        });
    }

    closeEditParticipant = () => {
        this.setState({ createAccountModalOpen: false });
    }

    render() {
        const { createAccountModalOpen } = this.state;
        let { activeParticipant, canEdit} = this.props;
        canEdit = !utils.isNullOrEmpty(canEdit) ? canEdit : true;
        return (
            <div>
                <Modal
                    HeaderText={'PARTICIPANT UPDATED SUCCESSFULLY!'}
                    IsOpen={createAccountModalOpen}
                    handleCloseModal={() => this.closeEditParticipant()}
                >
                    {/* <p><span>Anirudh Challa</span> has been added as aprticipant to the program.</p>
                    <p>Please ask the participant to visit the inbox of the registered email id and <span>click on the verification link</span> to access the account page.</p>
                    <p><b>Participant did not receive the verification email?</b></p>
                    <Button
                        title={'Re-Send Email'}
                        uppercase
                    /> */}
                </Modal>
                <div className="edit-container">
                    {activeParticipant ? 
                    <div className="section-header">
                        <div className="section-top-details">CUS-{activeParticipant.userNo}</div>
                        <div className="section-top-details name-section">{activeParticipant.companyDetails.name}</div>
                        <div className="section-top-details">{activeParticipant.email}</div>
                    </div> : ''}
                    <div className="section-header">
                        <h2 className="section-title" dangerouslySetInnerHTML={{ __html: 'Customer Details' }}></h2>
                    </div>
                    {activeParticipant ? <Tabs>
                        <div label="Personal">
                        {this.props.selectedCustomer ? <EditParticipant canEdit={canEdit} uid={this.props.selectedCustomer.uid} addParticipant={this.addParticipant} />: ''}
                        </div> 
                        <div label="Company">
                        {this.props.selectedCustomer ? <ParticipantCompanyDetails/>: ''}
                        </div>
                        <div label="Status Logs">
                        {this.props.selectedCustomer ? <StatusLogs/>: ''}
                        </div>
                        {/* <div label="Consent Logs">
                            <p className="work-in-progress">Work in Progress...</p>
                        </div> */}
                    </Tabs> : ''}
                </div>
            </div>
        );
    }
}

function mapState(state) {
    const { activeParticipant } = state.participant;
    const { currentProgram } = state.authentication;
    return { currentProgram, activeParticipant };
}

const actionCreators = {
    showModal: userActions.showEditParticipantModal,
    getParticipant : participantActions.getParticipantDetails,
    getLogs : participantActions.getParticipantStatusLogs
}

export default connect(mapState, actionCreators)(CustomerEdit);

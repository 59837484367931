import React from 'react';
import Button from '../../../../../Common/Button/Button'
import Input from '../../../../../Common/Input/Input'
import './AddUser.css';
import { connect } from 'react-redux'
import {adminSettingsActions} from '../../../../../../redux/actions/adminSettingsActions';
import { userActions } from '../../../../../../redux/actions/userActions';
import { userService } from '../../../../../../services/userService';
import { utils } from '../../../../../../helpers/utils';
import { Redirect } from 'react-router-dom';
import { debounce } from "debounce";

class AddUser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      confirmAccountDetails: false,
      errors: {
      }
    }
    this.checkEmail = debounce(this.checkEmailInSystem, 300);
    this.checkPhone = debounce(this.checkPhoneInSystem, 300);
  }

  onChangeHandler = (name, value) => {
    const {errors } = this.state;
    delete errors[name];
    this.props.setUser({
        ...this.props.activeAdminUser,
        [name]: value
    });

    this.setState({
      errors
    });
  }

  handleToggleChange = (name, value) => {
    const { errors } = this.state;
    delete errors[name];
    this.props.setUser({
      ...this.props.activeAdminUser,
      [name]: value
   });
    this.setState({
      errors
    });
  }

  handleButtonSelection = (value) => {
    const { user, errors } = this.state;
    delete errors['team'];

    this.props.setUser({
      ...this.props.activeAdminUser,
      team: value,
      roleName: ''
   });
    this.setState({
      errors, teamValue:value
    });
  }

  handleMultiSelectWithCheckBox = (name, value) => {
    const { user, errors } = this.state;
    delete errors[name];


    this.props.setUser({
      ...this.props.activeAdminUser,
      [name]: value
   });
    this.setState({
      errors
    });
  }

  checkPhoneInSystem = () => {
    const { errors } = this.state;
    const { activeAdminUser} = this.props;
    const mainThis = this;
    userService.checkIfPhoneExists(`+1${activeAdminUser.phoneNum}`)
    .then((data) => {
      const {userPhoneNumberExists} = data;
      if (userPhoneNumberExists) {
        errors.phoneNum = 'Phone number already exists in our system.';
      }
      mainThis.setState({
        errors
      });
    });
  }

  onPhoneNumChange = (name, value) => {
    const { user, errors } = this.state;
    delete errors[name];

    if (!utils.isNullOrEmpty(value)) {
        this.checkPhone();
      } else {
        errors.phoneNum = 'Phone number is invalid.';
      }

    this.props.setUser({
      ...this.props.activeAdminUser,
      [name]: value
   });
    this.setState({
      errors
    });
  }


  checkEmailInSystem = () => {
    const { errors } = this.state;
    const { activeAdminUser} = this.props;
    const mainThis = this;
    userService.checkIfEmailExists(activeAdminUser.email)
    .then((data) => {
      const {userExists} = data;
      if (userExists) {
        errors.email = 'Email already exists in our system.';
      }
      mainThis.setState({
        errors
      });
    });
  }

  onEmailChange = (name, value) => {
    const { user, errors } = this.state;
    delete errors[name];

    if (!utils.isNullOrEmpty(value)) {
      if (name === 'email') {
        if (utils.validateEmail(value)) {
          this.checkEmail();
        } else {
          errors.email = 'The email id is invalid';
        }
      }
    }

    this.props.setUser({
      ...this.props.activeAdminUser,
      [name]: value
   });
    this.setState({
      errors
    });
  }

  onCatChange = (role) => {
    const { user, errors } = this.state;
    delete errors['role'];
    if(role.value === 'Super Admin' || role.value === 'Admin'){
      delete errors['clients'];
      delete errors['programs'];
    }

    this.props.setUser({
      ...this.props.activeAdminUser,
      role: role.value,
      roleName : role.label
   });
    this.setState({
      errors
    });

  }

  verifyForm = (user) => {
    let errors = {};
    let errorCount = 0;
    if (utils.isNullOrEmpty(user.firstName)) {
      errors.firstName = 'First name is required.'
      errorCount++;
    }
    if (utils.isNullOrEmpty(user.lastName)) {
      errors.lastName = 'Last name is required.'
      errorCount++;
    }
    if (utils.isNullOrEmpty(user.role)) {
      errors.role = 'Please select a role.'
      errorCount++;
    }
    if(user.role !== 'superAdmin' && user.role !== 'enervaAdmin'){
      if (utils.isNullOrEmpty(user.clients)) {
        errors.clients = 'Please select a client.'
        errorCount++;
      }
      if (utils.isNullOrEmpty(user.programs)) {
        errors.programs = 'Please select a program.'
        errorCount++;
      }
    }

    if (utils.isNullOrEmpty(user.team)) {
      errors.team = 'Please select a team.'
      errorCount++;
    }

    if (!utils.isNullOrEmpty(user.phoneNum) && (user.phoneNum.length > 1 && user.phoneNum.length < 11)) {
      errors.phoneNum = 'Phone number is invalid.'
      errorCount++;
    } else if (utils.isNullOrEmpty(user.phoneNum) || (user.phoneNum.length <= 1)) {
      errors.phoneNum = 'Phone number is required.'
      errorCount++;
    }

    if (!utils.isNullOrEmpty(user.email)) {
      if (!utils.validateEmail(user.email)) {
        errors.email = 'Email id is invalid.'
        errorCount++;
      }
    } else {
      errors.email = 'Email id is required.'
      errorCount++;
    }
    if (errorCount > 0) {
      this.setState({ errors });
    }
    return errorCount === 0;
  }

  toggleConfirmation = ()=>{
    this.setState({
      confirmAccountDetails : !this.state.confirmAccountDetails
    });
  }

  submitForm = ()=>{
    const {userModalMode} = this.props;
    if(userModalMode === 'add'){
      this.props.createUser(this.props.activeAdminUser);
    } else {
      this.props.updateUser(this.props.activeAdminUser);
    }
  }

  confirmUserDetails = () => {
    if (this.verifyForm(this.props.activeAdminUser)) {
      this.setState({
        confirmAccountDetails: true
      })
    }
  }
  setConfirmed = () => {
    this.setState(() => {
      setTimeout(()=> {
        this.props.showUserModal(false);
        this.props.handleUserModal();
      }, 3000);
    })
  }
  render() {
    const { configData, registeredSuccessfully, clientList, programList, userModalMode, activeAdminUser,userUpdateError, userCreationError, actions, updatingUser, creatingUser} = this.props;
    if (registeredSuccessfully) {
      return <Redirect to="/account" />;
    } 
    else if ((!userUpdateError && !updatingUser) || (!userCreationError && !creatingUser)) {
      this.setConfirmed();
      return (
        <div className="add-user-container">
          <div className="container-box">
            <div className="section-header">
              <h2 className="section-title" dangerouslySetInnerHTML={{ __html: `${'CONFIRMED'}` }}></h2>
            </div>
            <div className="user-details-container">
              <div className="user-details-container-left">
              <div className="user-details-row"><label>Name</label><span>{`${activeAdminUser.firstName} ${activeAdminUser.lastName}`}</span></div>
              <div className="user-details-row"><label>Mobile Number</label><span>{utils.formatPhoneNumber(activeAdminUser.phoneNum)}</span></div>
              <div className="user-details-row"><label>Email</label><span>{activeAdminUser.email}</span></div>
              <div className="user-details-row"><label>Team</label><span>{activeAdminUser.team}</span></div>
              <div className="user-details-row"><label>Role</label><span>{activeAdminUser.roleName}</span></div>
              <div className="user-details-row"><label>Assigned Client(s)</label><span>{['superAdmin','enervaAdmin'].includes(activeAdminUser.role) ? 'All clients' : (activeAdminUser.clients ? activeAdminUser.clients : []).map((item)=>item.label).join(', ')}</span></div>
              <div className="user-details-row"><label>Assigned Program(s)</label><span>{['superAdmin','enervaAdmin'].includes(activeAdminUser.role) ? 'All programs' : (activeAdminUser.programs? activeAdminUser.programs : []).map((item)=>item.label).join(', ')}</span></div>
              </div>
              <div className='vertical-separator-box'>
                <span className='separator'></span>
              </div>
              <div className="user-details-container-right">
                {userModalMode !== 'add' ? <p>
                  <b>{activeAdminUser.firstName}'s</b> account has been successfully updated.
                </p>
                :
                <p>
                  <b>{activeAdminUser.firstName}</b> has been successfully added as a <b>{activeAdminUser.roleName} - {activeAdminUser.team}</b> and will been notified via email.
                </p>}
              </div>
            </div>
          </div>
        </div>
      )
    }
    else {
      const {errors, confirmAccountDetails } = this.state;
      const thereAreErrors = !utils.isNullOrEmpty(errors);
      let programOptions = activeAdminUser.clients ? programList.filter((program)=>{
        let cId = activeAdminUser.clients ? activeAdminUser.clients.map((id)=> id.value) : '';
        let pId = program.client ? program.client.id : '';
        if(cId.includes(pId)) {
          return {label:program.name, value :program.id};
        }
      }) : '';
      return (
        <div className="add-user-container">
          <div className="container-box">
            <div className="section-header">
              <h2 className="section-title" dangerouslySetInnerHTML={{ __html: `${confirmAccountDetails ? 'Confirm Details' : 'User Details'}` }}></h2>
            </div>
            {confirmAccountDetails ?
              <div className="user-details-container">
                <div className="user-details-container-left">
                  <div className="user-details-row"><label>Name</label><span>{`${activeAdminUser.firstName} ${activeAdminUser.lastName}`}</span></div>
                  <div className="user-details-row"><label>Mobile Number</label><span>{utils.formatPhoneNumber(activeAdminUser.phoneNum)}</span></div>
                  <div className="user-details-row"><label>Email</label><span>{activeAdminUser.email}</span></div>
                  {/* <div className="user-details-row"><label>2 Factor Authentication</label><span>{activeAdminUser.twoFactorAuth ? 'Enabled' : 'Disabled'}</span></div> */}
                  <div className="user-details-row"><label>Team</label><span>{activeAdminUser.team}</span></div>
                  <div className="user-details-row"><label>Role</label><span>{activeAdminUser.roleName}</span></div>
                  <div className="user-details-row"><label>Assigned Client(s)</label><span>{['superAdmin','enervaAdmin'].includes(activeAdminUser.role) ? 'All clients' : (activeAdminUser.clients ? activeAdminUser.clients : []).map((item)=>item.label).join(', ')}</span></div>
                  <div className="user-details-row"><label>Assigned Program(s)</label><span>{['superAdmin','enervaAdmin'].includes(activeAdminUser.role) ? 'All programs' : (activeAdminUser.programs? activeAdminUser.programs : []).map((item)=>item.label).join(', ')}</span></div>
                </div>
                <div className='vertical-separator-box'>
                  <span className='separator'></span>
                </div>
                <div className="user-details-container-right">
                  {userModalMode === "add" ? <p>
                    You are about to create a user and assign the user access to clients and programs.
                  </p> : <p>You are about to edit the details of a user and/or their access to clients and programs.</p>}
                  <p>
                    Please verify the details and confirm their accuracy.
                  </p>
                  {userUpdateError ? <p className="error-message">{userUpdateError}</p> : ''}
                </div>
              </div>
              :
              <div className="form-sections">
                <div className="form-section">
                  <Input
                    label={'First Name'}
                    name='firstName'
                    value={activeAdminUser.firstName}
                    error={errors['firstName']}
                    onChange={this.onChangeHandler}
                    placeholder={'Type your first name'}
                  />
                  <Input
                    label={'Last Name'}
                    name='lastName'
                    value={activeAdminUser.lastName}
                    error={errors['lastName']}
                    onChange={this.onChangeHandler}
                    placeholder={'Type your last name'}
                  />
                  <Input
                    type='phone'
                    label={'Mobile Number'}
                    name='phoneNum'
                    value={activeAdminUser.phoneNum}
                    error={errors['phoneNum']}
                    onChange={this.onPhoneNumChange}
                    placeholder={''}
                  />
                  <Input
                    label={'Email'}
                    type='email'
                    name='email'
                    value={activeAdminUser.email}
                    onChange={this.onEmailChange}
                    error={errors['email']}
                    placeholder={'Type your email id'}
                  />
                  <Input
                    type='buttonselect'
                    label={'Team'}
                    name='team'
                    value={activeAdminUser.team}
                    options={['Core Team', 'Contractor', 'Client']}
                    error={errors['team']}
                    onChange={this.handleButtonSelection}
                  />
                </div>
                <div className='vertical-separator-box'>
                  <span className='separator'></span>
                </div>
                <div className="form-section">
                  { configData ? 
                  <Input
                    label={'Role'}
                    type='dropdown'
                    name='role'
                    disabled={!activeAdminUser.team}
                    error={errors['role']}
                    onChange={this.onCatChange}
                    value={activeAdminUser.roleName ? {label : activeAdminUser.roleName, value : activeAdminUser.role} : undefined}
                    options={activeAdminUser && activeAdminUser.team ? configData.adminRoles[activeAdminUser.team].map((role)=>{ return {label : role.name, value : role.value};}) : ""}
                    isSearchable={false}
                  /> : ''}
                  {(activeAdminUser.role === 'superAdmin' || activeAdminUser.role === 'enervaAdmin') ?
                    <Input
                      label={'Assigned Client(s)'}
                      name='clients'
                      value={'Assigned to all clients'}
                      disabled={true}
                    />
                    : <Input
                      type='multiselect'
                      label={'Assigned Client(s)'}
                      name='clients'
                      value={activeAdminUser.clients}
                      options={clientList.map((client)=>{ return {label:client.name, value :client.id};})}
                      error={errors['clients']}
                      onChange={this.handleMultiSelectWithCheckBox}
                    />}
                  {(activeAdminUser.role === 'superAdmin' || activeAdminUser.role === 'enervaAdmin' || utils.isNullOrEmpty(activeAdminUser.clients)) ?
                    <Input
                      label={'Assigned Program(s)'}
                      name='programs'
                      value={'Assigned to all programs of clients'}
                      disabled={true}
                    />
                    :
                    <Input
                      type='multiselect'
                      label={'Assigned Program(s)'}
                      name='programs'
                      value={activeAdminUser.programs}
                      options={programOptions.map((program)=>{ return {label:program.name, value :program.id}})}
                      error={errors['programs']}
                      onChange={this.handleMultiSelectWithCheckBox}
                    />
                  }
                </div>
              </div>
            }

          </div>
          {confirmAccountDetails ?
            <div className="confirm-btn-container">
              <Button
                title={'Edit Details'}
                className='inversed-btn'
                uppercase
                onClick={this.toggleConfirmation}
              />
              <Button
                title={userModalMode === 'add' ? 'Confirm User' : 'Confirm Update'}
                className='signup-btn'
                uppercase
                onClick={this.submitForm}
              />
            </div>
            :
            <Button
              title={userModalMode === 'add' ? 'Add User' : 'Edit User'}
              className='signup-btn'
              onClick={this.confirmUserDetails}
              uppercase
              disabled={thereAreErrors}
            />
          }
        </div>
      );
    }
  }
}

function mapState(state) {
  const { clientList, clientsLoading, programList, programsLoading, configData, userModalMode, activeAdminUser, userUpdateError, updatingUser, userCreationError, creatingUser} = state.settings;
  return { programList, programsLoading, clientList, clientsLoading, configData, userModalMode, activeAdminUser, userUpdateError, updatingUser, userCreationError, creatingUser};
}

const actionCreators = {
  createUser: userActions.createAdminUser,
  updateUser: userActions.updateAdminUser,
  setUser : adminSettingsActions.setActiveAdminUser,
  showUserModal: adminSettingsActions.showUserModal
}

export default connect(mapState, actionCreators)(AddUser);

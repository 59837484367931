import React from "react";
import "./SendCheque.css";
import ModalAppDataComponent from "../../../Common/ModalAppDetails/ModalAppData";
import { applicationService } from "../../../../services/applicationService";
import LoaderComponent from "../../../Common/Loader/Loader";
import Input from "../../../Common/Input/Input";
import Button from "../../../Common/Button/Button";
import { utils } from "../../../../helpers/utils";
class SendCheque extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      appLoading: true,
      applicationObj: {},
      errors: {},
      sendChequeFinally: false,
      sendIRFinally: false,
      infoConfirmation: false,
      infoIRConfirmation: false,
      openApplicationModal: false,
      postalCodeEditable: false,
      irNote: "",
      paymentMode: "",
      payeeMailingAddress: {
        locationInfo: null,
        manualPostalCode: null,
        address2: "",
      },
    };
  }
  async componentDidMount() {
    applicationService.getApplication(this.props.applicationId).then((appObj) => {
      this.setState({
        applicationObj: appObj,
        appLoading: false,
      });
    });
  }
  handleConfirmIR = () => {
    applicationService
      .updateApplication(this.props.applicationId, { stage: "Post-Project", status: "Application Information Requested" })
      .then((data) => {
        this.setState({ sendIRFinally: true }, () => {
          setTimeout(() => {
            this.props.closeActionModal(true);
            this.props.reload();
          }, 2000);
        });
      });
  };
  handleSendCheque = () => {
    const { payeeMailingAddress, paymentMode } = this.state;
    applicationService
      .updateApplication(this.props.applicationId, {
        payeeMailingAddress: payeeMailingAddress,
        paymentMode: paymentMode,
        status: "Incentive Recommendation Approval Pending",
      })
      .then((data) => {
        this.setState({ sendChequeFinally: true }, () => {
          setTimeout(() => {
            this.props.closeActionModal();
            this.props.reload();
          }, 2000);
        });
      });
  };
  handlePostalCodeEdit = (editable) => {
    const { payeeMailingAddress, errors } = this.state;
    let postalCode = payeeMailingAddress.manualPostalCode;
    if (editable && utils.isNullOrEmpty(payeeMailingAddress.manualPostalCode)) {
      postalCode = payeeMailingAddress.locationInfo ? payeeMailingAddress.locationInfo.postalCode : "";
    }
    if (!editable) {
      delete errors["manualPostalCode"];
      postalCode = payeeMailingAddress.locationInfo ? payeeMailingAddress.locationInfo.postalCode : "";
    }
    this.setState({
      postalCodeEditable: editable,
      payeeMailingAddress: {
        ...payeeMailingAddress,
        manualPostalCode: postalCode,
      },
      errors,
    });
  };
  onChangeHandler = (name, value) => {
    const { payeeMailingAddress, errors } = this.state;
    delete errors[name];
    this.setState({
      payeeMailingAddress: {
        ...payeeMailingAddress,
        [name]: value,
      },
      errors,
    });
  };
  onLocationChange = (locationInfo) => {
    const { payeeMailingAddress, errors, postalCodeEditable } = this.state;
    delete errors["payeeAddress"];
    if (!postalCodeEditable) {
      delete errors["manualPostalCode"];
    }
    let obj = {
      payeeMailingAddress: {
        ...payeeMailingAddress,
        locationInfo,
      },
      errors,
    };
    if ((!(locationInfo ? true : false) || locationInfo.postalCode === "") && !postalCodeEditable) {
      obj.payeeMailingAddress.manualPostalCode = "";
    }
    this.setState(obj);
  };
  handleSendIRModal = () => {
    this.setState({ openApplicationModal: true });
  };
  handleConfirmSendCheque = () => {
    if (this.verifyForm()) {
      this.setState({ infoConfirmation: true });
    }
  };
  handleSendChequeGoBack = () => {
    this.setState({ infoConfirmation: false });
  };
  handleSendIRGoBack = () => {
    this.setState({ openApplicationModal: false });
  };
  verifyForm = () => {
    let errors = {};
    let errorCount = 0;
    const { postalCodeEditable, payeeMailingAddress, paymentMode } = this.state;
    let postalCode = "";
    if (utils.isNullOrEmpty(payeeMailingAddress.locationInfo)) {
      errors.payeeAddress = "Payee Address is required.";
      errorCount++;
    }
    if (!utils.isNullOrEmpty(payeeMailingAddress.manualPostalCode)) {
      postalCode = payeeMailingAddress.manualPostalCode;
    } else if (!postalCodeEditable) {
      postalCode = payeeMailingAddress.locationInfo ? payeeMailingAddress.locationInfo.postalCode : "";
    }

    if (utils.isNullOrEmpty(postalCode)) {
      errors["manualPostalCode"] = "Postal Code required.";
      errorCount++;
    } else if (!utils.validateCAPostalCode(postalCode)) {
      errors["manualPostalCode"] = "Invalid Postal code.";
      errorCount++;
    }
    if (utils.isNullOrEmpty(paymentMode)) {
      errors.paymentMode = "Payment Mode is required.";
      errorCount++;
    }
    if (errorCount > 0) {
      this.setState({ errors }, () => {
        let allErrorRefs = document.querySelectorAll(".input-box-error,.error-box");
        if (allErrorRefs.length > 0) {
          let firstErrorRef = allErrorRefs[0];
          firstErrorRef.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
        }
      });
    }
    return errorCount === 0;
  };
  handleSendIR = () => {
    this.setState({ infoIRConfirmation: true });
  };
  handleIRNote = (name, value) => {
    this.setState({ irNote: value });
  };
  handleConfirmIRGoBack = () => {
    this.setState({ infoIRConfirmation: false });
  };

  handleButtonSelection = (value) => {
    const { errors } = this.state;
    delete errors["paymentMode"];

    this.setState({
      errors,
      paymentMode: value,
    });
  };

  render() {
    const {
      payeeMailingAddress,
      applicationObj,
      appLoading,
      errors,
      infoConfirmation,
      sendChequeFinally,
      openApplicationModal,
      postalCodeEditable,
      irNote,
      infoIRConfirmation,
      sendIRFinally,
      paymentMode,
    } = this.state;

    let postalCode = "";
    if (!utils.isNullOrEmpty(payeeMailingAddress.manualPostalCode)) {
      postalCode = payeeMailingAddress.manualPostalCode;
    } else if (!postalCodeEditable) {
      postalCode = payeeMailingAddress.locationInfo ? payeeMailingAddress.locationInfo.postalCode : "";
    }
    return (
      <div className="send-cheque-container">
        <React.Fragment>
          {appLoading ? <LoaderComponent /> : <ModalAppDataComponent applicationObj={applicationObj}></ModalAppDataComponent>}
          <div className="section-below-header">
            <h2
              className="section-title"
              dangerouslySetInnerHTML={{ __html: openApplicationModal ? "Open Application" : "Send Manually" }}
            ></h2>
          </div>
          {openApplicationModal ? (
            <div className="open-app-container">
              <div className="main-container">
                {sendIRFinally ? (
                  <React.Fragment>
                    <p>
                      The application stage has been changed to 'Post-Project' and the status has been changed to 'Application Information
                      Requested' successfully.
                    </p>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <p>
                      This action will change the application stage to 'Post-Project' and the status will be changed to 'Application
                      Information Requested' and will have to be resubmitted for review.
                    </p>
                    {infoIRConfirmation ? <p>Are you sure you want to open the application?</p> : ""}
                  </React.Fragment>
                )}
                <div className="form-inner-section">
                  <div className="note-label">IR Note:</div>
                  <div className="form-element full">
                    <Input type="richtext" disabled={infoIRConfirmation} value={irNote} onChange={this.handleIRNote} name="notes" />
                  </div>
                </div>
              </div>
              {sendIRFinally ? (
                ""
              ) : infoIRConfirmation ? (
                <div className="button-set ">
                  <Button className="inversed-btn" title={"go back"} onClick={() => this.handleConfirmIRGoBack()} uppercase />
                  <Button className="submit-details" title={"confirm"} uppercase onClick={() => this.handleConfirmIR()} />
                </div>
              ) : (
                <div className="button-set ">
                  <Button className="inversed-btn" title={"go back"} onClick={() => this.handleSendIRGoBack()} uppercase />
                  <Button
                    className="submit-details"
                    disabled={!utils.cleanHTMLText(irNote)}
                    title={"send ir"}
                    uppercase
                    onClick={() => this.handleSendIR()}
                  />
                </div>
              )}
            </div>
          ) : infoConfirmation ? (
            <div className="info-container">
              <div className="payee-address-container-left">
                <div className="payee-details-row">
                  <label>Payee Name:</label>
                  <span>{applicationObj.payeeInfo ? applicationObj.payeeInfo.payeeName : ""}</span>
                </div>
                <div className="payee-details-row">
                  <label>Payment Mode:</label>
                  <span>{paymentMode}</span>
                </div>
                <div className="payee-details-row">
                  <label>Mailing Address:</label>
                  <span>
                    {utils.isNullOrEmpty(payeeMailingAddress.locationInfo) ? "" : payeeMailingAddress.locationInfo.location_data.label}
                  </span>
                </div>
                <div className="payee-details-row">
                  <label></label>
                  <span>{payeeMailingAddress.address2}</span>
                </div>
                <div className="payee-details-row">
                  <label></label>
                  <span>{utils.formatCAPostalCode(postalCode)}</span>
                </div>
                <div className="payee-details-row">
                  <label></label>
                  <span>{payeeMailingAddress.locationInfo ? payeeMailingAddress.locationInfo.city : ""}</span>
                </div>
                <div className="payee-details-row">
                  <label></label>
                  <span>{payeeMailingAddress.locationInfo ? payeeMailingAddress.locationInfo.province : ""}</span>
                </div>
              </div>
              <div className="vertical-separator-box">
                <span className="separator"></span>
              </div>
              <div className="payee-address-container-right">
                {sendChequeFinally ? (
                  <p>
                    A request to send a {paymentMode.toLowerCase()} via mail for the application (ID: #ESB-{applicationObj.appNo}) has been successfully
                    registered.
                  </p>
                ) : (
                  <React.Fragment>
                    <p>You are about to change the Payment Mode on this application to '{paymentMode}'.</p>
                    <p>Are you sure?</p>
                  </React.Fragment>
                )}
              </div>
            </div>
          ) : (
            <div className="container">
              <div className="payee-form-section">
                <div className="payee-details">
                  <div className="detail-one">
                    <label>Payee Name:</label>
                    <span>{applicationObj.payeeInfo ? applicationObj.payeeInfo.payeeName : ""}</span>
                  </div>
                  <div className="detail-two">
                    <Input
                      type="buttonselect"
                      label={"Payment Mode:"}
                      name="paymentMode"
                      value={paymentMode}
                      options={["Cheque", "Bank Draft"]}
                      error={errors["paymentMode"]}
                      onChange={this.handleButtonSelection}
                    />
                    <Input
                      type="location"
                      name="payeeAddress"
                      label={"Mailing Address:"}
                      error={errors["payeeAddress"]}
                      value={payeeMailingAddress.locationInfo ? payeeMailingAddress.locationInfo.location_data : ""}
                      onChange={this.onLocationChange}
                      placeholder={"Address Line 1"}
                    />
                    <div className="without-label">
                      <Input
                        label={""}
                        name="address2"
                        value={payeeMailingAddress.address2}
                        onChange={this.onChangeHandler}
                        placeholder={"Address Line 2"}
                      />
                      <Input
                        label=""
                        type="postalCode"
                        name="manualPostalCode"
                        value={postalCode}
                        onChange={this.onChangeHandler}
                        placeholder={"Enter Postal Code"}
                        error={errors["manualPostalCode"]}
                        onEdit={this.handlePostalCodeEdit}
                        editable={postalCodeEditable}
                        disabled
                      />
                      <Input
                        label=""
                        value={payeeMailingAddress.locationInfo ? payeeMailingAddress.locationInfo.city : ""}
                        onChange={this.onChangeHandler}
                        placeholder={""}
                        disabled
                      />
                      <Input
                        label=""
                        value={payeeMailingAddress.locationInfo ? payeeMailingAddress.locationInfo.province : ""}
                        onChange={this.onChangeHandler}
                        placeholder={""}
                        disabled
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {openApplicationModal || sendChequeFinally ? (
            ""
          ) : infoConfirmation ? (
            <div className="button-set ">
              <Button className="inversed-btn" title={"go back"} uppercase onClick={() => this.handleSendChequeGoBack()} />
              <Button className="submit-details" title={`send ${paymentMode}`} uppercase onClick={() => this.handleSendCheque()} />
            </div>
          ) : (
            <div className="button-set ">
              <Button className="inversed-btn" title={"Send An Ir"} onClick={() => this.handleSendIRModal()} uppercase />
              <Button className="submit-details" title={"confirm details"} uppercase onClick={() => this.handleConfirmSendCheque()} />
            </div>
          )}
        </React.Fragment>
      </div>
    );
  }
}
export default SendCheque;

import {participantActionTypes} from './participantActionTypes';
import {userService} from '../../services/userService';
import {utils} from '../../helpers/utils';

export const participantActions = {
    getParticipants,
    getParticipantsRetrofit,
    showAddParticipantModal,
    getParticipantDetails,
    getParticipantStatusLogs,
    resetEmailVerifyModal
};

function resetEmailVerifyModal(){
    return { type : participantActionTypes.RESET_EMAIL_VERIFICATION_MODAL};
}

function showAddParticipantModal(show){
    return { type : participantActionTypes.SHOW_ADD_PARTICIPANT_MODAL, show};
}

// function mapErrors(firebaseError){
//     let errors = {};
//     let { code, message } = firebaseError;
//     if(code === 'auth/wrong-password'){
//         errors['password'] = 'The password does not match the username';
//     } else if(code === 'auth/user-not-found'){
//         errors['username'] = 'This username does not exist in our system';
//     } else {
//         errors['username'] = message;
//     }
//      return errors;
// }

// function extractErrMessage(firebaseError){
//     return firebaseError.message;
// }

function getParticipants(program, type, searchTerm, config, offset){
    return dispatch => {
        dispatch(request());
        userService.getParticipants(program, type, searchTerm, config, offset).then((res)=>{
            let users = res.data;
            let userList = [];
            for(let key in users){
                const user = users[key];
                let item = {
                    uid: user.id,
                    Name:  user.companyName,
                    Status: `${user.status}`,
                    CreatedDate: `${utils.formatDate(user.createdAt)}`,
                    EmailID: user.email,
                    isBlocked: user.isBlocked,
                    userInfo : user
                };
                if(type === "contractor"){ item.ContractorID = `${user.userNo || user.id}`;}
                if(type === "customer"){ item.CustomerID = `${user.userNo || user.id}`;}
                userList.push(item);
            }  
            dispatch(success(userList));
        });
    };

    function request() { return { type: participantActionTypes.GET_PARTICIPANTS_REQUEST,userType:type } }
    function success(list) { return { type: participantActionTypes.GET_PARTICIPANTS_SUCCESS, userType:type, data : list, offset } }
    // function failure(error) { return { type: userActionTypes.GET_PARTICIPANTS_FAILURE, error: extractErrMessage(error) } }
}

function getParticipantsRetrofit(program, type, searchTerm, config, offset){
    return dispatch => {
        dispatch(request());
        userService.getParticipantsRetrofit(program, type, searchTerm, config, offset).then((res)=>{
            let users = res.data;
            let userList = [];
            for(let key in users){
                const user = users[key];
                let accCategory = user.applicant_rep_flag.toLowerCase() === 'n' ? 'applicant' : 'applicant_rep';
                let item = {
                    uid: user.person_id,
                    firstName:  user.first_name,
                    lastName:  user.last_name,
                    CreatedDate: `${utils.formatDate(user.createdAt)}`,
                    EmailID: user.email,
                    accCategory: accCategory,
                    userInfo : user
                };
                if(type === "contractor"){ item.ContractorID = `${user.person_id}`;}
                if(type === "customer"){ item.CustomerID = `${user.person_id}`;}
                userList.push(item);
            }  
            dispatch(success(userList));
        });
    };

    function request() { return { type: participantActionTypes.GET_PARTICIPANTS_REQUEST,userType:type } }
    function success(list) { return { type: participantActionTypes.GET_PARTICIPANTS_SUCCESS, userType:type, data : list, offset } }
    // function failure(error) { return { type: userActionTypes.GET_PARTICIPANTS_FAILURE, error: extractErrMessage(error) } }
}

function getParticipantDetails(program, userId) {
    return dispatch => {
        dispatch(request());
        userService.getParticipantDetails(program, userId).then((participantDetails)=>{
            dispatch(success(participantDetails));
        }).catch((error)=>{
            dispatch(failure("Error"));
        });
    };

    function request() { return { type: participantActionTypes.GET_PARTICIPANT_DETAILS_REQUEST } }
    function success(participantDetails) { return { type: participantActionTypes.GET_PARTICIPANT_DETAILS_SUCCESS, participantDetails } }
    function failure(error) { return { type: participantActionTypes.GET_PARTICIPANT_DETAILS_FAILURE, error } }
}

function getParticipantStatusLogs(program, userId) {
    return dispatch => {
        dispatch(request());
        userService.getStatusLogs(program, userId).then((logs)=>{
            dispatch(success(logs));
        }).catch((error)=>{
            dispatch(failure("Error"));
        });
    };

    function request() { return { type: participantActionTypes.GET_PARTICIPANT_STATUS_LOGS_REQUEST } }
    function success(logs) { return { type: participantActionTypes.GET_PARTICIPANT_STATUS_LOGS_SUCCESS, logs } }
    function failure(error) { return { type: participantActionTypes.GET_PARTICIPANT_STATUS_LOGS_FAILURE, error } }
}
import React from "react";
import "./ViewChangeRequests.css";
import Table from "../../../Common/Table/Table";
import EmptyContainer from "../../../Common/EmptyContainer/EmptyContainer";
import { AuthContext } from "../../../../firebaseAuthContext";
import { applicationService } from "../../../../services/applicationService";
import LoaderComponent from "../../../Common/Loader/Loader";
import ModalAppDataComponent from "../../../Common/ModalAppDetails/ModalAppData";
import StatusLogIcon from "../../../../images/StatusLog.svg";
import ViewChangeRequestLogs from "../ViewChangeRequestLogs/ViewChangeRequestLogs";
import { connect } from "react-redux";
import { appActions } from "../../../../redux/actions/appActions";
import LeftArrow from '../../../../images/leftArrow.svg';
class ViewChangeRequests extends React.Component {
  static contextType = AuthContext;
  constructor(props) {
    super(props);
    this.state = {
      applicationObj: {},
      changeReqObj: {},
      newProjectDeadline: null,
      errors: {},
      reviewLoading: true,
      readyForApprove: false,
      approvedSuccessfully: false,
      confirmingApproval: false,
      changeNote: "",
      reviewStatus: { value: "", label: "Select status from list" },
      showChangeRequestLogs: false,
    };
  }

  async componentDidMount() {
    applicationService.getApplication(this.props.applicationId).then((appObj) => {
      this.props.getChangeRequestLogs(this.props.applicationId);
      this.setState({
        applicationObj: appObj,
        reviewLoading: false,
      });
    });
  }

  handleChangeRequestLogs = () => {
    this.setState({ showChangeRequestLogs: true });
  };
  closeChangeReqLogs = () => {
    this.setState({ showChangeRequestLogs: false });
  };

  render() {
    const { reviewLoading, applicationObj, changeReqObj, showChangeRequestLogs } = this.state;
    const { activeChangeRequests, activeChangeReqLogs, applicationId, closeChangeReq } = this.props;
    return (
      <React.Fragment>
        {!showChangeRequestLogs ? (
          <div>
            <div className="change-review-container">
              {reviewLoading ? (
                <LoaderComponent />
              ) : (
                <React.Fragment>
                  <ModalAppDataComponent applicationObj={applicationObj}></ModalAppDataComponent>
                  <button onClick={closeChangeReq} className="back-button">
                          <img src={LeftArrow} alt="arrow" />
                          Back
                    </button>
                  <div className="section-below-header1">
                    <h2 className="section-title" dangerouslySetInnerHTML={{ __html: "View Change Requests" }}></h2>
                    <div>
                      <img src={StatusLogIcon} alt="Change Status Logs" onClick={this.handleChangeRequestLogs} />
                      <span className="tooltip">Change Request Logs</span>
                    </div>
                  </div>
                  {activeChangeRequests.length ? (
                    <div className="container-box">
                      <div className="form-sections">
                        <Table
                          rows={activeChangeRequests}
                          headings={[
                            { title: "Change Request No.", value: "changeReqNo" },
                            { title: "Created Date", value: "createdAt" },
                            { title: "Request Type", value: "reqType" },
                            { title: "Description", value: "description" },
                            { title: "Status", value: "status" },
                            { title: "View", value: "actionURL" },
                          ]}
                        />
                      </div>
                    </div>
                  ) : (
                    <div className="container-box">
                      <EmptyContainer>No Change Requests to Show</EmptyContainer>
                    </div>
                  )}
                </React.Fragment>
              )}
            </div>
          </div>
        ) : (
          <div className="view-requests-logs-inside-view-requests-container">
            <ViewChangeRequestLogs
              applicationId={this.props.applicationId}
              closeChangeReqLogs={this.closeChangeReqLogs}
              activeChangeReqLogs={activeChangeReqLogs}
            />
          </div>
        )}
      </React.Fragment>
    );
  }
}
function mapState(state) {
  const { activeChangeReqLogs, appChangeReqLogsLoading } = state.application;
  return {
    activeChangeReqLogs,
    appChangeReqLogsLoading,
  };
}

const actionCreators = {
  getChangeRequestLogs: appActions.getApplicationChangeRequestLogs,
};

export default connect(mapState, actionCreators)(ViewChangeRequests);

import firebase from '../helpers/Firebase';
import { utils } from '../helpers/utils';
const API_URL = firebase.apiUrl;

export const labelService = {
   createLabel,
   getLabels
};

function getLabels(){
    return utils.makeWebRequest(`${API_URL}/labels`,"GET");
}

function createLabel(data){
    return utils.makeWebRequest(`${API_URL}/labels`,"POST", data);
}

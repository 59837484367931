import {participantActionTypes} from '../actions/participantActionTypes';

const initialState = { activeStatusLogs:[], activeParticipant : null, activeParticipantLoading:false, participantStatusLogs : [], errors : {}, customerList:[],contractorList:[],moreParticipants:true,participantOffset:0};

export function participant(state = initialState, action) {
  switch (action.type) {
    case participantActionTypes.GET_PARTICIPANT_DETAILS_REQUEST:
      return {...state,
        activeParticipantLoading : true
      };
    case participantActionTypes.GET_PARTICIPANT_DETAILS_SUCCESS:
      return {...state,
        activeParticipantLoading : false,
        activeParticipant : action.participantDetails
      };
    case participantActionTypes.GET_PARTICIPANT_STATUS_LOGS_SUCCESS:
      return {...state,
         activeStatusLogs : action.logs
      };
    case participantActionTypes.GET_PARTICIPANTS_REQUEST:
      return {...state,
        [`${action.userType}sLoading`] : true
    }; 
    case participantActionTypes.GET_PARTICIPANTS_SUCCESS:
      return {...state,
        participantOffset : (action.offset + action.data.length), 
        moreParticipants: action.data.length > 0,
        [`${action.userType}sLoading`] : false,
        [`${action.userType}List`] : action.offset > 0 ? state[`${action.userType}List`].concat(action.data) : action.data
    };    
    default:
      return state
  }
}
import React from "react";
import DIARRow from "./DIARRow";
import SearchInput from "../../../Common/SearchInput/SearchInput";
import FilterDropdown from "../../../Common/FilterDropdown/FilterDropdown";
import { appActions } from "../../../../redux/actions/appActions";
import { connect } from "react-redux";
import { debounce } from "debounce";
import "./DIAR.css";
import { adminSettingsActions } from "../../../../redux/actions/adminSettingsActions";
import Button from "../../../Common/Button/Button";
import { utils } from "../../../../helpers/utils";
import DateRangePicker from "../../../Common/DateRangePicker/DateRangePicker";
import { CSVLink } from "react-csv";
import CheckBox from "../../../Common/CheckBox/CheckBox";
import Drawer from "../../../Common/Drawer/Drawer";
import SkeletonLoading from "../../../Common/SkeletonLoading/SkeletonLoading";
import { applicationService } from "../../../../services/applicationService";
import FilterVector from "../../../../images/FilterVector.svg";

class DIARTab extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      allAppsSelected: false,
      namesOnBankAccount: [],
      openDrawer: false,
      selectedStatusFilters: [],
      selectedFlagFilters: [],
      dateRange: {},
    };
    this.searchDebouncedData = debounce(this.searchData, 300);
  }

  async componentDidMount() {
    this.initialLoad();
  }
  initialLoad = async () => {
    this.props.loadAdminConfig();
    let namesOnBankAccount = await applicationService.getNameOnBankAccount(this.props.currentProgram);
    this.searchData(true);
    this.setState({ namesOnBankAccount: namesOnBankAccount });
  };
  ShowMoreDetails = (id) => {
    let actElem = document.getElementById(id);
    if (actElem.classList.contains("active-row")) {
      actElem.classList.remove("active-row");
    } else {
      let actContElem = document.querySelector(".diar-details-row.active-row");
      if (actContElem !== null) {
        actContElem.classList.remove("active-row");
      }
      actElem.classList.add("active-row");
    }
  };

  onStatusFilterChange = (SelectedStatusFilters) => {
    this.props.setFilters("DIAR", {
      SelectedStatusFilters,
    });
  };

  onLabelFilterChange = (SelectedLabelFilters) => {
    this.props.setFilters("DIAR", {
      SelectedLabelFilters,
    });
  };

  onSearch = (searchInput) => {
    this.props.setFilters("DIAR", {
      searchInput,
    });
  };

  onDateChange = (dateRange) => {
    if (dateRange) {
      dateRange.key = "Payment - Incentive Recommendation Approval Pending Time";
      this.setState({ dateRange });
    } else {
      this.setState({ dateRange: {} });
    }
  };

  searchData = (refresh = false) => {
    const { sortConfig, SelectedStatusFilters, SelectedLabelFilters, currentProgram, stream } = this.props;
    let config = {
      statuses: SelectedStatusFilters,
      labels: SelectedLabelFilters,
      sort: sortConfig,
      program: currentProgram,
      stream: stream,
    };
    this.props.getApplicationsForDIAR(this.props.searchInput, config);
  };

  handleListScroll = () => {
    if (this.props.moreApps) {
      this.searchDebouncedData();
    }
  };

  sortData = (property) => {
    this.props.setFilters("DIAR", {
      sortConfig: { name: property, direction: this.props.sortConfig.direction === "desc" ? "asc" : "desc" },
    });
  };

  onStreamChange = (stream) => {
    this.props.setFilters("DIAR", {
      stream,
    });
  };

  toggleDrawer = () => {
    this.setState({ openDrawer: false });
  };

  handleCheckboxChange = (e) => {
    const { name, value, checked } = e.target;
    let checkedStatus = this.state[name];
    if (checked) {
      this.setState({ selectedStatusFilters: [...this.state.selectedStatusFilters, name] });
    } else {
      this.setState({ selectedStatusFilters: this.state.selectedStatusFilters.filter((item) => item !== name) });
    }
  };
  handleProgramCheckboxChange = (e) => {
    const { name, value, checked } = e.target;
    let checkedStatus = this.state[name];
    if (checked) {
      this.setState({ selectedFlagFilters: [...this.state.selectedFlagFilters, name] });
    } else {
      this.setState({ selectedFlagFilters: this.state.selectedFlagFilters.filter((item) => item !== name) });
    }
  };

  clearFilters = () => {
    this.setState({
      selectedStatusFilters: [],
      selectedFlagFilters: [],
      dateRange: {},
    });
    this.props.setFilters("DIAR", {
      stream: [],
      SelectedLabelFilters: [],
      dateRange: [],
    });
    this.initialLoad();
  };

  applyFiters = () => {
    if (Object.keys(this.state.dateRange).length > 0) {
      this.props.setFilters("DIAR", {
        stream: this.state.selectedFlagFilters,
        SelectedLabelFilters: this.state.selectedStatusFilters,
        dateRange: this.state.dateRange,
      });
      this.setState({ openDrawer: false });
    } else {
      this.props.setFilters("DIAR", {
        stream: this.state.selectedFlagFilters,
        SelectedLabelFilters: this.state.selectedStatusFilters,
      });
      this.setState({ openDrawer: false });
    }
  };
  render() {
    const {
      appList,
      incentiveCapApprovalExceeded,
      selectedApplications,
      selectedApplicationsIncentiveTotal,
      SelectedLabelFilters,
      labels,
      labelsLoading,
      adminUserInfo,
      currentProgram,
      applicationsByStatusLoading,
      appListOffset,
      stream,
      configData,
    } = this.props;
    let actions = {};
    if (adminUserInfo && adminUserInfo.permissions) {
      actions = adminUserInfo.permissions[currentProgram].actions;
    }

    let FilterOptions = labels.map((item, index) => {
      return { value: item.name, id: item.id };
    });

    let applicationsTotalIncentive = 0.0;
    if (appList.length > 0) {
      for (let app of appList) {
        applicationsTotalIncentive += parseFloat(app.PostProjectReviewerApprovedIncentive);
      }
    }
    return (
      <div className="diar-tab">
        <div className="diar-top-bar actions">
          {!utils.isNullOrEmpty(actions) && (
            <div className="button-set">
              <div className="diar-incentive-amount">
                <div className="label">Total Incentive:</div>
                <div className={!incentiveCapApprovalExceeded ? "incentiveTotal" : "incentiveTotal exceeded"}>
                  {Object.keys(selectedApplications).length === 0
                    ? `$${applicationsTotalIncentive
                        .toFixed(2)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
                    : this.selectedApplicationsIncentiveTotal !== 0.0
                    ? `$${selectedApplicationsIncentiveTotal
                        .toFixed(2)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
                    : "$0.00"}
                </div>
              </div>
              {actions["Incentive Review Board-Approve"] ? (
                <Button
                  disabled={Object.keys(selectedApplications).length === 0 || incentiveCapApprovalExceeded ? true : false}
                  title={"approve"}
                  uppercase
                  onClick={() => this.props.showActionModal({ modalType: "show-selected-apps-for-action", actionType: "approve" })}
                />
              ) : (
                ""
              )}
              {actions["Incentive Review Board-Recommend Rejection"] ? (
                <Button
                  disabled={Object.keys(selectedApplications).length === 0 || incentiveCapApprovalExceeded ? true : false}
                  title={"Recommend Rejection"}
                  uppercase
                  onClick={() => this.props.showActionModal({ modalType: "show-selected-apps-for-action", actionType: "reject" })}
                />
              ) : (
                ""
              )}
              {actions["Incentive Review Board-Assign for QA/QC"] ? (
                <Button
                  disabled={Object.keys(selectedApplications).length === 0 || incentiveCapApprovalExceeded ? true : false}
                  title={"Assign for QA/QC"}
                  uppercase
                  onClick={() => this.props.showActionModal({ modalType: "show-selected-apps-for-action", actionType: "qaqc" })}
                />
              ) : (
                ""
              )}
            </div>
          )}
        </div>
        <div className="diar-top-bar secondRow">
          <div className="diar-search">
            <SearchInput value={this.props.searchInput} placeholder="Search for Applications" onClick={this.onSearch} />
          </div>
          <div>
            <CSVLink
              className="exportDIARBtn"
              filename={`DIAR-${utils.getCurrentDateAndTimeStamp()}.csv`}
              headers={[
                { label: "Application ID", key: "appNo" },
                { label: "Contractor Company Name", key: "contractorName" },
                { label: "Customer Company Name", key: "customerName" },
                { label: "Expected Project Start Date", key: "expectedProjectStartDate" },
                { label: "Expected Project End Date", key: "expectedProjectEndDate" },
                { label: "Is Receiving Other Funding?", key: "isReceivingOtherFunding" },
                { label: "Measures Assigned", key: "measureNames" },
                { label: "Application Link", key: "applicationLink" },
                { label: "Measure Categories", key: "measureCategories" },
                { label: "Pre-Project Application Draft Submitted Date", key: "preProjectApplicationDraftSubmittedDate" },
                { label: "Project Completion Deadline", key: "projectCompletionDeadline" },
                { label: "Pre-Project Application Draft Approved Date", key: "preProjectApplicationDraftApprovedDate" },
                { label: "Post-Project Application Submitted Date", key: "postProjectApplicationSubmittedDate" },
                { label: "Incentive Recommended Date", key: "incentiveRecommendedDate" },
                { label: "Recommended Incentive", key: "recommendedIncentive" },
                { label: "Assigned Payee Name", key: "payeeName" },
                { label: "Payee Account Category", key: "payeeAccountCategory" },
                { label: "Actual Project End Date", key: "actualProjectEndDate" },
                { label: "Site Visit", key: "qaQcType" },
                { label: "Is Participant Total Incentive under $1,000,000", key: "customer1MIncentiveCapHit" },
                { label: "Incentive Percentage of Cost", key: "IncentivePercentage" },
                { label: "Difference in Amounts", key: "differenceAmountPost" },
                { label: "Reason For Difference", key: "explanationForDifference" },
                { label: "Is the Payee Name Different from Name on Bank Account?", key: "differentNameOnBankAccount" },
                { label: "Delegated Payee Name", key: "delegatedPayeeName" },
                { label: "Followed up via Phone Call", key: "followedUpViaPhoneCall" },
                { label: "Followed up via Email", key: "followedUpViaEmail" },
                { label: "Comments", key: "approvedBankDetailsNote" },
              ]}
              data={utils.buildDIARExport(appList, this.state.namesOnBankAccount)}
            >
              <Button uppercase title={"Export DIAR"} />
            </CSVLink>
          </div>
          <div className="applications-top-bar-filter-btn">
            <Button
              className="filter-btn incent-Btn"
              uppercase
              title={"Filters"}
              svg={FilterVector}
              onClick={() => this.setState({ openDrawer: true })}
            />
          </div>

          <Drawer toggleDrawer={this.toggleDrawer} openDrawer={this.state.openDrawer} className={"program-panel-drawer"}>
            <div className="filter-drawer" style={{ width: "90%" }}>
              <h3>FILTER BY</h3>
              <DateRangePicker
                onChange={this.onDateChange}
                className={"diar-filterCal"}
                value={[this.state.dateRange.startDate, this.state.dateRange.endDate]}
              />

              {configData ? (
                <div className="all-filters" style={{ overflowX: "hidden" }}>
                  <div className="filter">
                    <label className="filter-label">Filter By Label</label>
                    <div className="filter-options">
                      {FilterOptions.length &&
                        !labelsLoading &&
                        FilterOptions.map((status, i) => {
                          return (
                            <label key={i}>
                              <input
                                type="checkbox"
                                name={status.id}
                                checked={this.state.selectedStatusFilters.includes(status.id)}
                                value={status.id}
                                onChange={(e) => this.handleCheckboxChange(e)}
                              />{" "}
                              {status.value}
                            </label>
                          );
                        })}
                    </div>
                  </div>
                  {currentProgram === "esb" ? (
                    <div className="filter">
                      <label className="filter-label">Filter By Program</label>
                      <div className="filter-options">
                        {currentProgram === "esb" &&
                          [
                            { value: "Energy Savings For Business", id: "esb" },
                            { value: "Comprehensive Energy Savings", id: "ces" },
                          ]?.map((flagItem, i) => {
                            return (
                              <label key={i}>
                                <input
                                  type="checkbox"
                                  name={flagItem.id}
                                  checked={this.state.selectedFlagFilters.includes(flagItem.id)}
                                  value={flagItem.id}
                                  onChange={(e) => this.handleProgramCheckboxChange(e)}
                                />{" "}
                                <span>{flagItem.icon}</span>
                                {flagItem.value}
                              </label>
                            );
                          })}
                      </div>
                    </div>
                  ) : null}
                </div>
              ) : null}
            </div>
            <Button className="inversed-btn drawer-btn first-btn" title="CLEAR ALL FILTERS" onClick={this.clearFilters} />
            <Button className="drawer-btn" title="APPLY FILTERS" onClick={this.applyFiters} />
          </Drawer>
        </div>
        <div className="diar-details-header diar-details-row">
          <div className="diar-details">
            <div>
              <CheckBox
                checked={!utils.isNullOrEmpty(selectedApplications)}
                unselect={Object.keys(selectedApplications).length !== appList.length}
                onClick={() => {
                  this.props.handleAllAppsSelection();
                }}
              />
            </div>
            <div className="sort-icon" onClick={() => this.sortData("appNo", "string")}>
              <span>ID</span>
            </div>
            <div className="sort-icon" onClick={() => this.sortData("payeeInfo.payeeName", "string")}>
              Payee
            </div>
            <div className="sort-icon" onClick={() => this.sortData("companyName", "string")}>
              Participant
            </div>
            <div className="sort-icon" onClick={() => this.sortData("contractor.label", "string")}>
              Contractor
            </div>
            <div className="sort-icon" onClick={() => this.sortData("PostProjectReviewerApprovedIncentive", "string")}>
              Incentive
            </div>
            <div className="sort-icon" onClick={() => this.sortData("Payment - Incentive Recommendation Approval Pending Time", "date")}>
              Assigned to ERA Date
            </div>
            <div className="sort-icon" onClick={() => this.sortData("Actual Project Cost", "string")}>
              Project Cost
            </div>
            <div className="sort-icon" onClick={() => this.sortData("payeeNameIsPersonalName", "string")}>
              Payee Name is Personal Name
            </div>
            <div></div>
          </div>
        </div>
        <div className="diar-details-list" id="CustomerDetailsList" onScroll={this.handleListScroll}>
          {applicationsByStatusLoading && utils.isNullOrEmpty(actions) ? (
            <SkeletonLoading />
          ) : appList && appList.length ? (
            appList.map((application, i) => (
              <DIARRow
                adminUserInfo={adminUserInfo}
                showActionModal={this.props.showActionModal}
                application={application}
                key={`application-${i}`}
                id={`application-${i}`}
                setFilters={this.props.setFilters}
                handleAppselection={this.props.handleAppselection}
                checkedRow={selectedApplications[application.id]}
                ShowMoreDetails={this.ShowMoreDetails}
                currentProgram={currentProgram}
              />
            ))
          ) : (
            <div className="no-records-found">No records.</div>
          )}
        </div>
      </div>
    );
  }
}

function mapState(state) {
  const { configData } = state.settings;
  const { adminUserInfo, currentProgram } = state.authentication;
  const { appList, appListOffset, applicationsByStatusLoading, appStatusLogsLoading, activeStatusLogs, moreApps, labels, labelsLoading } =
    state.application;
  return {
    currentProgram,
    adminUserInfo,
    appList,
    appListOffset,
    applicationsByStatusLoading,
    appStatusLogsLoading,
    activeStatusLogs,
    moreApps,
    configData,
    labels,
    labelsLoading,
  };
}

const actionCreators = {
  loadAdminConfig: adminSettingsActions.getAdminConfig,
  getApplicationsForDIAR: appActions.getApplicationsForDIAR,
  getApplicationsTotalIncentive: appActions.getApplicationsTotalIncentive,
};

export default connect(mapState, actionCreators)(DIARTab);

import React from 'react';
import { utils } from '../../../../helpers/utils';
import FlagIcon from "../../../Common/FlagIcon/FlagIcon";
import CheckBox from '../../../Common/CheckBox/CheckBox';
import WhiteXIcon from '../../../../images/WhiteXIcon.svg';
import AdminActionButton from '../../../Common/AdminActionButton/AdminActionButton';
class ApplicationTab extends React.Component {
   
    constructor(props) {
        super(props);
        this.state = {
         
        }
    }

    viewApplication = (id) => {
        window.open(`viewapplication/${id}`, '_blank');
    }
 
    onChange=(id)=>{
   
    }
    render() {
        const { application,adminUserInfo, currentProgram } = this.props;

        let actions = {};
        if(adminUserInfo && adminUserInfo.permissions){
            actions = adminUserInfo.permissions[currentProgram].actions;
        }

        return (
            <div className='diar-details-row' id={this.props.id}>
                <div className="diar-details">
                    <div><label>Select:</label><span><CheckBox checked={this.props.checkedRow}  onClick={() => {   this.props.handleAppselection(application); }} /></span></div>
                    <div><label>ID:</label><span>{application.appNo}</span></div>
                    <div><label>Payee:</label><span>{application.payeeName}</span></div>
                    <div><label>Participant:</label><span>{application.customerCompanyName}</span></div>
                    <div><label>Contractor:</label><span>{application.contractorCompanyName}</span></div>
                    <div><label>Incentive:</label><span>{utils.formatAsCAD(application.PostProjectReviewerApprovedIncentive)}{` (${application.IncentivePercentage}%)`}</span></div>
                    <div><label>Assigned to ERA Date:</label><span>{utils.formatAmericanDate(application.incentiveRecommendedDate)}</span></div>
                    <div><label>Project Cost:</label><span>{utils.formatAsCAD(application.ProjectCost)}</span></div>
                    <div><label>Payee Name is Personal Name:</label><span>{application.payeeNameIsPersonalName?'Yes':'No'}</span></div>
                    <div className="show-more-btn" onClick={() => this.props.ShowMoreDetails(this.props.id)}></div>
                </div>
                <div className="diar-labels">
                    {application.labels ? Object.keys(application.labels).map((lKey)=>{
                        let label = application.labels[lKey];
                        return <div 
                        className="row-label-tag" 
                        style={{backgroundColor:`${label.color}`}}
                        onClick={()=>{
                            this.props.setFilters("DIAR", {
                                SelectedLabelFilters : [label.id]
                              });
                        }}
                        >
                            {label.name}
                            {actions['Incentive Review Board-Remove Label'] ? <img src={WhiteXIcon} alt="remove label" onClick={(e) => { 
                                e.stopPropagation();
                                return this.props.showActionModal({ ...application, modalType: 'remove-label', selectedLabel : label }); 
                                }}/> : ''}
                            </div>;
                    }) : ''}
                </div>
                <div className="diar-more-details">
                    <div> 
                    <AdminActionButton 
                        iconClass={`label-icon`}
                        actionName={`Incentive Review Board-Assign Label`}
                        showActionModal={this.props.showActionModal}
                        application={application}
                        appModalType={'assign-label'}
                        title={'Assign Label'}
                    />
                        <AdminActionButton 
                        iconClass={`view-icon`}
                        actionName={`Incentive Review Board-View Application`}
                        viewApplication={this.viewApplication}
                        application={application}
                        title={'View Application'}
                    />
                         <AdminActionButton 
                        iconClass={`status-log-icon`}
                        actionName={`Incentive Review Board-Status Logs`}
                        showActionModal={this.props.showActionModal}
                        application={application}
                        appModalType={'view-status-log'}
                        title={'Status Logs'}
                    />
                        {/* <AdminActionButton 
                        iconClass={`status-log-icon`}
                        actionName={`Incentive Review Board-Change Request Logs`}
                        showActionModal={this.props.showActionModal}
                        application={application}
                        appModalType={'view-change-request-logs'}
                        title={'Change Request Logs'}
                    /> */}
                        <AdminActionButton 
                        iconClass={`change-request-icon`}
                        actionName={`Incentive Review Board-View Change Requests`}
                        showActionModal={this.props.showActionModal}
                        application={application}
                        appModalType={'view-change-requests'}
                        title={'View Change Requests'}
                    />
                    </div>
                </div>
            </div>
        );
    }
}

export default ApplicationTab;

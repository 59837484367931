import React from 'react';
import { ReactComponent as ViewDocument } from '../../../../images/EyeOpen.svg'
import { ReactComponent as DownloadDocument } from '../../../../images/DownloadVector.svg'
import { ReactComponent as DeleteDocument } from '../../../../images/DeleteVector.svg'

class DocumentsDetail extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        const { DocumentDetail, Index } = this.props
        return (
            <div className="contractor-document-details">
                <div className="document-detail">{Index}</div>
                <div className="document-detail">{DocumentDetail.DocumentName}</div>
                <div className="document-detail">{DocumentDetail.DocumentType}</div>
                <div className="document-detail document-actions">
                    <span onClick={() => this.props.documentAction('view', DocumentDetail.Id)}><ViewDocument width="24" height="24" /></span>
                    <span onClick={() => this.props.documentAction('download', DocumentDetail.Id)}><DownloadDocument width="20" height="20" /></span>
                    <span onClick={() => this.props.documentAction('delete', DocumentDetail.Id)}><DeleteDocument width="20" height="20" /></span>
                </div>
            </div>
        );
    }
}

export default DocumentsDetail;

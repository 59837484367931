import React from 'react';
import './Reports.css';
import AdminLayout from '../../../Common/AdminLayout';
import { AuthContext } from '../../../../firebaseAuthContext';
import SearchInput from '../../../Common/SearchInput/SearchInput'
import { ReactComponent as NavigationRight } from '../../../../images/NavigationRight.svg'

class Reports extends React.Component {
  static contextType = AuthContext;
  constructor(props) {
    super(props);
    this.state = {

    }
  }

  componentDidMount() {

  }

  render() {
    const { displayName } = this.context.user;
    const ProgramsList = [
      {
        Program: 'ESB Program',
        Client: 'ERA',
        StartDate: '29 Mar 2019',
        EndDate: '29 Mar 2022',
        Status: 'In Progress'
      },
      {
        Program: 'ESB Program',
        Client: 'ERA',
        StartDate: '29 Mar 2019',
        EndDate: '29 Mar 2022',
        Status: 'In Progress'
      },
      {
        Program: 'ESB Program',
        Client: 'ERA',
        StartDate: '29 Mar 2019',
        EndDate: '29 Mar 2022',
        Status: 'In Progress'
      },
      {
        Program: 'ESB Program',
        Client: 'ERA',
        StartDate: '29 Mar 2019',
        EndDate: '29 Mar 2022',
        Status: 'In Progress'
      },
      {
        Program: 'ESB Program',
        Client: 'ERA',
        StartDate: '29 Mar 2019',
        EndDate: '29 Mar 2022',
        Status: 'In Progress'
      }
    ]
    return (
      <AdminLayout showFooter={false} props={this.props}>
        {/* <h4>Welcome, <span className="highlight-text">{displayName}</span></h4> */}
        <div className="program-list-container">
          <h4>Work in Progress...</h4>
        </div>
      </AdminLayout>
    );
  }
}

export default Reports

import firebase from '../helpers/Firebase';
import { utils } from '../helpers/utils';
const API_URL = firebase.apiUrl;
//const API_URL = 'http://localhost:5001/dolphin-21153/northamerica-northeast1';

export const applicationService = {
    getActiveContractors,
    createApplication,
    getApplication,
    getApplicationChangeRequest,
    getApplicationReview,
    updateApplication,
    getFacilitiesConfig,
    getappFlagsConfig,
    getappStatusConfig,
    getipnStatusConfig,
    createFacility,
    getFacility,
    updateFacility,
    getFacilitiesForCustomer,
    getApplicationsForParticipant,
    getMeasureCategories,
    getMeasureTypes,
    getMeasures,
    getMeasureConfig,
    createAppMeasure,
    updateAppMeasure,
    getAppMeasures,
    getAppMeasure,
    deleteAppMeasure,
    createAppDocument,
    deleteAppDocument,
    getAppDocument,
    updateAppDocument,
    getAppDocuments,
    getApplicationsByStage,
    getApplicationsByStageRetrofit,
    getApplicationsToReview,
    getPostProjectApplicationsReview,
    assignApplicationToReviewer,
    createApplicationReview,
    createPostProjectApplicationReview,
    getAppStatusLogs,
    getAppPortLogs,
    getApplicationChangeRequestLogs,
    getApplicationChangeRequests,
    searchChangeRequests,
    updateChangeRequest,
    getAppNotes,
    createNotes,
    flagApplication,
    getFlagLogs,
    getMeasureLogs,
    getMeasureDetailLogs,
    approveQAQC,
    getApplicationsByStatus,
    getApplicationsTotalIncentive,
    assignApplicationsForPaymentProcess,
    getIPNSByStatus,
    getIPN,
    getIPNForContactsExport,
    getCIBCTransactionExport,
    updateIPN,
    createAppReviewDocument,
    updateAppReviewDocument,
    deleteAppReviewDocument,
    getAppReviewDocuments,
    getAppReviewDocumentsLogs,
    getAppBankDetails,
    createAppBankDetailsReview,
    getChequeExport,
    getBankDraftExport,
    getApplicationsStateLogs,
    getApplicationRetrofit,
    getFacilitiesForCustomerRetrofit,
    getFacilitiesByApplicationIdRetrofit,
    getFacilitiesDetailsRetrofit,
    getApplicationsCommentsByIdRetrofit,
    getParticipantRetrofit,
    getExcelByApplicationAndFacilityIdRetrofit,
    syncApplicationSummaryRetrofit,
    virusScanForFileUploads,
    createRetrofitAppReviewDocument,
    getRetrofitAppReviewDocuments,
    getRetrofitAppReviewDocumentsLogs,
    deleteRetrofitAppReviewDocument,
    updateRetrofitAppReviewDocument,
    getRetrofitAppNotes,
    createRetrofitNotes,
    getParticipantApplicationsRetrofit,
    getApplicationsToReviewRetrofit,
    assignRetrofitApplicationToReviewer,
    assignRetrofitApplicationToSecondaryReviewer,
    getRetrofitFlagLogs,
    completePrimaryReview,
    completeSecondaryReview,
    retrofitCompleteReview,
    retrofitInvoiceReview,
    postRetrofitApplicationReviewState,
    getRetrofitApplicationReviewState,
    getRetrofitApplicationsReviewProgress,
    postRetrofitApplicationsReviewProgress,
    createRetrofitReviewProcessDocument,
    updateRetrofitReviewProcessDocument,
    deleteRetrofitReviewProcessDocument,
    getRetrofitReviewProcessDocuments,
    getRetrofitReviewProcessDocumentsLogs,
    getRetrofitApplicationChecklist,
    getRetrofitChecklistDocumentByFacilityId,
    getRetrofitFlagStatus,
    getNameOnBankAccount,
    getCESIncentiveBonusCalculations
};

function virusScanForFileUploads(fileObj, folderPath){
    return utils.handleFileUpload(fileObj, folderPath);
}

function getFacilitiesConfig(){
    return utils.getAdminConfigDoc('facilityConfig');
}
function getappFlagsConfig(){
    return utils.getAdminConfigDoc('flagsConfig');
}
function getappStatusConfig(){
    return utils.getAdminConfigDoc('appStatuses');
}
function getipnStatusConfig(){
    return utils.getAdminConfigDoc('ipnStatuses');
}
function getFacilitiesForCustomer(customerId){
    return utils.makeWebRequestForApplications(`${API_URL}/facilities/all/customer/${customerId}`,"GET");
}

function getMeasureCategories(){
    return utils.getAdminConfigDoc('measureCategories');
}

function getMeasureTypes(measureCategory){
    return utils.makeWebRequestForApplications(`${API_URL}/measures/types/${measureCategory}`,"GET");
}

function getMeasures(measureType){
    return utils.makeWebRequestForApplications(`${API_URL}/measures/measurecodes/${measureType}`,"GET");
}

function getMeasureConfig(stage, measureCode,currentProgram){
    return utils.makeWebRequestForApplications(`${API_URL}/measures${currentProgram === "speed"? "/spp" : "" }/${(stage === 'Post-Project' || stage === 'Payment') ? 'postproject' :''}measureconfig/${measureCode}`,"GET");
}

function getActiveContractors(){
    return utils.makeWebRequestForApplications(`${API_URL}/users/all/contractors/active`,"GET");
}

function createApplication(data){
    return utils.makeWebRequestForApplications(`${API_URL}/applications`,"POST", data);
}

function getApplication(appId){
    return utils.makeWebRequestForApplications(`${API_URL}/applications/${appId}`,"GET");
}
function getApplicationRetrofit(appId){
    return utils.makeWebRequestForApplications(`${API_URL}/retrofit/applications/${appId}`,"GET");
}

function getFacilitiesByApplicationIdRetrofit(appId){
    return utils.makeWebRequestForApplications(`${API_URL}/retrofit/facilities/applications/${appId}`,"GET");
}

function syncApplicationSummaryRetrofit(appId){
    return utils.makeWebRequestForApplications(`${API_URL}/retrofit/sync/application/${appId}`,"GET");
}

function getParticipantRetrofit(participantId){
    return utils.makeWebRequestForApplications(`${API_URL}/retrofit/representative/${participantId}`,"GET");
}

function getExcelByApplicationAndFacilityIdRetrofit(appId,facilityId){
    return utils.makeWebRequestFileDownload(`${API_URL}/excel/application/${appId}/facility/${facilityId}`,"GET");
}

function getRetrofitChecklistDocumentByFacilityId(stage, appId, facilityId){
    return utils.makeWebRequestForApplications(`${API_URL}/retrofit/checklist/${stage}/${appId}/${facilityId}`,"GET");
}

function getRetrofitFlagStatus(appId, stage){
    return utils.makeWebRequestForApplications(`${API_URL}/retrofit/previousflagstatus/${appId}/${stage}`,"GET");
}

function getApplicationsTotalIncentive(){
    return utils.makeWebRequestForApplications(`${API_URL}/applications/totalincentive/sum`,"GET");
}

function getApplicationChangeRequest(changeReqId){
    return utils.makeWebRequestForApplications(`${API_URL}/applications/changerequests/${changeReqId}`,"GET");
}

function getApplicationsForParticipant(participantType, participantId){
    return utils.makeWebRequestForApplications(`${API_URL}/applications/all/${participantType}/${participantId}`,"GET");
}

function getParticipantApplicationsRetrofit(personId, searchTerm = "", offset = 0){
    let filterObj = {
        "searchTerm": searchTerm,
        "filters": {
        },
        "offset": offset,
        "size": 40
    };

    filterObj.filters['applicant_person_id'] = [personId];
    return utils.makeWebRequest(`${API_URL}/searchIESO/retrofit_applications`, "POST", filterObj);
}

function getNameOnBankAccount(currentProgram){
    return utils.makeWebRequest(`${API_URL}/ipn/bankdetails/nameonaccount/${currentProgram}`,"GET");
}

function searchChangeRequests(searchTerm = "", config={statuses:[],requestTypes:[], stream:[]}, offset = 0){
    // console.log('Config=>', config);
    let filterObj = {
        "searchTerm" : searchTerm,
        "filters"  : {
        },
        "offset" : offset,
        "size" : 40
    };
    if(config.statuses && config.statuses.length){
        filterObj.filters["status"] = config.statuses;
    }
    if(config.requestTypes && config.requestTypes.length){
        filterObj.filters["requestType"] = config.requestTypes;
    }
    if(config.program && config.program.length){
        filterObj.filters["program"] = [config.program];
    }
    // if(config.stream && config.stream.length){
    //     filterObj.filters["stream"] = [];
    // }
    if(config.sort){
        filterObj.sort = config.sort;
    }
    return utils.makeWebRequestForApplications(`${API_URL}/search/changerequests`,"POST",filterObj);
}

function getApplicationsByStage(stage, searchTerm = "", config, offset = 0){
    let filterObj = {
        "searchTerm" : searchTerm,
        "filters"  : {
            "stage" : [stage]
        },
        "offset" : offset,
        "size" : 40
    };
    if(stage === "Pre-Project" || stage === "Post-Project" || stage === "Pre-Approval") {
        filterObj.filters["isActive"] = config.activeApps;
    }
    if(config.statuses && config.statuses.length){
        filterObj.filters["status"] = config.statuses;
    }
    if(config.flags && config.flags.length){
        filterObj.filters["flag.status"] = config.flags;
    }
    if(config.program && config.program.length){
        filterObj.filters["program"] = [config.program];
    }
    if(config.stream && config.stream.length){
        filterObj.filters["stream"] = config.stream;
    }
    if(config.sort){
        filterObj.sort = config.sort;
    }
    return utils.makeWebRequestForApplications(`${API_URL}/search/applications`,"POST",filterObj);
}

function getApplicationsByStageRetrofit(stage, searchTerm = "", config, offset = 0){
    let filterObj = {
        "searchTerm" : searchTerm,
        "filters"  : {},
        "offset" : offset,
        "size" : 40
    };
    if (config.statuses && config.statuses.length) {
        filterObj.filters["application_status_name"] = config.statuses;
    }
    if (config.stage && config.stage.length) {
        filterObj.filters["application_status_id"] = config.stage;
    }
    if(config.flags && config.flags.length){
        filterObj.filters["flag.status"] = config.flags;
    }
    if(config.sort){
        filterObj.sort = config.sort;
    }
    return utils.makeWebRequestForApplications(`${API_URL}/searchIESO/retrofit_applications`,"POST",filterObj);
}

function getApplicationsByStatus( searchTerm = "", config, offset = 0, size = 40){
    let filterObj = {
        "searchTerm" : searchTerm,
        "filters"  : {
           
        },
        "offset" : offset,
        "size" : size
    };
    if(config.statuses && config.statuses.length){
        filterObj.filters["status"] = config.statuses;
    }
    if(config.labels && config.labels.length){
        filterObj.labels = config.labels;
    }
    if(config.sort){
        filterObj.sort = config.sort;
    }
    if(config.dateRange){
        filterObj.dateRange = config.dateRange;
    }
    if(config.program && config.program.length){
        filterObj.filters["program"] = [config.program];
    }
    if(config.stream && config.stream.length){
        filterObj.filters["stream"] = config.stream;
    }
    return utils.makeWebRequestForApplications(`${API_URL}/search/applications`,"POST",filterObj);
}


function getIPNSByStatus( searchTerm = "", config, offset = 0){

    let filterObj = {
         "searchTerm" : searchTerm,
          "filters"  : {
          
        },
         "offset" : offset,
         "size" : 40
     };
     if(config.statuses && config.statuses.length){
         filterObj.filters["status"] = config.statuses;
     }
 
     if(config.sort){
         filterObj.sort = config.sort;
     }
     if(config.dateRange){
         filterObj.dateRange = config.dateRange;
     }
     if(config.program && config.program.length){
        filterObj.filters["program"] = [config.program];
    }
     return utils.makeWebRequestForApplications(`${API_URL}/search/incentivepaymentnotes`,"POST",filterObj);
 } 

function getIPN(ipnId){
    return utils.makeWebRequestForApplications(`${API_URL}/ipn/${ipnId}`,"GET");
}

function getIPNForContactsExport(ipnId){
    return utils.makeWebRequestForApplications(`${API_URL}/ipn/contacts/export/${ipnId}`,"GET");
}

function getCIBCTransactionExport(ipnId){
    return utils.makeWebRequestForApplications(`${API_URL}/ipn/cibc/export/${ipnId}`,"GET");
}

function getChequeExport(ipnId){
    return utils.makeWebRequestForApplications(`${API_URL}/ipn/cheque/export/${ipnId}`,"GET");
}

function getBankDraftExport(ipnId){
    return utils.makeWebRequestForApplications(`${API_URL}/ipn/bankdraft/export/${ipnId}`,"GET");
}

function getApplicationsToReview(stage, searchTerm = "", config, offset = 0){
    let filterObj = {
        "searchTerm" : searchTerm,
        "filters"  : {
            "stage" : [stage],
            "status" : ['Application Draft Under Review','Application Information Requested','Application Under Review','QA/QC In Progress','Banking Details In Review'],
            [stage === "Post-Project" ? "postProjectReviewer.value" : stage === "Payment" ? "paymentReviewer.value" : "reviewer.value"] : [config.reviewerId],
        },
        "offset" : offset,
        "size" : 40
    };
    if(stage === "Pre-Project" || stage === "Post-Project") {
        filterObj.filters["isActive"] = config.activeApps;
    }
    if(config.statuses && config.statuses.length){
        filterObj.filters["status"] = config.statuses;
    }
    if(config.flags && config.flags.length){
        filterObj.filters["flag.status"] = config.flags;
    }
    if(config.program && config.program.length){
        filterObj.filters["program"] = [config.program];
    }
    if(config.stream && config.stream.length){
        filterObj.filters["stream"] = config.stream;
    }
    if(config.sort){
        filterObj.sort = config.sort;
    }
    return utils.makeWebRequestForApplications(`${API_URL}/search/applications`,"POST",filterObj);
}

function getApplicationsToReviewRetrofit(stage, searchTerm = "", config, offset = 0){
    let validFlagStatuses = ["New Assigned Pre-Project Application", "New Assigned Post-Project Application", "QA-QC - Mandatory", "QA-QC - Random Selection", "Peer Review Assigned", "Information Request (IR)", "Return Application for Edits (IR)", "Enerva Guidance", "Awaiting IESO Exception Approval", "Reject Application", "Cancelled Application", "Withdrawn Application", "Peer Review Approved", "Peer Review Rejected", "Invoice Assigned for Review", "Return Invoice For Edits", "Approve Invoice"];
    let filterObj = {
        "searchTerm" : searchTerm,
        "filters"  : {
            "stage" : [stage],
            "flag.status" : validFlagStatuses,
        },
        "offset" : offset,
        "size" : 200
    };
    if(config.statuses && config.statuses.length){
        filterObj.filters["application_status_name"] = config.statuses;
    }
    if (config.stage && config.stage.length) {
        filterObj.filters["application_status_id"] = config.stage;
    }
    if(config.flags && config.flags.length){
        let flagsToShow = config.flags.filter((flag) => validFlagStatuses.includes(flag));
        filterObj.filters["flag.status"] = flagsToShow;
    }
    if(config.sort){
        filterObj.sort = config.sort;
    }
    return utils.makeWebRequestForApplications(`${API_URL}/searchIESO/retrofit_applications`,"POST",filterObj);
}

function updateApplication(appId, data){
    return utils.makeWebRequestForApplications(`${API_URL}/applications/${appId}`,"PUT", data);
}
function updateIPN(ipnId, data){
    return utils.makeWebRequestForApplications(`${API_URL}/ipn/${ipnId}`,"PUT", data);
}

function createFacility(data){
    return utils.makeWebRequestForApplications(`${API_URL}/facilities`,"POST", data);
}

function getFacility(facilityId){
    return utils.makeWebRequestForApplications(`${API_URL}/facilities/${facilityId}`,"GET");
}

function getFacilitiesForCustomerRetrofit(facilityId){
    return utils.makeWebRequestForApplications(`${API_URL}/retrofit/facilities/${facilityId}`,"GET");
}

function getFacilitiesDetailsRetrofit(appId,facilityId){
    return utils.makeWebRequestForApplications(`${API_URL}/retrofit/applications/${appId}/facilitiesdetails/${facilityId}`,"GET");
}

function getApplicationsCommentsByIdRetrofit(appId){
    return utils.makeWebRequestForApplications(`${API_URL}/retrofit/comments/applications/${appId}`,"GET");
}

function updateFacility(facilityId, data){
    return utils.makeWebRequestForApplications(`${API_URL}/facilities/${facilityId}`,"PUT", data);
}
function flagApplication(data){
    return utils.makeWebRequestForApplications(`${API_URL}/applications/flagapplication`,"POST", data);
}
function createAppMeasure(data){
    return utils.makeWebRequestForApplications(`${API_URL}/applications/measure`,"POST", data);
}
function createNotes(data){
    return utils.makeWebRequestForApplications(`${API_URL}/applications/notes`,"POST", data);
}

function createRetrofitNotes(data){
    return utils.makeWebRequestForApplications(`${API_URL}/retrofit/notes`,"POST", data);
}

function updateAppMeasure(measureId, data){
    return utils.makeWebRequestForApplications(`${API_URL}/applications/measure/${measureId}`,"PUT", data);
}

function getAppMeasure(measureId){
    return utils.makeWebRequestForApplications(`${API_URL}/applications/measure/${measureId}`,"GET");
}

function deleteAppMeasure(measureId){
    return utils.makeWebRequestForApplications(`${API_URL}/applications/measure/${measureId}`,"DELETE");
}

function getAppMeasures(applicationId){
    return utils.makeWebRequestForApplications(`${API_URL}/applications/measure/all/${applicationId}`,"GET");
}

function createAppDocument(data){
    return utils.makeWebRequestForApplications(`${API_URL}/applications/documents`,"POST", data);
}

function updateAppDocument(documentId, data){
    return utils.makeWebRequestForApplications(`${API_URL}/applications/documents/${documentId}`,"PUT", data);
}

function getAppDocument(documentId){
    return utils.makeWebRequestForApplications(`${API_URL}/applications/documents/${documentId}`,"GET");
}

function deleteAppDocument(documentId){
    return utils.makeWebRequestForApplications(`${API_URL}/applications/documents/${documentId}`,"DELETE");
}

//APPLICATION BANK DETAILS
function getAppBankDetails(applicationId){
    return utils.makeWebRequestForApplications(`${API_URL}/applications/bankdetails/${applicationId}`,"GET");
}

function createAppBankDetailsReview(applicationId, appReviewObj){
    return utils.makeWebRequestForApplications(`${API_URL}/applications/bankdetailsreview/${applicationId}`,"POST", appReviewObj);
}

//APPLICATION REVIEW DOCUMENT
function createAppReviewDocument(data){
    return utils.makeWebRequestForApplications(`${API_URL}/applications/reviewdocuments`,"POST", data);
}

function updateAppReviewDocument(documentId, data){
    return utils.makeWebRequestForApplications(`${API_URL}/applications/reviewdocuments/${documentId}`,"PUT", data);
}

function deleteAppReviewDocument(documentId){
    return utils.makeWebRequestForApplications(`${API_URL}/applications/reviewdocuments/${documentId}`,"DELETE");
}

function getAppReviewDocuments(applicationId){
    return utils.makeWebRequestForApplications(`${API_URL}/applications/reviewdocuments/all/${applicationId}`,"GET");
}

function getAppReviewDocumentsLogs(applicationId){
    return utils.makeWebRequestForApplications(`${API_URL}/logging/application/reviewdocuments/${applicationId}`,"GET");
}

//RETROFIT APPLICATION REVIEW DOCUMENT
function createRetrofitAppReviewDocument(data){
    return utils.makeWebRequestForApplications(`${API_URL}/retrofit/reviewdocuments`,"POST", data);
}

function updateRetrofitAppReviewDocument(documentId, data){
    return utils.makeWebRequestForApplications(`${API_URL}/retrofit/reviewdocuments/${documentId}`,"PUT", data);
}

function deleteRetrofitAppReviewDocument(documentId){
    return utils.makeWebRequestForApplications(`${API_URL}/retrofit/reviewdocuments/${documentId}`,"DELETE");
}

function getRetrofitAppReviewDocuments(application_id){
    return utils.makeWebRequestForApplications(`${API_URL}/retrofit/reviewdocuments/all/${application_id}`,"GET");
}

function getRetrofitAppReviewDocumentsLogs(application_id){
    return utils.makeWebRequestForApplications(`${API_URL}/retrofit/logging/reviewdocuments/${application_id}`,"GET");
}

function assignApplicationToReviewer(data){
    return utils.makeWebRequestForApplications(`${API_URL}/applications/assignreviewer`,"POST", data);
}

function assignRetrofitApplicationToReviewer(data){
    return utils.makeWebRequestForApplications(`${API_URL}/retrofit/assignreviewer`,"POST", data);
}

function assignRetrofitApplicationToSecondaryReviewer(data){
    return utils.makeWebRequestForApplications(`${API_URL}/retrofit/assignsecondaryreviewer`,"POST", data);
}

function completePrimaryReview(data){
    return utils.makeWebRequestForApplications(`${API_URL}/retrofit/completereview`,"POST", data);
}

function completeSecondaryReview(data){
    return utils.makeWebRequestForApplications(`${API_URL}/retrofit/completesecondaryreview`,"POST", data);
}

function createApplicationReview(applicationId, appReviewObj){
    return utils.makeWebRequestForApplications(`${API_URL}/applications/review/${applicationId}`,"POST", appReviewObj);
}
function createPostProjectApplicationReview(applicationId, appReviewObj){
    return utils.makeWebRequestForApplications(`${API_URL}/applications/postprojectreview/${applicationId}`,"POST", appReviewObj);
}

function approveQAQC(data){
    return utils.makeWebRequestForApplications(`${API_URL}/applications/approveqaqc`,"POST", data);
}

function assignApplicationsForPaymentProcess(data){
    return utils.makeWebRequestForApplications(`${API_URL}/applications/applicationsforpaymentprocessing`,"POST", data);
}

function getAppDocuments(applicationId){
    return utils.makeWebRequestForApplications(`${API_URL}/applications/documents/all/${applicationId}`,"GET");
}

function getAppStatusLogs(applicationId){
    return utils.makeWebRequestForApplications(`${API_URL}/logging/application/status/${applicationId}`,"GET");
}

function getAppPortLogs(applicationId){
    return utils.makeWebRequestForApplications(`${API_URL}/logging/application/status/port/${applicationId}`,"GET");
}

function getApplicationsStateLogs(applicationId){
    // console.log("App Services",applicationId);
    return utils.makeWebRequestForApplications(`${API_URL}/logging/application/state/${applicationId}`,"GET");
}

function getApplicationChangeRequests(applicationId){
    return utils.makeWebRequestForApplications(`${API_URL}/applications/changerequests/all/${applicationId}`,"GET");
}

function getCESIncentiveBonusCalculations(appId, stage, data){
    return utils.makeWebRequestForApplications(`${API_URL}/applications/ces/calculate/incentive/${appId}/${stage}`,"POST", data);
}

function getApplicationChangeRequestLogs(applicationId){
    return utils.makeWebRequestForApplications(`${API_URL}/logging/application/changerequests/${applicationId}`,"GET");
}

function getAppNotes(applicationId){
    return utils.makeWebRequestForApplications(`${API_URL}/applications/notes/all/${applicationId}`,"GET");
}

function getRetrofitAppNotes(applicationId){
    return utils.makeWebRequestForApplications(`${API_URL}/retrofit/notes/all/${applicationId}`,"GET");
}

function getFlagLogs(applicationId){
    return utils.makeWebRequestForApplications(`${API_URL}/logging/application/flags/${applicationId}`,"GET");
}

function getRetrofitFlagLogs(applicationId){
    return utils.makeWebRequestForApplications(`${API_URL}/retrofit/logging/application/flags/${applicationId}`,"GET");
}

function getMeasureLogs(applicationId){
    return utils.makeWebRequestForApplications(`${API_URL}/logging/application/measures/${applicationId}`,"GET");
}

function getMeasureDetailLogs(measureId){
    return utils.makeWebRequestForApplications(`${API_URL}/logging/application/detail/measures/${measureId}`,"GET");
}

function getApplicationReview(appId){
    return utils.makeWebRequestForApplications(`${API_URL}/applications/review/${appId}`,"GET");
}
function getPostProjectApplicationsReview(appId){
    return utils.makeWebRequestForApplications(`${API_URL}/applications/postprojectreview/${appId}`,"GET");
}

function updateChangeRequest(id, data){
    return utils.makeWebRequestForApplications(`${API_URL}/applications/changerequests/${id}`,"PUT", data);
}

function retrofitCompleteReview(applicationId,data){
    return utils.makeWebRequestForApplications(`${API_URL}/retrofit/review/${applicationId}`,"POST", data);
}

function retrofitInvoiceReview(applicationId,data){
    return utils.makeWebRequestForApplications(`${API_URL}/retrofit/invoicereview/${applicationId}`,"POST", data);
}

function postRetrofitApplicationReviewState(reviewerId,data){
    return utils.makeWebRequestForApplications(`${API_URL}/retrofit/reviewstate/${reviewerId}`,"POST", data);
}

function getRetrofitApplicationReviewState(reviewerId){
    return utils.makeWebRequestForApplications(`${API_URL}/retrofit/reviewstate/${reviewerId}`,"GET");
}

function getRetrofitApplicationsReviewProgress(applicationId){
    return utils.makeWebRequestForApplications(`${API_URL}/retrofit/reviewprogress/${applicationId}`,"GET");
}

function postRetrofitApplicationsReviewProgress(applicationId, data){
    return utils.makeWebRequestForApplications(`${API_URL}/retrofit/reviewprogress/${applicationId}`,"POST", data);
}

function getRetrofitApplicationChecklist(applicationId){
    return utils.makeWebRequestForApplications(`${API_URL}/retrofit/checklist/${applicationId}`,"GET");
}

//RETROFIT REVIEW PROCESS DOCUMENT UPLOADS APIs
function createRetrofitReviewProcessDocument(data){
    return utils.makeWebRequestForApplications(`${API_URL}/retrofit/reviewdocuments/progress`,"POST", data);
}

function updateRetrofitReviewProcessDocument(documentId, data){
    return utils.makeWebRequestForApplications(`${API_URL}/retrofit/reviewdocuments/progress/${documentId}`,"PUT", data);
}

function deleteRetrofitReviewProcessDocument(documentId){
    return utils.makeWebRequestForApplications(`${API_URL}/retrofit/reviewdocuments/progress/${documentId}`,"DELETE");
}

function getRetrofitReviewProcessDocuments(facilityId){
    return utils.makeWebRequestForApplications(`${API_URL}/retrofit/reviewdocuments/progress/all/${facilityId}`,"GET");
}

function getRetrofitReviewProcessDocumentsLogs(facilityId){
    return utils.makeWebRequestForApplications(`${API_URL}/retrofit/logging/reviewprocessdocuments/${facilityId}`,"GET");
}
import React from 'react';
import { AuthContext } from '../../../../firebaseAuthContext';
import Button from '../../../Common/Button/Button';
import './NetworkError.css';
import ProgramLayout from '../../../Common/ProgramLayout';

class NetworkError extends React.Component {
  static contextType = AuthContext;
  constructor(props) {
    super(props);
    this.state = {
    }
  }



  render() {
    return (
      <ProgramLayout showFooter={false} showSideBarMenu={false} props={this.props}>
        <div className="notfound-container">
          {/* <div className="logo"/> */}
          {/* <img src={BrokenLink} alt='broken link'/> */}
          <svg xmlns="http://www.w3.org/2000/svg" width="274.852" height="98.617" viewBox="0 0 274.852 98.617">
            <g
              id="_404-broken-wire"
              data-name="404-broken-wire"
              transform="matrix(0.985, -0.174, 0.174, 0.985, 12.253, -126.945)"
              opacity="0.75"
            >
              <g id="Group_1348" data-name="Group 1348" transform="translate(51.932 172.046)">
                <g id="Group_1347" data-name="Group 1347">
                  <path
                    id="Path_528"
                    data-name="Path 528"
                    d="M159.48,193.192a2.745,2.745,0,0,0-3.882,0l-6.224,6.224a6.016,6.016,0,0,1-4.283,1.774,2.745,2.745,0,1,0,0,5.49,11.472,11.472,0,0,0,8.165-3.382l6.224-6.224A2.745,2.745,0,0,0,159.48,193.192Z"
                    transform="translate(-142.345 -192.388)"
                    fill="#048041"
                  />
                </g>
              </g>
              <g id="Group_1350" data-name="Group 1350" transform="translate(0 171.997)">
                <g id="Group_1349" data-name="Group 1349">
                  <path
                    id="Path_529"
                    data-name="Path 529"
                    d="M78.039,222.534l-6.224-6.225a11.471,11.471,0,0,0-8.165-3.382H39.172l1.067-1.6a2.745,2.745,0,0,0,.32-2.391l-.751-2.255h4.458a2.745,2.745,0,0,0,0-5.49H37.978l-.223-.668,4.657-3.1a2.745,2.745,0,0,0-3.045-4.568l-6.584,4.39a6.038,6.038,0,0,1-3.36,1.018l-69.447,1.267a2.745,2.745,0,0,0-2.745,2.745,2.745,2.745,0,0,0,2.745,2.745l69.446-1.267a11.532,11.532,0,0,0,3.445-.527l2.06,6.179L29.8,217.084a2.745,2.745,0,0,0-.171,2.75l3.882,7.763-73.537,1.267a2.745,2.745,0,0,0-2.745,2.745,2.745,2.745,0,0,0,2.745,2.745l75.549-1.267a3.1,3.1,0,0,1,2.208.915l4.149,4.149a2.745,2.745,0,0,0,3.882-3.882l-4.116-4.116h7.331a6.018,6.018,0,0,1,4.283,1.774l9.158,9.158A2.745,2.745,0,0,0,66.3,237.2l-9.158-9.158a11.471,11.471,0,0,0-8.165-3.382H38.184l-2.93-5.86.257-.386H63.65a6.018,6.018,0,0,1,4.283,1.774l6.224,6.224a2.745,2.745,0,0,0,3.882-3.882Z"
                    transform="translate(0 -192.388)"
                    fill="#048041"
                  />
                </g>
              </g>
              <g id="Group_1352" data-name="Group 1352" transform="translate(108.277 169.124)">
                <g id="Group_1351" data-name="Group 1351">
                  <path
                    id="Path_530"
                    data-name="Path 530"
                    d="M413.507,222.016l-66.752.5a11.537,11.537,0,0,0-3.445.527l-2.06-6.179,5.124-7.685a2.745,2.745,0,0,0,.171-2.75l-3.882-7.763,71.748-.108a2.745,2.745,0,1,0,0-5.49l-73.759.108a3.1,3.1,0,0,1-2.208-.915l-4.149-4.149a2.745,2.745,0,1,0-3.882,3.882l4.116,4.116H327.2a6.018,6.018,0,0,1-4.283-1.774l-9.158-9.158a2.745,2.745,0,1,0-3.882,3.882l9.158,9.158A11.472,11.472,0,0,0,327.2,201.6h10.795l2.93,5.86-.257.386H312.527a6.018,6.018,0,0,1-4.283-1.774l-6.224-6.224a2.745,2.745,0,1,0-3.882,3.882l6.224,6.224a11.471,11.471,0,0,0,8.165,3.382h24.478l-1.067,1.6a2.745,2.745,0,0,0-.32,2.391l.751,2.255h-4.8a2.745,2.745,0,1,0,0,5.49H338.2l.223.668-4.657,3.1a2.745,2.745,0,1,0,3.046,4.568l6.584-4.39a6.038,6.038,0,0,1,3.36-1.018l66.751-.5a2.745,2.745,0,1,0,0-5.49Z"
                    transform="translate(-297.333 -184.371)"
                    fill="#048041"
                  />
                </g>
              </g>
              <g id="Group_1354" data-name="Group 1354" transform="translate(117.249 204.286)">
                <g id="Group_1353" data-name="Group 1353">
                  <path
                    id="Path_531"
                    data-name="Path 531"
                    d="M336.574,280.565a11.472,11.472,0,0,0-8.165,3.382l-6.224,6.224a2.745,2.745,0,1,0,3.882,3.882l6.224-6.224a6.018,6.018,0,0,1,4.283-1.774,2.745,2.745,0,0,0,0-5.49Z"
                    transform="translate(-321.38 -280.565)"
                    fill="#048041"
                  />
                </g>
              </g>
              <g id="Group_1356" data-name="Group 1356" transform="translate(80.638 160.321)">
                <g id="Group_1355" data-name="Group 1355">
                  <path
                    id="Path_532"
                    data-name="Path 532"
                    d="M245.635,193.586a5.563,5.563,0,0,0-4.552-2.367H226.62a.066.066,0,0,1-.062-.089L236.432,164a2.745,2.745,0,0,0-5.16-1.875L221.4,189.256a5.552,5.552,0,0,0,5.222,7.449h14.463a.052.052,0,0,1,.054.028.052.052,0,0,1,.008.061l-6.89,18.931-.163-1.087a2.745,2.745,0,0,0-5.43.812l1.875,12.516a2.746,2.746,0,0,0,4.683,1.506l8.829-9.074a2.744,2.744,0,0,0-3.936-3.824l-.52.534,6.711-18.44A5.553,5.553,0,0,0,245.635,193.586Z"
                    transform="translate(-221.064 -160.321)"
                    fill="#048041"
                  />
                </g>
              </g>
            </g>
          </svg>
          <h2> Your connection seems to be broken.</h2>
          <p>Please make sure you are connected to the internet and try again.</p>
          <Button title={"Refresh Page"} uppercase onClick={() => window.location.reload(false)} />
        </div>
      </ProgramLayout>
    );
  }
}

export default NetworkError;


import ProgramHeader from './ProgramHeader/ProgramHeader';
import ProgramFooter from './ProgramFooter/ProgramFooter';
import ProgramSideBarMenu from './ProgramSideBarMenu/ProgramSideBarMenu'
import React from 'react'

function ProgramLayout(props) {
   let showHeader = true, showFooter = true, showSideBarMenu = true;
   if (typeof props.showHeader !== 'undefined') { showHeader = props.showHeader; }
   if (typeof props.showFooter !== 'undefined') { showFooter = props.showFooter; }
   if (typeof props.showSideBarMenu !== 'undefined') { showSideBarMenu = props.showSideBarMenu; }
   document.title =`${props.props ? props.props.currentProgram =='retrofit' ? 'Dolphin - Retrofit(IESO)' : props.props.currentProgram =='speed' ? 'Dolphin - SPEED' : 'Dolphin - ESB' : ''}`;
   return (
      <React.Fragment>
         {showHeader ? <ProgramHeader history={props.history} /> : null}
         <div className='program-dashboard-container' id='AdminDashboard'>
            {showSideBarMenu ? <ProgramSideBarMenu history={props.history} /> : null}
            <div className="program-dashboard">
               {props.children}
            </div>
         </div>
         {showFooter ? <ProgramFooter /> : null}
      </React.Fragment>
   );
}

export default ProgramLayout;
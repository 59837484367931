import React, { useState, useEffect, useRef } from "react";
import "./CardsSlider.css"; // Create a CSS file for styling
import ArrowForwardIosRoundedIcon from "@material-ui/icons/ArrowForwardIosRounded";
import ArrowBackIosRoundedIcon from "@material-ui/icons/ArrowBackIosRounded";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const CardSlider = ({ cards }) => {
  return (
    <Carousel
      additionalTransfrom={0}
      arrows
      autoPlaySpeed={3000}
      centerMode={false}
      className=""
      containerClass="container"
      dotListClass=""
      draggable
      focusOnSelect={false}
      infinite={false}
      itemClass=""
      keyBoardControl
      minimumTouchDrag={80}
      pauseOnHover
      renderArrowsWhenDisabled={false}
      renderButtonGroupOutside={false}
      renderDotsOutside={false}
      responsive={{
        superLargeDesktop: {
          // the naming can be any, depends on you.
          breakpoint: { max: 4000, min: 2000 },
          items: 7,
        },
        desktop: {
          breakpoint: {
            max: 2000,
            min: 1416,
          },
          items: 5,
          partialVisibilityGutter: 40,
        },
        tablet: {
          breakpoint: {
            max: 1416,
            min: 940,
          },
          items: 4,
          partialVisibilityGutter: 30,
        },
        mobile: {
          breakpoint: {
            max: 940,
            min: 580,
          },
          items: 3,
          partialVisibilityGutter: 30,
        },
        smallMobile: {
          breakpoint: {
            max: 580,
            min: 464,
          },
          items: 2,
          partialVisibilityGutter: 30,
        },
        xtraSmallMobile: {
          breakpoint: {
            max: 464,
            min: 0,
          },
          items: 1,
          partialVisibilityGutter: 30,
        },
        
      }}
      // customLeftArrow={<ArrowBackIosRoundedIcon  style={{ color: "#048041" }}  fontSize="large" />}
      // customRightArrow={<ArrowForwardIosRoundedIcon style={{ color: "#048041" }} fontSize="large" />}
      rewind={false}
      rewindWithAnimation={false}
      rtl={false}
      shouldResetAutoplay
      showDots={false}
      sliderClass=""
      slidesToSlide={1}
      swipeable
    >
      {cards.map((card, index) => (
        <div key={index} className="cards">
          {card}
        </div>
      ))}
    </Carousel>
  );
  //   const [showPrevButton, setShowPrevButton] = useState(false);
  //   const [showNextButton, setShowNextButton] = useState(false);
  //   const sliderRef = useRef(null);

  //   const scrollLeft = () => {
  //     if (sliderRef.current) {
  //       const cardWidth = sliderRef.current.querySelector('.cards').offsetWidth;
  //       sliderRef.current.scrollLeft -= cardWidth;
  //       console.log("scroll left ------------->",cardWidth, sliderRef.current.scrollLeft)
  //     }
  //   };

  //   const scrollRight = () => {
  //     if (sliderRef.current) {
  //       const cardWidth = sliderRef.current.querySelector('.cards').offsetWidth;
  //       sliderRef.current.scrollLeft += cardWidth;
  //       console.log("scroll right ------------->",cardWidth, sliderRef.current.scrollLeft)
  //     }
  //   };

  //   useEffect(() => {
  //     const updateButtons = () => {
  //       const container = sliderRef.current;
  //       console.log("widths --------->", container.scrollWidth, container.offsetWidth, container.scrollLeft, window.innerWidth, container.clientWidth)
  //       if (container) {
  //         if(container.scrollLeft === 0 && (container.offsetWidth < 700 || window.innerWidth < container.offsetWidth + 420 )){
  //           setShowPrevButton(false)
  //           setShowNextButton(true);
  //         } else if(container.scrollLeft > 0 && container.scrollLeft + container.offsetWidth < container.scrollWidth){
  //           setShowPrevButton(true)
  //           setShowNextButton(true);
  //         } else if(container.scrollLeft > 0 && container.scrollLeft + container.offsetWidth > container.scrollWidth) {
  //           setShowPrevButton(true)
  //           setShowNextButton(false);
  //         }
  //       } else {
  //         setShowPrevButton(false)
  //           setShowNextButton(false);
  //       }
  //     };

  //     const handleResize = () => {
  //       updateButtons();
  //     };

  //     if (sliderRef.current) {
  //       sliderRef.current.addEventListener('scroll', updateButtons);
  //       window.addEventListener('resize', handleResize);
  //       handleResize(); // Initial update
  //     }

  //     return () => {
  //       if (sliderRef.current) {
  //         sliderRef.current.removeEventListener('scroll', updateButtons);
  //         window.removeEventListener('resize', handleResize);
  //       }
  //     };
  //   }, []);

  //   return (
  //     <div className="card-slider-container">
  //       {showPrevButton && (
  //         <div className="prev-button" onClick={scrollLeft} >
  //           <ArrowBackIosRoundedIcon  style={{ color: "#048041" }}  fontSize="large" />
  //         </div>
  //       )}
  //       <div className="card-slider" ref={sliderRef}>
  //         {cards.map((card, index) => (
  //           <div key={index} className="cards">
  //             {card}
  //           </div>
  //         ))}
  //       </div>
  //       {showNextButton && (
  //         <div className="next-button" onClick={scrollRight}>
  //           <ArrowForwardIosRoundedIcon style={{ color: "#048041" }} fontSize="large" />
  //         </div>
  //       )}
  //     </div>
  //   );
};

export default CardSlider;

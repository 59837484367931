import React from 'react';
import { connect } from 'react-redux'
import { userActions } from '../../../../../redux/actions/userActions';
import './ContractorReview.css';
import ContractorReviewDetail from './ContractorReviewDetail'
import Input from '../../../../Common/Input/Input';
import Button from '../../../../Common/Button/Button'
import { participantActions } from '../../../../../redux/actions/participantActions';
import { userService } from '../../../../../services/userService';
import { AuthContext } from '../../../../../firebaseAuthContext';
import {utils} from '../../../../../helpers/utils';
import firebase from '../../../../../helpers/Firebase';
import LoaderComponent from '../../../../Common/Loader/Loader';

class ContractorReview extends React.Component {
    static contextType = AuthContext;
    constructor(props) {
        super(props);
        this.state = {
            EditDetailsForReview: true,
            reviewCompleted : false,
            reviewLoading : true,
            reviewExists : false,
            review : {
                reviewStatus : 'Participating',
                reviewNote : '',
                companyProvidedFullLegalName : false,
                companyNameFreeOfNonsense : false,
                companyProvidedWebsite : false,
                websiteReflectsContractorProfile : false,
                searchResultsReflectiveOfContractor : false,
                searchResultsAllegingFraud : false,
                address : false,
                isAddressRealViaMappingSite : false,
                doesAddressMatchFirmsSearchAddress : false,
                isFirmListedWithSESA : false,
                isFirmListedWithCanREA : false 
            }
        }
    }

    async componentDidMount() {
        userService.getUserDetails(firebase.auth.currentUser.uid, (success, userInfo)=>{
            userService.getContractorReview(this.props.selectedContractor.uid).then((data)=>{
                this.setState({
                    review:data,
                    userInfo,
                    reviewExists:true,
                    reviewLoading : false,
                    EditDetailsForReview : false
                });
            }).catch((err)=>{
                this.setState({
                    userInfo,
                    reviewLoading : false
                })
            })
        });
        this.props.getParticipant(this.props.currentProgram, this.props.selectedContractor.uid);
    }

    completeReview = () => {
        this.setState({
            EditDetailsForReview: !this.state.EditDetailsForReview
        })
    }

    confirmReview = ()=>{
        const { userInfo } = this.state;
        const { currentProgram } = this.props;
        let data = {...this.state.review};
        data.reviewer = `${userInfo.firstName} ${userInfo.lastName}`;
        data.program = currentProgram;
        if(this.state.reviewExists){
            userService.updateContractorReview(this.props.selectedContractor.uid,data).then(()=>{
                this.setState({
                    reviewCompleted : true
                },()=>{
                    this.props.getParticipants(this.props.currentProgram, 'contractor');
                })
            });;
        } else {
            userService.createContractorReview(this.props.selectedContractor.uid,data).then(()=>{
                this.setState({
                    reviewCompleted : true
                },()=>{
                    this.props.getParticipants(this.props.currentProgram, 'contractor');
                })
            });;
        }
    }

    handleToggleChange = (name, val)=>{
        this.setState({
            review : {
                ...this.state.review,
                [name] : val
            }
        });
    }

    renderReviewDetails = ()=>{
        const { activeParticipant } = this.props;
        const { EditDetailsForReview, review } = this.state;
        const companyDetails = activeParticipant ? activeParticipant.companyDetails : { licenseReqs : {}};
        const licenseReqs = activeParticipant ? activeParticipant.licenseReqs : {};
        const ContractorReviewDetails = [
            {
                paramName : 'companyProvidedFullLegalName',
                ReviewParameter: 'Did the company provide its Full Legal Name?',
                SharedDetail: companyDetails.name,
                ReviewStatus: review.companyProvidedFullLegalName
            },
            {
                paramName : 'companyNameFreeOfNonsense',
                ReviewParameter : 'Is the company name free of vulgarities and is not nonsense?',
                SharedDetail : '',
                ReviewStatus : review.companyNameFreeOfNonsense
            },
            {
                paramName : 'companyProvidedWebsite',
                ReviewParameter: 'Did the company provide a website?',
                SharedDetail: companyDetails.website,
                ReviewStatus: review.companyProvidedWebsite
            },{
                paramName : 'websiteReflectsContractorProfile',
                ReviewParameter: 'Does the website reflect the contractor profile?',
                SharedDetail: '',
                ReviewStatus: review.websiteReflectsContractorProfile
            },{
                paramName : 'searchResultsReflectiveOfContractor',
                ReviewParameter: 'Are there search results reflective of the contractor?',
                SharedDetail: '',
                ReviewStatus: review.searchResultsReflectiveOfContractor
            },{
                paramName : 'searchResultsAllegingFraud',
                ReviewParameter: 'Are there search results alleging fraud or criminal activity?',
                SharedDetail: '',
                ReviewStatus: review.searchResultsAllegingFraud
            },
            {
                paramName : 'address',
                ReviewParameter: 'Address (headquarters or site)',
                SharedDetail: `${companyDetails.address1}${companyDetails.address2 ? ', ' + companyDetails.address2 : ''}, ${companyDetails.city}, ${companyDetails.province} - ${companyDetails.postalCode}`,
                ReviewStatus: review.address
            },
            {
                paramName : 'isAddressRealViaMappingSite',
                ReviewParameter: 'Is the address real when searched through a mapping site?',
                SharedDetail: '',
                ReviewStatus: review.isAddressRealViaMappingSite
            },
            {
                paramName : 'doesAddressMatchFirmsSearchAddress',
                ReviewParameter: 'Is the address identified as that of the firm from the search result?',
                SharedDetail: '',
                ReviewStatus: review.doesAddressMatchFirmsSearchAddress
            },
            {
                paramName : 'isFirmListedWithSESA',
                ReviewParameter: 'Is the firm listed with Solar Alberta (SESA)?',
                SharedDetail: licenseReqs ? (licenseReqs.memberOfSESA ? 'Yes' : 'No') : 'No',
                ReviewStatus: review.isFirmListedWithSESA
            },
            {
                paramName : 'isFirmListedWithCanREA',
                ReviewParameter: 'Is the firm listed with CanREA?',
                SharedDetail: licenseReqs ? (licenseReqs.memberOfCanREA ? 'Yes' : 'No') : 'No',
                ReviewStatus: review.isFirmListedWithCanREA
            }
        ]

        return  ContractorReviewDetails.map((ReviewDetail, i) => (
            <ContractorReviewDetail disableFields={!this.state.EditDetailsForReview} onChange={this.handleToggleChange} EditDetailsForReview={EditDetailsForReview} ReviewDetail={ReviewDetail} Index={i + 1} />
        ))
    }

    onStatusChange = (val)=>{
        this.setState({
            review : {
                ...this.state.review,
                reviewStatus : val.value
            }
        });
    }

    // handleNotesChange = (e)=>{
    //     const {name, value} = e.target;
    //     this.setState({
    //         review : {
    //         ...this.state.review,
    //         reviewNote : value
    //     }});
    // }
    handleNotesChange = (name, value) => {
        this.setState({
            review : {
            ...this.state.review,
            reviewNote : value
        }});
      }

    render() {
        const { activeParticipant } = this.props;
        const { EditDetailsForReview, review, reviewLoading, reviewCompleted, userInfo } = this.state;
        const {reviewStatus, reviewNote} = review;
        if(reviewLoading){
            return <LoaderComponent></LoaderComponent>;
        }
        return (
            <div>
                <div className="review-container">
                    {activeParticipant ? <React.Fragment>
                    <div className="section-header">
                        <div className="section-top-details">CON-{activeParticipant.userNo}</div>
                        <div className="section-top-details name-section">{activeParticipant.companyDetails.name}</div>
                        <div className="section-top-details">{activeParticipant.email}</div>
                    </div>
                    <div className="section-below-header">
                        <h2 className="section-title" dangerouslySetInnerHTML={{ __html: 'Contractor Review' }}></h2>
                        <div className="review-status-selection">
                            <Input
                                type='dropdown'
                                disabled={!EditDetailsForReview}
                                label={'CHANGE STATUS'}
                                name='accountCategory'
                                onChange={this.onStatusChange}
                                value={{value : reviewStatus, label : reviewStatus}}
                                options={[{ value: 'Participating', label: 'Participating' }, { value: 'Flagged for QA/QC', label: 'Flagged for QA/QC' },{ value: 'Suspended', label: 'Suspended' }]}
                                placeholder={'Select status from list'}
                            />
                        </div>
                    </div>
                    <div className="contractor-review-container">
                        <div className={`contractor-review-table ${EditDetailsForReview ? 'review-mode' : ''}`}>
                            <div className="contractor-review-table-left">
                                <div className="contractor-review-header">
                                    {EditDetailsForReview ? <div className="review-detail">S.No</div>
                                        : ''}
                                    <div className="review-detail">Review Parameter</div>
                                    <div className="review-detail">Shared Detail</div>
                                    <div className="review-detail">Confirmed & Approved</div>
                                </div>
                                <div className="contractor-review-inner-container">
                                {this.renderReviewDetails()}
                                </div>
                            </div>
                            <div className='vertical-separator-box'>
                                <span className='separator'></span>
                            </div>
                            <div className="contractor-review-table-right">
                                {reviewCompleted ? 
                                <p>This contractor has been reviewed and the contractor status has been updated.<br/><br/>
                                Last update made by {userInfo.firstName} {userInfo.lastName}.<br/><br/>
                                Last Update Date :<br/>
                                {utils.formatDate(new Date())}
                                </p>
                                :
                                <p>You are about to complete the review and update the status of the contractor.<br/><br/>
                                Please verify the details and the notes and confirm their accuracy
                                </p>}
                            </div>
                        </div>
                        <div className="review-notes-container">
                            <label>Notes:</label>
                            <Input disabled={!EditDetailsForReview} type={'richtext'} value={reviewNote} onChange={this.handleNotesChange} name="reviewNote" />
                        </div>
                    </div>
                    {EditDetailsForReview ?
                        <div className="review-btn-container">
                            <Button
                                title={'Complete Review'}
                                className='signup-btn'
                                disabled={!utils.cleanHTMLText(reviewNote)}
                                onClick={this.completeReview}
                                uppercase
                            />
                        </div>
                        :
                        <div className="review-btn-container">
                            <Button
                                title={'Edit Review'}
                                className='edit-review'
                                onClick={this.completeReview}
                                uppercase
                            />
                            {!reviewCompleted ? 
                            <Button
                                title={'Confirm Review'}
                                disabled={!utils.cleanHTMLText(reviewNote)}
                                className='signup-btn'
                                uppercase
                                onClick={this.confirmReview}
                            /> : ''}
                        </div>
                    }
                    </React.Fragment> : ''}
                </div>
            </div>
        );
    }
}

function mapState(state) {
    const { activeParticipant } = state.participant;
    const { currentProgram } = state.authentication;
    return { currentProgram, activeParticipant };
}

const actionCreators = {
    showModal: userActions.showEditParticipantModal,
    getParticipant : participantActions.getParticipantDetails,
    getParticipants: participantActions.getParticipants
}

export default connect(mapState, actionCreators)(ContractorReview);
import {adminSettingsActionTypes} from './adminSettingsActionTypes';
import {userService} from '../../services/userService';

export const adminSettingsActions = {
    getAdminUsers,
    getClients,
    getPrograms,
    showUserModal,
    getAdminConfig,
    getSPPAdminConfig,
    getAdminConfigRetrofit,
    setActiveAdminUser
};

function setActiveAdminUser(user){
    return { type : adminSettingsActionTypes.SET_ACTIVE_ADMIN_USER, user};
}

function showUserModal(show, mode, user){
    let dupUser = {}
    if(mode === 'edit'){
        dupUser = {...user};
        if(dupUser){
            let role = dupUser.role;
            dupUser.roleName = role.name;
            dupUser.role = role.value;
        }
    }
    return { type : adminSettingsActionTypes.SHOW_ADD_USER_MODAL, show, mode, user: dupUser};
}

// function mapErrors(firebaseError){
//     let errors = {};
//     let { code, message } = firebaseError;
//     if(code === 'auth/wrong-password'){
//         errors['password'] = 'The password does not match the username';
//     } else if(code === 'auth/user-not-found'){
//         errors['username'] = 'This username does not exist in our system';
//     } else {
//         errors['username'] = message;
//     }
//      return errors;
// }

function extractErrMessage(firebaseError){
    return firebaseError.message;
}

function getAdminUsers(){
    return dispatch => {
        dispatch(request());
        userService.getAdminUsers().then((users)=>{
            dispatch(success(users));
        }).catch((error)=>{
            console.error('ERROR', error);
            failure(error);
        });
    };

    function request() { return { type: adminSettingsActionTypes.GET_ADMIN_USERS_REQUEST} }
    function success(list) { return { type: adminSettingsActionTypes.GET_ADMIN_USERS_SUCCESS, data : list } }
    function failure(error) { return { type: adminSettingsActionTypes.GET_ADMIN_USERS_FAILURE, error } }
}

function getClients(){
    return dispatch => {
        dispatch(request());
        userService.getClients().then((clients)=>{
            dispatch(success(clients));
        }).catch((error)=>{
            failure(error);
        });
    };
    function request() { return { type: adminSettingsActionTypes.GET_CLIENTS_REQUEST} }
    function success(list) { return { type: adminSettingsActionTypes.GET_CLIENTS_SUCCESS, data : list } }
    function failure(error) { return { type: adminSettingsActionTypes.GET_CLIENTS_FAILURE, error } }
}

function getPrograms(){
    return dispatch => {
        dispatch(request());
        userService.getPrograms().then((programs)=>{
            dispatch(success(programs));
        }).catch((error)=>{
            failure(error);
        });
    };
    function request() { return { type: adminSettingsActionTypes.GET_PROGRAMS_REQUEST} }
    function success(list) { return { type: adminSettingsActionTypes.GET_PROGRAMS_SUCCESS, data : list } }
    function failure(error) { return { type: adminSettingsActionTypes.GET_PROGRAMS_FAILURE, error } }
}

function getAdminConfig(specificDoc){
    return dispatch => {
        dispatch(request());
        userService.getAdminConfig(specificDoc)
            .then((data) => { 
                    dispatch(success(data));
                }).catch((error) => {
                    dispatch(failure(error));
                }
            );
    };

    function request() { return { type: adminSettingsActionTypes.GET_ADMIN_CONFIG_REQUEST } }
    function success(data) { return { type: adminSettingsActionTypes.GET_ADMIN_CONFIG_SUCCESS, data } }
    function failure(error) { return { type: adminSettingsActionTypes.GET_ADMIN_CONFIG_FAILURE, error: extractErrMessage(error) } }
}
function getSPPAdminConfig(specificDoc){
    return dispatch => {
        dispatch(request());
        userService.getSPPAdminConfig(specificDoc)
            .then((data) => { 
                    dispatch(success(data));
                }).catch((error) => {
                    dispatch(failure(error));
                }
            );
    };

    function request() { return { type: adminSettingsActionTypes.GET_ADMIN_CONFIG_REQUEST } }
    function success(data) { return { type: adminSettingsActionTypes.GET_ADMIN_CONFIG_SUCCESS, data } }
    function failure(error) { return { type: adminSettingsActionTypes.GET_ADMIN_CONFIG_FAILURE, error: extractErrMessage(error) } }
}
function getAdminConfigRetrofit(specificDoc){
    return dispatch => {
        dispatch(request());
        userService.getAdminConfigRetrofit(specificDoc)
            .then((data) => { 
                    dispatch(success(data));
                }).catch((error) => {
                    dispatch(failure(error));
                }
            );
    };

    function request() { return { type: adminSettingsActionTypes.GET_ADMIN_CONFIG_REQUEST } }
    function success(data) { return { type: adminSettingsActionTypes.GET_ADMIN_CONFIG_SUCCESS, data } }
    function failure(error) { return { type: adminSettingsActionTypes.GET_ADMIN_CONFIG_FAILURE, error: extractErrMessage(error) } }
}
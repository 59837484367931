import {userActionTypes} from './userActionTypes';
import {userService} from '../../services/userService';

export const userActions = {
    checkIfEmailExists,
    setLoginError,
    setLoginField,
    showRegisterModal,
    showSubmitProfileModal,
    showLogoutConfirmationModal,
    showAddParticipantModal,
    passwordReset,
    changePassword,
    clearPasswordReset,
    clearChangePassword,
    login,
    logout,
    updateUserDetails,
    submitProfile,
    getUserDetails,
    verifyEmail,
    delete: _delete,
    createAdminUser,
    updateAdminUser,
    setActiveProgram,
    setSideBarMenuCollapse
};

function setSideBarMenuCollapse(menuCollapse){
    return { type : userActionTypes.SET_SIDEBARMENU_COLLAPSE, menuCollapse };
}

function setActiveProgram(programName){
    return { type : userActionTypes.SET_ACTIVE_PROGRAM, programName };
}

function setLoginError(errors){
    return { type : userActionTypes.LOGIN_SET_ERROR, errors};
}

function setLoginField(key, val){
    return { type : userActionTypes.LOGIN_SET_FIELD, fieldName : key, fieldVal : val};
}

function showRegisterModal(show){
    return { type : userActionTypes.SHOW_REGISTER_MODAL, show};
}

function showSubmitProfileModal(show){
    return { type : userActionTypes.SHOW_SUBMIT_PROFILE_MODAL, show};
}

function showLogoutConfirmationModal(show){
    return { type : userActionTypes.SHOW_LOGOUT_CONFIRMATION_MODAL, show};
}

function showAddParticipantModal(show){
    return { type : userActionTypes.SHOW_ADD_PARTICIPANT_MODAL, show};
}

function mapErrors(firebaseError){
    let errors = {};
    let { code, message } = firebaseError;
    if(code === 'auth/wrong-password'){
        errors['password'] = 'You have entered an invalid username or password';
    } else if(code === 'auth/user-not-found'){
        errors['password'] = 'You have entered an invalid username or password';
    } else {
        errors['password'] = message;
    }
     return errors;
}

function extractErrMessage(firebaseError){
    return firebaseError.message;
}

function clearPasswordReset(){
    return { type: userActionTypes.PASSWORD_RESET_CLEAR }
}
function clearChangePassword(){
    return { type: userActionTypes.CHANGE_PASSWORD_CLEAR }
}

function checkIfEmailExists(email){
    return dispatch => {
        userService.checkIfEmailExists(email)
            .then(
                result => { 
                    dispatch(request(result.data.userExists));
                },
                error => {
                    dispatch(request(false));
                }
            );
    };

    function request(userExists) { return { type: userActionTypes.CHECK_USER_EXISTS, userExists } }
}


// function getParticipants(type){
//     return dispatch => {
//         dispatch(request());
//         userService.getParticipants(type).then((users)=>{
//             let userList = [];
//             for(let key in users){
//                 const user = users[key];
//                 let item = {
//                     uid: user.uid,
//                     Name:  `${user.firstName} ${user.lastName}`,
//                     Status: `${user.status}`,
//                     CreatedDate: `${utils.formatDate(user.createdAt)}`,
//                     EmailID: user.email,
//                     userInfo : user
//                 };
//                 if(type === "contractor"){ item.ContractorID = `CON-${user.userNo || user.uid}`;}
//                 if(type === "customer"){ item.CustomerID = `CUS-${user.userNo || user.uid}`;}
//                 userList.push(item);
//             }  
//             dispatch(success(userList));
//         });
//     };

//     function request() { return { type: userActionTypes.GET_ADMIN_USERS_REQUEST,userType:type } }
//     function success(list) { return { type: userActionTypes.GET_ADMIN_USERS_SUCCESS, userType:type, data : list } }
//     // function failure(error) { return { type: userActionTypes.GET_ADMIN_USERS_FAILURE, error: extractErrMessage(error) } }
// }

function verifyEmail(program, email){
    return dispatch => {
        if(program === 'esb'){
            dispatch(request());
            userService.sendEmailVerificationLink(email)
                .then(
                    result => { 
                        dispatch(success());
                    },
                    error => {
                        dispatch(failure(error));
                    }
                );
        } else {
            dispatch(request());
            userService.sendEmailVerificationEmailSPP(email)
                .then(
                    result => { 
                        dispatch(success());
                    },
                    error => {
                        dispatch(failure(error));
                    }
                );
        }
    };

    function request() { return { type: userActionTypes.EMAIL_VERIFICATION_REQUEST } }
    function success() { return { type: userActionTypes.EMAIL_VERIFICATION_SUCCESS } }
    function failure(error) { return { type: userActionTypes.EMAIL_VERIFICATION_FAILURE, error: extractErrMessage(error) } }
}

function passwordReset(email){
    return dispatch => {
        dispatch(request());
        userService.resetPassword(email)
            .then(
                result => { 
                    dispatch(success());
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request() { return { type: userActionTypes.PASSWORD_RESET_REQUEST } }
    function success() { return { type: userActionTypes.PASSWORD_RESET_SUCCESS } }
    function failure(error) { return { type: userActionTypes.PASSWORD_RESET_FAILURE, error: extractErrMessage(error) } }
}

function login(history, username, password) {
    return dispatch => {
        dispatch(request({ username }));
        userService.login(username, password)
            .then(async(user) => { 
                    const result = await user.user.getIdTokenResult();
                    if(result.claims.role){
                        history.push('/home');dispatch(success());
                    } else {
                        await userService.logout();
                        dispatch(failure({message : "Access denied!"}));
                    }
                }).catch((error)=>{
                    dispatch(failure(error));
                });
    };

    function request() { return { type: userActionTypes.LOGIN_REQUEST } }
    function success() { return { type: userActionTypes.LOGIN_SUCCESS } }
    function failure(error) { return { type: userActionTypes.LOGIN_FAILURE, errors: mapErrors(error) } }
}

function changePassword(username, oldPassword, newPassword) {
    return dispatch => {
        dispatch(request());
        userService.changePassword(username, oldPassword, newPassword)
            .then(
                message => { 
                    dispatch(success(message));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request() { return { type: userActionTypes.CHANGE_PASSWORD_REQUEST } }
    function success(message) { return { type: userActionTypes.CHANGE_PASSWORD_SUCCESS, message } }
    function failure(error) { return { type: userActionTypes.CHANGE_PASSWORD_FAILURE, error: extractErrMessage(error) } }
}

function logout() {
    userService.logout();
    return { type: userActionTypes.LOGOUT };
}

function createAdminUser(user) {
    return dispatch => {
        dispatch(request());
        userService.createAdminUser(user)
            .then((res) => { 
                    userService.getAdminUsers().then((users)=>{
                        dispatch(success(users));
                    }).catch((error)=>{
                        dispatch(failure(error));
                    });
                }).catch((error)=>{
                    console.log('Error,',error);
                    dispatch(failure(error)); 
                })
    };

    function request() { return { type: userActionTypes.CREATE_ADMIN_USER_REQUEST } }
    function success(users) { return { type: userActionTypes.CREATE_ADMIN_USER_SUCCESS, users } }
    function failure(error) { return { type: userActionTypes.CREATE_ADMIN_USER_FAILURE, error } }
}

function updateAdminUser(user) {
    return dispatch => {
        dispatch(request());
        userService.updateAdminUser(user)
            .then((res) => { 
                    userService.getAdminUsers().then((users)=>{
                        dispatch(success(users));
                    }).catch((error)=>{
                        dispatch(failure(error));
                    });
                }).catch((error)=>{
                    console.log('Error,',error);
                    dispatch(failure(error)); 
                })
    };

    function request() { return { type: userActionTypes.UPDATE_ADMIN_USER_REQUEST } }
    function success(users) { return { type: userActionTypes.UPDATE_ADMIN_USER_SUCCESS, users } }
    function failure(error) { return { type: userActionTypes.UPDATE_ADMIN_USER_FAILURE, error } }

}


function submitProfile(uid, userDetails) {
    return dispatch => {
        dispatch(request());
        userService.updateUserDetails(uid, userDetails)
            .then(
                result => { 
                    dispatch(success());
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request() { return { type: userActionTypes.SUBMIT_PROFILE_REQUEST } }
    function success() { return { type: userActionTypes.SUBMIT_PROFILE_SUCCESS } }
    function failure(error) { return { type: userActionTypes.SUBMIT_PROFILE_FAILURE, error } }
}

function updateUserDetails(uid, userDetails) {
    return dispatch => {
        dispatch(request());
        userService.updateUserDetails(uid, userDetails)
            .then(
                user => { 
                    dispatch(success());
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request() { return { type: userActionTypes.UPDATE_USER_REQUEST } }
    function success() { return { type: userActionTypes.UPDATE_USER_SUCCESS } }
    function failure(error) { return { type: userActionTypes.UPDATE_USER_FAILURE, error } }
}


function getUserDetails(userId) {
    return dispatch => {
        dispatch(request());
        userService.getUserDetails(userId, (successful, userDetails)=>{
            if(successful){
                dispatch(success(userDetails));
            } else {
                dispatch(failure("Error"));
            }
        });
    };

    function request() { return { type: userActionTypes.GET_USER_DETAILS_REQUEST } }
    function success(userDetails) { return { type: userActionTypes.GET_USER_DETAILS_SUCCESS, userDetails } }
    function failure(error) { return { type: userActionTypes.GET_USER_DETAILS_FAILURE, error } }
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
    return dispatch => {
        dispatch(request(id));

        userService.delete(id)
            .then(
                user => dispatch(success(id)),
                error => dispatch(failure(id, error.toString()))
            );
    };

    function request(id) { return { type: userActionTypes.DELETE_REQUEST, id } }
    function success(id) { return { type: userActionTypes.DELETE_SUCCESS, id } }
    function failure(id, error) { return { type: userActionTypes.DELETE_FAILURE, id, error } }
}
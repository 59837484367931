import React from "react";
import "./AppFlagLogs.css";
import ModalAppDataComponent from "../../../Common/ModalAppDetails/ModalAppData";
import Table from "../../../Common/Table/Table";
import EmptyContainer from "../../../Common/EmptyContainer/EmptyContainer";
import { applicationService } from "../../../../services/applicationService";
import LoaderComponent from "../../../Common/Loader/Loader";
import FlagIcon from "../../../Common/FlagIcon/FlagIcon";
import Button from "../../../Common/Button/Button";
import FlagApplication from "../flagApplication/flagApplication";
import { connect } from "react-redux";
import { utils } from "../../../../helpers/utils";
import { appActions } from "../../../../redux/actions/appActions";

class ApplicationFlagLogs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      applicationObj: {},
      setApplicationFlag: false,
      flagAssigned: false,
    };
  }
  async componentDidMount() {
    if (this.props.currentProgram !== "retrofit") {
      applicationService.getApplication(this.props.applicationId).then((appObj) => {
        this.setState({
          applicationObj: appObj,
          loading: false,
        });
      });
    } else {
      this.props.getRetrofitFlagLogs(this.props.applicationId);
      this.setState({
        loading: this.props.appFlagsLoading,
      });
    }
  }

  showSetApplicationFlag = () => {
    this.setState({ setApplicationFlag: true });
  };

  closeFlagApplication = () => {
    this.setState({ setApplicationFlag: false });
  };
  getFlagSetStatus = () => {
    this.setState({ flagAssigned: true });
  };
  render() {
    const { application, FlagLogs, skipTopBit, adminUserInfo, appFlagsLoading, reload, currentProgram } = this.props;
    const { applicationObj, loading, setApplicationFlag, flagAssigned } = this.state;
    let color =
      FlagLogs && FlagLogs.length
        ? FlagLogs[0].changedTo
          ? FlagLogs[0].changedTo.status === "No Internal Status"
            ? ""
            : FlagLogs[0].changedTo.colorCode
          : ""
        : "";
    let flagStatus =
      FlagLogs && FlagLogs.length ? (FlagLogs[0].changedTo ? FlagLogs[0].changedTo.status : "No Internal Status") : "No Internal Status";
    let canEdit = true;
    if (adminUserInfo && adminUserInfo.permissions) {
      let actions = adminUserInfo.permissions[currentProgram].actions;
      if (utils.isNullOrEmpty(actions[`Application Board-Flag Application`])) {
        canEdit = false;
      }
    }
    return (
      <React.Fragment>
        {setApplicationFlag ? (
          <div className="flag-application-inside-view-flag-logs">
            <FlagApplication
              applicationId={this.props.applicationId}
              closeFlagApplication={this.closeFlagApplication}
              getFlagSetStatus={this.getFlagSetStatus}
              reload={reload}
              closeActionModal={this.props.closeActionModal}
            />
          </div>
        ) : (
          <div className="flag-logs-container">
            {skipTopBit ? (
              ""
            ) : (
              <React.Fragment>
                {currentProgram === "retrofit" ? (
                  appFlagsLoading ? (
                    <LoaderComponent />
                  ) : (
                    <div className="section-header">
                      <div className="section-top-details">{application.id}</div>
                      <div className="section-top-details name-section">{application.applicantName}</div>
                      <div className="section-top-details">{application.applicantEmail}</div>
                    </div>
                  )
                ) : loading ? (
                  <LoaderComponent />
                ) : (
                  <ModalAppDataComponent applicationObj={applicationObj}></ModalAppDataComponent>
                )}
                <div className="section-below-header1">
                  <div className="title-container">
                    <h2 className="section-title" dangerouslySetInnerHTML={{ __html: "Application Flag" }}></h2>
                    <div className="flag-current-status">
                      <FlagIcon color={color ? color : "EmptyFlag"} />
                      <p>{flagStatus}</p>
                    </div>
                  </div>
                  {canEdit ? (
                    <div className="app-flag-btn">
                      <Button title={"Flag This Application"} uppercase onClick={this.showSetApplicationFlag} />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                {/* <div className="section-below-header2">
              <h2 className="section-title" dangerouslySetInnerHTML={{ __html: "Flag Logs" }}></h2>
            </div> */}
              </React.Fragment>
            )}
            <div className="container-box">
              {FlagLogs && FlagLogs.length ? (
                //  /<FlagIcon className="flag-app-icon" color={flag.colorCode} />
                <Table
                  rows={FlagLogs}
                  headings={[
                    { title: "Changed From", value: "changedFrom", custom: "flag" },
                    { title: "Changed To", value: "changedTo", custom: "flag" },
                    { title: "Notes", value: "changeNotes" },
                    { title: "Log Time", value: "createdAt" },
                    { title: "Updated By", value: "updatedBy" },
                  ]}
                />
              ) : (
                <EmptyContainer>No Logs to Show</EmptyContainer>
              )}
            </div>{" "}
          </div>
        )}
      </React.Fragment>
    );
  }
}
function mapState(state) {
  const { adminUserInfo, currentProgram } = state.authentication;
  const { flagLogs, appFlagsLoading } = state.application;
  return { adminUserInfo, currentProgram, flagLogs, appFlagsLoading };
}
const actionCreators = {
  getRetrofitFlagLogs: appActions.getRetrofitFlagLogs,
};
export default connect(mapState, actionCreators)(ApplicationFlagLogs);

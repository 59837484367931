import React from "react";
import "./AssignPaymentForApproval.css";
import Input from "../../../Common/Input/Input";
import Button from "../../../Common/Button/Button";
import ModalAppDataComponent from "../../../Common/ModalAppDetails/ModalAppData";
import { applicationService } from "../../../../services/applicationService";
import { connect } from "react-redux";
import LoaderComponent from "../../../Common/Loader/Loader";
import { utils } from "../../../../helpers/utils";
import { adminSettingsActions } from "../../../../redux/actions/adminSettingsActions";
import CheckBox from "../../../Common/CheckBox/CheckBox";
class AssignPaymentForApproval extends React.Component {
  constructor(props) {
    super(props);
    const { adminUserInfo } = this.props;
    this.state = {
      assignedPaymentForApproval: false,
      assignedSuccessfully: false,
      confirmingPaymentForApproval: false,
      appFlagNotes: "",
      activeAppId: "",
      appPaymentApprovalLoading: true,
      applicationObj: {},
      approvalDetails: {
        status: "Banking Details Pending",
        payeeNameIsPersonalName: false,
        incentiveRecommendedIsAccurate: false,
        customerCompanyIncentiveCapHit: false,
        customer1MIncentiveCapHit: false,
        incentiveRecommendationNote: "",
        actionNote: "",
        actionTaker: `${adminUserInfo && adminUserInfo.firstName} ${adminUserInfo && adminUserInfo.lastName}`,
      },
      paymentApprovalParameters: [
        "Payee Name is a Personal Name",
        "Incentive Recommended is Accurate",
        "$500k for Applicant Incentive Cap Hit",
      ],
    };
  }
  async componentDidMount() {
    this.props.loadAdminConfig();
    applicationService.getApplication(this.props.applicationId).then((appObj) => {
      this.setState({
        applicationObj: appObj,
        appPaymentApprovalLoading: false,
      });
    });
  }
  onChange = (name, value) => {
    const { approvalDetails } = this.state;
    approvalDetails[name] = value;
    this.setState({
      approvalDetails: approvalDetails,
    });
  };
    
  handleNotesChange = async (name, value) => {
    const {approvalDetails}= this.state;
    approvalDetails['incentiveRecommendationNote']=value;
    approvalDetails['actionNote']=value;
    this.setState({
      approvalDetails:approvalDetails,
    });
  };
  // handleNotesChange = async (e) => {
  //   const { name, value } = e.target;
  //   const {approvalDetails}= this.state;
  //   approvalDetails['incentiveRecommendationNote']=value;
  //   approvalDetails['actionNote']=value;
  //   this.setState({
  //     approvalDetails:approvalDetails,
  //   });
  // };
  confirmingPaymentApproval = () => {
    this.setState({
      confirmingPaymentForApproval: !this.state.confirmingPaymentForApproval,
    });
  };
  assignforApproval = () => {
    const { approvalDetails } = this.state;
    applicationService.updateApplication(this.props.applicationId, approvalDetails).then((res) => {
      this.setState(
        {
          assignedSuccessfully: true,
        },
        () => {
          setTimeout(() => {
            this.props.closeActionModal(true);
          }, 5000);
        }
      );
    });
  };
  render() {
    const { configData } = this.props;
    const { appPaymentApprovalLoading, approvalDetails, paymentApprovalParameters,assignedSuccessfully} = this.state;
    // let flagsList = [];
    // if (configData) {
    //   flagsList = configData.appFlags.flags.filter((item)=>item.trigger !== 'Automated');
    // }
    const { applicationObj, assignedPaymentForApproval, confirmingPaymentForApproval} = this.state;
    return (
      <div className="assign-payment-forapproval-container">
        <React.Fragment>
          {appPaymentApprovalLoading ? (
            <LoaderComponent />
          ) : (
            <React.Fragment>
              {!assignedPaymentForApproval ? (
                <ModalAppDataComponent applicationObj={applicationObj}></ModalAppDataComponent>
              ) : (
                // <div className="section-header">
                //   <div className="section-top-details">ESB-{applicationObj.appNo}</div>
                //   <div className="section-top-details name-section">{applicationObj.companyName}</div>
                //   {<div className="section-top-details">{applicationObj.contractor ? applicationObj.contractor.label : ""}</div>}
                // </div>
                ""
              )}
            </React.Fragment>
          )}
          <div className="section-below-header">
            <h2 className="section-title" dangerouslySetInnerHTML={{ __html: "APPROVE RECOMMENDATION" }}></h2>
          </div>
          <div className="payment-approval-review-container">
            <div className={`payment-approval-review-table ${!confirmingPaymentForApproval ? "review-mode" : ""}`}>
              <div className="payment-approval-review-table-left">
                <div className="payment-approval-review-header">
                  <div className="review-detail">Review Parameter</div>
                  <div className="review-detail">Details</div>
                  <div className="review-detail">Response</div>
                </div>
                <div className="payment-approval-review-inner-container">
                  <React.Fragment>
                    <div className="payment-approval-review-details">
                      <div className="review-detail">Payee Name is a Personal Name</div>
                      <div className="review-detail">{applicationObj.payeeInfo && applicationObj.payeeInfo.payeeName}</div>
                      <div className="review-detail review-status">
                      {!confirmingPaymentForApproval ?  <Input
                          type="switch"
                          name="payeeNameIsPersonalName"
                          checked={approvalDetails.payeeNameIsPersonalName}
                          onChange={this.onChange}
                        />
                        : approvalDetails.payeeNameIsPersonalName ? 'Yes' : 'No'}
                      </div>
                    </div>
                    <div className="payment-approval-review-details">
                      <div className="review-detail">Incentive Recommended is Accurate</div>
                      <div className="review-detail">{utils.formatAsCAD(applicationObj.PostProjectReviewerApprovedIncentive)}</div>
                      <div className="review-detail review-status">
                      {!confirmingPaymentForApproval ?  <Input
                          type="switch"
                          name="incentiveRecommendedIsAccurate"
                          checked={approvalDetails.incentiveRecommendedIsAccurate}
                          onChange={this.onChange}
                        /> :approvalDetails.incentiveRecommendedIsAccurate ? 'Yes' : 'No'}
                      </div>
                    </div>
                    <div className="payment-approval-review-details">
                      <div className="review-detail">$500k for Applicant Incentive Cap Hit</div>
                      <div className="review-detail">--</div>
                      <div className="review-detail review-status">
                      {!confirmingPaymentForApproval ? 
                        <Input
                          type="switch"
                          name="customerCompanyIncentiveCapHit"
                          checked={approvalDetails.customerCompanyIncentiveCapHit}
                          onChange={this.onChange}
                        />:approvalDetails.customerCompanyIncentiveCapHit ? 'Yes' : 'No'}
                      </div>
                    </div>
                    <div className="payment-approval-review-details">
                      <div className="review-detail">$1M for Applicant Incentive Cap Hit</div>
                      <div className="review-detail">--</div>
                      <div className="review-detail review-status">
                      {!confirmingPaymentForApproval ? 
                        <Input
                          type="switch"
                          name="customer1MIncentiveCapHit"
                          checked={approvalDetails.customer1MIncentiveCapHit}
                          onChange={this.onChange}
                        />:approvalDetails.customer1MIncentiveCapHit ? 'Yes' : 'No'}
                      </div>
                    </div>
                  </React.Fragment>
                </div>
              </div>
              {confirmingPaymentForApproval ? (
                <React.Fragment>
                  <div className="vertical-separator-box">
                    <span className="separator"></span>
                  </div>
                  {assignedSuccessfully ? (
                      <p>You have successfully approved the incentive recommendation for
                      Application ID #ESB-{applicationObj.appNo} .</p>
                    ) : (
                  <div className="payment-approval-review-table-right">
                    <span>You are about to approve the incentive recommendation for the Application ID <span className="green-text">#ESB-{applicationObj.appNo}</span>.</span>
                    <span className="confirmation-msg">Are you sure?</span>
                  </div>
                    )}
                </React.Fragment>
              ) : (
                ""
              )}
            </div>
            <div className="form-inner-section">
                <div className="form-label bold full">Incentive Recommendation Note for ERA:</div>
              </div>
            <div className="form-inner-section">
                <div className="form-element full">
                  <Input
                    type="richtext"
                     disabled={confirmingPaymentForApproval}
                    value={approvalDetails.incentiveRecommendationNote}
                    onChange={this.handleNotesChange}
                    name="notes"
                  />
                </div>
              </div>
          </div>
          {(assignedPaymentForApproval || assignedSuccessfully) ? (
            ""
          ) : (
            <div className="button-block">
              <div className="button-set">
              {confirmingPaymentForApproval ? (
                  <Button className="inversed-btn" title={"EDIT"} uppercase onClick={this.confirmingPaymentApproval} />
                ) : (
                  <Button className="inversed-btn" title={"CANCEL"} uppercase onClick={() => this.props.closeActionModal()} />
                )}
                {confirmingPaymentForApproval ? (
                  <Button className="signup-btn" title={"Confirm RECOMMENDATION"} uppercase onClick={this.assignforApproval} />
                ) : (
                  <Button
                    className="signup-btn"
                    title={"APPROVE RECOMMENDATION"}
                    uppercase
                    onClick={this.confirmingPaymentApproval}
                    disabled={
                      utils.isNullOrEmpty(approvalDetails.incentiveRecommendationNote) ||
                      utils.isNullOrEmpty(utils.cleanHTMLText(approvalDetails.incentiveRecommendationNote))
                    }
                  />
                )}
              </div>
            </div>
          )}
        </React.Fragment>
      </div>
    );
  }
}
function mapState(state) {
  const { adminUserInfo } = state.authentication;
  const { configData } = state.settings;
  return {
    adminUserInfo,
    configData,
  };
}
const actionCreators = {
  loadAdminConfig: adminSettingsActions.getAdminConfig,
};
export default connect(mapState, actionCreators)(AssignPaymentForApproval);

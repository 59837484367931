import firebase from '../helpers/Firebase';
import { utils } from '../helpers/utils';
let API_URL = firebase.apiUrl;
const HOST_URL = firebase.hostUrl;
// API_URL = 'http://localhost:5001/dolphin-21153/northamerica-northeast1';

export const userService = {
    getAdminConfig,
    getAdminConfigRetrofit,
    login,
    logout,
    getUserDetails,
    getParticipantDetails,
    getParticipantDetailsRetrofit,
    resetPassword,
    changePassword,
    sendEmailVerificationLink,
    sendEmailVerificationEmailSPP,
    updateUserDetails,
    updateUserProfile,
    createAdminUser,
    updateAdminUser,
    getAdminUser,
    getParticipants,
    getParticipantsRetrofit,
    getAdminUsers,
    getClients,
    createClient,
    getPrograms,
    checkIfEmailExists,
    checkIfPhoneExists,
    getContractorReview,
    createContractorReview,
    updateContractorReview,
    getStatusLogs,
    getReviewers,
    getFrontUserDetails,
    updateClient,
    createProgram,
    updateProgram,
    getUserLoginActivities,
    updateUserLoginActivities,
    unblockUserAccount,
    getAllUserLoginActivities,
    getFrontUserDetailsSpeed,
    getSPPAdminConfig
};


function getContractorReview(uid, data){
    return utils.makeWebRequest(`${API_URL}/users/contractor/review/${uid}`,"GET", data);
}

function createContractorReview(uid, data){
    return utils.makeWebRequest(`${API_URL}/users/contractor/review/${uid}`,"POST", data);
}

function updateContractorReview(uid, data){
    return utils.makeWebRequest(`${API_URL}/users/contractor/review/${uid}`,"PUT", data);
}

function getAdminUser(uid){
    return utils.makeWebRequest(`${API_URL}/users/${uid}`,"GET");
}

function getParticipants(program, accountCategory, searchTerm = "", config={statuses:[]}, offset = 0){
    let filterObj = {
        "searchTerm" : searchTerm,
        "filters"  : {
            "accountCategory" : [accountCategory]
        },
        "offset" : offset,
        "size" : 40
    };
    if(config.statuses.length){
        filterObj.filters["status"] = config.statuses;
    }
    if(config.sort){
        filterObj.sort = config.sort;
    }
    return utils.makeWebRequest(`${API_URL}/search/${program === 'esb' ? '' : 'spp/'}participants`,"POST",filterObj);
}

function getParticipantsRetrofit(program, accountCategory, searchTerm = "", config={statuses:[]}, offset = 0){
    let filterObj = {
        "searchTerm" : searchTerm,
        "filters"  : {
        },
        "offset" : offset,
        "size" : 40
    };
    if(accountCategory === 'customer'){
        filterObj.filters['applicant_flag'] = ['y'];
        filterObj.filters['applicant_rep_flag'] = ['n'];
    } else {
        filterObj.filters['applicant_flag'] = ['y','n'];
        filterObj.filters['applicant_rep_flag'] = ['y'];
    }
    return utils.makeWebRequest(`${API_URL}/searchIESO/retrofit_representatives`,"POST",filterObj);
}

function getStatusLogs(program, uid){
    return utils.makeWebRequest(`${API_URL}/logging/${program === 'speed' ? 'speed/' : ''}status/${uid}`,"GET");
}

function createAdminUser(data){
    return utils.makeWebRequest(`${API_URL}/users/admin`,"POST", data);
}

function updateAdminUser(data){
    return utils.makeWebRequest(`${API_URL}/users/admin`,"PUT", data);
}

function getAdminUsers(){
    return utils.makeWebRequest(`${API_URL}/users/admin`,"GET");
}

function getReviewers(){
    return utils.makeWebRequest(`${API_URL}/users/admin/reviewer`,"GET");
}

function getClients(){
    return utils.makeWebRequest(`${API_URL}/clients/all`,"GET");
}

function createClient(data){
    return utils.makeWebRequest(`${API_URL}/clients/manage`,"POST", data);
}

function updateClient(uid, data){
    // console.log(`${API_URL}/clients/manage/${uid}`)
    return utils.makeWebRequest(`${API_URL}/clients/manage/${uid}`,"PUT", data);
}

function getPrograms(){
    return utils.makeWebRequest(`${API_URL}/programs/all`,"GET");
}
function createProgram(data){
    return utils.makeWebRequest(`${API_URL}/programs/manage`,"POST", data);
}


function updateProgram(uid, data){
    // console.log(`${API_URL}/clients/manage/${uid}`)
    return utils.makeWebRequest(`${API_URL}/programs/manage/${uid}`,"PUT", data);
}

function updateUserProfile(program, uid, data){
    return utils.makeWebRequest(`${API_URL}/users/${program === 'esb' ? '' : program === 'speed' ? 'spp/' : 'retrofit/'}${uid}`,"PUT", data);
}

function login(username, password) {
    return firebase.auth.signInWithEmailAndPassword(username, password);
}

function checkIfEmailExists(emailId){
    return utils.makeWebRequest(`${API_URL}/users/emailexists`,"POST", {emailId});
}

function checkIfPhoneExists(phoneNumber){
    return utils.makeWebRequest(`${API_URL}/users/phoneexists`,"POST", {phoneNumber});
}

function changePassword(username, oldPassword, newPassword) {
    return new Promise((resolve, reject) => {
        firebase.auth.signInWithEmailAndPassword(username, oldPassword)
        .then((_) => {
            let user = firebase.auth.currentUser;
            user.updatePassword(newPassword).then(function() {
                resolve("Successfully changed password");
              }).catch(function(error) {
                // An error happened.
                reject(error);
              });
        })
        .catch((error) => {
            reject(error);
        });
    });
}

function logout() {
    return firebase.auth.signOut().then(() => {
        // Sign-out successful.
      });
}

function getUserDetails(userId, callback) {
    firebase.db.collection('adminInfo').doc(userId).get().then(async function(doc) {
        if (doc.exists) {
            let userObj = doc.data();
            let userPermissionType = `${userObj.team} ${userObj.role.value}`;
            let permissions = await firebase.db.collection('adminPermissions').doc(userPermissionType).get();
            userObj.permissions = permissions.data();
            // console.log('User=>', userObj);
            callback(true, userObj);
        } else {
            callback(false);
        }
    }).catch(function(error) {
        console.log("GET USER DETAILS ERROR =>", error);
        // console.log("Error getting document:", error);
        callback(false);
    });
}

function getFrontUserDetails(userId, callback) {
    firebase.db.collection('userInfo').doc(userId).get().then(function(doc) {
        if (doc.exists) {
            // console.log("Document data:", doc.data());
            callback(true, doc.data());
        } else {
            callback(false);
        }

    }).catch(function(error) {
        console.log("Error getting document:", error);
        callback(false);
    });
}

function getFrontUserDetailsSpeed(userId, callback) {
    firebase.db.collection('era').doc('speed').collection('userInfo').doc(userId).get().then(function(doc) {
        if (doc.exists) {
            callback(true, doc.data());
        } else {
            callback(false);
        }

    }).catch(function(error) {
        console.log("Error getting document:", error);
        callback(false);
    });
}

function getParticipantDetails(program, uid) {
    return utils.makeWebRequest(`${API_URL}/users/${program === 'esb' ? '' : 'spp/'}participant/${uid}`,"GET");
}

function getParticipantDetailsRetrofit(uid) {
    return utils.makeWebRequest(`${API_URL}/retrofit/representative/${uid}`,"GET");
}

function resetPassword(uid){
    return utils.makeWebRequest(`${API_URL}/users/admin/resetpassword`,"PUT", { uid });
}

function getAdminConfig(formName){
    return utils.makeWebRequest(`${API_URL}/admin/adminconfig${formName ? '/' + formName : ''}`,"GET");
}

function getSPPAdminConfig(formName){
    return utils.makeWebRequest(`${API_URL}/admin/spp/adminconfig${formName ? '/' + formName : ''}`,"GET");
}

function getAdminConfigRetrofit(formName){
    return utils.makeWebRequest(`${API_URL}/retrofit/adminconfig${formName ? '/' + formName : ''}`,"GET");
}

function updateUserDetails(program, uid, userInfo) {
    // console.log(`${API_URL}/users/${uid}`)
    return updateUserProfile(program, uid, userInfo);
}

function sendAccountManagementEmail(task, email){
    return utils.makeWebRequest(`${API_URL}/users/accountmanagement`,"POST", {task,email});
}

async function sendEmailVerificationLink(email){
    return sendAccountManagementEmail('resendEmailVerification',email);
}

function sendEmailVerificationEmailSPP(email){
    return utils.makeWebRequest(`${API_URL}/users/spp/participant/resendemail`,"POST", {email});
}

//block and unblock user api
function getUserLoginActivities(email) {
    return utils.makeWebRequest(`${API_URL}/useractivity/checkforblock?email=${email}`,"GET");
}

function updateUserLoginActivities(data) {
    return utils.makeWebRequest(`${API_URL}/useractivity/checkforblock`,"PUT", data);
}

function unblockUserAccount(data) {
    return utils.makeWebRequest(`${API_URL}/useractivity/unblock`,"PUT", data);
}

function getAllUserLoginActivities() {
    return utils.makeWebRequest(`${API_URL}/useractivity/all`,"GET");
}
import React from "react";
import "./Notes.css";
import Table from "../../../Common/Table/Table";
import EmptyContainer from "../../../Common/EmptyContainer/EmptyContainer";
import { applicationService } from "../../../../services/applicationService";
import LoaderComponent from "../../../Common/Loader/Loader";
import Button from "../../../Common/Button/Button";
import Input from "../../../Common/Input/Input";
import { utils } from "../../../../helpers/utils";
import { connect } from "react-redux";
import { appActions } from "../../../../redux/actions/appActions";
class ApplicationNotes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      applicationObj: {},
      showAddNotes: false,
      noteAddedSuccessfully: false,
      confirmingNoteAddition: false,
      appNote: "",
      Notes: [],
    };
  }
  handleNotesChange = (name, value) => {
    this.setState({
      appNote: value,
    });
  };
  getNotesList = () => {
    const { currentProgram, applicationId } = this.props;
    if (currentProgram === 'retrofit') {
      this.props.getRetrofitNotes(applicationId);
    } else {
      this.props.getNotes(applicationId);
    }
  };
  componentDidMount() {
    this.getNotesList();
    const { application } = this.props;
    this.setState({
      applicationObj: application,
    })
  }
  showaddNote = () => {
    this.setState({
      showAddNotes: true,
      noteAddedSuccessfully: false,
    });
  };
  closeAddNote = () => {
    this.setState({
      showAddNotes: false,
      noteAddedSuccessfully: false,
      appNote: "",
    });
  };
  addNote = () => {
    let { appNote } = this.state;
    const { currentProgram } = this.props;
    if (appNote) {
      if (currentProgram === 'retrofit') {
        applicationService
        .createRetrofitNotes({
          createdBy: `${this.props.adminUserInfo.firstName} ${this.props.adminUserInfo.lastName}`,
          note: appNote,
          application_id: `${this.props.applicationId}`,
        })
        .then((res) => {
          this.setState(
            {
              noteAddedSuccessfully: true,
              appNote: "",
              showAddNotes: false,
              confirmingNoteAddition:false,
            },
            () => {
              this.getNotesList();
            }
          );
        });
      } else {
        applicationService
        .createNotes({
          createdBy: `${this.props.adminUserInfo.firstName} ${this.props.adminUserInfo.lastName}`,
          note: appNote,
          applicationId: this.props.applicationId,
        })
        .then((res) => {
          this.setState(
            {
              noteAddedSuccessfully: true,
              appNote: "",
              showAddNotes: false,
              confirmingNoteAddition:false,
            },
            () => {
              this.getNotesList();
            }
          );
        });
      }
    }
  };
  confirmingNoteAddition = () => {
    this.setState({
      confirmingNoteAddition: !this.state.confirmingNoteAddition,
    });
  };
  render() {
    const { skipTopBit, Notes, currentProgram, appNotesLoading } = this.props;
    const { applicationObj, showAddNotes, noteAddedSuccessfully, confirmingNoteAddition, appNote } = this.state;
    return (
      <div className="notes-list-container">
        {skipTopBit ? (
          ""
        ) : (
          <React.Fragment>
            {appNotesLoading ? (
              <LoaderComponent />
            ) : (
              <div className="section-header">
            <div className="section-top-details">{applicationObj.appNo}</div>
            <div className="section-top-details name-section">
              {currentProgram === "retrofit" ? applicationObj.applicantName : applicationObj.customerCompanyName}
            </div>
            <div className="section-top-details">
              {currentProgram === "retrofit" ? applicationObj.applicantEmail : applicationObj.contractorCompanyName}
            </div>
          </div>
            )}
            <div className="section-below-header">
              {/* / dangerouslySetInnerHTML={{ __html: 'Internal Notes' }} */}
              <div className="button-set">
                <h2 className="section-title"> Internal Notes</h2>{" "}
                <Button className="add-btn add-icon" title={"Add A Note"} uppercase onClick={this.showaddNote} />
              </div>
            </div> 
          </React.Fragment>
        )}

        <div className="container-box">
          {showAddNotes ? (
            <React.Fragment>
              {noteAddedSuccessfully ? (
                <p>Note Added successfully!</p>
              ) : (
                <React.Fragment>
                  <div className="information-container">
                    <div className="information-container-label">Add A Note:</div>
                    <div className="information-container-section">
                      <Input
                        type="richtext"
                        disabled={confirmingNoteAddition}
                        value={appNote}
                        onChange={this.handleNotesChange}
                        name="note"
                      />
                    </div>
                  </div>
                  <div className="button-set">
                    {confirmingNoteAddition ? (
                      <Button className="inversed-btn" title={"EDIT"} uppercase onClick={this.confirmingNoteAddition} />
                    ) : (
                      <Button className="inversed-btn" title={"CANCEL"} uppercase onClick={() => this.closeAddNote()} />
                    )}
                    {confirmingNoteAddition ? (
                      <Button
                        className="signup-btn"
                        title={"Add Note"}
                        uppercase
                        onClick={this.addNote}
                        disabled={utils.isNullOrEmpty(utils.cleanHTMLText(appNote))}
                      />
                    ) : (
                      <Button
                        className="signup-btn"
                        title={"Confirm Note"}
                        uppercase
                        onClick={this.confirmingNoteAddition}
                        disabled={utils.isNullOrEmpty(utils.cleanHTMLText(appNote))}
                      />
                    )}
                  </div>
                </React.Fragment>
              )}
            </React.Fragment>
          ) : (
            ""
          )}
          {Notes && Notes.length ? (
            <React.Fragment>
              <Table
                rows={Notes}
                headings={[
                  { title: "S. No.", value: "SNO" },
                  { title: "Created By", value: "createdBy" },
                  { title: "Notes", value: "note" },
                  { title: "Created At", value: "createdAt" },
                ]}
              />
            </React.Fragment>
          ) : (
            <EmptyContainer>No Notes to Show</EmptyContainer>
          )}
        </div>
      </div>
    );
  }
}

function mapState(state) {
  const { Notes, appNotesLoading } = state.application;
  const { currentProgram } = state.authentication;
  return {
    Notes,
    currentProgram,
    appNotesLoading
  };
}
const actionCreators = {
  getNotes: appActions.getApplicationsNotes,
  getRetrofitNotes: appActions.getRetrofitApplicationsNotes
};
export default connect(mapState, actionCreators)(ApplicationNotes);

import React from 'react';
import './DatePicker.css';
import TextField from '@material-ui/core/TextField';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';

const TextFieldComponent = (props) => {
    return <TextField {...props} disabled={true} />
  }


const DatePicker = (props) => {

    return <div className={`date-picker ${props.classname ? props.classname : ''}`}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
                disabled={props.disabled}
                disableToolbar
                variant="inline"
                format="MM/dd/yyyy"
                placeholder="MM/DD/YYYY"
                id={props.name}
                value={props.value}
                minDate={props.minDate}
                maxDate={props.maxDate}
                onChange={props.onChange}
                KeyboardButtonProps={{
                    'aria-label': 'change date',
                }}
                TextFieldComponent={TextFieldComponent}
                autoOk={true}
            />
        </MuiPickersUtilsProvider>
    </div>
}


export default DatePicker;
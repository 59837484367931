import {adminSettingsActionTypes} from '../actions/adminSettingsActionTypes';
import {userActionTypes} from '../actions/userActionTypes';
const initialState = { userModalOpen:false, userModalMode:'add', configData : null, activeAdminUser : {
  firstName: '',
  role:'',
  roleName : '',
  lastName: '',
  phoneNum: '',
  email: '',
  team: '',
  clients: [],
  programs: [],
  twoFactorAuth: ''
}, usersLoading : false, clientsLoading:false, programsLoading:false, userError:null, clientsError:null, programsError:null,userList:[], clientList : [], programList:[], updatingUser: true, creatingUser: true};
export function settings(state = initialState, action) {
  switch (action.type) {
    case adminSettingsActionTypes.GET_ADMIN_USERS_REQUEST:
      return {...state,
        usersLoading : true
    }; 
    case adminSettingsActionTypes.GET_ADMIN_USERS_SUCCESS:
      return {...state,
        usersLoading : false,
        userList : action.data
    };     
    case adminSettingsActionTypes.GET_ADMIN_USERS_FAILURE:
      return {...state,
        usersLoading : false,
        userError : action.error
    };  
    case adminSettingsActionTypes.GET_CLIENTS_REQUEST:
      return {...state,
        clientsLoading : true
    }; 
    case adminSettingsActionTypes.GET_CLIENTS_SUCCESS:
      return {...state,
        clientsLoading : false,
        clientList : action.data
    };     
    case adminSettingsActionTypes.GET_CLIENTS_FAILURE:
      return {...state,
        clientsLoading : false,
        clientError : action.error
    };   
    case adminSettingsActionTypes.GET_PROGRAMS_REQUEST:
      return {...state,
        programsLoading : true
    }; 
    case adminSettingsActionTypes.GET_PROGRAMS_SUCCESS:
      return {...state,
        programsLoading : false,
        programList : action.data
    };     
    case adminSettingsActionTypes.GET_PROGRAMS_FAILURE:
      return {...state,
        programsLoading : false,
        programError : action.error
    };
    case adminSettingsActionTypes.GET_ADMIN_CONFIG_REQUEST:
      return {...state,
        loadingConfigData : true
    }; 
    case adminSettingsActionTypes.GET_ADMIN_CONFIG_SUCCESS:
      return {...state,
        configDataLoaded : true,
        loadingConfigData:false,
        configData : action.data
    };    
    case adminSettingsActionTypes.GET_ADMIN_CONFIG_FAILURE:
      return {...state,
        configDataError : action.error,
        configDataLoaded : false,
        loadingConfigData:false
    };   

    case userActionTypes.CREATE_ADMIN_USER_REQUEST:
      return {...state,
        creatingUser : true,
        userCreationError: false
    }; 
    case userActionTypes.CREATE_ADMIN_USER_SUCCESS:
      return {...state,
        creatingUser : false,
        userCreatedSuccessfully : true,
        userList : action.users,
        userCreationError: false
        // userModalOpen : false
    };    
    case userActionTypes.CREATE_ADMIN_USER_FAILURE:
      return {...state,
        userCreationError : action.error
    };   
    case userActionTypes.UPDATE_ADMIN_USER_REQUEST:
      return {...state,
        updatingUser : true,
        userUpdateError : false
    }; 
    case userActionTypes.UPDATE_ADMIN_USER_SUCCESS:
      return {...state,
        updatingUser : false,
        userUpdatedSuccessfully : true,
        userList : action.users,
        // userModalOpen : false,
        userUpdateError : false
    };    
    case userActionTypes.UPDATE_ADMIN_USER_FAILURE:
      return {...state,
        userUpdateError : action.error
    };   
    case adminSettingsActionTypes.SHOW_ADD_USER_MODAL:
      return {...state,
        userModalOpen : action.show,
        userModalMode : action.mode,
        activeAdminUser : action.user || initialState.activeAdminUser,
        userUpdateError : '',
        userCreationError : '',
        updatingUser: true,
        creatingUser: true,
    };   
    case adminSettingsActionTypes.SET_ACTIVE_ADMIN_USER:
      return {...state,
        activeAdminUser : action.user
    };   

    default:
      return state
  }
}
import React from "react";
import { utils } from "../../../../helpers/utils";
import FlagIcon from "../../../Common/FlagIcon/FlagIcon";
import AdminActionButton from "../../../Common/AdminActionButton/AdminActionButton";
import { applicationService } from "../../../../services/applicationService";

class ApplicationTab extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  viewApplication = (id) => {
    window.open(`viewapplication/${id}`, "_blank");
  };
  reviewRetrofitApplication = (application) => {
    let rId = "";
    if (application.isPrimaryReviewer) {
      rId = application && application.reviewer.value;
    } else {
      rId = application && application.secondaryReviewer.value;
    }
    let appId = application.id;
    let forSecondaryReview = "Peer Review Assigned";
    let currentFlagStatus = application.flag ? application.flag.status : "";
    applicationService.getRetrofitApplicationReviewState(rId).then((data) => {
      if (utils.isNullOrEmpty(data)) {
        if (currentFlagStatus === forSecondaryReview) {
          window.open(`secondaryreview/${appId}`, "_blank");
        } else {
          window.open(`primaryreview/${appId}`, "_blank");
        }
      } else if (data.reviewerState === "Idle") {
        if (data) {
          if (currentFlagStatus === forSecondaryReview) {
            window.open(`secondaryreview/${appId}`, "_blank");
          } else {
            window.open(`primaryreview/${appId}`, "_blank");
          }
        }
      } else if (data.reviewerState === "In-Progress") {
        alert(`Please note that you can review only one application at a time. Pause and close the open application review tab to proceed with reviewing the current application.`);
      }
    });
  }

  render() {
    const { application, currentProgram } = this.props;
    let showReviewDocument = false;
    if (
      currentProgram === "retrofit" ||
      application.status === "Application Draft Submitted" ||
      application.status === "Application Draft Under Review" ||
      application.status === "Application Information Requested" ||
      application.status === "Application Rejected"
    ) {
      showReviewDocument = true;
    }
    const status = application.status ? application.status.toLowerCase() : "";
    const statusConfig = {
      showReviewapplication: status === "application draft under review",
      showRejectApplication: ["application information requested", "application draft under review"].includes(status),
    };
    return (
      <div className="preproject-details-row" id={this.props.id}>
        <div
          className={application.hasOwnProperty("isActive") && !application.isActive ? "preproject-details inactive" : "preproject-details"}
        >
          <div>
            <label>ID:</label>
            <span>
              {application.flag ? (
                <React.Fragment>
                  <FlagIcon currentStatus={application.flag.status} className="flag-app-icon" color={application.flag.colorCode} />
                </React.Fragment>
              ) : (
                ""
              )}
              {application.appNo}
            </span>
          </div>
          {currentProgram !== "retrofit" ? (
            <>
              <div>
                <label>Customer:</label>
                <span>{application.customerCompanyName}</span>
              </div>
              <div>
                <label>Contractor:</label>
                <span>{application.contractorCompanyName}</span>
              </div>
              <div>
                <label>Status:</label>
                <span>{application.status}</span>
              </div>
              <div>
                <label>Assigned for Review:</label>
                <span>{application["Pre-Project - Application Draft Under Review Time"]}</span>
              </div>
              <div>
                <label>Days in Review:</label>
                <span>{application.daysInReview}</span>
              </div>
              <div>
                <label>Submitted Date:</label>
                <span>{application.submissionDate}</span>
              </div>
              <div>
                <label>Estimated Incentive:</label>
                <span>{utils.formatAsCAD(application.estimatedIncentive)}</span>
              </div>
              <div>
                <label>IR Response Received:</label>
                <span>{application.IRResponseReceived}</span>
              </div>
            </>
          ) : (
            <>
              <div>
                <label>Application Name:</label>
                <span>{application.applicationName}</span>
              </div>
              <div>
                <label>Application Project Type:</label>
                <span>{application.applicationProjectType}</span>
              </div>
              <div>
                <label>Email:</label>
                <span>{application.applicantEmail}</span>
              </div>
              <div>
                <label>Multi-Site:</label>
                <span>{application.multiSite}</span>
              </div>
              <div>
                <label>Status:</label>
                <span>{application.status}</span>
              </div>
              <div>
                <label>Created Date:</label>
                <span>{application.createdDate}</span>
              </div>
              <div>
                <label>Submitted Date:</label>
                <span>{application.submissionDate}</span>
              </div>
              <div>
                <label>Estimated Incentive:</label>
                <span>{utils.formatAsCAD(application.estimatedIncentive)}</span>
              </div>
              <div>
                <label>IR Response:</label>
                <span>{application.preProjectResubmission}</span>
              </div>
            </>
          )}
          <div className="show-more-btn" onClick={() => this.props.ShowMoreDetails(this.props.id)}></div>
        </div>
        <div className="preproject-more-details">
          <div>
            <AdminActionButton
              iconClass={`view-icon`}
              actionName={`Review Board-View Application`}
              viewApplication={this.viewApplication}
              application={application}
              title={"View Application"}
            />
            {application.hasOwnProperty("isActive") && !application.isActive ? (
              ""
            ) : (
              <React.Fragment>
                {/* <AdminActionButton 
                            iconClass={`status-log-icon`}
                            actionName={`Review Board-Flag Logs`}
                            showActionModal={this.props.showActionModal}
                            application={application}
                            appModalType={'flag-logs-application'}
                            title={'Flag Logs'}
                        /> */}
                <AdminActionButton
                  iconClass={`flag-icon`}
                  actionName={`Review Board-Application Flags`}
                  showActionModal={this.props.showActionModal}
                  application={application}
                  appModalType={"flag-logs-application"}
                  title={"Application Flag"}
                />
                <AdminActionButton
                  iconClass={`status-log-icon`}
                  actionName={`Review Board-Internal Status Logs`}
                  showActionModal={this.props.showActionModal}
                  application={application}
                  appModalType={"internal-status-logs"}
                  title={"Internal Status Logs"}
                />
                <AdminActionButton
                  iconClass={`approve-app-icon`}
                  actionName={`Review Board-Start Primary Review`}
                  reviewRetrofitApplication={this.reviewRetrofitApplication}
                  // showActionModal={this.props.showActionModal}
                  application={application}
                  appModalType={"start-primary-review"}
                  title={"Start Primary Review"}
                />
                <AdminActionButton
                  iconClass={`approve-app-icon`}
                  actionName={`Review Board-Start Secondary Review`}
                  reviewRetrofitApplication={this.reviewRetrofitApplication}
                  // showActionModal={this.props.showActionModal}
                  application={application}
                  appModalType={"start-secondary-review"}
                  title={"Start Secondary Review"}
                />
                {statusConfig.showReviewapplication ? (
                  <AdminActionButton
                    iconClass={`req-info-icon`}
                    actionName={`Review Board-Request Information`}
                    showActionModal={this.props.showActionModal}
                    application={application}
                    appModalType={"information-requested"}
                    title={"Request Information"}
                  />
                ) : (
                  ""
                )}
                {statusConfig.showRejectApplication ? (
                  <AdminActionButton
                    iconClass={`reject-app-icon`}
                    actionName={`Review Board-Reject Application`}
                    showActionModal={this.props.showActionModal}
                    application={application}
                    appModalType={"reject-app"}
                    title={"Reject Application"}
                  />
                ) : (
                  ""
                )}
                {statusConfig.showReviewapplication ? (
                  <AdminActionButton
                    iconClass={`approve-app-icon`}
                    actionName={`Review Board-Approve Application`}
                    showActionModal={this.props.showActionModal}
                    application={application}
                    appModalType={"review-application"}
                    title={"Approve Application"}
                  />
                ) : (
                  ""
                )}
                <AdminActionButton
                  iconClass={`status-log-icon`}
                  actionName={`Review Board-Status Logs`}
                  showActionModal={this.props.showActionModal}
                  application={application}
                  appModalType={"view-status-log"}
                  title={"Status Logs"}
                />
                <AdminActionButton
                  iconClass={`change-request-icon`}
                  actionName={`Review Board-View Change Requests`}
                  showActionModal={this.props.showActionModal}
                  application={application}
                  appModalType={"view-change-requests"}
                  title={"View Change Requests"}
                />
                <AdminActionButton
                  iconClass={`ir-completed`}
                  actionName={`Review Board-IR Completed`}
                  showActionModal={this.props.showActionModal}
                  application={application}
                  appModalType={"ir-completed"}
                  title={"IR Completed"}
                />
                <AdminActionButton
                  iconClass={`enerva-guidance`}
                  actionName={`Review Board-Enerva Guidance Received`}
                  showActionModal={this.props.showActionModal}
                  application={application}
                  appModalType={"enerva-guidance-received"}
                  title={"Enerva Guidance Received"}
                />
                <AdminActionButton
                  iconClass={`ieso-completed`}
                  actionName={`Review Board-IESO Exception Decision`}
                  showActionModal={this.props.showActionModal}
                  application={application}
                  appModalType={"ieso-exception-decision"}
                  title={"IESO Exception Decision"}
                />
                <AdminActionButton
                  iconClass={`app-rejected`}
                  actionName={`Review Board-Application Rejected as Ineligible`}
                  showActionModal={this.props.showActionModal}
                  application={application}
                  appModalType={"application-rejected-as-ineligible"}
                  title={"Application Rejected as Ineligible"}
                />
                <AdminActionButton
                  iconClass={`app-withdraw`}
                  actionName={`Review Board-Application Withdrawn`}
                  showActionModal={this.props.showActionModal}
                  application={application}
                  appModalType={"application-rejected-as-ineligible"}
                  title={"Application Withdrawn"}
                />
                <AdminActionButton
                  iconClass={`app-cancelled`}
                  actionName={`Review Board-Application Cancelled`}
                  showActionModal={this.props.showActionModal}
                  application={application}
                  appModalType={"application-rejected-as-ineligible"}
                  title={"Application Cancelled"}
                />
                <AdminActionButton
                  iconClass={`ir-completed`}
                  actionName={`Review Board-Application Approved`}
                  showActionModal={this.props.showActionModal}
                  application={application}
                  appModalType={"application-approved"}
                  title={"Application Approved"}
                />
              </React.Fragment>
            )}
            {showReviewDocument && (
              <AdminActionButton
                iconClass={`upload-icon`}
                actionName={`Review Board-Review Documents`}
                showActionModal={this.props.showActionModal}
                application={application}
                appModalType={"review-documents"}
                title={"Review Documents"}
              />
            )}
            <AdminActionButton
              iconClass={`notes-icon`}
              actionName={`Review Board-Internal Notes`}
              showActionModal={this.props.showActionModal}
              application={application}
              appModalType={"view-notes"}
              title={"Internal Notes"}
            />
            <AdminActionButton
              iconClass={`state-icon`}
              actionName={`Review Board-Application State`}
              showActionModal={this.props.showActionModal}
              application={application}
              appModalType={"application-state"}
              title={"Application State"}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default ApplicationTab;

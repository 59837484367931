export const participantActionTypes = {
    SHOW_ADD_PARTICIPANT_MODAL: 'SHOW_ADD_PARTICIPANT_MODAL',
    SET_ACTIVE_PARTICIPANT : 'PARTICIPANT_SET_ACTIVE_PARTICIPANT',
    GET_PARTICIPANTS_REQUEST : 'GET_PARTICIPANTS_REQUEST',
    GET_PARTICIPANTS_SUCCESS : 'GET_PARTICIPANTS_SUCCESS',
    GET_PARTICIPANTS_FAILURE : 'GET_PARTICIPANTS_FAILURE',
    GET_PARTICIPANT_DETAILS_REQUEST: 'GET_PARTICIPANT_DETAILS_REQUEST',
    GET_PARTICIPANT_DETAILS_SUCCESS: 'GET_PARTICIPANT_DETAILS_SUCCESS',
    GET_PARTICIPANT_DETAILS_FAILURE: 'GET_PARTICIPANT_DETAILS_FAILURE',
    GET_PARTICIPANT_STATUS_LOGS_REQUEST: 'GET_PARTICIPANT_STATUS_LOGS_REQUEST',
    GET_PARTICIPANT_STATUS_LOGS_SUCCESS: 'GET_PARTICIPANT_STATUS_LOGS_SUCCESS',
    GET_PARTICIPANT_STATUS_LOGS_FAILURE: 'GET_PARTICIPANT_STATUS_LOGS_FAILURE',
    RESET_EMAIL_VERIFICATION_MODAL : 'RESET_EMAIL_VERIFICATION_MODAL'
};
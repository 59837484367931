import React from 'react';
import './ChangeRequestBoard.css';
import Input from '../../Common/Input/Input';
import ChangeRequests from './ChangeRequests/ChangeRequests'
import ProgramLayout from '../../Common/ProgramLayout';
import LoaderComponent from '../../Common/Loader/Loader'
import Modal from '../../Common/Modal/Modal';
import { connect } from 'react-redux'
import { appActions } from '../../../redux/actions/appActions';
import Button from '../../Common/Button/Button'
import { AuthContext } from '../../../firebaseAuthContext';
import { utils } from '../../../helpers/utils';
import ChangeRequestReview from './ChangeRequestReview/ChangeRequestReview';
import ViewRequest from './ViewRequest/ViewRequest';
import { applicationService } from '../../../services/applicationService';
import { userActions } from "../../../redux/actions/userActions";
class ChangeRequestBoard extends React.Component {
  static contextType = AuthContext;
  constructor(props) {
    super(props);
    let currentProgram = "esb";
    if(props.history){
      if(this.props.history.location.pathname.includes("speed")){
        currentProgram = "speed";
      }
    }
    this.props.setActiveProgram(currentProgram);
    this.state = {
      showReviewChangeRequestModal: false,
      showViewRequestModal : false,
      showRejectRequestModal : false,
      showReinitiateRequestModal:false,
      reqRejectedSuccessfully: false,
      confirmingRejection : false,
      rejectedReqReason : '',
      reqReinitiatedSuccessfully: false,
      confirmingReinitiation : false,
      reiniateReqReason : '',
      changeRequestSelRecord:{},
      confirmingReinstation:false,
      reinstationMessage : false,
      changeRequestActionHeader:'CHANGE REQUEST REVIEW',
      SelectedStatusFilters: [],
      SelectedReqTypeFilters: [],
      searchInput: "",
      stream: [],
      sortConfig: { name : "createdAt", direction : "desc"}
    }
  }

  showActionModal = (selectedRequestData) => {
    this.setState({
      changeRequestSelRecord:selectedRequestData
    },()=>{
      if (selectedRequestData.modalType === 'v') {
        const newToggleState = !this.state.showEditParticipantModal;
        this.setState({
          showEditParticipantModal: newToggleState
        })
      } else if (selectedRequestData.modalType === 'review') {
        this.setState({
          showReviewChangeRequestModal: !this.state.showReviewChangeRequestModal
        })
      } else if (selectedRequestData.modalType === 'view-change-request') {
        this.setState({
          showViewRequestModal: !this.state.showViewRequestModal
        })
      } else if (selectedRequestData.modalType === 'reject-change-request') {
        this.setState({
          showRejectRequestModal: !this.state.showRejectRequestModal,
          reqRejectedSuccessfully:false
        });
      } else if (selectedRequestData.modalType === 'reinitiate-change-request') {
        this.setState({
          showReviewChangeRequestModal: !this.state.showReviewChangeRequestModal
        })
      }
    });
  }

  handleRejectReasonChange = (name, value) => {
    this.setState({
      rejectedReqReason: value
    })
  }


  closeActionModal = (reload) => {
    this.setState({
      showReviewChangeRequestModal:false,
      showViewRequestModal:false,
      showEditParticipantModal: false,
      showReviewParticipantModal: false,
      showSubmittedApplicationsModal : false,
      showRejectRequestModal: false,
      showReinitiateRequestModal:false,
      showResendMailModal: false,
      showDocumentsModal: false,
      confirmingSuspension:false,
      confirmingFlagForQC : false,
      suspensionMessage:'',
      reinstationMessage:'',
      flagForQCMessage:''
    }, () => {
      if(reload){
        this.searchData(true);
      }
    });
  }

  handleReasonChange = (name, value) => {
    this.setState({
      [name]: value
    })
  }

  confirmRejectReq = ()=>{
    this.setState({
      confirmingRejection: !this.state.confirmingRejection
    });
  }

  confirmReiniateRequest = ()=>{
    this.setState({
      confirmingReinitiation: !this.state.confirmingReinitiation
    });
  }

  rejectChangeRequest = () => {
    let { rejectedReqReason } = this.state
    let { adminUserInfo } = this.props
    if (rejectedReqReason) {
        applicationService.updateChangeRequest(
          this.state.changeRequestSelRecord.id,
          {
              status : 'Rejected',
              actionTaker : `${adminUserInfo.firstName} ${adminUserInfo.lastName}`,
              changeNote : rejectedReqReason
          }
      ).then((res) => {
          this.setState({
              reqRejectedSuccessfully: true
          }, () => {
              setTimeout(() => {
                  this.closeActionModal(true);
              }, 5000);
          })
      });
    }
  }

  reinitiateChangeRequest = () => {
    let { reiniateReqReason } = this.state
    let { adminUserInfo } = this.props
    if (reiniateReqReason) {
        applicationService.updateChangeRequest(
          this.state.changeRequestSelRecord.id,
          {
              status : 'Submitted',
              actionTaker : `${adminUserInfo.firstName} ${adminUserInfo.lastName}`,
              changeNote : reiniateReqReason
          }
      ).then((res) => {
          this.setState({
              reqReinitiatedSuccessfully: true
          }, () => {
              setTimeout(() => {
                  this.closeActionModal(true);
              }, 5000);
          })
      });
    }
  }

  setFilters = (newState)=>{
    this.setState(newState,()=>{
      this.searchData(true);
    });
  }

  searchData = (refresh = false)=>{
    const { sortConfig, SelectedStatusFilters, SelectedReqTypeFilters, searchInput, stream} = this.state;
    let config = { statuses : SelectedStatusFilters, requestTypes: SelectedReqTypeFilters, sort : sortConfig, program: this.props.currentProgram, stream:stream };
    this.props.searchChangeRequests(searchInput, config, refresh ? 0 : this.props.changeReqListOffset);
  }

  render() {
    const { displayName } = this.context.user;
    const { contractorsLoading, customersLoading , currentProgram } = this.props;
    const {
      showViewRequestModal,
      showReviewChangeRequestModal,
      showRejectRequestModal,
      showReinitiateRequestModal,
      reqRejectedSuccessfully,
      confirmingRejection,
      rejectedReqReason,
      reqReinitiatedSuccessfully,
      confirmingReinitiation,
      reiniateReqReason
    } = this.state;
    return (
      <ProgramLayout showFooter={false} props={this.props}>
        <Modal
            IsOpen={showReviewChangeRequestModal}
            className={'esb-modal'}
            handleCloseModal={() => this.closeActionModal()}
            modalSize={'large'}
          >
            <ChangeRequestReview changeReqId={this.state.changeRequestSelRecord.id} closeActionModal={this.closeActionModal}/>
        </Modal>
        <Modal
            IsOpen={showViewRequestModal}
            className={'esb-modal'}
            handleCloseModal={() => this.closeActionModal()}
            modalSize={'large'}
          >
            <ViewRequest changeReqId={this.state.changeRequestSelRecord.id} closeActionModal={this.closeActionModal}/>
        </Modal>
        <Modal
            HeaderText={`Confirm Reinitiation`}
            className={'esb-modal'}
            IsOpen={showReinitiateRequestModal}
            handleCloseModal={() => this.closeActionModal()}
          >
            {reqReinitiatedSuccessfully ?
              <p>Change request reinitiated successfully!</p>
              :
              <React.Fragment>
                <div className='information-container'>
                  <div className='information-container-section'>
                  <p>Change request reinitiated successfully!</p>
                    <Input type="richtext" disabled={confirmingReinitiation} value={reiniateReqReason} onChange={this.handleReasonChange} name="reiniateReqReason" />
                  </div>
                </div>
                <div className='button-set'>
                  {confirmingReinitiation ? <Button
                  className='signup-btn'
                  title={'Reinitiate Request'}
                  uppercase
                  onClick={this.reinitiateChangeRequest}
                  disabled={!reiniateReqReason}
                />: <Button
                className='signup-btn'
                title={'Confirm Reinitiation'}
                uppercase
                onClick={this.confirmReiniateRequest}
                disabled={!reiniateReqReason}
              /> }
                  {confirmingReinitiation ? <Button
                    className='inversed-btn'
                    title={'EDIT'}
                    uppercase
                    onClick={this.confirmReiniateRequest}
                  /> : <Button
                  className='inversed-btn'
                  title={'CANCEL'}
                  uppercase
                  onClick={() => this.closeActionModal()}
                />}
                </div>
              </React.Fragment>
            }
          </Modal>
        <Modal
            HeaderText={`Reason for Rejection`}
            className={'esb-modal'}
            IsOpen={showRejectRequestModal}
            handleCloseModal={() => this.closeActionModal()}
          >
            {reqRejectedSuccessfully ?
              <p>Change request rejected successfully!</p>
              :
              <React.Fragment>
                <div className='information-container'>
                  <div className='information-container-section'>
                    <Input type="richtext" disabled={confirmingRejection} value={rejectedReqReason} onChange={this.handleRejectReasonChange} name="rejectedReqReason" />
                  </div>
                </div>
                <div className='button-set'>
                  {confirmingRejection ? <Button
                      className='inversed-btn'
                      title={'EDIT'}
                      uppercase
                      onClick={this.confirmRejectReq}
                    /> : <Button
                    className='inversed-btn'
                    title={'CANCEL'}
                    uppercase
                    onClick={() => this.closeActionModal()}
                  />}
                  {confirmingRejection ? <Button
                  className='signup-btn'
                  title={'Reject Request'}
                  uppercase
                  onClick={this.rejectChangeRequest}
                  disabled={utils.isNullOrEmpty(utils.cleanHTMLText(rejectedReqReason))}
                />: <Button
                className='signup-btn'
                title={'Confirm Rejection'}
                uppercase
                onClick={this.confirmRejectReq}
                disabled={utils.isNullOrEmpty(utils.cleanHTMLText(rejectedReqReason))}
              /> }
                </div>
              </React.Fragment>
            }
          </Modal>
        {(contractorsLoading || customersLoading) ?
          <LoaderComponent>
            <h3>Welcome, <span className="highlight-text">{displayName}</span></h3>
            <h3>Please wait while the page loads..</h3>
          </LoaderComponent>
          : null}
        <React.Fragment>
          <ChangeRequests
                  SelectedStatusFilters={this.state.SelectedStatusFilters}
                  SelectedReqTypeFilters={this.state.SelectedReqTypeFilters}
                  stream={this.state.stream}
                  searchInput={this.state.searchInput}
                  sortConfig={this.state.sortConfig}
                  setFilters={this.setFilters}
                  showActionModal={this.showActionModal}
                  showModal={this.props.showModal}
          />
        </React.Fragment>
       
      </ProgramLayout>
    );
  }
}

function mapState(state) {
  const { adminUserInfo, contractorsLoading, customersLoading,emailResent, resendingEmail , currentProgram } = state.authentication;
  const { changeReqListOffset } = state.application;
  return { currentProgram,adminUserInfo, changeReqListOffset, contractorsLoading, customersLoading, emailResent, resendingEmail };
}

const actionCreators = {
  searchChangeRequests: appActions.searchChangeRequests,
  setActiveProgram : userActions.setActiveProgram,
}

export default connect(mapState, actionCreators)(ChangeRequestBoard);

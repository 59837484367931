import React from "react";
import Tabs from "../../Common/Tabs/Tabs";
import PreProject from "./PreProject/PreProject";
import Payment from "./Payment/Payment";
import Cancelled from "./Cancel/Cancel";
import PreApprovalNotice from "./PreApprovalNotice/PreApprovalNotice";
import PostProject from "./PostProject/PostProject";
import ProgramLayout from "../../Common/ProgramLayout";
import Modal from "../../Common/Modal/Modal";
import { connect } from "react-redux";
import { userActions } from "../../../redux/actions/userActions";
import { appActions } from "../../../redux/actions/appActions";
import Button from "../../Common/Button/Button";
import { AuthContext } from "../../../firebaseAuthContext";
import Input from "../../Common/Input/Input";
import { userService } from "../../../services/userService";
import { applicationService } from "../../../services/applicationService";
import "./ApplicationsBoard.css";
import StatusLogs from "../ReviewBoard/StatusLogs/StatusLogs";
import ViewChangeRequests from "./ViewChangeRequests/ViewChangeRequests";
import ViewChangeRequestLogs from "./ViewChangeRequestLogs/ViewChangeRequestLogs";
import ApplicationNotes from "./Notes/Notes.js";
import AppFlagLogs from "./AppFlagLogs/AppFlagLogs.js";
import AppMeasureLogs from "./AppMeasureLogs/AppMeasureLogs.js";
import Drawer from "../../Common/Drawer/Drawer";
import { utils } from "../../../helpers/utils";
import { adminSettingsActions } from "../../../redux/actions/adminSettingsActions";
import { debounce } from "debounce";
import FlagApplication from "./flagApplication/flagApplication";
import FlagIcon from "../../Common/FlagIcon/FlagIcon";
import AssignPaymentForApproval from "./AssignPaymentForApproval/AssignPaymentForApproval";
import CustomerApplications from "./CustomerApplications/CustomerApplications";
import RejectRecommendation from "./RejectRecommendation/RejectRecommendation";
import ReviewDocuments from "./ReviewDocuments/ReviewDocuments";
import SendCheque from "./SendCheque/SendCheque";
import AppState from "./AppState/AppState";
import PortCustomer from "./PortCustomer/PortCustomer";
import { ReactComponent as DownArrow } from "../../../images/DownArrow.svg";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import firebase from "../../../helpers/Firebase";
import CardSlider from "../../Common/CardsSlider/CardsSlider";
import MoveToWaitlisted from "./Waitlist/MoveToWaitlisted";
import InfoVector from "../../../images/SmallInfoIcon.svg";
import LoaderComponent from "../../Common/Loader/Loader";
import PeakDemandSavingsIcon from "../../../images/PeakDemandSavings.svg";
import IncentiveIcon from "../../../images/IncentiveIcon.svg";
import EnergySavings from "../../../images/EnergySavings.svg";
import TooltipInfo from "../../Common/TooltipInfo/TooltipInfo";
// Import Swiper styles

class ApplicationsBoard extends React.Component {
  static contextType = AuthContext;
  constructor(props) {
    super(props);
    this.db = firebase.db;
    this.applicationBoardCollectionName = "applicationBoardStats";
    this.docRef = null;

    let currentProgram = "esb";
    this.docRef = this.db.collection(this.applicationBoardCollectionName).doc("allStats");

    if (props.history) {
      if (this.props.history.location.pathname.includes("speed")) {
        currentProgram = "speed";
        this.docRef = this.db.collection("era").doc("speed").collection(this.applicationBoardCollectionName).doc("allStats");
      }
      if (this.props.history.location.pathname.includes("retrofit")) {
        currentProgram = "retrofit";
        this.docRef = this.db.collection("ieso").doc("retrofit").collection(this.applicationBoardCollectionName).doc("allStats");
      }
    }
    this.props.setActiveProgram(currentProgram);
    this.state = {
      openAssignInvoiceDrawer: false,
      openAssignPrimaryDrawer: false,
      openAssignSecondaryDrawer: false,
      activeChangeReqLogs: [],
      showFlagModal: false,
      reviewerList: [],
      firstReviewer: null,
      secondReviewer: null,
      activeAppId: "",
      activeAppNo: "",
      activeCustomerId: "",
      activeCustomerCategory: "",
      showAssignForReviewModal: false,
      showSendChequeModal: false,
      showStatusLogsModal: false,
      showChangeRequestsModal: false,
      showChangeRequestLogsModal: false,
      showNotesModal: false,
      showFlagLogsModal: false,
      showMeasureLogsModal: false,
      showAddFlagModal: false,
      showAssignforApprovalModal: false,
      showRejectRecommendationModal: false,
      showAppStateModal: false,
      showPortCustomer: false,
      showViewCustomerApplications: false,
      showIncentivePaymentCompletedModal: false,
      IncentivePaymentCompletedSuccessfully: false,
      selectedTab: "Pre-Project",
      assigningReviewer: false,
      assignedSuccessfully: false,
      appFlagLoading: true,
      appFlagType: "",
      showReviewDocumentsModal: false,
      application: {},
      openChangeProgramMenu: false,
      showWaitlistModal: false,
      selectedProgramReports: "ALL",
      reportingStats: {},
      primaryReviewTypeOptions: [
        { label: "New Assigned Pre-Project Application", value: "New Assigned Pre-Project Application" },
        { label: "QA-QC - Mandatory", value: "QA-QC - Mandatory" },
        { label: "QA-QC - Random Selection", value: "QA-QC - Random Selection" },
      ],
      postProjectPrimaryReviewTypeOptions: [
        { label: "New Assigned Post-Project Application", value: "New Assigned Post-Project Application" },
        { label: "QA-QC - Mandatory", value: "QA-QC - Mandatory" },
        { label: "QA-QC - Random Selection", value: "QA-QC - Random Selection" },
      ],
      primaryReviewer: "",
      primaryReviewType: "",
      showRetrofitReviewerAssignedMesssage: false,
      showRetrofitInternalStatusLogsModal: false,
      postProjectReviewer: "",
      postProjectReviewType: "",
      secondaryReviewer: "",
      secondaryReviewType: "",
      postProjectSecondaryReviewer: "",
      postProjectSecondaryReviewType: "",
      showRetrofitReviewerAssignedMesssage: false,
      showRetrofitInternalStatusLogsModal: false,
      postProjectReviewer: "",
      postProjectReviewType: "",
      appInvoice: [],
      finalInvoiceReviewer: "",
      applicationPageLoader: true,
      "Pre-ProjectFilters": {
        SelectedStatusFilters: [],
        SelectedFlagFilters: [],
        StageFilters: [1, 2, 3, 4, 5, 6, 7, 8, 9, 26, 27, 28, 29, 30, 31, 37, 38, 39],
        searchInput: "",
        sortConfig: { name: "createdAt", direction: "desc" },
        onlyActiveApps: [true, false],
        stream: [],
      },
      "Post-ProjectFilters": {
        SelectedStatusFilters: [],
        SelectedFlagFilters: [],
        StageFilters: [11, 12, 13, 14, 15, 16, 17, 18, 19],
        searchInput: "",
        sortConfig: { name: "createdAt", direction: "desc" },
        onlyActiveApps: [true, false],
        stream: [],
      },
      "Pre-ApprovalFilters": {
        SelectedStatusFilters: [],
        SelectedFlagFilters: [],
        searchInput: "",
        sortConfig: { name: "createdAt", direction: "desc" },
        onlyActiveApps: [true, false],
        stream: [],
      },
      PaymentFilters: {
        SelectedStatusFilters: [],
        StageFilters: [10, 20, 21, 22, 32, 33, 34, 35, 36, 40],
        SelectedFlagFilters: [],
        stream: [],
        searchInput: "",
        sortConfig: { name: "createdAt", direction: "desc" },
      },
      CancelledFilters: {
        SelectedStatusFilters: [],
        StageFilters: [25],
        SelectedFlagFilters: [],
        searchInput: "",
        sortConfig: { name: "createdAt", direction: "desc" },
      },
    };
    this.searchDebouncedData = debounce(this.searchData, 450);
  }

  handleappFlagTypeChange = (value) => {
    this.setState({
      appFlagType: value,
    });
  };

  componentDidMount() {
    this.props.loadUserDetails();
    if (this.props.history.location.pathname.includes("retrofit")) {
      this.props.loadAdminConfigRetrofit();
    } else {
      this.props.loadAdminConfig();
    }
    userService.getReviewers().then((reviewerList) => {
      this.setState({
        reviewerList,
      });
    });
    this.docRef.onSnapshot((snapshot) => {
      let stats = snapshot.data();
      if (this.props.history.location.pathname.includes("retrofit")) {
        stats = snapshot.data()["retrofit"];
      }
      this.setState({ reportingStats: stats, applicationPageLoader: false });
    });
  }

  search;

  setFilters = (stage, newState) => {
    let updateObj = {};
    updateObj = {
      [`${stage}Filters`]: {
        ...this.state[`${stage}Filters`],
        ...newState,
      },
    };
    this.setState(updateObj, () => {
      this.searchDebouncedData();
    });
  };

  searchData = () => {
    let stage = this.state.selectedTab;
    const { sortConfig, SelectedStatusFilters, SelectedFlagFilters, searchInput, onlyActiveApps, StageFilters, stream } =
      this.state[`${stage}Filters`];
    let config = {
      statuses: SelectedStatusFilters,
      stage: StageFilters,
      flags: SelectedFlagFilters,
      sort: sortConfig,
      activeApps: onlyActiveApps,
      program: this.props.currentProgram,
      stream: stream,
    };
    if (this.props.currentProgram !== "retrofit") {
      this.props.getApplicationsByStage(stage, searchInput, config, 0);
    } else {
      this.props.getApplicationsByStageRetrofit(stage, searchInput, config, 0);
    }
  };

  showActionModal = (selectedapplicationData) => {
    this.setState({
      selectedapplicationData,
    });
    if (selectedapplicationData.modalType === "assign-for-review") {
      let updateState = {
        activeAppId: selectedapplicationData.id,
        activeAppstage: selectedapplicationData.stage,
        openDrawer: true,
      };
      if (!utils.isNullOrEmpty(selectedapplicationData.reviewer)) {
        if (selectedapplicationData.stage === "Pre-Project") {
          updateState.firstReviewer = selectedapplicationData.reviewer;
        } else if (selectedapplicationData.stage === "Post-Project") {
          updateState.firstReviewer = selectedapplicationData.reviewer;
        }
      }

      if (!utils.isNullOrEmpty(selectedapplicationData.postProjectReviewer)) {
        if (selectedapplicationData.stage === "Payment") {
          updateState.firstReviewer = selectedapplicationData.postProjectReviewer;
        } else if (selectedapplicationData.stage === "Post-Project") {
          updateState.secondReviewer = selectedapplicationData.postProjectReviewer;
        }
      }

      if (!utils.isNullOrEmpty(selectedapplicationData.paymentReviewer)) {
        if (selectedapplicationData.stage === "Payment") {
          updateState.secondReviewer = selectedapplicationData.paymentReviewer;
        }
      }
      this.setState(updateState);
    } else if (selectedapplicationData.modalType === "assign-for-primary-review") {
      let updateState = {
        activeAppId: selectedapplicationData.id,
        activeAppstage: selectedapplicationData.stage,
        openAssignPrimaryDrawer: true,
      };
      this.setState(updateState);
    } else if (selectedapplicationData.modalType === "assign-for-secondary-review") {
      let updateState = {
        activeAppId: selectedapplicationData.id,
        activeAppstage: selectedapplicationData.stage,
        openAssignSecondaryDrawer: true,
      };
      this.setState(updateState);
    } else if (selectedapplicationData.modalType === "send-cheque") {
      this.setState({
        showSendChequeModal: true,
        activeAppId: selectedapplicationData.id,
      });
    } else if (selectedapplicationData.modalType === "assign-invoice-for-review") {
      applicationService.getApplicationRetrofit(selectedapplicationData.id).then((data) => {
        this.setState({
          activeAppId: selectedapplicationData.id,
          appInvoice: data && data.applicationDetails ? data.applicationDetails.invoice : [],
          openAssignInvoiceDrawer: true,
        });
      });
    } else if (selectedapplicationData.modalType === "view-status-log") {
      this.props.getLogs(selectedapplicationData.id);
      this.setState({
        showStatusLogsModal: true,
        activeAppId: selectedapplicationData.id,
      });
    } else if (selectedapplicationData.modalType === "internal-status-logs") {
      this.setState({
        showRetrofitInternalStatusLogsModal: true,
        activeAppId: selectedapplicationData.id,
        application: selectedapplicationData,
      });
    } else if (selectedapplicationData.modalType === "view-change-requests") {
      this.props.getChangeRequests(selectedapplicationData.id);
      this.setState({
        showChangeRequestsModal: true,
        activeAppId: selectedapplicationData.id,
      });
    } else if (selectedapplicationData.modalType === "view-change-request-logs") {
      this.props.getChangeRequestLogs(selectedapplicationData.id);
      this.setState({
        showChangeRequestLogsModal: true,
        activeAppId: selectedapplicationData.id,
      });
    } else if (selectedapplicationData.modalType === "view-notes") {
      const { adminUserInfo } = this.props;
      this.setState({
        showNotesModal: true,
        adminUserInfo: adminUserInfo,
        activeAppId: selectedapplicationData.id,
        application: selectedapplicationData,
      });
    } else if (selectedapplicationData.modalType === "flag-application") {
      this.setState({
        showAddFlagModal: true,
        activeAppId: selectedapplicationData.id,
      });
    } else if (selectedapplicationData.modalType === "flag-logs-application") {
      this.props.getFlagLogs(selectedapplicationData.id);
      this.setState({
        showFlagLogsModal: true,
        activeAppId: selectedapplicationData.id,
      });
    } else if (selectedapplicationData.modalType === "measure-logs-application") {
      this.props.getMeasureLogs(selectedapplicationData.id);
      this.setState({
        showMeasureLogsModal: true,
        activeAppId: selectedapplicationData.id,
      });
    } else if (selectedapplicationData.modalType === "approve-recommendation") {
      this.setState({
        showAssignforApprovalModal: true,
        activeAppId: selectedapplicationData.id,
      });
    } else if (selectedapplicationData.modalType === "reject-Recommendation") {
      this.setState({
        showRejectRecommendationModal: true,
        activeAppId: selectedapplicationData.id,
      });
    } else if (selectedapplicationData.modalType === "application-state") {
      this.setState({
        showAppStateModal: true,
        activeAppId: selectedapplicationData.id,
      });
    } else if (selectedapplicationData.modalType === "port-customer") {
      this.setState({
        showPortCustomer: true,
        activeAppId: selectedapplicationData.id,
      });
    } else if (selectedapplicationData.modalType === "view-customer-applications") {
      //console.log("selected application----",selectedapplicationData);
      this.setState({
        showViewCustomerApplications: true,
        application: selectedapplicationData,
        activeCustomerId: selectedapplicationData.customerId,
        activeCustomerCategory: selectedapplicationData.customerCategory,
      });
    } else if (selectedapplicationData.modalType === "incentive-payment-completed") {
      //console.log("selected application----",selectedapplicationData);
      this.setState({
        showIncentivePaymentCompletedModal: true,
        activeAppId: selectedapplicationData.id,
        activeAppNo: selectedapplicationData.appNo,
      });
    } else if (selectedapplicationData.modalType === "review-documents") {
      // console.log("selected application---->",selectedapplicationData);
      const { adminUserInfo } = this.props;
      this.setState({
        showReviewDocumentsModal: true,
        adminUserInfo: adminUserInfo,
        activeAppId: selectedapplicationData.id,
        application: selectedapplicationData,
      });
    } else if (selectedapplicationData.modalType === "app-waitlist") {
      this.setState({
        showWaitlistModal: true,
        activeAppId: selectedapplicationData.id,
      });
    } else {
      // Do Nothing
    }
  };

  closeActionModal = (reload) => {
    this.setState(
      {
        showFlagModal: false,
        showAssignForReviewModal: false,
        showStatusLogsModal: false,
        showChangeRequestsModal: false,
        showChangeRequestLogsModal: false,
        showNotesModal: false,
        firstReviewer: null,
        secondReviewer: null,
        appFlagType: null,
        assignedSuccessfully: false,
        openDrawer: false,
        openAssignInvoiceDrawer: false,
        openAssignSecondaryDrawer: false,
        openAssignPrimaryDrawer: false,
        showAddFlagModal: false,
        showFlagLogsModal: false,
        showMeasureLogsModal: false,
        showAssignforApprovalModal: false,
        showRejectRecommendationModal: false,
        showViewCustomerApplications: false,
        showIncentivePaymentCompletedModal: false,
        IncentivePaymentCompletedSuccessfully: false,
        activeAppNo: "",
        showReviewDocumentsModal: false,
        showSendChequeModal: false,
        showAppStateModal: false,
        showPortCustomer: false,
        showRetrofitInternalStatusLogsModal: false,
        showWaitlistModal: false,
      },
      () => {
        if (reload) {
          this.searchData();
        }
      }
    );
  };

  flagApplicationForQaQc = async () => {};

  onTabChange = (selectedTab) => {
    this.setState({
      selectedTab,
    });
  };
  selectPrimaryReviewer = (opt) => {
    this.setState({
      primaryReviewer: opt,
    });
  };
  selectPostProjectPrimaryReviewer = (opt) => {
    this.setState({
      postProjectReviewer: opt,
    });
  };
  selectPostProjectPrimaryReviewerOptions = (opt) => {
    this.setState({
      postProjectReviewType: opt,
    });
  };
  selectPrimaryReviewerOptions = (opt) => {
    this.setState({
      primaryReviewType: opt,
    });
  };
  selectSecondaryReviewer = (opt) => {
    this.setState({
      secondaryReviewer: opt,
    });
  };
  selectPostProjectSecondaryReviewer = (opt) => {
    this.setState({
      postProjectSecondaryReviewer: opt,
    });
  };
  selectPostProjectSecondaryReviewerOptions = (opt) => {
    this.setState({
      postProjectSecondaryReviewType: opt,
    });
  };
  selectSecondaryReviewerOptions = (opt) => {
    this.setState({
      secondaryReviewType: opt,
    });
  };
  setPrimaryReviewer = () => {
    this.setState({
      showRetrofitReviewerAssignedMesssage: true,
    });
  };
  selectInvoiceReviewer = (opt) => {
    this.setState({
      finalInvoiceReviewer: opt,
    });
  };
  selectPostProjectInvoiceReviewer = (opt) => {
    this.setState({
      postProjectInvoiceReviewer: opt,
    });
  };
  onReviewerChange = (firstReviewer) => {
    this.setState({
      firstReviewer,
    });
  };
  onSecondReviewerChange = (secondReviewer) => {
    this.setState({
      secondReviewer,
    });
  };
  completeIncentivePayment = () => {
    const { activeAppId } = this.state;
    applicationService.updateApplication(activeAppId, { status: "Incentive Payment Completed", actionNote: "" }).then((res) => {
      this.setState(
        {
          IncentivePaymentCompletedSuccessfully: true,
        },
        () => {
          setTimeout(() => {
            this.closeActionModal(true);
          }, 5000);
        }
      );
    });
  };
  assignApplicationForReview = () => {
    const { adminUserInfo } = this.props;
    const { appFlagType } = this.state;
    this.setState(
      {
        assigningReviewer: true,
      },
      () => {
        var reviewer = { label: "" };
        let assignReviewObj = {
          applicationId: this.state.activeAppId,
          stage: this.state.selectedapplicationData.stage,
          reviewerId: this.state.firstReviewer,
          postProjectReviewer: this.state.secondReviewer,
          actionTaker: `${adminUserInfo && adminUserInfo.firstName} ${adminUserInfo && adminUserInfo.lastName}`,
        };
        if (this.state.selectedapplicationData.stage !== "Post-Project" && this.state.selectedapplicationData.stage !== "Payment") {
          reviewer = this.state.firstReviewer;
        } else if (this.state.selectedapplicationData.stage === "Payment") {
          reviewer = this.state.secondReviewer;
        } else {
          reviewer = this.state.secondReviewer;
          assignReviewObj.flag = { definition: `${appFlagType.definition}`, status: appFlagType.value, colorCode: appFlagType.colorCode };
        }
        assignReviewObj.actionNote = `Application assigned to ${reviewer && reviewer.label} for review`;

        applicationService.assignApplicationToReviewer(assignReviewObj).then((res) => {
          this.setState(
            {
              assigningReviewer: false,
              assignedSuccessfully: true,
            },
            () => {
              setTimeout(() => {
                this.closeActionModal(true);
              }, 500);
            }
          );
        });
      }
    );
  };

  assignApplicationForPrimaryReview = (assignType) => {
    const { adminUserInfo } = this.props;
    const {
      finalInvoiceReviewer,
      primaryReviewType,
      primaryReviewer,
      selectedapplicationData,
      postProjectReviewType,
      postProjectReviewer,
      activeAppId,
    } = this.state;
    let assignReviewObj = {
      applicationId: `${activeAppId}`,
      stage: selectedapplicationData.stage,
      actionTaker: `${adminUserInfo && adminUserInfo.firstName} ${adminUserInfo && adminUserInfo.lastName}`,
    };

    if (selectedapplicationData.stage === "Pre-Project" && assignType === "appReviewer") {
      assignReviewObj.reviewerId = primaryReviewer;
      assignReviewObj.flag =
        selectedapplicationData && selectedapplicationData.subType === "assign"
          ? primaryReviewType.label
          : selectedapplicationData.flag.status;
      assignReviewObj.actionNote = `Application assigned to ${primaryReviewer.label} for review`;
    } else if (selectedapplicationData.stage === "Post-Project" && assignType === "appReviewer") {
      assignReviewObj.reviewerId = postProjectReviewer;
      assignReviewObj.flag =
        selectedapplicationData && selectedapplicationData.subType === "assign"
          ? postProjectReviewType.label
          : selectedapplicationData.flag.status;
      assignReviewObj.actionNote = `Application assigned to ${postProjectReviewer.label} for review`;
    } else if (selectedapplicationData.stage === "Payment" && assignType === "invoiceReviewer") {
      assignReviewObj.reviewerId = finalInvoiceReviewer;
      assignReviewObj.actionNote = `Application invoice assigned to ${finalInvoiceReviewer.label} for review`;
      assignReviewObj.flag = "Invoice Assigned for Review";
    }
    applicationService.assignRetrofitApplicationToReviewer(assignReviewObj).then((res) => {
      this.setState(
        {
          primaryReviewer: "",
          primaryReviewType: "",
          postProjectReviewType: "",
          postProjectReviewer: "",
          showRetrofitReviewerAssignedMesssage: false,
          finalInvoiceReviewer: "",
        },
        () => {
          this.closeActionModal(true);
        }
      );
    });
  };

  assignApplicationForSecondaryReview = () => {
    const { adminUserInfo } = this.props;
    const { secondaryReviewer, selectedapplicationData, postProjectSecondaryReviewer, activeAppId } = this.state;
    let assignReviewObj = {
      applicationId: `${activeAppId}`,
      stage: selectedapplicationData.stage,
      actionTaker: `${adminUserInfo && adminUserInfo.firstName} ${adminUserInfo && adminUserInfo.lastName}`,
    };

    if (selectedapplicationData.stage === "Pre-Project") {
      assignReviewObj.reviewerId = secondaryReviewer;
      assignReviewObj.flag = "Peer Review Assigned";
      assignReviewObj.actionNote = `Application assigned to ${secondaryReviewer.label} for Secondary Review`;
    } else if (selectedapplicationData.stage === "Post-Project") {
      assignReviewObj.reviewerId = postProjectSecondaryReviewer;
      assignReviewObj.flag = "Peer Review Assigned";
      assignReviewObj.actionNote = `Application assigned to ${postProjectSecondaryReviewer.label} Secondary Review`;
    }
    applicationService.assignRetrofitApplicationToSecondaryReviewer(assignReviewObj).then((res) => {
      this.setState(
        {
          secondaryReviewer: "",
          postProjectSecondaryReviewer: "",
          showRetrofitReviewerAssignedMesssage: false,
        },
        () => {
          this.closeActionModal(true);
        }
      );
    });
  };

  toggleAssignInvoiceDrawer = (status) => {
    let updateObj = {
      openAssignInvoiceDrawer: status,
    };
    if (!status) {
      updateObj.finalInvoiceReviewer = "";
      updateObj.showRetrofitReviewerAssignedMesssage = false;
    }
    this.setState(updateObj);
  };

  toggleDrawer = (status) => {
    let updateObj = {
      openDrawer: status,
    };
    if (!status) {
      updateObj.appFlagType = null;
      updateObj.secondReviewer = null;
      updateObj.firstReviewer = null;
    }
    this.setState(updateObj);
  };

  toggleAssignSecondaryDrawer = (status) => {
    let updateObj = {
      openAssignSecondaryDrawer: status,
    };
    if (!status) {
      updateObj.primaryReviewType = "";
      updateObj.primaryReviewer = "";
      updateObj.postProjectReviewType = "";
      updateObj.postProjectReviewer = "";
      updateObj.showRetrofitReviewerAssignedMesssage = false;
    }
    this.setState(updateObj);
  };

  toggleAssignPrimaryDrawer = (status) => {
    let updateObj = {
      openAssignPrimaryDrawer: status,
    };
    if (!status) {
      updateObj.primaryReviewType = "";
      updateObj.primaryReviewer = "";
      updateObj.postProjectReviewType = "";
      updateObj.postProjectReviewer = "";
      updateObj.showRetrofitReviewerAssignedMesssage = false;
    }
    this.setState(updateObj);
  };

  handleOpenProgramChangeMenu = (currVal) => {
    this.setState({ openChangeProgramMenu: !currVal });
  };

  render() {
    const { displayName } = this.context.user;
    const {
      activeStatusLogs,
      activeChangeReqLogs,
      activeChangeRequests,
      appStatusLogsLoading,
      appChangeReqLogsLoading,
      appNotesLoading,
      flagLogs,
      appFlagsLoading,
      measureLogs,
      configData,
      currentProgram,
    } = this.props;
    const {
      openAssignInvoiceDrawer,
      openAssignSecondaryDrawer,
      openAssignPrimaryDrawer,
      selectedapplicationData,
      showFlagModal,
      showAssignForReviewModal,
      showSendChequeModal,
      assigningReviewer,
      firstReviewer,
      secondReviewer,
      reviewerList,
      assignedSuccessfully,
      showStatusLogsModal,
      showChangeRequestsModal,
      showChangeRequestLogsModal,
      openDrawer,
      showNotesModal,
      showAddFlagModal,
      showFlagLogsModal,
      showMeasureLogsModal,
      appFlagType,
      showAssignforApprovalModal,
      showRejectRecommendationModal,
      showViewCustomerApplications,
      showIncentivePaymentCompletedModal,
      activeAppNo,
      IncentivePaymentCompletedSuccessfully,
      showRetrofitInternalStatusLogsModal,
      showReviewDocumentsModal,
      showAppStateModal,
      showPortCustomer,
      primaryReviewTypeOptions,
      postProjectPrimaryReviewTypeOptions,
      primaryReviewType,
      primaryReviewer,
      showRetrofitReviewerAssignedMesssage,
      postProjectReviewType,
      postProjectReviewer,
      finalInvoiceReviewer,
      appInvoice,
      secondaryReviewer,
      postProjectSecondaryReviewer,
      openChangeProgramMenu,
      selectedProgramReports,
      reportingStats,
      showWaitlistModal,
      applicationPageLoader,
    } = this.state;
    if (applicationPageLoader) {
      return <LoaderComponent />;
    }

    const participantInfo = selectedapplicationData && selectedapplicationData.userInfo;
    var reviewerLabel = "";
    if (selectedapplicationData && selectedapplicationData.stage === "Post-Project") {
      reviewerLabel = "Pre-Project Reviewer";
    } else if (selectedapplicationData && selectedapplicationData.stage === "Payment") {
      reviewerLabel = "Post-Project Reviewer";
    } else {
      reviewerLabel = "Reviewer";
    }
    let flagsList = [];
    if (configData) {
      flagsList = configData.appFlags ? configData.appFlags.flags.filter((item) => item.assignReview === "Show") : [];
    }
    let sortedReviewerList = reviewerList.sort(function (a, b) {
      return a.firstName.localeCompare(b.firstName);
    });
    let widthOutput = document.querySelector("#width");

    function updateSize() {
      widthOutput = window.innerWidth;
    }
    updateSize();
    window.addEventListener("resize", updateSize());
    let dataToBeShown = {};
    if (currentProgram === "speed") {
      dataToBeShown = !utils.isNullOrEmpty(reportingStats) && reportingStats[currentProgram];
    }
    if (currentProgram === "retrofit") {
      dataToBeShown = reportingStats;
    }
    if (currentProgram === "esb") {
      dataToBeShown = !utils.isNullOrEmpty(reportingStats) && reportingStats[selectedProgramReports.toLowerCase()];
    }
    console.log(dataToBeShown);
    const cards =
      currentProgram !== "esb"
        ? currentProgram === "speed"
          ? [
              // Your card components go here
              <>
                <p>Active Pre-Project</p>
                <div className="details-box">
                  <span className="count">{dataToBeShown["preProjectCount"]}</span>
                  <div className="vertical-bar"></div>
                  <span className="dollar" title="Estimated Incentive">
                    <img src={IncentiveIcon} alt="Dollar" />
                    {dataToBeShown["preProjectTotalIncentive"] ? utils.formatNumberWithOnlyCommas(dataToBeShown["preProjectTotalIncentive"]) : "$0.00"}
                  </span>
                </div>
              </>,
              <>
                <div style={{ display: "flex", flexDirection: "row", width: "100%"  }}>
                  <p>Pre-Approved</p>
                  <TooltipInfo
                    customIcon={InfoVector}
                    dynamicPosition={true}
                    helpText={`Sum of all pre-approved applications awaiting post-project submission`}
                  />
                </div>
                <div className="details-box">
                  <span className="count">{dataToBeShown["preApprovalCount"]}</span>
                  <div className="vertical-bar"></div>
                  <span className="dollar" title="Pre-Approved Incentive">
                    <img src={IncentiveIcon} alt="Dollar" />
                    {dataToBeShown["preApprovalTotalIncentive"] ? utils.formatNumberWithOnlyCommas(dataToBeShown["preApprovalTotalIncentive"]) : "$0.00"}
                  </span>
                </div>
              </>,
              <>
                <div style={{ display: "flex", flexDirection: "row", width: "100%"  }}>
                  <p>Active Post-Project</p>
                  <TooltipInfo
                    customIcon={InfoVector}
                    dynamicPosition={true}
                    helpText={`Sum of all active Post-Project applications awaiting Incentive Recommendation`}
                  />
                </div>
                <div className="details-box">
                  <span className="count">{dataToBeShown["postProjectCount"]}</span>
                  <div className="vertical-bar"></div>
                  <span className="dollar" title="Actual Incentive">
                    <img src={IncentiveIcon} alt="Dollar" />
                    {dataToBeShown["postProjectTotalIncentive"] ? utils.formatNumberWithOnlyCommas(dataToBeShown["postProjectTotalIncentive"]) : "$0.00"}
                  </span>
                </div>
              </>,
              <>
                <div style={{ display: "flex", flexDirection: "row", width: "100%"  }}>
                  <p>Paid Out</p>
                  <TooltipInfo
                    customIcon={InfoVector}
                    dynamicPosition={true}
                    helpText={`Sum of all applications that have been approved for payout or have already been paid an incentive`}
                  />
                </div>
                <div className="details-box">
                  <span className="count">{dataToBeShown["paymentCount"]}</span>
                  <div className="vertical-bar"></div>
                  <span className="dollar" title="Approved Incentive">
                    <img src={IncentiveIcon} alt="Dollar" />
                    {dataToBeShown["paymentTotalIncentive"] ? utils.formatNumberWithOnlyCommas(dataToBeShown["paymentTotalIncentive"]) : "$0.00"}
                  </span>
                </div>
              </>,
            ]
          : [
              // Your card components go here
              <>
                <div style={{ display: "flex", flexDirection: "row", width: "100%"  }}>
                  <p>Application Pipeline</p>
                  <TooltipInfo
                    customIcon={InfoVector}
                    dynamicPosition={true}
                    helpText={`Applications that are yet to be submitted for Pre-project review`}
                  />
                </div>
                <div className="details-box">
                  <span className="count">{dataToBeShown[selectedProgramReports.toLowerCase()]["preProjectPipelineCount"]}</span>
                  <div className="vertical-bar"></div>
                  <div className="value-box">
                    <span className="dollar" title="Estimated Incentive">
                      <img src={IncentiveIcon} width="17px" alt="Dollar" />
                      {dataToBeShown[selectedProgramReports.toLowerCase()]["preProjectPipelineTotalIncentive"] ? utils.formatNumberWithOnlyCommas(
                        dataToBeShown[selectedProgramReports.toLowerCase()]["preProjectPipelineTotalIncentive"]
                      ) : "$0.00"}
                    </span>
                    <span className="dollar" title="Estimated Energy Savings (kWh)">
                      <img src={EnergySavings} width="17px" alt="Energy Savings" />
                      {dataToBeShown[selectedProgramReports.toLowerCase()]["preProjectPipelineElectricitySavings"] ? utils.formatKwh(dataToBeShown[selectedProgramReports.toLowerCase()]["preProjectPipelineElectricitySavings"]) : "0.00 kWh"}
                    </span>
                    <span className="dollar" title="Estimated Peak Demand Savings (kW)">
                      <img src={PeakDemandSavingsIcon} width="17px" alt="Peak Demand Savings" />
                      {dataToBeShown[selectedProgramReports.toLowerCase()]["preProjectPipelinePeakDemandSavings"] ? utils.formatKw(dataToBeShown[selectedProgramReports.toLowerCase()]["preProjectPipelinePeakDemandSavings"]) : "0.00 kW"}
                    </span>
                  </div>
                </div>
              </>,
              <>
                <p>Active Pre-Project</p>
                <div className="details-box">
                  <span className="count">{dataToBeShown[selectedProgramReports.toLowerCase()]["preProjectCount"]}</span>
                  <div className="vertical-bar"></div>
                  <div className="value-box">
                    <span className="dollar" title="Estimated Incentive">
                      <img src={IncentiveIcon} width="17px" alt="Dollar" />
                      {dataToBeShown[selectedProgramReports.toLowerCase()]["preProjectTotalIncentive"] ? utils.formatNumberWithOnlyCommas(dataToBeShown[selectedProgramReports.toLowerCase()]["preProjectTotalIncentive"]) : "$0.00"}
                    </span>
                    <span className="dollar" title="Estimated Energy Savings (kWh)">
                      <img src={EnergySavings} width="17px" alt="Energy Savings" />
                      {dataToBeShown[selectedProgramReports.toLowerCase()]["preProjectElectricitySavings"] ? utils.formatKwh(dataToBeShown[selectedProgramReports.toLowerCase()]["preProjectElectricitySavings"]) : "0.00 kWh"}
                    </span>
                    <span className="dollar" title="Estimated Peak Demand Savings (kW)">
                      <img src={PeakDemandSavingsIcon} width="17px" alt="Peak Demand Savings" />
                      {dataToBeShown[selectedProgramReports.toLowerCase()]["preProjectPeakDemandSavings"] ? utils.formatKw(dataToBeShown[selectedProgramReports.toLowerCase()]["preProjectPeakDemandSavings"]) : "0.00 kW"}
                    </span>
                  </div>
                </div>
              </>,
              <>
                <div style={{ display: "flex", flexDirection: "row", width: "100%"  }}>
                  <p>Pre-Approved</p>
                  <TooltipInfo
                    customIcon={InfoVector}
                    dynamicPosition={true}
                    helpText={`Sum of all pre-approved applications awaiting post-project submission`}
                  />
                </div>
                <div className="details-box">
                  <span className="count">{dataToBeShown[selectedProgramReports.toLowerCase()]["preApprovedCount"]}</span>
                  <div className="vertical-bar"></div>
                  <div className="value-box">
                    <span className="dollar" title="Pre-Approved Incentive">
                      <img src={IncentiveIcon} width="17px" alt="Dollar" />
                      {dataToBeShown[selectedProgramReports.toLowerCase()]["preApprovedTotalIncentive"] ? utils.formatNumberWithOnlyCommas(dataToBeShown[selectedProgramReports.toLowerCase()]["preApprovedTotalIncentive"]) : "$0.00"}
                    </span>
                    <span className="dollar" title="Pre-Approved Energy Savings (kWh)">
                      <img src={EnergySavings} width="17px" alt="Energy Savings" />
                      {dataToBeShown[selectedProgramReports.toLowerCase()]["preApprovedElectricitySavings"] ? utils.formatKwh(dataToBeShown[selectedProgramReports.toLowerCase()]["preApprovedElectricitySavings"]) : "0.00 kWh"}
                    </span>
                    <span className="dollar" title="Pre-Approved Peak Demand Savings (kW)">
                      <img src={PeakDemandSavingsIcon} width="17px" alt="Peak Demand Savings" />
                      {dataToBeShown[selectedProgramReports.toLowerCase()]["preApprovedPeakDemandSavings"] ? utils.formatKw(dataToBeShown[selectedProgramReports.toLowerCase()]["preApprovedPeakDemandSavings"]) : "0.00 kW"}
                    </span>
                  </div>
                </div>
              </>,
              <>
                <div style={{ display: "flex", flexDirection: "row", width: "100%"  }}>
                  <p>Active Post-Project</p>
                  <TooltipInfo
                    customIcon={InfoVector}
                    dynamicPosition={true}
                    helpText={`Sum of all active Post-Project applications awaiting Incentive Recommendation`}
                  />
                </div>
                <div className="details-box">
                  <span className="count">{dataToBeShown[selectedProgramReports.toLowerCase()]["postProjectCount"]}</span>
                  <div className="vertical-bar"></div>
                  <div className="value-box">
                    <span className="dollar" title="Actual Incentive">
                      <img src={IncentiveIcon} width="17px" alt="Dollar" />
                      {dataToBeShown[selectedProgramReports.toLowerCase()]["postProjectTotalIncentive"] ? utils.formatNumberWithOnlyCommas(dataToBeShown[selectedProgramReports.toLowerCase()]["postProjectTotalIncentive"]) : "$0.00"}
                    </span>
                    <span className="dollar" title="Actual Energy Savings (kWh)">
                      <img src={EnergySavings} width="17px" alt="Energy Savings" />
                      {dataToBeShown[selectedProgramReports.toLowerCase()]["postProjectElectricitySavings"] ? utils.formatKwh(dataToBeShown[selectedProgramReports.toLowerCase()]["postProjectElectricitySavings"]) : "0.00 kWh"}
                    </span>
                    <span className="dollar" title="Actual Peak Demand Savings (kW)">
                      <img src={PeakDemandSavingsIcon} width="17px" alt="Peak Demand Savings" />
                      {dataToBeShown[selectedProgramReports.toLowerCase()]["postProjectPeakDemandSavings"] ? utils.formatKw(dataToBeShown[selectedProgramReports.toLowerCase()]["postProjectPeakDemandSavings"]) : "0.00 kW"}
                    </span>
                  </div>
                </div>
              </>,
              <>
                <div style={{ display: "flex", flexDirection: "row", width: "100%"  }}>
                  <p>Incentive Invoice Pending</p>
                  {/* <HtmlTooltip
                  title={
                    <React.Fragment>
                      {"Sum of all active applications in post-project that haven't yet been approved or rejected"}
                    </React.Fragment>
                  }
                  placement="right"
                  arrow
                >
                  <span>
                    <img src={InfoVector} alt="Info" />
                  </span>
                </HtmlTooltip> */}
                </div>
                <div className="details-box">
                  <span className="count">{dataToBeShown[selectedProgramReports.toLowerCase()]["incentiveInvoicePendingCount"]}</span>
                  <div className="vertical-bar"></div>
                  <div className="value-box">
                    <span className="dollar" title="Actual Incentive">
                      <img src={IncentiveIcon} width="17px" alt="Dollar" />
                      {dataToBeShown[selectedProgramReports.toLowerCase()]["incentiveInvoicePendingTotalIncentive"] ? utils.formatNumberWithOnlyCommas(
                        dataToBeShown[selectedProgramReports.toLowerCase()]["incentiveInvoicePendingTotalIncentive"]
                      ) : "$0.00"}
                    </span>
                    <span className="dollar" title="Actual Energy Savings (kWh)">
                      <img src={EnergySavings} width="17px" alt="Energy Savings" />
                      {dataToBeShown[selectedProgramReports.toLowerCase()]["incentiveInvoicePendingElectricitySavings"] ? utils.formatKwh(dataToBeShown[selectedProgramReports.toLowerCase()]["incentiveInvoicePendingElectricitySavings"]) : "0.00 kWh"}
                    </span>
                    <span className="dollar" title="Actual Peak Demand Savings (kW)">
                      <img src={PeakDemandSavingsIcon} width="17px" alt="Peak Demand Savings" />
                      {dataToBeShown[selectedProgramReports.toLowerCase()]["incentiveInvoicePendingPeakDemandSavings"] ? utils.formatKw(dataToBeShown[selectedProgramReports.toLowerCase()]["incentiveInvoicePendingPeakDemandSavings"]) : "0.00 kW"}
                    </span>
                  </div>
                </div>
              </>,
              <>
                <div style={{ display: "flex", flexDirection: "row", width: "100%"  }}>
                  <p>Paid Out</p>
                  <TooltipInfo
                    customIcon={InfoVector}
                    dynamicPosition={true}
                    helpText={`Sum of all applications that have been approved for payout or have already been paid an incentive`}
                  />
                </div>
                <div className="details-box">
                  <span className="count">{dataToBeShown[selectedProgramReports.toLowerCase()]["paymentCount"]}</span>
                  <div className="vertical-bar"></div>
                  <div className="value-box">
                    <span className="dollar" title="Approved Incentive">
                      <img src={IncentiveIcon} width="17px" alt="Dollar" />
                      {dataToBeShown[selectedProgramReports.toLowerCase()]["paymentTotalIncentive"] ? utils.formatNumberWithOnlyCommas(dataToBeShown[selectedProgramReports.toLowerCase()]["paymentTotalIncentive"]) : "$0.00"}
                    </span>
                    <span className="dollar" title="Approved Energy Savings (kWh)">
                      <img src={EnergySavings} width="17px" alt="Energy Savings" />
                      {dataToBeShown[selectedProgramReports.toLowerCase()]["paymentElectricitySavings"] ? utils.formatKwh(dataToBeShown[selectedProgramReports.toLowerCase()]["paymentElectricitySavings"]) : "0.00 kWh"}
                    </span>
                    <span className="dollar" title="Approved Peak Demand Savings (kW)">
                      <img src={PeakDemandSavingsIcon} width="17px" alt="Peak Demand Savings" />
                      {dataToBeShown[selectedProgramReports.toLowerCase()]["paymentPeakDemandSavings"] ? utils.formatKw(dataToBeShown[selectedProgramReports.toLowerCase()]["paymentPeakDemandSavings"]) : "0.00 kW"}
                    </span>
                  </div>
                </div>
              </>,
            ]
        : [
            // Your card components go here
            <>
              <p>Waitlist</p>
              <div className="details-box">
                <span className="count">{dataToBeShown["waitlistedCount"]}</span>
                <div className="vertical-bar"></div>
                <span className="dollar" title="Estimated Incentive">
                  <img src={IncentiveIcon} alt="Dollar" />
                  {dataToBeShown["waitlistedTotalIncentive"] ? utils.formatNumberWithOnlyCommas(dataToBeShown["waitlistedTotalIncentive"]) : "$0.00"}
                </span>
              </div>
            </>,
            <>
              <p>Active Pre-Project</p>
              <div className="details-box">
                <span className="count">{dataToBeShown["preProjectCount"]}</span>
                <div className="vertical-bar"></div>
                <span className="dollar" title="Estimated Incentive">
                  <img src={IncentiveIcon} alt="Dollar" />
                  {dataToBeShown["preProjectTotalIncentive"] ? utils.formatNumberWithOnlyCommas(dataToBeShown["preProjectTotalIncentive"]) : "$0.00"}
                </span>
              </div>
            </>,
            <>
              <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
                <p>Pre-Approved</p>
                <TooltipInfo
                  customIcon={InfoVector}
                  dynamicPosition={true}
                  helpText={`Sum of all pre-approved applications awaiting post-project submission`}
                />
              </div>
              <div className="details-box">
                <span className="count">{dataToBeShown["preApprovalCount"]}</span>
                <div className="vertical-bar"></div>
                <span className="dollar" title="Pre-Approved Incentive">
                  <img src={IncentiveIcon} alt="Dollar" />
                  {dataToBeShown["preApprovalTotalIncentive"] ? utils.formatNumberWithOnlyCommas(dataToBeShown["preApprovalTotalIncentive"]) : "$0.00"}
                </span>
              </div>
            </>,
            <>
              <div style={{ display: "flex", flexDirection: "row", width: "100%"  }}>
                <p>Active Post-Project</p>
                <TooltipInfo
                  customIcon={InfoVector}
                  dynamicPosition={true}
                  helpText={`Sum of all active Post-Project applications awaiting Incentive Recommendation`}
                />
              </div>
              <div className="details-box">
                <span className="count">{dataToBeShown["postProjectCount"]}</span>
                <div className="vertical-bar"></div>
                <span className="dollar" title="Actual Incentive">
                  <img src={IncentiveIcon} alt="Dollar" />
                  {dataToBeShown["postProjectTotalIncentive"] ? utils.formatNumberWithOnlyCommas(dataToBeShown["postProjectTotalIncentive"]) : "$0.00"}
                </span>
              </div>
            </>,
            <>
              <div style={{ display: "flex", flexDirection: "row", width: "100%"  }}>
                <p>Paid Out</p>
                <TooltipInfo
                  customIcon={InfoVector}
                  dynamicPosition={true}
                  helpText={`Sum of all applications that have been approved for payout or have already been paid an incentive`}
                />
              </div>
              <div className="details-box">
                <span className="count">{dataToBeShown["paymentCount"]}</span>
                <div className="vertical-bar"></div>
                <span className="dollar" title="Approved Incentive">
                  <img src={IncentiveIcon} alt="Dollar" />
                  {dataToBeShown["paymentTotalIncentive"] ? utils.formatNumberWithOnlyCommas(dataToBeShown["paymentTotalIncentive"]) : "$0.00"}
                </span>
              </div>
            </>,
          ];
    return (
      <ProgramLayout showFooter={false} props={this.props}>
        <Drawer toggleDrawer={this.toggleDrawer} openDrawer={openDrawer} className={"program-panel-drawer"}>
          <div className="assign-reviewer-modal">
            <h3>ASSIGN APPLICATION FOR REVIEW</h3>

            {assignedSuccessfully ? (
              selectedapplicationData && selectedapplicationData.stage === "Post-Project" ? (
                <p>Application assigned successfully to a post-project reviewer!</p>
              ) : selectedapplicationData && selectedapplicationData.stage === "Payment" ? (
                <p>Payment stage reviewer for this application has been assigned.</p>
              ) : (
                <p>Application assigned to reviewer successfully!</p>
              )
            ) : (
              <React.Fragment>
                {selectedapplicationData && selectedapplicationData.stage !== "Post-Project" ? (
                  selectedapplicationData && selectedapplicationData.stage !== "Payment" ? (
                    <p>Please select a reviewer for this application below</p>
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )}
                <Input
                  label={reviewerLabel}
                  placeholder={"Please select a reviewer"}
                  type="dropdown"
                  name="reviewer"
                  disabled={
                    (selectedapplicationData && selectedapplicationData.stage === "Post-Project") ||
                    (selectedapplicationData && selectedapplicationData.stage === "Payment")
                  }
                  onChange={this.onReviewerChange}
                  value={
                    selectedapplicationData && selectedapplicationData.stage === "Payment"
                      ? selectedapplicationData.postProjectReviewer
                      : firstReviewer
                      ? firstReviewer
                      : undefined
                  }
                  options={reviewerList.map((reviewer) => {
                    return { label: `${reviewer.firstName} ${reviewer.lastName}`, value: reviewer.uid };
                  })}
                />
                {selectedapplicationData &&
                (selectedapplicationData.stage === "Post-Project" || selectedapplicationData.stage === "Payment") ? (
                  <React.Fragment>
                    <p>
                      Please select a {selectedapplicationData.stage === "Payment" ? `payment` : `post-project`} reviewer for this
                      application below
                    </p>
                    <Input
                      label={"Reviewer"}
                      placeholder={"Please select a reviewer"}
                      type="dropdown"
                      name="reviewer"
                      onChange={this.onSecondReviewerChange}
                      value={secondReviewer ? secondReviewer : undefined}
                      options={reviewerList.map((reviewer) => {
                        return { label: `${reviewer.firstName} ${reviewer.lastName}`, value: reviewer.uid };
                      })}
                      isSearchable={false}
                    />
                    {selectedapplicationData && selectedapplicationData.stage !== "Payment" ? (
                      <Input
                        label={"Select a Flag"}
                        placeholder={"Please select a flag"}
                        className={"flag-dropdown"}
                        type="dropdown"
                        name="appflagtype"
                        onChange={this.handleappFlagTypeChange}
                        value={appFlagType}
                        iconEnabled={true}
                        options={flagsList.map((flag) => {
                          return {
                            label: `${flag.status}`,
                            value: flag.status,
                            definition: flag.definition,
                            colorCode: flag.colorCode,
                            icon: <FlagIcon color={flag.colorCode} />,
                          };
                        })}
                      />
                    ) : (
                      ""
                    )}
                    <div className="button-set">
                      {/* <Button title={"CANCEL"} className="inversed-btn" uppercase onClick={() => this.closeActionModal()} /> */}
                      <Button
                        title={"CONFIRM"}
                        disabled={
                          selectedapplicationData && selectedapplicationData.stage === "Payment"
                            ? secondReviewer
                              ? false
                              : true
                            : secondReviewer && appFlagType
                            ? false
                            : true
                        }
                        uppercase
                        onClick={this.assignApplicationForReview}
                      />
                    </div>
                  </React.Fragment>
                ) : (
                  <div className="button-set">
                    {/* <Button title={"CANCEL"} className="inversed-btn" uppercase onClick={() => this.closeActionModal()} /> */}
                    {/* selectedapplicationData && selectedapplicationData.stage */}
                    <Button title={"CONFIRM"} disabled={firstReviewer ? false : true} uppercase onClick={this.assignApplicationForReview} />
                  </div>
                )}
              </React.Fragment>
            )}
          </div>
        </Drawer>
        <Drawer toggleDrawer={this.toggleAssignPrimaryDrawer} openDrawer={openAssignPrimaryDrawer} className={"program-panel-drawer"}>
          <div className="assign-reviewer-modal">
            <h3>
              {selectedapplicationData && selectedapplicationData.subType === "assign"
                ? "ASSIGN FOR PRIMARY REVIEW"
                : "CHANGE PRIMARY REVIEWER"}
            </h3>
            {!showRetrofitReviewerAssignedMesssage ? (
              selectedapplicationData && selectedapplicationData.stage === "Pre-Project" ? (
                <div>
                  <p>Please select a Primary Reviewer for this application below</p>
                  <Input
                    label={"Primary Reviewer"}
                    placeholder={"Please select a Primary Reviewer"}
                    type="dropdown"
                    name="primaryReviewer"
                    onChange={this.selectPrimaryReviewer}
                    value={
                      selectedapplicationData && selectedapplicationData.reviewer && !primaryReviewer
                        ? selectedapplicationData.reviewer
                        : primaryReviewer
                        ? primaryReviewer
                        : undefined
                    }
                    options={sortedReviewerList.map((primaryReviewer) => {
                      return { label: `${primaryReviewer.firstName} ${primaryReviewer.lastName}`, value: primaryReviewer.uid };
                    })}
                    isSearchable={false}
                  />
                  {selectedapplicationData && selectedapplicationData.subType === "assign" ? (
                    <Input
                      label="Review Type"
                      placeholder="Please select a Review Type"
                      type="dropdown"
                      name="primaryReviewType"
                      onChange={this.selectPrimaryReviewerOptions}
                      value={primaryReviewType ? primaryReviewType : undefined}
                      options={primaryReviewTypeOptions.map((type) => {
                        return type;
                      })}
                      isSearchable={false}
                    />
                  ) : (
                    ""
                  )}
                  <div className="button-set">
                    <Button
                      title={"ASSIGN"}
                      disabled={
                        selectedapplicationData && selectedapplicationData.subType === "change"
                          ? !primaryReviewer
                          : !primaryReviewType || !primaryReviewer
                      }
                      uppercase
                      onClick={() => this.setPrimaryReviewer()}
                    />
                  </div>
                </div>
              ) : (
                <div>
                  <p>Please select a Post-Project Primary Reviewer for this application below</p>
                  <Input
                    label={"Primary Reviewer"}
                    placeholder={"Please select a Primary Reviewer"}
                    type="dropdown"
                    name="postProjectReviewer"
                    onChange={this.selectPostProjectPrimaryReviewer}
                    value={
                      selectedapplicationData && selectedapplicationData.postProjectReviewer && !postProjectReviewer
                        ? selectedapplicationData.postProjectReviewer
                        : postProjectReviewer
                        ? postProjectReviewer
                        : undefined
                    }
                    options={sortedReviewerList.map((primaryReviewer) => {
                      return { label: `${primaryReviewer.firstName} ${primaryReviewer.lastName}`, value: primaryReviewer.uid };
                    })}
                    isSearchable={false}
                  />
                  {selectedapplicationData && selectedapplicationData.subType === "assign" ? (
                    <Input
                      label="Review Type"
                      placeholder="Please select a Review Type"
                      type="dropdown"
                      name="postProjectReviewType"
                      onChange={this.selectPostProjectPrimaryReviewerOptions}
                      value={postProjectReviewType ? postProjectReviewType : undefined}
                      options={postProjectPrimaryReviewTypeOptions.map((type) => {
                        return type;
                      })}
                      isSearchable={false}
                    />
                  ) : (
                    ""
                  )}
                  <div className="button-set">
                    <Button
                      title={"ASSIGN"}
                      disabled={
                        selectedapplicationData && selectedapplicationData.subType === "change"
                          ? !postProjectReviewer
                          : !postProjectReviewer || !postProjectReviewType
                      }
                      uppercase
                      onClick={() => this.setPrimaryReviewer()}
                    />
                  </div>
                </div>
              )
            ) : (
              selectedapplicationData &&
              (selectedapplicationData && selectedapplicationData.stage === "Pre-Project" ? (
                <div>
                  {selectedapplicationData && selectedapplicationData.subType === "change" ? (
                    <p>
                      You are about to change the reviewer of the application from{" "}
                      <span>{selectedapplicationData.reviewer && selectedapplicationData.reviewer.label}</span> to{" "}
                      <span>{primaryReviewer.label}</span>. Are you sure?
                    </p>
                  ) : (
                    <p>
                      You are about to assign the application to <span>{primaryReviewer.label}</span> and the internal status of the
                      application will be changed to <span>{primaryReviewType.label}</span>. Are you sure?
                    </p>
                  )}
                  <div className="button-set retro-btn">
                    <Button
                      title={"CANCEL"}
                      className="inversed-btn"
                      uppercase
                      onClick={() => this.setState({ showRetrofitReviewerAssignedMesssage: false })}
                    />
                    <Button title={"CONFIRM ASSIGNMENT"} uppercase onClick={() => this.assignApplicationForPrimaryReview("appReviewer")} />
                  </div>
                </div>
              ) : (
                <div>
                  {selectedapplicationData && selectedapplicationData.subType === "change" ? (
                    <p>
                      You are about to change the reviewer of the application from{" "}
                      <span>{selectedapplicationData.postProjectReviewer && selectedapplicationData.postProjectReviewer.label}</span> to{" "}
                      <span>{postProjectReviewer.label}</span>. Are you sure?
                    </p>
                  ) : (
                    <p>
                      You are about to assign the application to <span>{postProjectReviewer.label}</span> and the internal status of the
                      application will be changed to <span>{postProjectReviewType.label}</span>. Are you sure?
                    </p>
                  )}
                  <div className="button-set retro-btn">
                    <Button
                      title={"CANCEL"}
                      className="inversed-btn"
                      uppercase
                      onClick={() => this.setState({ showRetrofitReviewerAssignedMesssage: false })}
                    />
                    <Button title={"CONFIRM ASSIGNMENT"} uppercase onClick={() => this.assignApplicationForPrimaryReview("appReviewer")} />
                  </div>
                </div>
              ))
            )}
          </div>
        </Drawer>
        <Drawer toggleDrawer={this.toggleAssignSecondaryDrawer} openDrawer={openAssignSecondaryDrawer} className={"program-panel-drawer"}>
          <div className="assign-reviewer-modal">
            <h3>ASSIGN FOR SECONDARY REVIEW</h3>
            {!showRetrofitReviewerAssignedMesssage ? (
              selectedapplicationData && selectedapplicationData.stage === "Pre-Project" ? (
                <div>
                  <p>Please select a Secondary Reviewer for this application below</p>
                  <Input
                    label="Primary Reviewer"
                    disabled={true}
                    value={selectedapplicationData.reviewer && selectedapplicationData.reviewer.label}
                  />
                  <Input
                    label={"Secondary Reviewer"}
                    placeholder={"Please select a Secondary Reviewer"}
                    type="dropdown"
                    name="secondaryReviewer"
                    onChange={this.selectSecondaryReviewer}
                    value={secondaryReviewer ? secondaryReviewer : undefined}
                    options={sortedReviewerList.map((secondaryReviewer) => {
                      return { label: `${secondaryReviewer.firstName} ${secondaryReviewer.lastName}`, value: secondaryReviewer.uid };
                    })}
                    isSearchable={false}
                  />
                  <div className="button-set">
                    <Button title={"ASSIGN"} disabled={!secondaryReviewer} uppercase onClick={() => this.setPrimaryReviewer()} />
                  </div>
                </div>
              ) : (
                <div>
                  <p>Please select a Post-Project Secondary Reviewer for this application below</p>
                  <Input
                    label="Primary Reviewer"
                    disabled={true}
                    value={
                      selectedapplicationData &&
                      selectedapplicationData.postProjectReviewer &&
                      selectedapplicationData.postProjectReviewer.label
                    }
                  />
                  <Input
                    label={"Secondary Reviewer"}
                    placeholder={"Please select a Secondary Reviewer"}
                    type="dropdown"
                    name="postProjectSecondaryReviewer"
                    onChange={this.selectPostProjectSecondaryReviewer}
                    value={postProjectSecondaryReviewer ? postProjectSecondaryReviewer : undefined}
                    options={sortedReviewerList.map((primaryReviewer) => {
                      return { label: `${primaryReviewer.firstName} ${primaryReviewer.lastName}`, value: primaryReviewer.uid };
                    })}
                    isSearchable={false}
                  />
                  <div className="button-set">
                    <Button title={"ASSIGN"} disabled={!postProjectSecondaryReviewer} uppercase onClick={() => this.setPrimaryReviewer()} />
                  </div>
                </div>
              )
            ) : selectedapplicationData && selectedapplicationData.stage === "Pre-Project" ? (
              <div>
                <p>
                  You are about to assign the application to <span>{secondaryReviewer.label}</span> and the internal status of the
                  application will be changed to <span>Peer Review Assigned</span>. Are you sure?
                </p>
                <div className="button-set retro-btn">
                  <Button
                    title={"CANCEL"}
                    className="inversed-btn"
                    uppercase
                    onClick={() => this.setState({ showRetrofitReviewerAssignedMesssage: false })}
                  />
                  <Button title={"CONFIRM ASSIGNMENT"} uppercase onClick={() => this.assignApplicationForSecondaryReview()} />
                </div>
              </div>
            ) : (
              <div>
                <p>
                  You are about to assign the application to <span>{postProjectSecondaryReviewer.label}</span> and the internal status of
                  the application will be changed to <span>Peer Review Assigned</span>. Are you sure?
                </p>
                <div className="button-set retro-btn">
                  <Button
                    title={"CANCEL"}
                    className="inversed-btn"
                    uppercase
                    onClick={() => this.setState({ showRetrofitReviewerAssignedMesssage: false })}
                  />
                  <Button title={"CONFIRM ASSIGNMENT"} uppercase onClick={() => this.assignApplicationForSecondaryReview()} />
                </div>
              </div>
            )}
          </div>
        </Drawer>
        <Drawer toggleDrawer={this.toggleAssignInvoiceDrawer} openDrawer={openAssignInvoiceDrawer} className={"program-panel-drawer"}>
          <div className="assign-reviewer-modal">
            <h3>
              {selectedapplicationData && selectedapplicationData.subType === "change"
                ? "CHANGE INVOICE REVIEWER"
                : "ASSIGN INVOICE FOR REVIEW"}
            </h3>
            {appInvoice.length === 0 ? (
              <p>No Invoice to show for this application.</p>
            ) : !showRetrofitReviewerAssignedMesssage ? (
              <div>
                <p>Please select a Invoice Reviewer for the invoice below</p>
                <Input
                  label="Post Project Reviewer"
                  type="text"
                  disabled={true}
                  value={selectedapplicationData.postProjectReviewer && selectedapplicationData.postProjectReviewer.label}
                />
                <Input
                  label={"Reviewer"}
                  placeholder={"Please select a Reviewer Name"}
                  type="dropdown"
                  name="finalInvoiceReviewer"
                  onChange={this.selectInvoiceReviewer}
                  value={
                    selectedapplicationData && selectedapplicationData.subType === "change"
                      ? selectedapplicationData.finalInvoiceReviewer
                      : finalInvoiceReviewer
                      ? finalInvoiceReviewer
                      : undefined
                  }
                  options={sortedReviewerList.map((primaryReviewer) => {
                    return { label: `${primaryReviewer.firstName} ${primaryReviewer.lastName}`, value: primaryReviewer.uid };
                  })}
                  isSearchable={false}
                />
                <div className="button-set">
                  <Button title={"ASSIGN"} disabled={!finalInvoiceReviewer} uppercase onClick={() => this.setPrimaryReviewer()} />
                </div>
              </div>
            ) : (
              <div>
                {selectedapplicationData && selectedapplicationData.subType === "change" ? (
                  <p>
                    You are about to change the reviewer of the invoice from{" "}
                    <span>{selectedapplicationData.finalInvoiceReviewer.label}</span> to <span>{finalInvoiceReviewer.label}</span>. Are you
                    sure?
                  </p>
                ) : (
                  <p>
                    You are about to assign the invoice to <span>{finalInvoiceReviewer.label}</span> for review. Are you sure?
                  </p>
                )}
                <div className="button-set retro-btn">
                  <Button
                    title={"CANCEL"}
                    className="inversed-btn"
                    uppercase
                    onClick={() => this.setState({ showRetrofitReviewerAssignedMesssage: false })}
                  />
                  <Button
                    title={"CONFIRM ASSIGNMENT"}
                    uppercase
                    onClick={() => this.assignApplicationForPrimaryReview("invoiceReviewer")}
                  />
                </div>
              </div>
            )}
          </div>
        </Drawer>
        <React.Fragment>
          {/* The below modal is for Flag Contractor/Customer for QC  */}
          <Modal
            IsOpen={showAssignforApprovalModal}
            className={"esb-modal"}
            handleCloseModal={() => this.closeActionModal()}
            modalSize={"large"}
            // loading={appStatusLogsLoading}
          >
            <AssignPaymentForApproval
              applicationId={this.state.activeAppId}
              closeActionModal={this.closeActionModal}
            ></AssignPaymentForApproval>
          </Modal>
          <Modal
            IsOpen={showRejectRecommendationModal}
            className={"esb-modal"}
            handleCloseModal={() => this.closeActionModal()}
            modalSize={"large"}
            // loading={appStatusLogsLoading}
          >
            <RejectRecommendation applicationId={this.state.activeAppId} closeActionModal={this.closeActionModal}></RejectRecommendation>
          </Modal>
          <Modal
            IsOpen={showWaitlistModal}
            className={"esb-modal"}
            handleCloseModal={() => this.closeActionModal()}
            modalSize={"large"}
            // loading={appStatusLogsLoading}
          >
            <MoveToWaitlisted applicationId={this.state.activeAppId} closeActionModal={this.closeActionModal}></MoveToWaitlisted>
          </Modal>
          <Modal
            IsOpen={showViewCustomerApplications}
            className={"esb-modal"}
            handleCloseModal={() => this.closeActionModal()}
            modalSize={"large"}
          >
            <CustomerApplications
              customerId={this.state.activeCustomerId}
              customerCategory={this.state.activeCustomerCategory}
              closeActionModal={this.closeActionModal}
              application={this.state.application}
            ></CustomerApplications>
          </Modal>
          <Modal
            IsOpen={showIncentivePaymentCompletedModal}
            className={"esb-modal"}
            HeaderText={"COMPLETE INCENTIVE PAYMENT"}
            handleCloseModal={() => this.closeActionModal()}
            modalSize={"small"}
          >
            {!IncentivePaymentCompletedSuccessfully ? (
              <React.Fragment>
                <p>
                  You are about to change status of Application ID #{activeAppNo} to
                  <br /> 'Incentive Payment Completed'
                </p>
                <p>Are you sure?</p>
                <div className="button-set">
                  <Button className="inversed-btn" title={"Cancel"} uppercase onClick={() => this.closeActionModal()} />
                  <Button title={"Complete Payment"} uppercase onClick={this.completeIncentivePayment} />
                </div>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <p>
                  Status changed for Application ID <span className="green-text">#{activeAppNo}.</span>
                </p>
              </React.Fragment>
            )}
          </Modal>
          <Modal
            HeaderText={`FLAG ${
              participantInfo && participantInfo.accountCategory && participantInfo.accountCategory.toUpperCase()
            } FOR QC`}
            className={"esb-modal"}
            IsOpen={showFlagModal}
            handleCloseModal={() => this.closeActionModal()}
          >
            <p>Are you sure you want to Flag the {participantInfo && participantInfo.accountCategory} for QC?</p>
            <div className="button-set">
              <Button className="inversed-btn" title={"YES"} uppercase onClick={this.flagApplicationForQaQc} />
              <Button title={"NO"} uppercase onClick={() => this.closeActionModal()} />
            </div>
          </Modal>
          <Modal
            HeaderText={`ASSIGN APPLICATION FOR REVIEW`}
            className={"esb-modal assign-reviewer-modal"}
            IsOpen={showAssignForReviewModal}
            loading={assigningReviewer}
            handleCloseModal={() => this.closeActionModal()}
          >
            {assignedSuccessfully ? (
              <p>Application assigned to reviewer successfully!</p>
            ) : (
              <React.Fragment>
                <p>Please select a reviewer for this application below</p>
                <Input
                  label={"Reviewer"}
                  placeholder={"Please select a reviewer"}
                  type="dropdown"
                  name="reviewer"
                  onChange={this.onReviewerChange}
                  value={firstReviewer ? firstReviewer : undefined}
                  options={reviewerList.map((reviewer) => {
                    return { label: `${reviewer.firstName} ${reviewer.lastName}`, value: reviewer.uid };
                  })}
                  isSearchable={false}
                />
                <div className="button-set">
                  <Button title={"CANCEL"} className="inversed-btn" uppercase onClick={() => this.closeActionModal()} />
                  <Button title={"CONFIRM"} disabled={firstReviewer ? false : true} uppercase onClick={this.assignApplicationForReview} />
                </div>
              </React.Fragment>
            )}
          </Modal>
          <Modal IsOpen={showSendChequeModal} className={"esb-modal"} handleCloseModal={() => this.closeActionModal()} modalSize={"large"}>
            <SendCheque applicationId={this.state.activeAppId} reload={this.searchData} closeActionModal={() => this.closeActionModal()} />
          </Modal>
          <Modal IsOpen={showAppStateModal} className={"esb-modal"} handleCloseModal={() => this.closeActionModal()} modalSize={"large"}>
            <AppState applicationId={this.state.activeAppId} reload={this.searchData} closeActionModal={() => this.closeActionModal()} />
          </Modal>
          <Modal IsOpen={showPortCustomer} className={"esb-modal"} handleCloseModal={() => this.closeActionModal()} modalSize={"large"}>
            <PortCustomer
              applicationId={this.state.activeAppId}
              reload={this.searchData}
              closeActionModal={() => this.closeActionModal()}
            />
          </Modal>
          <Modal
            IsOpen={showStatusLogsModal}
            className={"esb-modal"}
            handleCloseModal={() => this.closeActionModal()}
            modalSize={"large"}
            loading={appStatusLogsLoading}
          >
            <StatusLogs applicationId={this.state.activeAppId} activeStatusLogs={activeStatusLogs} />
          </Modal>
          <Modal
            skipTopBit={true}
            IsOpen={showRetrofitInternalStatusLogsModal}
            className={"esb-modal"}
            handleCloseModal={() => this.closeActionModal()}
            modalSize={"large"}
            applicationId={this.state.applicationId}
          >
            <AppFlagLogs
              appFlagsLoading={appFlagsLoading}
              FlagLogs={flagLogs}
              reload={this.searchData}
              closeActionModal={this.closeActionModal}
              application={this.state.application}
              applicationId={this.state.activeAppId}
            />
          </Modal>
          <Modal
            HeaderText={""}
            IsOpen={showChangeRequestsModal}
            className={"esb-modal"}
            handleCloseModal={() => this.closeActionModal()}
            modalSize={"large"}
          >
            <ViewChangeRequests
              applicationId={this.state.activeAppId}
              activeChangeRequests={activeChangeRequests}
              activeChangeReqLogs={activeChangeReqLogs}
            />
          </Modal>
          <Modal
            HeaderText={""}
            IsOpen={showChangeRequestLogsModal}
            className={"esb-modal"}
            handleCloseModal={() => this.closeActionModal()}
            modalSize={"large"}
            loading={appChangeReqLogsLoading}
          >
            <ViewChangeRequestLogs applicationId={this.state.activeAppId} />
          </Modal>
          <Modal
            skipTopBit={true}
            IsOpen={showNotesModal}
            className={"esb-modal"}
            handleCloseModal={() => this.closeActionModal()}
            modalSize={"large"}
            applicationId={this.state.applicationId}
          >
            <ApplicationNotes
              application={this.state.application}
              applicationId={this.state.activeAppId}
              adminUserInfo={this.state.adminUserInfo}
            />
          </Modal>
          <Modal
            skipTopBit={true}
            IsOpen={showReviewDocumentsModal}
            className={"esb-modal"}
            handleCloseModal={() => this.closeActionModal()}
            modalSize={"large"}
            applicationId={this.state.applicationId}
          >
            <ReviewDocuments
              application={this.state.application}
              applicationId={this.state.activeAppId}
              adminUserInfo={this.state.adminUserInfo}
            />
          </Modal>
          <Modal
            skipTopBit={true}
            IsOpen={showFlagLogsModal}
            className={"esb-modal"}
            handleCloseModal={() => this.closeActionModal()}
            modalSize={"large"}
            applicationId={this.state.applicationId}
          >
            <AppFlagLogs
              applicationId={this.state.activeAppId}
              reload={this.searchData}
              closeActionModal={this.closeActionModal}
              FlagLogs={flagLogs}
            />
          </Modal>
          <Modal
            skipTopBit={true}
            IsOpen={showMeasureLogsModal}
            className={"esb-modal"}
            handleCloseModal={() => this.closeActionModal()}
            modalSize={"large"}
            applicationId={this.state.applicationId}
          >
            <AppMeasureLogs applicationId={this.state.activeAppId} MeasureLogs={measureLogs} />
          </Modal>

          <Modal modalSize={"large"} className={"esb-modal"} IsOpen={showAddFlagModal}>
            <FlagApplication
              applicationId={this.state.activeAppId}
              reload={this.searchData}
              closeActionModal={this.closeActionModal}
            ></FlagApplication>
          </Modal>
        </React.Fragment>
        <div id="width" className="app-report">
          <div className="report-head">
            <p className="head">Application Report</p>
            {currentProgram === "esb" ? (
              <ClickAwayListener onClickAway={() => this.setState({ openChangeProgramMenu: false })}>
                <div className="program-select">
                  <div className="select-box" onClick={() => this.handleOpenProgramChangeMenu(openChangeProgramMenu)}>
                    <div className="selected-opt">{selectedProgramReports}</div>
                    <DownArrow width="12" height="6" />
                  </div>
                  <div className={`expand-profile-menu ${openChangeProgramMenu ? "profile-menu-open" : ""}`}>
                    <div className="expanded-menu-panel">
                      <div
                        onClick={() => {
                          this.setState({ selectedProgramReports: "ALL", openChangeProgramMenu: false });
                        }}
                      >
                        ALL
                      </div>
                      <div
                        onClick={() => {
                          this.setState({ selectedProgramReports: "ESB", openChangeProgramMenu: false });
                        }}
                      >
                        ESB
                      </div>
                      <div
                        onClick={() => {
                          this.setState({ selectedProgramReports: "CES", openChangeProgramMenu: false });
                        }}
                      >
                        CES
                      </div>
                    </div>
                  </div>
                </div>
              </ClickAwayListener>
            ) : currentProgram === "retrofit" ? (
              <ClickAwayListener onClickAway={() => this.setState({ openChangeProgramMenu: false })}>
                <div className="program-select">
                  <div className="select-box" onClick={() => this.handleOpenProgramChangeMenu(openChangeProgramMenu)}>
                    <div className="selected-opt">{selectedProgramReports}</div>
                    <DownArrow width="12" height="6" />
                  </div>
                  <div className={`expand-profile-menu ${openChangeProgramMenu ? "profile-menu-open" : ""}`}>
                    <div className="expanded-menu-panel">
                      <div
                        onClick={() => {
                          this.setState({ selectedProgramReports: "ALL", openChangeProgramMenu: false });
                        }}
                      >
                        ALL
                      </div>
                      <div
                        onClick={() => {
                          this.setState({ selectedProgramReports: "BOTH", openChangeProgramMenu: false });
                        }}
                      >
                        BOTH
                      </div>
                      <div
                        onClick={() => {
                          this.setState({ selectedProgramReports: "CUSTOM", openChangeProgramMenu: false });
                        }}
                      >
                        CUSTOM
                      </div>
                      <div
                        onClick={() => {
                          this.setState({ selectedProgramReports: "PRESCRIPTIVE", openChangeProgramMenu: false });
                        }}
                      >
                        PRESCRIPTIVE
                      </div>
                    </div>
                  </div>
                </div>
              </ClickAwayListener>
            ) : null}
          </div>
          <CardSlider cards={cards} />
        </div>
        <div className="horizontal-bar"></div>
        <React.Fragment>
          {currentProgram !== "retrofit" ? (
            <Tabs onTabChange={this.onTabChange}>
              <div label="Pre-Project">
                <PreProject
                  SelectedStatusFilters={this.state["Pre-ProjectFilters"].SelectedStatusFilters}
                  SelectedFlagFilters={this.state["Pre-ProjectFilters"].SelectedFlagFilters}
                  searchInput={this.state["Pre-ProjectFilters"].searchInput}
                  sortConfig={this.state["Pre-ProjectFilters"].sortConfig}
                  onlyActiveApps={this.state["Pre-ProjectFilters"].onlyActiveApps}
                  setFilters={this.setFilters}
                  showActionModal={this.showActionModal}
                />
              </div>
              <div label="Pre-Approval">
                <PreApprovalNotice
                  SelectedStatusFilters={this.state["Pre-ApprovalFilters"].SelectedStatusFilters}
                  SelectedFlagFilters={this.state["Pre-ApprovalFilters"].SelectedFlagFilters}
                  searchInput={this.state["Pre-ApprovalFilters"].searchInput}
                  sortConfig={this.state["Pre-ApprovalFilters"].sortConfig}
                  onlyActiveApps={this.state["Pre-ApprovalFilters"].onlyActiveApps}
                  setFilters={this.setFilters}
                  showActionModal={this.showActionModal}
                />
              </div>
              <div label="Post-Project">
                <PostProject
                  SelectedStatusFilters={this.state["Post-ProjectFilters"].SelectedStatusFilters}
                  SelectedFlagFilters={this.state["Post-ProjectFilters"].SelectedFlagFilters}
                  searchInput={this.state["Post-ProjectFilters"].searchInput}
                  sortConfig={this.state["Post-ProjectFilters"].sortConfig}
                  onlyActiveApps={this.state["Post-ProjectFilters"].onlyActiveApps}
                  setFilters={this.setFilters}
                  showActionModal={this.showActionModal}
                />
              </div>
              <div label="Payment">
                <Payment
                  SelectedStatusFilters={this.state["PaymentFilters"].SelectedStatusFilters}
                  SelectedFlagFilters={this.state["PaymentFilters"].SelectedFlagFilters}
                  searchInput={this.state["PaymentFilters"].searchInput}
                  sortConfig={this.state["PaymentFilters"].sortConfig}
                  setFilters={this.setFilters}
                  showActionModal={this.showActionModal}
                />
              </div>
            </Tabs>
          ) : (
            <Tabs onTabChange={this.onTabChange}>
              <div label="Pre-Project">
                <PreProject
                  SelectedStatusFilters={this.state["Pre-ProjectFilters"].SelectedStatusFilters}
                  SelectedFlagFilters={this.state["Pre-ProjectFilters"].SelectedFlagFilters}
                  StageFilters={this.state["Pre-ProjectFilters"].StageFilters}
                  searchInput={this.state["Pre-ProjectFilters"].searchInput}
                  sortConfig={this.state["Pre-ProjectFilters"].sortConfig}
                  onlyActiveApps={this.state["Pre-ProjectFilters"].onlyActiveApps}
                  setFilters={this.setFilters}
                  showActionModal={this.showActionModal}
                />
              </div>
              <div label="Post-Project">
                <PostProject
                  SelectedStatusFilters={this.state["Post-ProjectFilters"].SelectedStatusFilters}
                  SelectedFlagFilters={this.state["Post-ProjectFilters"].SelectedFlagFilters}
                  StageFilters={this.state["Post-ProjectFilters"].StageFilters}
                  searchInput={this.state["Post-ProjectFilters"].searchInput}
                  sortConfig={this.state["Post-ProjectFilters"].sortConfig}
                  onlyActiveApps={this.state["Post-ProjectFilters"].onlyActiveApps}
                  setFilters={this.setFilters}
                  showActionModal={this.showActionModal}
                />
              </div>
              <div label="Payment">
                <Payment
                  SelectedStatusFilters={this.state["PaymentFilters"].SelectedStatusFilters}
                  SelectedFlagFilters={this.state["PaymentFilters"].SelectedFlagFilters}
                  StageFilters={this.state["PaymentFilters"].StageFilters}
                  searchInput={this.state["PaymentFilters"].searchInput}
                  sortConfig={this.state["PaymentFilters"].sortConfig}
                  setFilters={this.setFilters}
                  showActionModal={this.showActionModal}
                />
              </div>
              <div label="Cancelled">
                <Cancelled
                  SelectedStatusFilters={this.state["CancelledFilters"].SelectedStatusFilters}
                  SelectedFlagFilters={this.state["CancelledFilters"].SelectedFlagFilters}
                  searchInput={this.state["CancelledFilters"].searchInput}
                  sortConfig={this.state["CancelledFilters"].sortConfig}
                  setFilters={this.setFilters}
                  showActionModal={this.showActionModal}
                />
              </div>
            </Tabs>
          )}
        </React.Fragment>
      </ProgramLayout>
    );
  }
}

function mapState(state) {
  const { adminUserInfo, currentProgram } = state.authentication;
  const { configData } = state.settings;
  const {
    applicationsByStageLoading,
    appStatusLogsLoading,
    activeStatusLogs,
    appListOffset,
    activeChangeRequests,
    activeChangeReqLogs,
    appChangeReqLogsLoading,
    appNotesLoading,
    flagLogs,
    appFlagsLoading,
    measureLogs,
  } = state.application;
  return {
    currentProgram,
    adminUserInfo,
    applicationsByStageLoading,
    appStatusLogsLoading,
    activeStatusLogs,
    appListOffset,
    activeChangeRequests,
    activeChangeReqLogs,
    appChangeReqLogsLoading,
    appNotesLoading,
    flagLogs,
    appFlagsLoading,
    measureLogs,
    configData,
  };
}

const actionCreators = {
  setActiveProgram: userActions.setActiveProgram,
  loadAdminConfig: adminSettingsActions.getAdminConfig,
  loadAdminConfigRetrofit: adminSettingsActions.getAdminConfigRetrofit,
  getApplicationsByStage: appActions.getApplicationsByStage,
  getApplicationsByStageRetrofit: appActions.getApplicationsByStageRetrofit,
  getLogs: appActions.getApplicationsStatusLogs,
  getChangeRequests: appActions.getApplicationChangeRequests,
  getChangeRequestLogs: appActions.getApplicationChangeRequestLogs,
  getFlagLogs: appActions.getFlagLogs,
  getRetrofitFlagLogs: appActions.getRetrofitFlagLogs,
  getMeasureLogs: appActions.getMeasureLogs,
  loadUserDetails: userActions.getUserDetails,
};

export default connect(mapState, actionCreators)(ApplicationsBoard);

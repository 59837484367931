import React from 'react';
import Button from '../../Common/Button/Button'
import Input from '../../Common/Input/Input'
import './AdminAccountVerification.css';
import { connect } from 'react-redux';
import { userActions } from '../../../redux/actions/userActions';
import AdminLayout from '../../Common/AdminLayout';
import InfoVector from '../../../images/InfoVector.svg';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';

const HtmlTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: '#048041',
    color: '#FFF',
    maxWidth: 220
  },
  arrow: {
    color: '#048041',
  }
}))(Tooltip);

class AdminAccountVerification extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      resendSMS: false,
      MobileNumber: '964-002-3662'
    }
  }

  goToDashboard = (path) => {
    this.props.history.push(path);
  }

  resendSMS = () => {
    this.setState({
      resendSMS: true
    })
  }

  onChangeHandler = () => {
    //Do something
  }

  render() {
    const { resendSMS, MobileNumber } = this.state
    return (
      <AdminLayout showHeader={false} showSideBarMenu={false}>
        <div className="admin-verify-container">
          <div className="logo"></div>
          <div className="section-header">
            <h2 className="section-title">Program Management Dashboard</h2>
          </div>
          <div className="container-box">
            <div className="section-overview">
              <h3>{resendSMS ? 'Authorisation Code Sent!' : 'Account Verification'}</h3>
              {resendSMS ? <p>A new authorisation code has been generated and sent to your registered mobile number {MobileNumber}. <br />Once you have received the authorisation code, please enter it below and click <b>Verify</b></p> :
                <p>To prevent abuse, we require you to verify your account through the use of SMS. <br />An authorisation code has been generated and sent to your registered mobile number {MobileNumber}</p>}
            </div>
            <div className="form-section">
              <Input
                onChange={() => this.onChangeHandler}
                placeholder={'Enter Authorisation Code'}
                type={'password'}
              />
              <div className="resend-links">Resend authorisation code?  <span onClick={() => this.resendSMS()} >click here</span>
                <HtmlTooltip
                  title={<React.Fragment>
                    {'Most carriers deliver the verification code within 10 seconds. If you do not receive the code within five minutes, please try again or contact'} <a href="mailto:help@enerva.ca">{'help@enerva.ca'}</a> {'for assistance.'}
                  </React.Fragment>}
                  placement="right" arrow>
                  <span>
                    <img src={InfoVector} alt='Info' />
                  </span>
                </HtmlTooltip>
              </div>
            </div>
            <Button
              className='verify-btn'
              title={'Verify'}
              large
              uppercase
              onClick={() => this.goToDashboard('/esb/participant-board')}
            />
          </div>
        </div>
      </AdminLayout>
    )
  }
}

function mapState(state) {
  return state;
}

const actionCreators = {
  showModal: userActions.showRegisterModal
}

export default connect(mapState, actionCreators)(AdminAccountVerification);

import React from "react";
import './ButtonSelection.css';

const ButtonSelection = (props) => {
    return (
        <div className={props.className} >
            <div className="button-selection-list">
                {props.options.map((option, i) => (
                    <div className="btn-selection" id={`btn-sel-${i}`}>
                        <label>
                            <input type="checkbox"
                                onClick={() => {
                                    props.onChange(option);
                                }}
                                value="1"
                                checked={props.value === option}
                            /><span>{option}</span>
                        </label>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default ButtonSelection;